import { Formik, useFormik } from "formik";
import React, { useState } from "react";
//import { Button, Form, Input, Label, Segment } from "semantic-ui-react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { Button as ButtonAnt } from "antd";
import * as Yup from "yup";
import { useSecciones } from "../../../../hooks";

import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import { useAuth } from "../../../../hooks";
import { alertas } from "../../../Comons/Alertas/alertas";

export function AddEditSeccionesPrincipalForm(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, Secciones } = props;
  const { auth } = useAuth();
  const { addSeccionesPrincipal, updateSeccionesPrincipal } = useSecciones();
  const [fileName, setFileName] = useState(null);
  const [dataFile, setDataFile] = useState(null);
  const [formData, setFormData] = useState(initialValues(Secciones, []));

  const handleFile = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const Worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(Worksheet);
    setDataFile(jsonData);
    setFormData({ ...formData, data_secciones: jsonData });
  };

  const handleDataSecciones = (data) => {
    setFormData({ ...formData, data_secciones: data });
  };

  const handleDownload = () => {
    // Datos del encabezado
    const data = [
      { "Nivel": "", "Volumen": "" },
    ];

    // Convertir los datos en formato de hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Crear un libro de trabajo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");

    // Exportar como archivo Excel
    XLSX.writeFile(workbook, "seccion_principal.xlsx");
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: Yup.object(
      Secciones ? updateSchame() : newSchame()
    ),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        const finalDataSecciones = [...(dataFile || []),
        ...formik.values.data_secciones,
        ];
        const dataToSend = { ...formValue, data_secciones: finalDataSecciones };
        if (Secciones) await updateSeccionesPrincipal(Secciones.id_secciones, dataToSend);
        else await addSeccionesPrincipal(dataToSend);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const divStyle = {
    textAlign: "left",
  };

  const renderUnidadLongitud = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      return `${auth.me.SistemasMedicion.longitud} `;
    }
    return "No especificado";
  };

  const renderUnidadVolumen = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.volumen) {
      return `${auth.me.SistemasMedicion.volumen} `;
    }
    return "No especificado";
  };

  return (
    <>
      <Formik>
        <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                required
                label="Nombre de la seccion"
                name="nombre_secciones"
                placeholder="Nombre de seccion"
                value={formik.values.nombre_secciones}
                onChange={formik.handleChange}
                error={formik.errors.nombre_secciones}
              />
            </Grid>

              <Grid item xs={12} sm={12}>
                {fileName && (
                  <p>
                    FileName: <span>{fileName}</span>
                  </p>
                )}
                <input type="file" onChange={(e) => handleFile(e)} />
              </Grid>

              <Grid item xs={12} sm={12}>
                <ButtonAnt type="primary" onClick={handleDownload}>
                  Descargar formato
                </ButtonAnt>
              </Grid>

            <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" gutterBottom>
                  Secciones
                </Typography>
              </Grid>
              <br/>

              {formik.values.data_secciones.map((variable, index) => (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={5.5}>
                      <TextField
                        fullWidth
                        required
                        label={`Nivel ${index + 1}: ` + renderUnidadLongitud()}
                        name={`data_secciones[${index}].Nivel`}
                        placeholder={"Nivel " + renderUnidadLongitud()}
                        value={variable.Nivel}
                        onChange={formik.handleChange}
                        error={formik.errors[`data_secciones[${index}].Nivel`]}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={5.5}>
                      <TextField
                        fullWidth
                        required
                        label={`Valor de variable: ` + renderUnidadVolumen()}
                        name={`data_secciones[${index}].Volumen`}
                        placeholder={"Volumen " + renderUnidadVolumen()}
                        value={variable.Volumen}
                        onChange={formik.handleChange}
                        error={formik.errors[`data_secciones[${index}].Volumen`]}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <Tooltip title="Eliminar" arrow>
                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{
                            height: '100%', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0',
                            margin: '0',
                            color: 'rgba(252, 0, 0, 0.8)',
                            border: '1px solid rgba(252, 0, 0, 0.8)',
                          }}
                          onClick={() =>
                            formik.setFieldValue(
                              "data_secciones",
                              formik.values.data_secciones.filter((_, i) => i !== index)
                            )
                          }
                        >
                          <DeleteIcon style={{ margin: '0' }} />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <br />
                </>
              ))}
              <br />
              <Tooltip title="Agregar secciones" arrow>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  style={{
                    color: 'rgb(25, 118, 210)',
                    border: '1px solid rgb(25, 118, 210)',
                    '&:hover': {
                      color: 'rgba(221, 223, 221, 0.62)',
                      border: '1px solid rgba(221, 223, 221, 0.62)',
                    }
                  }}
                  onClick={() =>
                    formik.setFieldValue("data_secciones", [
                      ...formik.values.data_secciones,
                      { Nivel: "", Volumen: "" },
                    ])
                  }
                >
                  <AddIcon />
                </Button>
              </Tooltip>
              <br />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
                {Secciones ? "Actualizar" : "Crear"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Formik>
    </>
  );
}

export function AddEditSeccionesFondoForm(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, seccionesFondo } = props;
  const { auth } = useAuth();
  const { addSeccionesFondo, updateSeccionesFondo } = useSecciones();
  const [fileName, setFileName] = useState(null);
  const [dataFile, setDataFile] = useState(null);
  const [formData, setFormData] = useState(initialValues(seccionesFondo, []));

  const handleFile = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const Worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(Worksheet);
    setDataFile(jsonData);
    setFormData({ ...formData, data_secciones: jsonData });
  };

  const handleDataSecciones = (data) => {
    setFormData({ ...formData, data_secciones: data });
  };

  const handleDownload = () => {
    // Datos del encabezado
    const data = [
      { "Nivel": "", "Volumen": "", "Incremento": "" },
    ];

    // Convertir los datos en formato de hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Crear un libro de trabajo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");

    // Exportar como archivo Excel
    XLSX.writeFile(workbook, "seccion_fondo.xlsx");
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: Yup.object(
      seccionesFondo ? updateSchame() : newSchame()
    ),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        const finalDataSecciones = [...(dataFile || []),
        ...formik.values.data_secciones,
        ];
        const dataToSend = { ...formValue, data_secciones: finalDataSecciones };
        if (seccionesFondo) await updateSeccionesFondo(seccionesFondo.id_secciones, dataToSend);
        else await addSeccionesFondo(dataToSend);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const divStyle = {
    textAlign: "left",
  };

  const renderUnidadLongitud = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      return `${auth.me.SistemasMedicion.longitud} `;
    }
    return "No especificado";
  };

  const renderUnidadVolumen = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.volumen) {
      return `${auth.me.SistemasMedicion.volumen} `;
    }
    return "No especificado";
  };

  return (
    <>
      <Formik>
        <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                required
                label="Nombre de la seccion"
                name="nombre_secciones"
                placeholder="Nombre de seccion"
                value={formik.values.nombre_secciones}
                onChange={formik.handleChange}
                error={formik.errors.nombre_secciones}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              {fileName && (
                <p>
                  FileName: <span>{fileName}</span>
                </p>
              )}
              <input type="file" onChange={(e) => handleFile(e)} />
            </Grid>

            <Grid item xs={12} sm={12}>
              <ButtonAnt type="primary" onClick={handleDownload}>
                Descargar formato
              </ButtonAnt>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h5" gutterBottom>
                  Secciones fondo
                </Typography>
              </Grid>
              <br />

              {formik.values.data_secciones.map((variable, index) => (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3.5}>
                      <TextField
                        fullWidth
                        required
                        label={`Nivel ${index + 1}: ` + renderUnidadLongitud()}
                        name={`data_secciones[${index}].Nivel`}
                        placeholder={"Nivel " + renderUnidadLongitud()}
                        value={variable.Nivel}
                        onChange={formik.handleChange}
                        error={formik.errors[`data_secciones[${index}].Nivel`]}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3.5}>
                      <TextField
                        fullWidth
                        required
                        label={`Valor de variable: ` + renderUnidadVolumen()}
                        name={`data_secciones[${index}].Volumen`}
                        placeholder={"Volumen " + renderUnidadVolumen()}
                        value={variable.Volumen}
                        onChange={formik.handleChange}
                        error={formik.errors[`data_secciones[${index}].Volumen`]}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3.5}>
                      <TextField
                        fullWidth
                        required
                        label={`Incremento: ` + renderUnidadLongitud() + "/" + renderUnidadVolumen()}
                        name={`data_secciones[${index}].Incremento`}
                        placeholder={"Incremento " + renderUnidadLongitud() + "/" + renderUnidadVolumen()}
                        value={variable.Incremento}
                        onChange={formik.handleChange}
                        error={formik.errors[`data_secciones[${index}].Incremento`]}
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={12} sm={1}>
                      <Tooltip title="Eliminar" arrow>
                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{
                            height: '100%', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0',
                            margin: '0',
                            color: 'rgba(252, 0, 0, 0.8)',
                            border: '1px solid rgba(252, 0, 0, 0.8)',
                          }}
                          onClick={() =>
                            formik.setFieldValue(
                              "data_secciones",
                              formik.values.data_secciones.filter((_, i) => i !== index)
                            )
                          }
                        >
                          <DeleteIcon style={{ margin: '0' }} />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <br />
                </>
              ))}
              <br />
              <Tooltip title="Agregar secciones" arrow>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  style={{
                    color: 'rgb(25, 118, 210)',
                    border: '1px solid rgb(25, 118, 210)',
                    '&:hover': {
                      color: 'rgba(221, 223, 221, 0.62)',
                      border: '1px solid rgba(221, 223, 221, 0.62)',
                    }
                  }}
                  onClick={() =>
                    formik.setFieldValue("data_secciones", [
                      ...formik.values.data_secciones,
                      { Nivel: "", Volumen: "", Incremento: "" },
                    ])
                  }
                >
                  <AddIcon />
                </Button>
              </Tooltip>
              <br />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
                {seccionesFondo ? "Actualizar" : "Crear"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Formik>
    </>
  );
}

function initialValues(data, dataFile) {


  return {
    nombre_secciones: data?.nombre_secciones || "",
    data_secciones: data?.data_secciones || [],
  };
}

function newSchame() {
  return {
    nombre_secciones: Yup.string().required(true),
    // data_secciones: Yup.object({
    //   Nivel: Yup.string().required(true),
    //   Volumen: Yup.number().required(true),
    //   Incremento: Yup.number(),
    // }),
  };
}

function updateSchame() {
  return {
    nombre_secciones: Yup.string().required(true),
    // data_secciones: Yup.object({
    //   Nivel: Yup.string().required(true),
    //   Volumen: Yup.number().required(true),
    //   Incremento: Yup.number(),
    // }),
  };
}
