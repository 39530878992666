
import { CaretRightOutlined } from '@ant-design/icons';
import { Box } from "@mui/material";
import { Collapse, Descriptions } from 'antd';
import { DataCard } from "../../../../components/DataCard";
import {
  ContainerData
} from "../../../../components/DataCard/styles";

import styled from 'styled-components';

const { Panel } = Collapse;

const StyledLabel = styled.span`
  font-weight: bold;
`;

const StyledDescriptionItem = styled(Descriptions.Item)`
  border: 1px solid #e8e8e8;
 
`;


export const calculatePercentage = (numeratorKey, denominatorKey, brazo, data) => {
    const numerator = data?.read_reg?.[brazo]?.[numeratorKey];
    const denominator = data?.read_reg?.[brazo]?.[denominatorKey];
    if (
      numerator !== undefined &&
      denominator !== undefined &&
      denominator !== 0
    ) {
      return ((1 - numerator / denominator) * 100).toFixed();
    } else {
      return 0;
    }
  };
  
export  const DataComponent = (props) => {
    
    const {
      dataObject,
      NBrazo,
      IV,
      GSV,
      VOLP,
      GOV,
      NSV,
      VOLR,
      TEMP,
      FLOW,
      DENS,
      PRES,
      CTL,
      CPL,
      CTPL,
      API,
      BSW,
    } = props;
  
    return (
      <Box role="tabpanel" hidden={NBrazo !== props.activeTab}>
        <DataCard
          data={dataObject}
          title={`Datos Observados Brazo ${NBrazo}`}
          dataOneTitle="Vol.indicado"
          dataOneInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[IV]}
          dataOneUnit="gal"
          dataTwoTitle="GSV"
          dataTwoInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[GSV]}
          dataTwoUnit="gal"
          dataThreeTitle="v.preset"
          dataThreeInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[VOLP]}
          dataThreeUnit="gal"
          dataFourTitle="Gov"
          dataFourInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[GOV]}
          dataFourUnit="gal"
          dataFiveTitle="Nsv"
          dataFiveInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[NSV]}
          dataFiveUnit="gal"
          dataSixTitle="v.restante"
          dataSixInfo={(dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[VOLP])-(dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[GOV])}
          dataSixUnit="gal"
        />
  
        <DataCard
          title={`Datos de campo Brazo ${NBrazo}`}
          dataOneTitle="Temperatura"
          dataOneInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[TEMP]}
          dataOneUnit="°F"
          dataTwoTitle="Flujo"
          dataTwoInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[FLOW]}
          dataTwoUnit="gal/m"
          dataThreeTitle="Densidad"
          dataThreeInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[DENS]}
          dataThreeUnit="Kg/m3"
          dataFourTitle="Presión"
          dataFourInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[PRES]}
          dataFourUnit="Psi"
        />
  
      </Box>
    );
  };

  export const DataComponentFactores = (props) => {
    const {
      dataObject,
      NBrazo,
      BSW,
      activeTab
    } = props;
  
    const renderDataCards = () => {
      const dataCards = [];
  
      for (let i = 1; i <= 6; i++) { // Puedes ajustar esto según el número máximo de componentes
        const componentKey = `C${i}`;
        const hasComponentData = dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[`CTL_RT_${componentKey}_B${NBrazo}`] !== undefined;
  
        if (hasComponentData) {
          dataCards.push(
            <DataCard
              key={`Componente_${i}`}
              title={`Factores de corrección Brazo ${NBrazo} Componente ${i}`}
              dataOneTitle="CTL"
              dataOneInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[`CTL_RT_${componentKey}_B${NBrazo}`]}
              dataTwoTitle="CPL"
              dataTwoInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[`CPL_RT_${componentKey}_B${NBrazo}`]}
              dataThreeTitle="CTPL"
              dataThreeInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[`CTPL_RT_${componentKey}_B${NBrazo}`]}
              dataFourTitle="Gravedad Api"
              dataFourInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[`GAPI_AV_${componentKey}_B${NBrazo}`]}
              dataFiveTitle="Bsw (%)"
              dataFiveInfo={dataObject?.read_reg?.[`Brazo_${NBrazo}`]?.[BSW]}
            />
          );
        }
      }
  
      return dataCards;
    };
  
    return (
      <Box role="tabpanel" hidden={NBrazo !== activeTab}>
        {renderDataCards()}
      </Box>
    );
  };
  



  export const DataExtra = (props) => {
    const { brazo, dataObject, toggleCollapse, collapsed } = props;
  
    // Función para generar los DescriptionItems de un medidor
    const renderDescriptionItems = (medidor) => {
      const keys = [
        'IV_DEV',
        'GOV_RT',
        'VOLP_RT',
        'FLOW_RT',
        'TEMP_RT',
        'PRES_RT',
        'DENS_RT'
      ];
  
      return keys.map((key) => {
        const value = dataObject?.read_reg?.[`Brazo_${brazo}`]?.[`MTR${medidor}_${key}_B${brazo}`];
        if (!value) return null; // No renderizar si el valor está vacío
        return (
          <StyledDescriptionItem key={key} label={<StyledLabel>{key}</StyledLabel>}>
            {value}
          </StyledDescriptionItem>
        );
      });
    };
  
    return (
      <Collapse bordered={false} activeKey={collapsed ? [] : ['1']}>
        <Panel
          header={<StyledLabel>Detalles Medidores</StyledLabel>}
          onClick={toggleCollapse}
          key="1"
          extra={<CaretRightOutlined onClick={toggleCollapse} />}
          style={{ borderRadius: '5px', marginBottom: '16px', border: 'none' }}
        >
          {[1, 2, 3, 4, 5].map((medidor) => {
            const descriptionItems = renderDescriptionItems(medidor);
            if (descriptionItems && descriptionItems.some(item => !!item)) { // Renderizar solo si hay al menos un DescriptionItem
              return (
                <ContainerData key={medidor}>
                  <Descriptions title={<StyledLabel>Medidor {medidor}</StyledLabel>}>
                    {descriptionItems}
                  </Descriptions>
                </ContainerData>
              );
            }
            return null;
          })}
        </Panel>
      </Collapse>
    );
  };
  