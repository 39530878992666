import React, { useEffect } from 'react';
import { useTransaccionesDescargadero } from '../../../../hooks';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import moment from 'moment';

export  function VistaTransaccionesDescargadero(props) {
  const {Transacciones}=props;
  const { retriveTransaccionesDescargadero, TransaccionesDescargaderoRTR } = useTransaccionesDescargadero();
  useEffect(() => {
      retriveTransaccionesDescargadero(Transacciones.n_orden.toString());
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fecha Creación</TableCell>
            <TableCell>Volumen Restante</TableCell>
            <TableCell>Volumen Entregado</TableCell>
            <TableCell>Volumen GOV</TableCell>
            <TableCell>Volumen GSV</TableCell>
            <TableCell>NSV</TableCell>
            <TableCell>IV</TableCell>
            <TableCell>Número Bahía</TableCell>
            <TableCell>Presión Promedio</TableCell>
            <TableCell>Temperatura Promedio</TableCell>
            <TableCell>Densidad Promedio</TableCell>
            <TableCell>Factor Medidor</TableCell>
            <TableCell>MK Factor</TableCell>
            <TableCell>CTL</TableCell>
            <TableCell>CPL</TableCell>
            <TableCell>CTPL</TableCell>
            <TableCell>BSW</TableCell>
            <TableCell>Gravedad API</TableCell>
            <TableCell>Número Transacción</TableCell>
            <TableCell>Totalizador Inicial</TableCell>
            <TableCell>Totalizador Final</TableCell>
            <TableCell>Fecha Inicio Cargue</TableCell>
            <TableCell>Fecha Fin Cargue</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {TransaccionesDescargaderoRTR?.map((transaccion) => (
            <TableRow key={transaccion?.id_transaccion}>
              <TableCell>{moment(transaccion?.time_created).format('MM-DD-YYYY')}</TableCell>
              <TableCell>{transaccion?.v_restante}</TableCell>
              <TableCell>{transaccion?.v_entregado}</TableCell>
              <TableCell>{transaccion?.vol_gov}</TableCell>
              <TableCell>{transaccion?.vol_gsv}</TableCell>
              <TableCell>{transaccion?.nsv}</TableCell>
              <TableCell>{transaccion?.iv}</TableCell>
              <TableCell>{transaccion?.num_bahia}</TableCell>
              <TableCell>{transaccion?.pres_promedio}</TableCell>
              <TableCell>{transaccion?.temp_promedio}</TableCell>
              <TableCell>{transaccion?.densidad_promedio}</TableCell>
              <TableCell>{transaccion?.meter_factor}</TableCell>
              <TableCell>{transaccion?.mk_factor}</TableCell>
              <TableCell>{transaccion?.ctl}</TableCell>
              <TableCell>{transaccion?.cpl}</TableCell>
              <TableCell>{transaccion?.ctpl}</TableCell>
              <TableCell>{transaccion?.bsw}</TableCell>
              <TableCell>{transaccion?.gravedad_api}</TableCell>
              <TableCell>{transaccion?.num_transaccion}</TableCell>
              <TableCell>{(parseInt(transaccion?.totalizer_init, 10) / 100).toFixed(2)}</TableCell>
              <TableCell>{(parseInt(transaccion?.totalizer_end, 10) / 100).toFixed(2)}</TableCell>
              <TableCell>{moment(transaccion?.fecha_initcargue).format('MM-DD-YYYY HH:MM')}</TableCell>
              <TableCell>{moment(transaccion?.fecha_endcargue).format('MM-DD-YYYY HH:MM')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


