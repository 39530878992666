import React, { useState } from "react";
import { Tab } from 'semantic-ui-react';

import { GruposPermisosAdmin } from '../../Admin/GruposPermisosAdmin/GruposPermisosAdmin';
import { Validaciones } from '../AdminBasePage/Validaciones';
import { ControlDocumentalAdmin } from '../ControlDocumentalPage/ControlDocumentalAdmin';
import { TankCustVerAdm } from '../VerificacionesPage/TankCustVerAdm';
import { VehCustVerAdm } from '../VerificacionesPage/VehCustVerAdm';
import { ParametrizacionDecimalesBseAdmin } from "../AdminBasePage/ParametrizacionDecimalesBseAdmin";

export function GroupedClientPages() {
  const [subActiveTab, setSubActiveTab] = useState(0);

  const handleSubTabChange = (e, { activeIndex }) => {
    setSubActiveTab(activeIndex);
  };

  const panes = [
    {
      menuItem: 'Permisos',
      render: () => <Tab.Pane><GruposPermisosAdmin /></Tab.Pane>,
    },
    {
      menuItem: 'Decimales',
      render: () => <Tab.Pane><ParametrizacionDecimalesBseAdmin/></Tab.Pane>,
    },
    {
      menuItem: 'Validaciones',
      render: () => <Tab.Pane><Validaciones /></Tab.Pane>,
    },
    {
      menuItem: 'Documentos',
      render: () => <Tab.Pane><ControlDocumentalAdmin /></Tab.Pane>,
    },
    {
      menuItem: 'Inspecciones tanque y vehículo',
      render: () => (
        <Tab.Pane>
          <Tab 
            panes={[
              {
                menuItem: 'Tanques',
                render: () => <Tab.Pane><TankCustVerAdm /></Tab.Pane>,
              },
              {
                menuItem: 'Vehículos',
                render: () => <Tab.Pane><VehCustVerAdm /></Tab.Pane>,
              },
            ]}
            activeIndex={subActiveTab}
            onTabChange={handleSubTabChange}
          />
        </Tab.Pane>
      ),
    },
  ];

  return <Tab panes={panes} />;
}
