 
import { BASE_API } from "../../utils/constants";
import { makeRequest, makeRequestSlate } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getOrdenesApi(token,schema) {
  const url = `${BASE_URL}${schema}/transacciones/orden`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getOrdenesFacturadorApi(token,schema) {
  const url = `${BASE_URL}${schema}/transacciones/facturador`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getOrdenesCargaderoApiSlate(start_date, end_date, token,schema) {
  const url = `${BASE_URL}${schema}/transacciones/ordenes-cargadero-slate/custom_data/?start_date=${start_date}&end_date=${end_date}`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addOrdenesApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/transacciones/orden/`;
  return makeRequest(url, "POST", token, data);
}

// Función para agregar un nuevo dato
export async function addOrdenesCargaderoApiSlate(data, token, schema) {
  const url = `${BASE_URL}${schema}/transacciones/ordenes-cargadero-slate/export_data/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateOrdenesApi(id, data, token, schema) {
  const url1 = `${BASE_URL}${schema}transacciones/orden/${id}/`;
  const url2 = `${BASE_URL}${schema}transacciones/ordenes-cargadero-slate/update_data/?id=${id}`;

  try {
    const response1 = await makeRequest(url1, "PUT", token, data);
    // Intentar realizar la solicitud secundaria, sin esperar su respuesta
    makeRequestSlate(url2, "PUT", token, data).catch(error => {
      console.error("Error al actualizar datos en Slate:", error);
    });

    return response1;

  } catch (error) {
    console.error("Error al actualizar los datos:", error);
    throw error; // Relanzar el error para que pueda ser manejado más arriba si es necesario
  }
}


// Función para eliminar 
export async function deleteOrdenesApi(id, token, schema) {
  const url1 = `${BASE_URL}${schema}transacciones/orden/${id}/`;
  const url2 = `${BASE_URL}${schema}transacciones/ordenes-cargadero-slate/delete_data/?id=${id}`;

  try {
    makeRequest(url1, "DELETE", token);
    makeRequest(url2, "DELETE", token);
  } catch (error) {
    console.error('Error deleting ordenes:', error);
    throw error;
  }
}