import React from "react";
import { useNavigate } from "react-router-dom";
import combustibles from "../../../Icons/black/ADMINISTRACIÓN COMBUSTIBLE.png";
import AccesoManual from "../../../Icons/black/Accesos/26.-ACCESO-MANUAL-NEGRO.png";
import Almacenamiento2 from "../../../Icons/black/Almacenamiento/16.TANQUES-NEGRO.png";
import control from "../../../Icons/black/Procesos/4.PROCESOS-NEGRO.png";
import Almacenamiento from "../../../Icons/black/Procesos/7. ALMACENAMIENTO NEGRO.png";
import Ordenes from "../../../Icons/black/Procesos/8.-ordenes-negro.png";
import Clientes from "../../../Icons/black/SociosDeNegocios/2.CLIENTES-NEGRO.png";
import TablaAforo from "../../../Icons/black/TablaAforo/19.-TABLA-DE-AFORO-NEGRO.png";
import Transportadora from "../../../Icons/black/Transporte/12.TRANSPORTADORANEGRO.png";
import personas from "../../../Icons/black/Usuarios/PERSONAS-NEGRO.png";
import usuario from "../../../Icons/black/Usuarios/USUARIO-NEGRO.png";
import Producto from "../../../Icons/black/Usuarios/producto-negroo.png";
import { useAuth } from "../../../hooks";


export function ItemsClientes() {
  let navigate = useNavigate();
  const { auth } = useAuth();

  const items = [
    {
      key: "inicio",
      icon: <img className="pequeña" src="https://static.thenounproject.com/png/3574480-200.png" />,
      label: "Inicio",
      onClick: () => navigate("/"),

    },
    {
      key: "maestros",
      icon: <img className="pequeña" src={usuario} />,
      label: "Datos maestros",
      children: [
        auth.me?.is_admin && {
          key: "grupos-permisos",
          icon: null,
          label: "Grupos de permisos",
          onClick: () => navigate("/GruposDePermisos"),
        },
        // auth.me?.is_admin && {
        //   key: "documentos-permisos",
        //   icon: null,
        //   label: "Documentos",
        //   onClick: () => navigate("/control/documentos"),
        // },
        auth.me?.is_admin && {
          key: "usuarios",
          icon: null,
          label: "Usuarios",
          onClick: () => navigate("/usuarios"),
        },
        {
          key: "operador",
          icon: null,
          label: "Operadores",
          onClick: () => navigate("/operadores"),
        },
        {
          key: "plantas",
          icon: null,
          label: "Ubicaciones",
          onClick: () => navigate("/plantas"),
        },
        // {
        //   key: "tankcustveradm",
        //   icon: null,
        //   label: "Revisiones para tanque",
        //   onClick: () => navigate("/tankcustveradm"),
        // },
        {
          key: "vehcustveradm",
          icon: null,
          label: "Revisiones para vehículo",
          onClick: () => navigate("/vehcustveradm"),
        },
      ].filter(Boolean),
    },
    {
      key: "productos-mezclas",
      icon: <img className="pequeña" src={Producto} />,
      label: "Productos-mezclas",
      children: [
        {
          key: "mezclas",
          icon: null,
          label: "Productos",
          onClick: () => navigate("/mezclas"),
        },

      ],
    },
    {
      key: "negocios",
      icon: <img className="pequeña" src={Clientes} />,
      label: "Socios de negocios",
      children: [
        {
          key: "clientes",
          icon: null,
          label: "Clientes",
          onClick: () => navigate("/clientes"),
        },
        {
          key: "proveedores",
          icon: null,
          label: "Proveedores",
          onClick: () => navigate("/proveedores"),
        },
        {
          key: "transportadora",
          icon: null,
          label: "Transportadora",
          onClick: () => navigate("/transportadora"),
        },
      ],
    },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_descargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_cargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento
    ) && {
      key: "procesos",
      icon: <img src={Almacenamiento}
        className="pequeña"
        alt="ícono"
        style={{ float: "left" }}
      />,
      label: "Procesos",
      children: [
        // auth.me?.owner2?.modulos_Activos?.modulo_descargadero && {
        //   key: "receipt",
        //   icon: null,
        //   label: "Recibo",
        //   onClick: () => navigate("/receipt"),
        // },
        // auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento && {
        //   key: "storage",
        //   icon: null,
        //   label: "Almacenamiento",
        //   onClick: () => navigate("/storage"),
        // },

        auth.me?.owner2?.modulos_Activos?.modulo_cargadero && {
          key: "dispatch",
          icon: null,
          label: "Despacho",
          onClick: () => navigate("/dispatch"),
        },
        // {
        //   key: "turns",
        //   icon: null,
        //   label: "Turnos",
        //   onClick: () => navigate("/turns"),
        // },

      ].filter(Boolean),
    },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_descargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_cargadero
    ) && {
      key: "ordenes",
      icon: <img className="pequeña" src={Ordenes} />,
      label: "Ordenes",
      children: [
        // auth.me?.owner2?.modulos_Activos?.modulo_descargadero && {
        //   key: "ordenes-recibo",
        //   icon: null,
        //   label: "Ordenes recibo",
        //   onClick: () => navigate("/OrdenesDescargadero"),
        // },
        auth.me?.owner2?.modulos_Activos?.modulo_cargadero && {
          key: "ordenes-despacho",
          icon: null,
          label: "Ordenes despacho",
          onClick: () => navigate("/ordenes"),
        },
        // {
        //   key: "Conciliaciones",
        //   icon: null,
        //   label: "Conciliaciones",
        //   onClick: () => navigate("/conciliacion/modulos"),
        // },
      ].filter(Boolean),
    },
    {
      key: "transporte",
      icon: <img className="pequeña" src={Transportadora} />,
      label: "Transporte",
      children: [
        {
          key: "conductor",
          icon: null,
          label: "Conductor",
          onClick: () => navigate("/conductor"),
        },
        {
          key: "vehiculos",
          icon: null,
          label: "Vehículos",
          onClick: () => navigate("/vehiculos"),
        },
        {
          key: "remolques",
          icon: null,
          label: "Remolques",
          onClick: () => navigate("/remolques"),
        },
      ],
    },
    // {
    //   key: "maquinaria",
    //   icon: <img className="pequeña" src={maquinaria_e} />,

    //   label: "Maquinaria",
    //   children: [
    //     {
    //       key: "maquinariamgm",
    //       icon: null,
    //       label: "Administración maquinaria",
    //       onClick: () => navigate("/maquinariamgm"),
    //     },
    //   ],
    // },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_descargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_cargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento
    ) && {
      key: "almacenamiento",
      icon: <img className="pequeña" src={Almacenamiento2} />,
      label: "Almacenamiento",
      children: [
        {
          key: "tanques",
          icon: null,
          label: "Tanques",
          onClick: () => navigate("/tanques"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento && {
          key: "mediciones",
          icon: null,
          label: "Mediciones",
          onClick: () => navigate("/data-ingreso"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento && {
          key: "conciliaciones-almacenamiento",
          icon: null,
          label: "Correcciones",
          onClick: () => navigate("/conciliaciones"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_almacenamiento && {
          key: "tabla-aforo",
          icon: <img className="pequeña" src={TablaAforo} />,
          label: "Tabla de aforo",
          children: [
            {
              key: "tabla-aforo-base",
              icon: null,
              label: "Tabla de aforo",
              onClick: () => navigate("/tabla_aforo"),
            },
            {
              key: "secciones",
              icon: null,
              label: "Secciones",
              onClick: () => navigate("/aforo/secciones"),
            },
            {
              key: "fracciones",
              icon: null,
              label: "Fracciones",
              onClick: () => navigate("/aforo/fracciones"),
            },

          ],
        },
      ].filter(Boolean),
    },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_turnos ||
      auth.me?.owner2?.modulos_Activos?.modulo_accesos
    ) && {
      key: "accesos",
      icon: <img src={AccesoManual} className="pequeña" alt="ícono" />,
      label: "Accesos",
      children: [
        auth.me?.owner2?.modulos_Activos?.modulo_accesos && {
          key: "configDisp",
          icon: null,
          label: "Configurar accesos",
          onClick: () => navigate("/configDisp"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_accesos && {
          key: "accesoManual",
          icon: null,
          label: "Acceso manual",
          onClick: () => navigate("/accesoManual"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_turnos && {
          key: "turnos",
          icon: null,
          label: "Turnos",
          onClick: () => navigate("/turnos"),
        },
        auth.me?.owner2?.modulos_Activos?.modulo_turnos && {
          key: "turnos-vista",
          icon: null,
          label: "Pantalla turnos",
          onClick: () => navigate("/turnos/visualizacion"),
        },
      ].filter(Boolean),
    },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_descargadero ||
      auth.me?.owner2?.modulos_Activos?.modulo_cargadero
    ) && {
      key: "controladores",
      icon: <img className="pequeña" src={control} />,

      label: "Controladores",
      children: [
        // {
        //   key: "mapeocontrolado",
        //   icon: null,
        //   label: "Mapeos",
        //   onClick: () => navigate("/mapeos/edit"),
        // },
        {
          key: "tipocontrolador",
          icon: null,
          label: "Tipo Controlador",
          onClick: () => navigate("/tiposcontroladores"),
        },
        {
          key: "controlador",
          icon: null,
          label: "Controlador",
          onClick: () => navigate("/controlador"),
        },
        {
          key: "mapeocontroladotanque",
          icon: null,
          label: "Mapeos Tanques",
          onClick: () => navigate("/mapeo/almacenamiento"),
        },
        // {
        //   key: "mapping",
        //   icon: null,
        //   label: "Mapping",
        //   onClick: () => navigate("/mapping"),
        // },
        // {
        //   key: "control",
        //   icon: null,
        //   label: "Control",
        //   onClick: () => navigate("/control"),
        // },
        // {
        //   key: "sensores",
        //   icon: null,
        //   label: "Sensores GE",
        //   onClick: () => navigate("/sensores")
        // }
      ],
    },
    (
      auth.me?.owner2?.modulos_Activos?.modulo_gestion
    ) && {
      key: "fuelmgm",
      icon: <img className="pequeña" src={combustibles} />,
      label: "Combustible",
      children: [
        {
          key: "fueladm",
          icon: null,
          label: "Suministro interno",
          onClick: () => navigate("/fuelmgm"),
        },
        {
          key: "consumointerno",
          icon: null,
          label: "Llenado de tanques",
          onClick: () => navigate("/consumointerno"),
        }
      ],
    },
  ];
  return {
    items
  };
}
