import { useState } from "react";
import Swal from "sweetalert2";
import {
  getDocumentosGutApi,
  addDocumentosGutApi,
  updateDocumentosGutApi,
  deleteDocumentosGutApi,
  generarDocumentosGutApi,
  getDocumentosFacturacionGutApi
} from "../../api/A_Doc_GUT/documentosGut";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useDocumentosGut() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    DocumentosGut: null,
    DocumentosGutDB: null,
    DocumentosGutFacturacion: null,
  });

  const { loading, error, DocumentosGut, DocumentosGutDB, DocumentosGutFacturacion } = state;

  const handleAddOrUpdateError = (result) => {
    if (result?.num_gut) {
      throw new Error("El número de la guía única se ha registrado con anterioridad");
    } else {
      errores();
    }
  };

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getDocumentosGutDB = async () => {
    try {
      const [result, response] = await getDocumentosGutApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, DocumentosGutDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getDocumentosGut = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getDocumentosGutApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, DocumentosGut: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };
  const getDocumentosGutFacturacion = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getDocumentosFacturacionGutApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, DocumentosGutFacturacion: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addDocumentosGut = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addDocumentosGutApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateDocumentosGut = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateDocumentosGutApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteDocumentosGut = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteDocumentosGutApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const GenerarDocumentosGut = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await generarDocumentosGutApi(id.id_orden, auth.token, auth.tenant);

      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
      return response;
    } catch (error) {
      console.log("errores", error);
      setState((prevState) => ({ ...prevState, loading: false, error }));
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      throw error;
    }
  };

  return {
    loading,
    error,
    DocumentosGutDB,
    DocumentosGut,
    DocumentosGutFacturacion,
    getDocumentosGutFacturacion,
    getDocumentosGutDB,
    getDocumentosGut,
    addDocumentosGut,
    updateDocumentosGut,
    deleteDocumentosGut,
    GenerarDocumentosGut,
  };
}
