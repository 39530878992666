import React, { useEffect, useState } from "react";
import { Grid, Typography } from '@mui/material';
import { DataGridNoOpt } from "../../../Components/Comons";
import { MQTTConfcolumns, MODBUSConfcolumns, OPCConfcolumns, variablesColumns } from './DataVisualizerFormat'
import { Table, Divider } from "antd";

export function DataVisualizer(props) {

    const { onClose, onRefetch, Sensores } = props;


    const MQTTConfigData = Sensores
        ? [{
            broker_address: Sensores.mqtt_config?.broker_address || "N/A",
            port: Sensores.mqtt_config?.port || 0,
            qos: Sensores.mqtt_config?.qos || 0,
        }]
        : [];

    //////////////////////////

    const MODBUSConfigData = Sensores
        ? [{
            ip: Sensores.modbus_config?.ip || "N/A",
            port: Sensores.modbus_config?.port || 0,
            base: Sensores.modbus_config?.base || 0,
        }]
        : [];

    //////////////////////////

    const OPCConfigData = Sensores
        ? [{
            node_id: Sensores.opcua_config?.node_id || "N/A",
            password: Sensores.opcua_config?.password || "N/A",
            server_url: Sensores.opcua_config?.server_url || "N/A",
            username: Sensores.opcua_config?.username || "N/A",
        }]
        : [];


    const variablesData = Sensores
        ? Sensores.variables?.map((item) => ({
            data_type: item.data_type || "N/A",
            divisor: item.divisor || 0,
            length: item.length || 0,
            name: item.name || "N/A",
            node_id: item.node_id || "N/A",
            register: item.register || "N/A",
            register_type: item.register_type || "N/A",
            tanque_rel: item.tanque_rel || 0,
            tipo_variable: item.tipo_variable || "N/A",
            topic: item.topic || "N/A",
        }))
        : [];


    return (
        <>
            <Grid container spacing={3}>
                {Sensores ? (
                    <>
                        {/* Condiciones de los protocolos */}
                        {Sensores.protocol === 'MQTT' ? (
                            <>
                                <Divider>Configuración</Divider>
                                <Grid item xs={12} sm={12}>
                                    <Table
                                        dataSource={MQTTConfigData}
                                        columns={MQTTConfcolumns}
                                    />
                                </Grid>
                            </>
                        ) : Sensores.protocol === 'MODBUS' ? (
                            <>
                                <Divider>Configuración</Divider>
                                <Grid item xs={12} sm={12}>
                                    <Table
                                        dataSource={MODBUSConfigData}
                                        columns={MODBUSConfcolumns}
                                    />
                                </Grid>
                            </>
                        ) : Sensores.protocol === 'OPC' ? (
                            <>
                                <Divider>Configuración</Divider>
                                <Grid item xs={12} sm={12}>
                                    <Table
                                        dataSource={OPCConfigData}
                                        columns={OPCConfcolumns}
                                    />
                                </Grid>
                            </>
                        ) : null}

                        <Divider>Variables</Divider>
                        <Grid item xs={12} sm={12}>
                            <Table
                                dataSource={variablesData}
                                columns={variablesColumns}
                            />
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} sm={12} textAlign={'center'}>
                        <Typography variant="h6">No hay datos para mostrar</Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );

}