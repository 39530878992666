export const columns = [

  {
    header: "Index",
    name: "codigo_aditivo",
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Nombre",
    name: "nombre",
    maxWidth: 1000, defaultFlex: 1
  }

];

export const filterValue = [
  { name: 'codigo_aditivo', operator: 'startsWith', type: 'string', value: '' },
  { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
];