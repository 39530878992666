import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import { AddEditCorrecciones } from "../../../Components/Client";

export function Funciones() {

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo cierre");
    setContentModal(
      <AddEditCorrecciones onClose={openCloseModal} onRefetch={onRefetch} />

    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  return {
    openCloseModal,
    handleAdd,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
