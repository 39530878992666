import { useState } from "react";
import { getProveedoresApi, addProveedoresApi, updateProveedoresApi, deleteProveedoresApi } from "../../api/A_Proveedores/proveedores";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useProveedores() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Proveedores: null,
    ProveedoresDB: null,
  });

  const { loading, error, Proveedores, ProveedoresDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getProveedoresDB = async () => {
    try {
      const [result, response] = await getProveedoresApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, ProveedoresDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getProveedores = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getProveedoresApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Proveedores: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addProveedores = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addProveedoresApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        if (result?.nit_proveedor) {
          throw new Error("Ya existe un proveedor registrado con este NIT");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateProveedores = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateProveedoresApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        if (result?.nit_proveedor) {
          throw new Error("Ya existe un proveedor registrado con este NIT");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteProveedores = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteProveedoresApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    ProveedoresDB,
    Proveedores,
    getProveedoresDB,
    getProveedores,
    addProveedores,
    updateProveedores,
    deleteProveedores,
  };
}
