import { BASE_API_PUBLIC } from "../../utils/constants";
import { BASE_API } from "../../utils/constants";
// Esta función realiza una solicitud GET a una ruta específica de la API (`${BASE_API}/...`)
// Proporciona un token de autorización en el encabezado de la solicitud.
// La respuesta se convierte en un objeto JSON y se devuelve junto con el objeto de respuesta.
// En caso de error, arroja un error.
export async function getLicenciaApi(token,schema) {
    try {
      const url = `${BASE_API}/${schema}/usuarios/licencia_v/validacion_licencia`;
      const params = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(url, params);
      const result = await response.json();
      
      return [result,response];
    } catch (error) {
      throw error;
    }
  }