import { useState } from "react";
import { getTransaccionesDescargaderoApi, addTransaccionesDescargaderoApi, updateTransaccionesDescargaderoApi, deleteTransaccionesDescargaderoApi, retrieveTransaccionesDescargaderoApi } from "../../api/A_OrdenDescargadero/transaccionesDescargadero";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useTransaccionesDescargadero() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    TransaccionesDescargadero: null,
    TransaccionesDescargaderoRTR: null,
    TransaccionesDescargaderoDB: null,
  });

  const { loading, error, TransaccionesDescargadero, TransaccionesDescargaderoRTR, TransaccionesDescargaderoDB } = state;

  const getTransaccionesDescargaderoDB = async () => {
    try {
      const [result, response] = await getTransaccionesDescargaderoApi(auth.token);
      setState((prevState) => ({ ...prevState, TransaccionesDescargaderoDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getTransaccionesDescargadero = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getTransaccionesDescargaderoApi(auth.token, auth.tenant);
      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
      }
      setState((prevState) => ({ ...prevState, loading: false, TransaccionesDescargadero: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const retriveTransaccionesDescargadero = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await retrieveTransaccionesDescargaderoApi(id, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false, TransaccionesDescargaderoRTR: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addTransaccionesDescargadero = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addTransaccionesDescargaderoApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const updateTransaccionesDescargadero = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateTransaccionesDescargaderoApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const deleteTransaccionesDescargadero = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteTransaccionesDescargaderoApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    TransaccionesDescargaderoDB,
    TransaccionesDescargaderoRTR,
    TransaccionesDescargadero,
    getTransaccionesDescargaderoDB,
    getTransaccionesDescargadero,
    addTransaccionesDescargadero,
    updateTransaccionesDescargadero,
    deleteTransaccionesDescargadero,
    retriveTransaccionesDescargadero,
  };
}
