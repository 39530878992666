import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useProductoBase,
  useProductoMezcla,
  useProductoTanque,
  useTanques,
} from "../../../../hooks";
import { alertas } from "../../../Comons/Alertas/alertas";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    marginBottom: theme.spacing(2), // Espacio inferior entre DatePicker y los campos
  },
}));



export function AddEditProductosTanque(props) {
  const classes = useStyles();
  const { Successful } = alertas();
  const { onClose, onRefetch, Productos } = props;
  const { ProductoMezclaDB, getProductoMezclaDB } = useProductoMezcla();
  const { tanquesDB, getTanquesDB } = useTanques();
  const { ProductoBaseDB, getProductoBaseDB } = useProductoBase();
  const { addProductoTanque, updateProductoTanque } = useProductoTanque();
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [showAllDays, setShowAllDays] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    getTanquesDB();
    getProductoMezclaDB();
    getProductoBaseDB();
  }, []);

  useEffect(() => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
    const dateTime = `${formattedDate} ${formattedTime}`;
    setCurrentDateTime(dateTime);
  }, []);

  const formik = useFormik({
    initialValues: initialValues(Productos),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (Productos) await updateProductoTanque(Productos.id, formValue);
        else await addProductoTanque(formValue);
        Successful();
        onClose();
        onRefetch();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const handleProductoMezclaChange = (_, value) => {
    formik.setFieldValue('productos', value ? value.id : '');

    // Obtener productos base asociados
    const productosBase = value?.productos_base || [];

    // Asignar productos base y sus tanques asociados
    productosBase.forEach((productoBase, index) => {
      formik.setFieldValue(`productos_${index + 2}`, productoBase.productos_base || '');
      formik.setFieldValue(`porcentaje_${index + 2}`, productoBase.porcentaje_producto || '');

      // Buscar el tanque asociado al producto base
      //const tanqueAsociado = tanquesDB.find(tanque => tanque.id_producto === productoBase.productos_base);
      const tanquesAsociados = tanquesDB.filter(tanque => tanque.id_producto === productoBase.productos_base);

      // Asignar los tanques como un array de IDs
      const tanquesIds = tanquesAsociados.map(tanque => tanque.id_tanque);

      // Establecer los tanques en el estado de Formik
      formik.setFieldValue(`tanques_${index + 2}`, tanquesIds.length > 0 ? tanquesIds : []);
    });

    // Limpiar campos adicionales si no hay productos base suficientes
    for (let i = productosBase.length + 2; i < 7; i++) {
      formik.setFieldValue(`productos_${i}`, '');
      formik.setFieldValue(`porcentaje_${i}`, '');
      formik.setFieldValue(`tanques_${i}`, []);
    }

  };

  const renderFieldGroup = (index) => (
    <>
      {renderAutocompleteP(`productos_${index}`, `Producto Base ${index - 1}`, ProductoBaseDB, formik, index)}
      {renderAutocompleteT(`tanques_${index}`, 'Tanques', tanquesDB, formik, index, 'id_tanque', 'nombre_tanque')}
    </>
  );

  return (
    <Formik>
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {/* fila 1 */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="codigo_producto"
              name="codigo_producto"
              label="Código de Producto"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.codigo_producto}
              error={
                formik.touched.codigo_producto &&
                Boolean(formik.errors.codigo_producto)
              }
              helperText={
                formik.touched.codigo_producto && formik.errors.codigo_producto
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="nombre"
              name="nombre"
              label="Identificacion relacion"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.nombre}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="productos"
              options={ProductoMezclaDB}
              getOptionLabel={(option) => option.nombre}
              value={
                ProductoMezclaDB?.find(
                  (opt) => opt.id === formik.values?.productos
                ) || null
              }
              onChange={handleProductoMezclaChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Productos"
                  fullWidth
                  variant="outlined"
                  error={
                    formik.errors.productos && Boolean(formik.errors.productos)
                  }
                  helperText={
                    formik.touched.productos && formik.errors.productos
                  }
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          {Array.from({ length: 5 }, (_, i) => renderFieldGroup(i + 2))}
          {/* fila 3 */}
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  API - BSW
                </Typography>
                {formik.values.api_bsw?.length > 0 && (
                  <DatePicker
                    className={classes.datePicker} // Aplica el margen inferior aquí
                    label="Fecha"
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
                {formik.values.api_bsw
                  .filter((variable) => {
                    const variableDate = new Date(variable.date);
                    const currentDate = new Date(currentDateTime);
                    return (
                      showAllDays ||
                      variableDate.toISOString().split("T")[0] ===
                      selectedDate.toISOString().split("T")[0]
                    );
                  })
                  .reverse()
                  .map((variable, index) => {
                    const reverseIndex =
                      formik.values.api_bsw.length - index - 1;
                    return (
                      <>
                        <Grid container spacing={2} key={reverseIndex}>
                          <Grid item xs={12} sm={5}>
                            <TextField
                              required
                              variant="outlined"
                              label={`API ${reverseIndex + 1}`}
                              name={`api_bsw[${reverseIndex}].api`}
                              fullWidth
                              placeholder="Valor API"
                              value={variable.api}
                              onChange={formik.handleChange}
                              error={
                                formik.errors[`api_bsw[${reverseIndex}].api`]
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <TextField
                              required
                              variant="outlined"
                              label={`BSW ${reverseIndex + 1}`}
                              name={`api_bsw[${reverseIndex}].bsw`}
                              fullWidth
                              placeholder="Valor BSW"
                              value={variable.bsw}
                              onChange={formik.handleChange}
                              error={
                                formik.errors[`api_bsw[${reverseIndex}].bsw`]
                              }
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Tooltip title="Eliminar variable" arrow>
                              <Button
                                variant="outlined"
                                color="secondary"
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "0",
                                  margin: "0",
                                  color: "rgba(252, 0, 0, 0.8)",
                                  border: "1px solid rgba(252, 0, 0, 0.8)",
                                }}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "api_bsw",
                                    formik.values.api_bsw.filter(
                                      (_, i) => i !== reverseIndex
                                    )
                                  )
                                }
                              >
                                <DeleteIcon style={{ margin: "0" }} />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <br />
                      </>
                    );
                  })}
                <Grid item xs={12}>
                  <Tooltip title="Agregar API-BSW" arrow>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      style={{
                        color: "rgb(25, 118, 210)",
                        border: "1px solid rgb(25, 118, 210)",
                        "&:hover": {
                          color: "rgba(221, 223, 221, 0.62)",
                          border: "1px solid rgba(221, 223, 221, 0.62)",
                        },
                      }}
                      onClick={() =>
                        formik.setFieldValue("api_bsw", [
                          ...formik.values.api_bsw,
                          { api: "", bsw: "", date: currentDateTime },
                        ])
                      }
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* fila 4 */}
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Variables extras
                </Typography>
                {formik.values.variables.map((variable, index) => (
                  <>
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={5}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          label={`Tipo de variable ${index + 1}`}
                          name={`variables[${index}].tipo`}
                          placeholder="Tipo de variable"
                          value={variable.tipo}
                          onChange={formik.handleChange}
                          error={formik.errors[`variables[${index}].tipo`]}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          required
                          variant="outlined"
                          fullWidth
                          label={`Valor de variable ${index + 1}`}
                          name={`variables[${index}].valorVariable`}
                          placeholder="Valor de variable"
                          value={variable.valorVariable}
                          onChange={formik.handleChange}
                          error={
                            formik.errors[`variables[${index}].valorVariable`]
                          }
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title="Eliminar variable" arrow>
                          <Button
                            variant="outlined"
                            color="secondary"
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0",
                              margin: "0",
                              color: "rgba(252, 0, 0, 0.8)",
                              border: "1px solid rgba(252, 0, 0, 0.8)",
                            }}
                            onClick={() =>
                              formik.setFieldValue(
                                "variables",
                                formik.values.variables.filter(
                                  (_, i) => i !== index
                                )
                              )
                            }
                          >
                            <DeleteIcon style={{ margin: "0" }} />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <br />
                  </>
                ))}
                <Grid item xs={12}>
                  <Tooltip title="Agregar variable" arrow>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      style={{
                        color: "rgb(25, 118, 210)",
                        border: "1px solid rgb(25, 118, 210)",
                        "&:hover": {
                          color: "rgba(221, 223, 221, 0.62)",
                          border: "1px solid rgba(221, 223, 221, 0.62)",
                        },
                      }}
                      onClick={() =>
                        formik.setFieldValue("variables", [
                          ...formik.values.variables,
                          { 
                            tipo: "", 
                            valorVariable: "" 
                          },
                        ])
                      }
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "rgb(204, 48, 43)",
                color: "white",
              }}
            >
              {Productos ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Formik>
  );
}

// Función para establecer valores iniciales para un objeto
function initialValues(data) {
  return {
    codigo_producto: data?.codigo_producto || "",
    nombre: data?.nombre || "",
    api_bsw: data?.api_bsw || [],
    variables: data?.variables || [],
    productos: data?.productos || "",

    productos_2: data?.productos_2 || "",
    tanques_2: data?.tanques_2 || [],

    productos_3: data?.productos_3 || "",
    tanques_3: data?.tanques_3 || [],

    productos_4: data?.productos_4 || "",
    tanques_4: data?.tanques_4 || [],

    productos_5: data?.productos_5 || "",
    tanques_5: data?.tanques_5 || [],

    productos_6: data?.productos_6 || "",
    tanques_6: data?.tanques_6 || [],
  };
}

// Función para crear un nuevo esquema de validación
function newSchema() {
  return {
    codigo_producto: Yup.string().required('El código del producto es obligatorio'),
    productos: Yup.string().required('El producto es obligatorio'),

  };
}

function renderAutocompleteP(id, label, options, formik, index, optionIdKey = 'id', optionLabelKey = 'nombre') {

  return (
    <Grid item xs={12} sm={6}>
      <Autocomplete
        id={id}
        options={[]}
        getOptionLabel={(option) => option[optionLabelKey]}
        value={options?.find((opt) => opt[optionIdKey] === formik.values[id]) || null}
        disabled
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            variant="outlined"
            error={formik.errors[id] && Boolean(formik.errors[id])}
            helperText={formik.touched[id] && formik.errors[id]}
            sx={{ background: '#fcfcfc' }}
            readOnly
            disabled={index > 6} // Deshabilitar si el índice es mayor que el número de productos base permitidos
          />
        )}
      />
    </Grid>
  );
}

function renderAutocompleteT(id, label, options, formik, index, optionIdKey = 'id', optionLabelKey = 'nombre') {

  // Obtener los IDs de los tanques desde formik.values[id]
  const tanqueIds = formik.values[id] || [];

  // Filtrar las opciones según los IDs de los tanques
  const filteredOptions = Array.isArray(options)
    ? options.filter((tanque) => tanqueIds.includes(tanque[optionIdKey]))
    : [];

  const selectedValue = filteredOptions.length > 0 ? filteredOptions[0] : null;

  return (
    <Grid item xs={12} sm={6}>
      <Autocomplete
        id={id}
        options={filteredOptions || []}
        getOptionLabel={(option) => option[optionLabelKey] || ""}
        value={selectedValue || ""}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            variant="outlined"
            error={formik.touched[id] && Boolean(formik.errors[id])} // Solo mostrar error si el campo ha sido tocado
            helperText={formik.touched[id] && formik.errors[id]} // Mostrar el mensaje de error
            sx={{ background: '#fcfcfc' }}
            readOnly // Asegúrate de que readOnly es lo que deseas
            disabled={index > 6} // Deshabilitar si el índice es mayor que el número de productos base permitidos
          />
        )}
      />
    </Grid>
  );
}


