import React, { useState, useEffect, createContext } from "react";
import { setToken, getToken, removeToken, setTenant, removeTenant, getTenant } from "../api/token";
import { useUser } from "../hooks";


 /*
  Context

  contexto para login y logout el cual  almacena datos como el token y el user data en local host

   */

export const AuthContext = createContext({
  auth: undefined,
  login: () => null,
  logout: () => null,
});

export function AuthProvider(props) {
  const { children } = props;
  const [auth, setAuth] = useState(undefined);
  const { getMe } = useUser();
  

  //refresh del token 
  useEffect(() => {
    (async () => {
      const token = getToken();
      const tenant = getTenant();
      if (token && tenant) {
        const me = await getMe(token, tenant);
        setAuth({ token, me, tenant });
      } else {
        setAuth(null);
      }
    })();
  }, []);

  const login = async (token, tenant) => {
    setTenant(tenant)
    //lo setea por medio de la funcion setToken que se encuentra en api
    setToken(token);
    const me = await getMe(token, tenant);
    setAuth({ token, me, tenant });
;
  };

  const logout = () => {
    if (auth) {
      removeToken();
      removeTenant();
      setAuth(null);
    }
  };

  const valueContext = {
    //llamamos a las funciones creadas anteriormente para que sean implementadas en el ValueContext
    auth,
    login,
    logout,
  };

  //elimina flash
  if (auth === undefined) return null;

  return (
    <AuthContext.Provider value={valueContext}>{children}</AuthContext.Provider>
  );
}