import { LeftOutlined } from "@ant-design/icons";
import { Tab, Tabs } from "@mui/material";
import { Popover } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { getReceiptDatosId } from "../../../../API/ProcesosApi";
import { IconTankSamll } from "../../../../components/IconTankSmall";
import { IconAlert } from "../../../../components/IconsAlert";
import { PopoverContent } from "../../../../components/PopoverContent";
import { Spinner } from "../../../../components/Spinner";
import { TableAlert } from "../../../../components/TableAlert";
import { Badge, Circle } from "../../../../components/common";
import { CardLeft } from "../../../../components/icons/card-left";
import { NewCardLeft } from "../../../../components/icons/new-card-tank";
import { StateBombDone } from "../../../../components/icons/state_bomb_done";
import { StateBombFail } from "../../../../components/icons/state_bomb_fail";
import { theme } from "../../../../theme";
import { ASGI_API, presentState } from "../../../../utils/constants";
import { DataComponent, DataComponentFactores, DataExtra, calculatePercentage } from "./Components";
import {
  BarraCarTank,
  CenteredText,
  ContainerBadge,
  ContainerBomb,
  ContainerCarTank,
  ContainerCardDetail,
  ContainerCircle,
  ContainerHeader,
  ContainerImages,
  ContainerImagesTank,
  ContainerTank,
  ContainerTankComplete,
  Text,
  Title
} from "./styles";




export const CardDetailReceipt = () => {
  const [data, setData] = useState([]);
  const [showBlinkState, setShowBlinkState] = useState(false);
  const [datosIdReceipt, setDatosIdReceipt] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [collapsed, setCollapsed] = useState(true);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  // this useMemo is for get the id of the url
  const idUrl = useMemo(() => {
    const url = window.location.pathname.split("/");
    return url[url.length - 1];
  }, []);

  // this useEffect is for get the data of the receipt
  useEffect(() => {
    if (idUrl === null) return;

    // Construir la URL de la conexión WebSocket
    let url = `${ASGI_API}/data/descargadero`;
    if (idUrl !== null) {
      url += `?bay=${idUrl}`;
    }

    // Crear una instancia del objeto WebSocket
    const socket = new WebSocket(url);
    setLoading(true);

    // Escuchar el evento 'message' para recibir los datos enviados por el servidor
    socket.addEventListener("message", (event) => {
      setData(JSON.parse(event.data));
      setLoading(false);
    });

    // Devolver una función que se ejecutará cuando el componente se desmonte
    return () => {
      // Cerrar la conexión WebSocket
      socket.close();
    };
  }, [idUrl]);

  const dataObject = useMemo(() => data[0], [data]);

  // this useMemo is for get the percent of the level of the tank
  const numBrazos = Object.keys(dataObject?.read_reg || {}).filter((key) =>
    key.startsWith("Brazo_")
  ).length;
  const percentLevelDes = useMemo(
    () => [
      calculatePercentage("GOV_RT", "VOLP_RT", "Brazo_1", dataObject),
      calculatePercentage(
        "GOV_RT_M_B_2",
        "VOLP_RT_M_B_2",
        "Brazo_2",
        dataObject
      ),
      calculatePercentage(
        "GOV_RT_M_B_3",
        "VOLP_RT_M_B_3",
        "Brazo_3",
        dataObject
      ),
      calculatePercentage(
        "GOV_RT_M_B_4",
        "VOLP_RT_M_B_4",
        "Brazo_4",
        dataObject
      ),
      calculatePercentage(
        "GOV_RT_M_B_5",
        "VOLP_RT_M_B_5",
        "Brazo_5",
        dataObject
      ),
      calculatePercentage(
        "GOV_RT_M_B_6",
        "VOLP_RT_M_B_6",
        "Brazo_6",
        dataObject
      ),
    ],
    [dataObject]
  );

  // this useMemo change showBlinkState for change appears and disappears
  useEffect(() => {
    const interval = setInterval(() => {
      setShowBlinkState(true);
      setTimeout(() => {
        setShowBlinkState(false);
      }, 1000);
    }, 2000);
    return () => clearInterval(interval);
  }, []);


  const tabItems = [];

  for (let i = 1; i <= numBrazos; i++) {
    tabItems.push(<Tab key={i} label={`Brazo ${i}`} value={i} />);
  }

  return (
    <ContainerCardDetail>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Popover
            placement="bottomRight"
            content={<PopoverContent data={datosIdReceipt} />}
            trigger="hover"
          >
            <div
              onMouseEnter={async () => {
                await getReceiptDatosId(dataObject?.read_reg?.Brazo_1?.ORDEN_N).then(
                  (r) => setDatosIdReceipt(r?.data.results[0])
                );
              }}
            >
              <ContainerHeader>
                <NavLink
                  to={"/receipt"}
                  style={{
                    marginRight: "85px",
                  }}
                >
                  <LeftOutlined />
                </NavLink>
                <Title>
                  Bahia <span>{dataObject?.read_reg?.Brazo_1?.BAY_NUMBER?? ""}</span>
                </Title>
                <div>
                  <Text fontSize={14}>
                    No ORDEN <span>{dataObject?.read_reg?.Brazo_1?.ORDEN_N ?? ""}</span>
                  </Text>
                </div>
              </ContainerHeader>
              <ContainerImages>
                <IconAlert
                  data={dataObject}
                  top={18}
                  size={15}
                  width={40}
                  height={52}
                />
                <ContainerTankComplete>
                  <ContainerImagesTank>
                    <ContainerBadge>
                      {" "}
                      <Badge fontSize={18}>
                        {presentState(dataObject?.read_reg?.Brazo_1?.PRESET_STATE)}
                      </Badge>
                    </ContainerBadge>
                    <ContainerTank>
                    
                    {percentLevelDes?.map(
                      (percent, index) =>
                        percent !== 0 && (
                          
                          <ContainerCarTank key={index} cantidad={numBrazos}>
                            <BarraCarTank percent={percent} >
                            <CenteredText cantidad={numBrazos}>
                            {percent}
                            %
                          </CenteredText>
                          </BarraCarTank>
                          </ContainerCarTank>
                        )
                    )}

                  <NewCardLeft />
                  </ContainerTank>
                    
                  </ContainerImagesTank>
                  <IconTankSamll percent={dataObject?.level_tank} />
                  <ContainerBomb
                    showBlink={showBlinkState}
                    done={dataObject?.read_reg?.Brazo_1?.PUMP_ST === 1}
                  >
                    {dataObject?.read_reg?.Brazo_1?.PUMP_ST === 1 ? (
                      <StateBombDone />
                    ) : (
                      <StateBombFail />
                    )}
                  </ContainerBomb>
                </ContainerTankComplete>
                <ContainerCircle>
                  <Circle
                    color={
                      dataObject?.read_reg?.Brazo_1?.RCU_STATE === 0
                        ? theme.default.primary.red
                        : theme.default.primary.green
                    }
                    size={25}
                  />
                </ContainerCircle>

              </ContainerImages>
             </div>

            <Tabs
              value={activeTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {tabItems}
            </Tabs>

            {Array.from({ length: numBrazos }).map((_, index) => (
              <>
              <DataComponent
                key={index}
                dataObject={dataObject}
                NBrazo={index + 1}
                IV={`IV_DEV${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                GSV={`GSV_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                VOLP={`VOLP_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                GOV={`GOV_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                NSV={`NSV_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                VOLR={`VOLP_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                TEMP={`TEMP_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                FLOW={`FLOW_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                DENS={`DENS_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                PRES={`PRES_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                API={`GAPI_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                BSW={`BSW_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
                activeTab={activeTab}
              />
              <DataComponentFactores
              key={index}
              dataObject={dataObject}
              NBrazo={index + 1}
              BSW={`BSW_RT${index !== 0 ? `_M_B_${index + 1}` : ""}`}
              activeTab={activeTab}
              />
              </>
            ))}

            <DataExtra brazo={activeTab} dataObject={dataObject} toggleCollapse={toggleCollapse} collapsed={collapsed} />
            
          </Popover>
          <TableAlert data={dataObject} />
        </>
      )}
    </ContainerCardDetail>
  );
};
