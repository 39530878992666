import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { HeaderPage } from "../../../Components/Client";
import { ModalBasic } from "../../../Components/Comons";
import { Funciones } from "./Funciones";
import { useAccesos, useControladores } from "../../../hooks";
// import { useConductor } from "../../../hooks";
import Swal from "sweetalert2";
import { Autocomplete } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

const options = [
    { value: '1', label: 'Activo solo turnos' },
    { value: '2', label: 'Activo solo accesos' },
    { value: '3', label: 'Activo turnos luego acceso' },
    { value: '4', label: 'Activo acceso luego turnos' },
];

export function ConfigTurnosAdmin() {
    const { getConfigTurnos, ConfigT, updateConfigTurnos, responseUpdt, loadingConfigT, handle_start_stop_access, loading_start_stop_access } = useAccesos();
    const { Controladores, getControladores } = useControladores();
    const [configT, setConfigT] = useState([]);
    const [updatedConfig, setUpdatedConfig] = useState([]);
    const [error, setError] = useState(false);
  
    const {
      refetch,
      contentModal,
      titleModal,
      showModal,
      openCloseModal,
    } = Funciones();
  
    const MemoizedModalBasic = React.memo(ModalBasic);
  
    useEffect(() => {
      getConfigTurnos();
      getControladores();
    }, [refetch]);
  
    useEffect(() => {
      setConfigT(ConfigT);
      setUpdatedConfig(ConfigT);
    }, [ConfigT]);
  
    const handleCheckboxChange = (id) => (event) => {
      let updatedConfigData = updatedConfig.map((row) =>
        row.id_conf === id ? { ...row, selecccion: event.target.checked ? '1' : '0' } : row
      );
  
      if ((event.target.checked && updatedConfigData?.find((row) => row.id_conf === id)?.selecccion === '2')
        || updatedConfigData?.find((row) => row.id_conf === id)?.selecccion === '4') {
        const codigoRow = updatedConfigData?.find((row) => row.descripcion === 'codigo');
        if (codigoRow) {
          codigoRow.selecccion = '0';
        }
  
        updatedConfigData = [...updatedConfigData];
      }
  
      setUpdatedConfig(updatedConfigData);
      setConfigT(updatedConfigData);
    };
  
    const handleSelectChange = (id) => (event, value) => {
      let updatedConfigData = updatedConfig.map((row) =>
        row.id_conf === id ? { ...row, selecccion: value?.value || '' } : row
      );
  
      if (event.target.value === '2' || event.target.value === '4') {
        const codigoRow = updatedConfigData?.find((row) => row.descripcion === 'codigo');
        if (codigoRow) {
          codigoRow.selecccion = '0';
        }
  
        updatedConfigData = [...updatedConfigData];
      }
  
      setUpdatedConfig(updatedConfigData);
      setConfigT(updatedConfigData);
      setError(!value || !value.value);
  
      if (value?.value) {
        setError(false);
      }
    };
  
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
  
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));
  
    const handleSubmit = () => {
      const selectedValues = configT.map(item => ({
        id_conf: item.id_conf,
        descripcion: item.descripcion,
        selecccion: item.selecccion
      }));
  
      try {
        const turnoAccesoSelecccion = selectedValues?.find(v => v.descripcion === "turno-acceso")?.selecccion;
        if (turnoAccesoSelecccion === "2" || turnoAccesoSelecccion === "4") {
          const updatedValues = selectedValues.map(item => {
            if (item.descripcion === "codigo") {
              return { ...item, selecccion: "0" };
            }
            return item;
          });
  
          updateConfigTurnos(updatedValues);
  
          Swal.fire({
            icon: 'success',
            title: 'Configuración guardada',
            text: 'Datos guardados correctamente',
          });
        } else {
          updateConfigTurnos(selectedValues);
  
          Swal.fire({
            icon: 'success',
            title: 'Configuración guardada',
            text: 'Datos guardados correctamente',
          });
        }
  
      } catch (Exception) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Ha sucedido un error',
        });
      }
    };
  
    const capitalize = (text) => {
      if (!text) return "";
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };
  
    return (
      <>
        <HeaderPage title="Configuración de métodos de acceso" />
        <br></br>
        {!ConfigT || !configT ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Descripción</StyledTableCell>
                        <StyledTableCell align="center">Selección</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {configT?.map((row) => (
                        <StyledTableRow key={row.id_conf}>
                          <StyledTableCell component="th" align="center" scope="row">
                            {capitalize(row.descripcion)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.id_conf !== 1 ? (
                              (row.descripcion === 'codigo' && updatedConfig?.find((configRow) => configRow.id_conf === 1)?.selecccion === '2')
                                || (row.descripcion === 'codigo' && updatedConfig?.find((configRow) => configRow.id_conf === 1)?.selecccion === '4') ? (
                                <Checkbox disabled />
                              ) : (
                                <Checkbox
                                  checked={row.selecccion === '1'}
                                  onChange={handleCheckboxChange(row.id_conf)}
                                />
                              )
                            ) : (
                              <Autocomplete
                                fullWidth
                                options={options}
                                getOptionLabel={(option) => option.label}
                                value={options?.find((option) => option.value === row.selecccion) || null}
                                onChange={handleSelectChange(row.id_conf)}
                                renderInput={(params) => (
                                  <TextField {...params}
                                    label="Selección"
                                    variant="outlined"
                                    required
                                    error={error}
                                    helperText={error ? 'Es necesario una selección' : ''}
                                  />
                                )}
                              />
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
  
              <Grid item xs={12} sm={12}>
                <Button type="submit" variant="contained" color="error" className="btn btn-danger" onClick={handleSubmit} fullWidth>
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </>
        )}
  
        {showModal && (
          <MemoizedModalBasic
            show={showModal}
            onClose={openCloseModal}
            title={titleModal}
            children={contentModal}
          />
        )}
      </>
    );
  }