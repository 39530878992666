import { BASE_API } from "../../utils/constants";
  import { makeRequest } from "../Request";
  const BASE_URL = `${BASE_API}/`;
  
  
  // Función para obtener los datos 
  export async function getVistaTipoTransacciones(token,schema,tipo,year,tanque_id){

    console.log('datos enviados',tipo,year,tanque_id)
    const url = `${BASE_URL}${schema}/integracion/transaccion-control?tipo_transaccion=${tipo}&year=${year}&tanque=${tanque_id}`;
    return makeRequest(url, "GET", token);
  }

  // Función para obtener los datos 
  export async function getVistaTipoTransaccionesTanques(token,schema,tipo,tanque_id,year) {
    const url = `${BASE_URL}${schema}/integracion/transaccion-control-tanque/?tipo_transaccion=${tipo}&tanque_id=${tanque_id}&year=${year}`;
    return makeRequest(url, "GET", token);
  }

  // Función para obtener los datos 
  export async function getVistaTipoTransaccionesAgnos(token,schema,tipo,year,tanque_id) {
    const url = `${BASE_URL}${schema}/integracion/v-entregado-por-mes?tipo_transaccion=${tipo}&year=${year}&tanque=${tanque_id}`;
    return makeRequest(url, "GET", token);
  }

  // Función para obtener los datos 
  export async function getVistaPorcentajes(token,schema,tipo,year) {
    const url = `${BASE_URL}${schema}/integracion/v-porcentaje?tipo_transaccion=${tipo}&year=${year}`;
    return makeRequest(url, "GET", token);
  }

  // Función para obtener los datos de un cliente
  export async function getCantidadProd(token,schema,tipo,year) {
    const url = `${BASE_URL}${schema}/integracion/producto-transaccion?tipo_transaccion=${tipo}&year=${year}`;
    return makeRequest(url, "GET", token);
  }

  // Función para obtener los datos de un cliente
  export async function getCantidadProdMes(token,schema,tipo,year) {
    const url = `${BASE_URL}${schema}/integracion/producto-mes?tipo_transaccion=${tipo}&year=${year}`;
    return makeRequest(url, "GET", token);
  }
  