import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";

import {
  AddEditOrdenesDescargadero,
  ImpresionTD,
  VistaTransaccionesDescargadero,
} from "../../../../Components/Client";
import { useOrdenesDescargadero } from "../../../../hooks";
import DateRangeForm from "../../../../Components/Client/OrdenDescargadero/OrdenesDescargadero/vistaFiltroOrdenes";
import { IconButton } from "@material-ui/core";
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import Tooltip from '@material-ui/core/Tooltip';
import { CheckCircle, Cancel } from '@mui/icons-material';
export function Funciones() {
  const { deleteOrdenesDescargadero, addOrdenesDescargaderoSlate } = useOrdenesDescargadero();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleViews = useCallback(
    (data) => {
      setTitleModal("Lista de transacciones");
      setContentModal(
        <VistaTransaccionesDescargadero
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Transacciones={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleSlateData = useCallback(() => {
    setTitleModal("Lista de ordenes para exportar a Slate");
    setContentModal(
      <DateRangeForm />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleExportSlateData = useCallback(
    async (data) => {
      const result = await Swal.fire({
        title: "Estas seguro?",
        text: "Los datos filtrados serán exportados a Slate!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, exportar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        await addOrdenesDescargaderoSlate(data);
        Swal.fire("Guardados!", "Datos guardados en Slate", "success");
        onRefetch();
        
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Operación cancelada", "error");
      }
    },
    [addOrdenesDescargaderoSlate, onRefetch]
  );

  const handleAdd = useCallback(() => {
    setTitleModal("Nueva Orden");

    setContentModal(
      <AddEditOrdenesDescargadero
        onClose={openCloseModal}
        onRefetch={onRefetch}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setTitleModal("Actualizar Ordenes descargadero");
      setContentModal(
        <AddEditOrdenesDescargadero
          onClose={openCloseModal}
          onRefetch={onRefetch}
          OrdenesDescargadero={data}
        />
      );

      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    async (data) => {
      const result = await Swal.fire({
        title: "Estas seguro?",
        text: "No podrás revertir esta acción!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        await deleteOrdenesDescargadero(data.id_orden);
        Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
        onRefetch();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Operación cancelada", "error");
      }
    },
    [deleteOrdenesDescargadero, onRefetch]
  );

  const ImpresionTiquet = (data) => {
    setTitleModal("Impresion Tiquete");
    setContentModal(
      <ImpresionTD onClose={openCloseModal} onRefetch={onRefetch} data={data} />
    );
    openCloseModal();
  };

  const columns = [
    {
      name: "time_created",
      header: "Fecha creación",
      group: "general",
      defaultFlex: 1,
      minWidth: 150,
      render: ({ value, cellProps }) => {
        return moment(value).format("MM-DD-YYYY");
      },
    },
    {
      header: "Numero de orden",
      name: "n_orden",
      group: "general",
      maxWidth: 1000,
      defaultFlex: 1,
    },

    {
      header: "Autorizado",
      name: "v_auth", 
      group: "general",
      maxWidth: 300, defaultFlex: 1,
       render: (rowData) => (
        rowData.data.v_auth ? (
          <CheckCircle color="primary" />
        ) : (
          <Cancel color="error" />
        )
      )
    },   
    {
      header: "Cliente",
      name: "cliente_nombre",
      group: "general",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Tanque",
      name: "tanque_nombre",
      group: 'producto',
      maxWidth: 1000,
      defaultFlex: 1,
    },

    {
      header: "Producto",
      name: "producto_nombre",
      group: "producto",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "BSW",
      name: "bsw",
      group: 'producto',
      maxWidth: 90, defaultFlex: 1,
      render: ({ value }) => {
        return (parseInt(value, 10) / 100).toFixed(2);
      }
    },
    {
      header: "API",
      name: "api",
      group: 'producto',
      maxWidth: 90,
      defaultFlex: 1,
      render: ({ value }) => {
        // Ensure the value is a string and remove leading zeros, then convert to number
        const numericValue = parseInt(value, 10);
        const formattedValue = (numericValue / 10).toFixed(1);
        return formattedValue;
      }
    },
    {
      header: "Entregado",
      name: "v_entregado",
      group: "producto",
      maxWidth: 90,
      defaultFlex: 1,
      render: ({ value }) => value / 100, // Dividir el valor por 100 antes de mostrarlo
    },
    {
      header: "Restante",
      name: "v_restante",
      group: "producto",
      maxWidth: 90,
      defaultFlex: 1,
      render: ({ value }) => value / 100, // Dividir el valor por 100 antes de mostrarlo
    },

    {
      header: "Autorizado",
      name: "tiempo_auth",

      maxWidth: 1000,
      defaultFlex: 1,
    },

    {
      header: "Tiquete",
      render: (data) => (
        <Tooltip title="IMPRESION DE TIQUET" arrow>
                <IconButton onClick={() => ImpresionTiquet(data)}>
                  <AdfScannerIcon />
                </IconButton>
              </Tooltip>
      ),
      maxWidth: 1000,
      defaultFlex: 1,
    },
  ];

  const extraColumns = [
    // {
    //   name: "time_created",
    //   header: "Fecha creación",
    //   group: "general",
    //   defaultFlex: 1,
    //   minWidth: 150,
    //   render: ({ value, cellProps }) => {
    //     return moment(value).format("MM-DD-YYYY");
    //   },
    // },
    {
      header: "Numero de orden",
      name: "n_orden",
      group: "general",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Estado",
      group: "general",
      name: "estado_orden",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Vehículo",
      name: "vehiculo_nombre",
      group: "transporte",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Conductor",
      name: "conductor_nombre",
      group: "transporte",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Remolque",
      name: "remolque_nombre",
      group: "transporte",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Transportadora",
      name: "transportadora_nombre",
      group: "transporte",
      maxWidth: 1000,
      defaultFlex: 1,
    },

    {
      header: "Sellos",
      name: "sellos",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "N. Factura",
      name: "n_factura",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Observaciónes",
      name: "observacion_orden",
      maxWidth: 1000,
      defaultFlex: 1,
    },
  ];

  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    handleViews,
    handleSlateData,
    handleExportSlateData,
    extraColumns,
    columns,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
