import React, { useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import "../../../../Scss/impresiones.scss";
import { useImpresiones } from "../../../../hooks";
import { useState } from "react";
import {  GraySwitch } from "./styles";
import logo from "./logo_syz.png"

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { data } = props;

  const [ medidor , setMedidor] = useState(1);

  /*
  Componente

  -componente de visualizacion del modal de visualizacion para tiquete de transacciones o una orden
  
 
  -se valida que no llegue vacio para que no haya errores
  -se obtiene los datos  mediante props desde su PageAdmin
   */


  return (
    <>
    {/* <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
  <label style={{ marginRight: '8px' }}>Medidores</label>
  <GraySwitch
    checked={medidor === 1}
    onChange={() => setMedidor(medidor === 1 ? 0 : 1)}
  />
</div> */}

    
    <div ref={ref}>
      <div className="contenedor">
      <table id="header-table">
        <tr>
          <td class="header-title">
            <div>
              <h3 style={{ color: "black" }}>TIQUETE DE TRANSACCIÓN CARGADERO</h3>
            </div>
          </td>
          <td class="header-logo">
            <img
              src={logo}
              class="company-logo"
              alt="Company logo"
            />
          </td>
        </tr>
      </table>
      </div>
      <div className="contenedor">
      <table id="cont_tiket" class="table">
        <thead>
          <tr class="titulo_tiket">
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DEL PROVEEDOR
            </th>
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DEL CLIENTE
            </th>
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DE LA TRANSPORTADORA
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colspan="2">Razón Social</th>
            <td colspan="2">{data?.empresa ? 'Telba' : ''}</td>
            <th colspan="2">Razón Social</th>
            <td colspan="2">{data?.empresa}</td>
            <th colspan="2">Razón Social</th>
            <td colspan="2">{data?.transportadora}</td>
          </tr>
          <tr>
            <th colspan="2">Nit</th>
            <td colspan="2">{data?.origen_nit}</td>
            <th colspan="2">Nit</th>
            <td colspan="2">{data?.nit_empresa}</td>
            <th colspan="2">Nit</th>
            <td colspan="2">{data?.nit_transportadora}</td>
          </tr>
          <tr>
            <th colspan="2">Planta</th>
            <td colspan="2">{data?.origen_razon}</td>
            <th colspan="2">Planta</th>
            <td colspan="2">{data?.planta_destino}</td>
            <th colspan="2">Cabezote</th>
            <td colspan="2">{data?.cabezote}</td>
          </tr>
          <tr>
            <th colspan="2">Dirección</th>
            <td colspan="2">{data?.origen}</td>
            <th colspan="2">Dirección</th>
            <td colspan="2">{data?.destino}</td>
            <th colspan="2">Remolque</th>
            <td colspan="2">{data?.remolque}</td>
          </tr>
          <tr>
            <th colspan="2">Contacto</th>
            <td colspan="2">{data?.origen_contacto}</td>
            <th colspan="2">Contacto</th>
            <td colspan="2">{data?.contacto_destino}</td>
            <th colspan="2">Nombre Conductor</th>
            <td colspan="2">{data?.conductor_nombre}</td>
          </tr>
          <tr>
            <th colspan="2">Cargo</th>
            <td colspan="2">{data?.cargo_origen}</td>
            <th colspan="2">Cargo:</th>
            <td colspan="2">{data?.cargo_destino}</td>
            <th colspan="2">Identificación Conductor:</th>
            <td colspan="2">{data?.conductor_documento}</td>
          </tr>
        </tbody>
        <thead>
          <tr class="titulo_tiket">
            <th class="titulo_tiket" colspan="4">
              DATOS DEL MEDIDOR
            </th>
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DEL PRODUCTO
            </th>
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DE LA TRANSACCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colspan="2">Bahía:</th>
            <td colspan="2">{data?.num_bahia}</td>
            <th colspan="2">Nombre:</th>
            <td colspan="2">{data?.nombre_producto}</td>
            <th colspan="2">Numero de Orden:</th>
            <td colspan="2">{data?.numero_orden}</td>
          </tr>
          <tr>
            <th colspan="2">Serial Medidor:</th>
            <td colspan="2">{data?.codigo_producto ? 'n/a' : ''}</td>
            <th colspan="2">Codigo:</th>
            <td colspan="2">{data?.codigo_producto}</td>
            <th colspan="2">Numero Transacción:</th>
            <td colspan="2">{data?.numero_transaccion}</td>
          </tr>
          <tr>
            <th colspan="2">Tag Medidor:</th>
            <td colspan="2">{data?.api ? 'n/a' : ''}</td>
            <th colspan="2">Gravedad API:</th>
            <td colspan="2">{data?.api_producto}</td>
            <th colspan="2">Fecha Transacción:</th>
            <td colspan="2">{data?.fecha_transaccion}</td>
          </tr>
          <tr>
            <th colspan="2">K-factor:</th>
            <td colspan="2">{data?.mk_factor}</td>
            <th colspan="2">BSW (%):</th>
            <td colspan="2">{data?.bsw_producto}</td>
            <th colspan="2">Inicio Cargue:</th>
            <td colspan="2">{data?.start_cargue}</td>
          </tr>
          <tr>
            <th colspan="2">Meter-Factor (MF):</th>
            <td colspan="2">{data?.meter_factor}</td>
            <th colspan="2">--------</th>
            <td colspan="2">--------</td>
            <th colspan="2">Fin Cargue:</th>
            <td colspan="2">{data?.end_cargue}</td>
          </tr>
        </tbody>

        <thead>
          <tr class="titulo_tiket">
            <th class="sub_titulo_tiket" colspan="4">
              DATOS DE LECTURAS
            </th>
            <th class="sub_titulo_tiket" colspan="8">
              DATOS CALCULADOS
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colspan="2">Lectura Inicial<br/> (MRo):</th>
            <td colspan="2">{data?.t_inicial}</td>
            <th colspan="2">Volumen Indicado<br/> (IV=MRc - MRo):</th>
            <td colspan="2">{data?.v_indicado}</td>
            <th colspan="2">
              Factor de Corrección Combinado<br/> (CCF = CTL * CPL * MF ):
            </th>
            <td colspan="2">{data?.ccf}</td>
          </tr>
          <tr>
            <th colspan="2">Lectura Final (MRc):</th>
            <td colspan="2">{data?.t_final}</td>
            <th colspan="2">Volumen Bruto Observado <br/> (GOV = IV * MF):</th>
            <td colspan="2">{data?.gov}</td>
            <th colspan="2">Volumen Bruto Estándar (GSV = IV * CCF):</th>
            <td colspan="2">{data?.gsv}</td>
          </tr>
          <tr>
            <th colspan="2">Temperatura Promedio (F):</th>
            <td colspan="2">{data?.temperatura}</td>
            <th colspan="2">Factor de Corrección por Temperatura (CTL):</th>
            <td colspan="2">{data?.ctl}</td>
            <th colspan="2">
              Factor de Corrección por BSW <br/>(CSW = 1 - %BSW/100):
            </th>
            <td colspan="2">{data?.fcbsw}</td>
          </tr>
          <tr>
            <th colspan="2">Presión Promedio<br/> (PSI):</th>
            <td colspan="2">{data?.presion}</td>
            <th colspan="2">Fator de Corrección por Presión <br/>(CPL):</th>
            <td colspan="2">{data?.cpl}</td>
            <th colspan="2">Volumen Neto Estandar<br/> (NSV = GSV * CSW):</th>
            <td colspan="2">{data?.nsv}</td>
          </tr>
        </tbody>
      </table>
      </div>
      <div className="contenedor">
      <footer style={{ textAlign: "center" }}>
        <table id="cont_tiket">
          <tr rowspan="4">
            <th
              colspan="12"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              OBSERVACIONES:
            </th>
          </tr>

          <tr rowspan="2">
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Operador:
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Identificación:
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Firma:
            </th>
          </tr>
        </table>
        <table>
          <tr rowspan="2">
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Documento generado por software SYZAPI®
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              www.grupo-syz.com
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "right" }}
            >
              Generado el  {data?.fecha_impresion}
            </th>
          </tr>
        </table>
      </footer>
     
    </div>
    </div>
    </>
  );
});

export const ProductosToPrint = React.forwardRef((props, ref) => {
  const { data } = props;



  return (
    <>
    

    
    <div ref={ref}>
      <div className="contenedor">      
      <table id="header-table">
        <tr>
          <td class="header-title">
            <div>
              <h3 style={{ color: "black" }}>TIQUETE DE TRANSACCIÓN POR PRODUCTO</h3>
            </div>
          </td>
          <td class="header-logo">
            <img
              src="https://seeklogo.com/images/E/Ecopetrol-logo-575EE75C00-seeklogo.com.png"
              class="company-logo"
              alt="Company logo"
            />
          </td>
        </tr>
      </table>
      </div>
      <div className="contenedor">
      <table id="cont_tiket" class="table">
        
        
        
        <thead>
    <tr class="titulo_tiket">
      <th class="titulo_tiket" colspan="12">
        DATOS DEL MEDIDOR UNO
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th colspan="2">GOV_RT:</th>
      <td colspan="2">{data?.meter_uno.MTR1_GOV_RT}</td>
      <th colspan="2">DENS_RT:</th>
      <td colspan="2">{data?.meter_uno.MTR1_DENS_RT}</td>
      <th colspan="2">GAPI_RT:</th>
      <td colspan="2">{data?.meter_uno.MTR1_GAPI_RT}</td>
    </tr>
    <tr>
      <th colspan="2">IV_DEV:</th>
      <td colspan="2">{data?.meter_uno.MTR1_IV_DEV}</td>
      <th colspan="2">FLOW_RT:</th>
      <td colspan="2">{data?.meter_uno.MTR1_FLOW_RT}</td>
      <th colspan="2">PRES_RT:</th>
      <td colspan="2">{data?.meter_uno.MTR1_PRES_RT}</td>
    </tr>
    <tr>
    <th colspan="2">TEMP_RT:</th>
      <td colspan="2">{data?.meter_uno.MTR1_TEMP_RT}</td>
      <th colspan="2">VOLP_RT:</th>
      <td colspan="2">{data?.meter_uno.MTR1_VOLP_RT}</td>
    </tr>
    
  </tbody>


  <thead>
    <tr class="titulo_tiket">
      <th class="titulo_tiket" colspan="12">
        DATOS DEL MEDIDOR DOS
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th colspan="2">GOV_RT:</th>
      <td colspan="2">{data?.meter_dos.MTR2_GOV_RT}</td>
      <th colspan="2">DENS_RT:</th>
      <td colspan="2">{data?.meter_dos.MTR2_DENS_RT}</td>
      <th colspan="2">GAPI_RT:</th>
      <td colspan="2">{data?.meter_dos.MTR2_GAPI_RT}</td>
    </tr>
    <tr>
      <th colspan="2">IV_DEV:</th>
      <td colspan="2">{data?.meter_dos.MTR2_IV_DEV}</td>
      <th colspan="2">FLOW_RT:</th>
      <td colspan="2">{data?.meter_dos.MTR2_FLOW_RT}</td>
      <th colspan="2">PRES_RT:</th>
      <td colspan="2">{data?.meter_dos.MTR2_PRES_RT}</td>
    </tr>
    <tr>
    <th colspan="2">TEMP_RT:</th>
      <td colspan="2">{data?.meter_dos.MTR2_TEMP_RT}</td>
      <th colspan="2">VOLP_RT:</th>
      <td colspan="2">{data?.meter_dos.MTR2_VOLP_RT}</td>
    </tr>
    
  </tbody>
  
        
      </table>
      </div>
      <div className="contenedor">
      <footer style={{ textAlign: "center" }}>
        <table id="cont_tiket">
          <tr rowspan="4">
            <th
              colspan="12"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              OBSERVACIONES:
            </th>
          </tr>

          <tr rowspan="2">
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Operador:
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Identificación:
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Firma:
            </th>
          </tr>
        </table>
        <table>
          <tr rowspan="2">
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              Documento generado por software SYZAPI®
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "left" }}
            >
              www.grupo-syz.com
            </th>
            <th
              colspan="4"
              style={{ backgroundColor: "white", textAlign: "right" }}
            >
              Generado el  {data?.fecha_impresion}
            </th>
          </tr>
        </table>
      </footer>
     
    </div>
    </div>
    </>
  )
});

export function ImpresionT(props) {

  

  const componentRef = useRef(null);
  const { data } = props;
  const { getImpresiones, Impresiones } = useImpresiones();
  

  useEffect(() => {
    getImpresiones(data.id_orden);
  }, []);

  return (
    <>
      <ComponentToPrint ref={componentRef} data={Impresiones} />

      <ReactToPrint
        trigger={() => <button class="btn btn-danger">Print</button>}
        content={() => componentRef.current}
      />
    </>
  );
}
