import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import {
    addProductoMezclaApi,
    deleteProductoMezclaApi,
    getProductoMezclaApi,
    updateProductoMezclaApi
} from "../../api/A_Productos/productos";

export function useProductoMezcla() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    ProductoMezcla: null,
    ProductoMezclaDB: null,
  });

  const { loading, error, ProductoMezcla, ProductoMezclaDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getProductoMezclaDB = async () => {
    try {
      const [result, response] = await getProductoMezclaApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, ProductoMezclaDB: result.results }));
      
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getProductoMezcla = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getProductoMezclaApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, ProductoMezcla: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addProductoMezcla = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addProductoMezclaApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };


  const updateProductoMezcla = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateProductoMezclaApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteProductoMezcla = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteProductoMezclaApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    ProductoMezclaDB,
    ProductoMezcla,
    getProductoMezclaDB,
    getProductoMezcla,

    addProductoMezcla,
    updateProductoMezcla,
    deleteProductoMezcla,
  };
}
