import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { AddEditTransportadora, DocumentTransportadora } from "../../../Components/Client";
import { useTransportadora } from "../../../hooks";
import { IconButton } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';

export function Funciones() {
  const { deleteTransportadora  } = useTransportadora();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);



  const handleAdd = useCallback(() => {
    setTitleModal("Nueva transportadora");
    setContentModal(
      <AddEditTransportadora onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback((data) => {
    setTitleModal("Actualizar transportadora");
    setContentModal(
      <AddEditTransportadora
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Transportadora={data}
      />
    );
    openCloseModal();

  }, [openCloseModal, onRefetch]);

  const handleDelete = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      await deleteTransportadora(data.id_transportadora);
      Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [deleteTransportadora, onRefetch]);

  const documentosUpload = (data) => {
    setTitleModal("Documentos de la transportadora");
    setContentModal(
      <DocumentTransportadora data={data}/>
    );
    openCloseModal();
  };
   const columns = [
    
    {
      header: "Nit Transportadora",
      name: "nit_transportadora",
      maxWidth: 1000, defaultFlex: 1,
      // style: ({ data }) => ({
       
      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Razón social",
      name: "nombre_transportadora",
      maxWidth: 1000, defaultFlex: 1,
      // style: ({ data }) => ({
        
      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Email",
      name: "id_empresa.email",
      maxWidth: 1000,
      defaultFlex: 1,
      render: ({ data }) => (
        
          <div style={{  padding: '8px', width: '250px' }}>{data.id_empresa?.email}</div>
          
      ),
     
    }, 
    {
      header: "Celular",
      name: "id_empresa?.celular",
      maxWidth: 1000,
      defaultFlex: 1,
      render: ({ data }) => (
        
        <div style={{  padding: '8px', width: '250px' }}>{data.id_empresa?.celular}</div>
        
    ),
    },
    {
      header: "Telefono",
      name: "id_empresa?.telefono",
      maxWidth: 1000,
      defaultFlex: 1,
      render: ({ data }) => (
        
        <div style={{  padding: '8px', width: '150px' }}>{data.id_empresa?.telefono}</div>
        
    ),
      
    }, 
    {
      header: "Documentos",
      render: ({ data }) => (
        <>
          <Tooltip title="Documentos" arrow>
            <IconButton onClick={() => documentosUpload(data.id_transportadora)}>
              <AdfScannerIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
 


  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    columns,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
