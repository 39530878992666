import { useState } from "react";
import {
  getCountriesApi,
  getDepartmentsByCountryApi,
  getCitiesByDepartmentApi,
  getAddressesApi,
  addAddressesApi,
  updateAddressesApi,
  deleteAddressesApi,
} from "../../api/A_Principal/direciones";
import { useAuth } from "..";
import { alertas } from "../../Components/Comons/Alertas/alertas";

export function useDirecciones() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    countries: [],
    departments: [],
    cities: [],
    Address: null,
    AddressDB: null,
  });

  const { loading, error, countries, departments, cities, Address, AddressDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getCountries = async () => {
    try {
      const [result, response] = await getCountriesApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, countries: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getDepartmentsByCountry = async (country) => {
    try {
      const [result, response] = await getDepartmentsByCountryApi(country, auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, departments: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getCitiesByDepartment = async (department) => {
    try {
      const [result, response] = await getCitiesByDepartmentApi(department, auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, cities: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getAddressDB = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getAddressesApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, AddressDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const getAddress = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getAddressesApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Address: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const addAddress = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addAddressesApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        throw new Error("Ya se ha registrado una dirección con este código");
      }
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateAddress = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateAddressesApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        throw new Error("Ya se ha registrado una planta con este NIT");
      }
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteAddress = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteAddressesApi(id, auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    countries,
    departments,
    cities,
    Address,
    AddressDB,
    getCountries,
    getDepartmentsByCountry,
    getCitiesByDepartment,
    getAddress,
    getAddressDB,
    addAddress,
    updateAddress,
    deleteAddress,
  };
}
