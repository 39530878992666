import React, { useEffect } from "react";
// import { Loader } from "semantic-ui-react";
import CircularProgress from '@mui/material/CircularProgress';
import { HeaderPage } from "../../../Components/Client";
import {
  DataGridBasic,
  ModalBasic
} from "../../../Components/Comons";
import { useOperador } from "../../../hooks";
import { filterValue } from "./operadoresFormat";
import { Funciones } from "./Funciones";

export function OperadoresAdmin() {
  const { loading, Operador, getOperador } = useOperador();

  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    handleViews,
    openCloseModal,
    columns
  } = Funciones();

  useEffect(() => {
    getOperador();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>
      {/* <HeaderPage title="Operador" btnTitle="Nuevo operador" icono="plus square icon" btnClick={handleAdd} /> */}

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>

          <DataGridBasic
            data={Operador.results}
            title="Operador"
            id="id_operador"
            filterValue={filterValue}
            columns={columns}
            updateData={handleUpdate}
            onDeleteData={handleDelete}
            visualizacionData={handleViews}
            msjVis="Visualizar datos del operador"
          />
        </>
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos
