import { useState } from "react";
import {
  
  getFraccionesApi,
  addFraccionesApi,
  updateFraccionesApi,
  deleteFraccionesApi,

  getSeccionesPrincipalApi,
  addSeccionesPrincipalApi,
  updateSeccionesPrincipalApi,
  deleteSeccionesPrincipalApi,

  getSeccionesFondoApi,
  addSeccionesFondoApi,
  updateSeccionesFondoApi,
  deleteSeccionesFondoApi,

  getTablaAforoApi,
  addTablaAforoApi,
  updateTablaAforoApi,
  deleteTablaAforoApi,

  getDataSeccionesApi,
  addDataSeccionesApi,
  updateDataSeccionesApi,
  deleteDataSeccionesApi
} from "../../api/TablasAforo";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useFracciones() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    fracciones: null,
    FraccionesDB: null,
  });

  const { loading, error, fracciones, FraccionesDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getFraccionesDB = async () => {
    try {
      const [result, response] = await getFraccionesApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, FraccionesDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getFracciones = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getFraccionesApi(auth.token, auth.tenant);

      if (response.status === 401) {
        SessionExpired();
        logout();
      }
      if (response.status === 403) {
        unauthorized();
      }

      setState((prevState) => ({ ...prevState, loading: false, fracciones: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addFracciones = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addFraccionesApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateFracciones = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateFraccionesApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteFracciones = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteFraccionesApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    fracciones,
    FraccionesDB,
    getFraccionesDB,
    getFracciones,
    addFracciones,
    updateFracciones,
    deleteFracciones,
  };
}


  ////////////////////////////////////



export function useSecciones() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    seccionesPrincipal: null,
    seccionesFondo: null,
    SeccionesPrincipalDB: null,
  });

  const { loading, error, seccionesPrincipal, seccionesFondo, SeccionesPrincipalDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getSeccionesPrincipalDB = async () => {
    try {
      const [result, response] = await getSeccionesPrincipalApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, SeccionesPrincipalDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getSeccionesPrincipal = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getSeccionesPrincipalApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, seccionesPrincipal: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addSeccionesPrincipal = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addSeccionesPrincipalApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateSeccionesPrincipal = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateSeccionesPrincipalApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteSeccionesPrincipal = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteSeccionesPrincipalApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getSeccionesFondoDB = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getSeccionesFondoApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, seccionesFondo: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getSeccionesFondo = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getSeccionesFondoApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, seccionesFondo: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addSeccionesFondo = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addSeccionesFondoApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateSeccionesFondo = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateSeccionesFondoApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteSeccionesFondo = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteSeccionesFondoApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    seccionesPrincipal,
    SeccionesPrincipalDB,
    getSeccionesFondoDB,
    getSeccionesPrincipalDB,
    getSeccionesPrincipal,
    addSeccionesPrincipal,
    updateSeccionesPrincipal,
    deleteSeccionesPrincipal,
    seccionesFondo,
    getSeccionesFondo,
    addSeccionesFondo,
    updateSeccionesFondo,
    deleteSeccionesFondo,
  };
}


    ////////////////////////////////////

    export function useTablaAforo() {
      const { auth, logout } = useAuth();
      const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();
    
      const [state, setState] = useState({
        loading: true,
        error: null,
        tablasAforo: null,
        tablasAforoDB: null
      });
    
      const { loading, error, tablasAforo, tablasAforoDB } = state;
    
      const handleResponse = (response) => {
        if (response.status === 401) {
          SessionExpired();
          logout();
        } else if (response.status === 403) {
          unauthorized();
        }
      };
    
      const getTablasAforoDB = async () => {
        try {
          const [result, response] = await getTablaAforoApi(auth.token, auth.tenant);
          setState((prevState) => ({ ...prevState, tablasAforoDB: result.results }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, error }));
        }
      };
    
      const getTablasAforo = async () => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await getTablaAforoApi(auth.token, auth.tenant);
    
          if (response.status === 401) {
            SessionExpired();
            logout();
          }
          if (response.status === 403) {
            unauthorized();
          }
    
          setState((prevState) => ({ ...prevState, loading: false, tablasAforo: result }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
        }
      };
    
      const addTablasAforo = async (data) => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await addTablaAforoApi(data, auth.token, auth.tenant);
    
          if (response.status === 400) {
            errores();
          }
          if (response.status === 403) {
            throw new Error(unauthorizedModal());
          }
    
          setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
          throw error;
        }
      };
    
      const updateTablasAforo = async (id, data) => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await updateTablaAforoApi(id, data, auth.token, auth.tenant);
          
          if (response.status === 400) {
            errores();
          }
          if (response.status === 403) {
            throw new Error(unauthorizedModal());
          }
    
          setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
          throw error;
        }
      };
    
      const deleteTablasAforo = async (id) => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await deleteTablaAforoApi(id, auth.token, auth.tenant);
          if (response.status === 403) {
            throw new Error(unauthorizedModal());
          }
          setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
        }
      };
    
      return {
        loading,
        error,
        tablasAforo,
        tablasAforoDB,
        getTablasAforoDB,
        getTablasAforo,
        addTablasAforo,
        updateTablasAforo,
        deleteTablasAforo,
      };
    }

    ////////////////////////////////////

    export function useDataSecciones() {
      const { auth, logout } = useAuth();
      const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();
    
      const [state, setState] = useState({
        loading: true,
        error: null,
        dataSecciones: null,
      });
    
      const { loading, error, dataSecciones } = state;
    
      const handleResponse = (response) => {
        if (response.status === 401) {
          SessionExpired();
          logout();
        } else if (response.status === 403) {
          unauthorized();
        }
      };
    
      const getDataSecciones = async () => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await getDataSeccionesApi(auth.token, auth.tenant);
          handleResponse(response);
          setState((prevState) => ({ ...prevState, loading: false, dataSecciones: result }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
        }
      };
    
      const addDataSecciones = async (data) => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await addDataSeccionesApi(data, auth.token, auth.tenant);
          if (response.status === 400) {
            errores();
          } else if (response.status === 403) {
            throw new Error(unauthorizedModal());
          }
          setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
          throw error;
        }
      };
    
      const updateDataSecciones = async (id, data) => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await updateDataSeccionesApi(id, data, auth.token, auth.tenant);
          if (response.status === 400) {
            errores();
          } else if (response.status === 403) {
            throw new Error(unauthorizedModal());
          }
          setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
          throw error;
        }
      };
    
      const deleteDataSecciones = async (id) => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await deleteDataSeccionesApi(id, auth.token, auth.tenant);
          if (response.status === 403) {
            throw new Error(unauthorizedModal());
          }
          setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
        }
      };
    
      return {
        loading,
        error,
        dataSecciones,
        getDataSecciones,
        addDataSecciones,
        updateDataSecciones,
        deleteDataSecciones,
      };
    }
    