import { useState } from "react";
import {
  getAditivosApi,
  addAditivosApi,
  updateAditivosApi,
  deleteAditivosApi,
} from "../../api/A_Productos/aditivos";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useAditivos() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Aditivos: null,
    AditivosDB: null,
  });

  const { loading, error, Aditivos, AditivosDB } = state;

  const getAditivosDB = async () => {
    try {
      const [result, response] = await getAditivosApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, AditivosDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getAditivos = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getAditivosApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Aditivos: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addAditivos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addAditivosApi(data, auth.token, auth.tenant);
      
      if (response.status == 400) {
        if (result?.codigo_aditivo) {
          throw new Error(
            "Ya existe un aditivo registrado con este codigo"
          );
        }  else {
          errores();
        }
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateAditivos = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateAditivosApi(id, data, auth.token, auth.tenant);
      if (response.status == 400) {
        if (result?.codigo_aditivo) {
          throw new Error(
            "Ya existe un aditivo registrado con este codigo"
          );
        }  else {
          errores();
        }
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteAditivos = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteAditivosApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    AditivosDB,
    Aditivos,
    getAditivosDB,
    getAditivos,
    addAditivos,
    updateAditivos,
    deleteAditivos,
  };
}
