import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useAuth } from '../../hooks';
import { BASE_API_PUBLIC } from '../../utils/constants';
import { BASE_API } from '../../utils/constants';

export function GenerarLicencias() {
  const [keyLicencia, setKeyLicencia] = useState('');
  const [nuevaFecha, setNuevaFecha] = useState('');
  const { auth } = useAuth();
  const generarToken = () => {
    const url = `${BASE_API}/tokens/generar/licencia/`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
         Authorization: `Bearer ${auth.token}`,
      },
      body: JSON.stringify({ key_licencia: keyLicencia, nueva_fecha: nuevaFecha }),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Hubo un error al generar el token');
        }
      })
      .then(data => {
        Swal.fire({
          icon: 'success',
          title: 'Token generado con éxito',
          text: `Token: ${data.token}`,
        });
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message,
        });
      });
  };

  return (
    <div className="container">
    
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="keyLicencia">Ingrese la clave de la licencia:</label>
                <input type="text" className="form-control" id="keyLicencia" value={keyLicencia} onChange={(event) => setKeyLicencia(event.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="nuevaFecha">Ingrese la nueva fecha de expiración:</label>
                <input type="date" className="form-control" id="nuevaFecha" value={nuevaFecha} onChange={(event) => setNuevaFecha(event.target.value)} />
              </div>
              <br />
              <button className="btn btn-danger" onClick={generarToken}>Generar Token</button>
            </div>
          </div>
        </div>
      
    </div>
  );
}