import { useState } from "react";
import {
  getParametrizacionDecimalesApi,
  updateParametrizacionDecimalesApi,
  getParametrizacionDecimalesAdminApi
  
} from "../../api/A_Parametrizaciones/ParametrizacionDecimal";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useDecimales() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Decimales: null,
    DecimalesCliente: null,

  });

  const { loading, error, Decimales, DecimalesCliente } = state;

  const getDecimales = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getParametrizacionDecimalesApi(auth.token, auth.tenant);
      if (response.status === 401) {
        SessionExpired();
        logout();
      }
      if (response.status === 403) {
        unauthorized();
      }
      setState((prevState) => ({ ...prevState, loading: false, Decimales: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getDecimalesAdmin = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getParametrizacionDecimalesAdminApi(auth.token, auth.tenant);
      if (response.status === 401) {
        SessionExpired();
        logout();
      }
      if (response.status === 403) {
        unauthorized();
      }
      setState((prevState) => ({ ...prevState, loading: false, DecimalesCliente: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const updateDecimales = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await updateParametrizacionDecimalesApi(id, data, auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  

  return {
    loading,
    error,
    Decimales,
    DecimalesCliente,
    getDecimales,
    updateDecimales,
    getDecimalesAdmin
  };
}
