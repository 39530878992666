import React, { useState } from "react";
import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack"
import SvgIcon from "@mui/material/SvgIcon"
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { CompaniesSearch } from "../../../sections/companies/companies-search";
import { TanquesCard } from "../../../sections/companies/tank-card";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export function CardsTanques(props) {
  const {
    data,
    activeChecklist,
    btnClick,
    onDeleteData,
    updateData,
    volumen,
    temperatura,
    makeChecklist,
    viewChecklist,
    viewHistoricChecklists,
  } = props;
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const itemsPerPage = 12; // mostrar 2 tanques por página

  const displayedData = data.filter((tanque) =>
    tanque.nombre_tanque.toLowerCase().includes(searchText.toLowerCase())
  );

  // Verificar si displayedData es null o undefined antes de acceder a su propiedad 'length'
  const totalItems = displayedData ? displayedData.length : 0;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginatedData = displayedData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (searchValue) => {
    setSearchText(searchValue);
    setPage(1); // Resetear la página actual cuando se realiza una búsqueda
  };
  const convertToCSV = (objArray) => {
    const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    const keys = Object.keys(array[0]);

    str += keys.join(",") + "\r\n";

    array.forEach((obj) => {
      let line = "";
      keys.forEach((key, index) => {
        if (index > 0) line += ",";
        if (typeof obj[key] === "object" && obj[key] !== null) {
          line += `"${JSON.stringify(obj[key]).replace(/"/g, '""')}"`;
        } else {
          line += obj[key];
        }
      });
      str += line + "\r\n";
    });

    return str;
  };

  const handleDownload = () => {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Box component="main"
        sx={{
          flexGrow: 1,
          py: 2,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Stack direction="row" justifyContent="space-between" spacing={4} alignItems="center">
                  <Stack spacing={1}>
                    <Typography variant="h4">
                      Administración de tanques
                    </Typography>
                    <Stack alignItems="center" direction="row" spacing={1}>
                    <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                    onClick={handleDownload}
                  >
                    Descargar
                  </Button>
                    </Stack>
                  </Stack>
                  <div>
                    <Button
                      sx={{
                        backgroundColor: "#D0302B",
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#BEBEBE", // Color gris cuando se hace hover
                        },
                      }}
                      startIcon={
                        <SvgIcon fontSize="small">
                          <PlusIcon />
                        </SvgIcon>
                      }
                      onClick={btnClick}
                    >
                      CREAR TANQUE
                    </Button>
                  </div>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12}>
                <CompaniesSearch onSearchChange={handleSearchChange} x={1} />
              </Grid>

              {paginatedData.map((tanque) => (
                <Grid item xs={12} md={6} lg={4} key={tanque.id_tanque}>
                  <TanquesCard
                    tank={tanque}
                    activeChecklist={activeChecklist}
                    volumen={volumen}
                    actualizar={updateData}
                    eliminar={onDeleteData}
                    temperatura={temperatura}
                    makeChecklist={makeChecklist}
                    //viewChecklist={viewChecklist}
                    viewHistoricChecklists={viewHistoricChecklists}
                  />
                </Grid>
              ))}
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Pagination
                count={totalPages}
                size="small"
                page={page}
                onChange={handlePageChange}
              />
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
