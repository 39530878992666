import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;
  
// Función para obtener los datos 
export async function getConductorApi(token,schema) {
  const url = `${BASE_URL}${schema}/conductores/conductorA`;
  return makeRequest(url, "GET", token);
}
  
// Función para obtener los datos 
export async function getOrdenesDescargaderoApi(token,schema) {
  const url = `${BASE_URL}${schema}/descargadero/orden-descargadero`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getOperadorApi(token, schema) {
  const url = `${BASE_URL}${schema}/operadores/operadores`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos de un cliente
export async function getClienteApi(token, schema) {
  const url = `${BASE_URL}${schema}/clientes/clientesA`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getProveedoresApi(token, schema) {
  const url = `${BASE_URL}${schema}/proveedores/proveedoresA`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getOrdenReciboApi(token, schema) {
  const url = `${BASE_URL}${schema}/descargadero/orden-descargadero`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getOrdenDespachoApi(token, schema) {
  const url = `${BASE_URL}${schema}/transacciones/orden`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getMezclasApi(token,schema) {
  const url = `${BASE_URL}${schema}/mezclas/mezcla`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getProductoBaseApi(token,schema) {
  const url = `${BASE_URL}${schema}/productos/productosbase`;
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos 
export async function getPlantasApi(token,schema) {
  const url = `${BASE_URL}${schema}/plantas/plantas`;
  return makeRequest(url, "GET", token);
}

export async function getTurnosApi(token,schema) {
  const url = `${BASE_URL}${schema}/turno/turnos/`;
  return makeRequest(url, "GET", token);
}

export async function getTransportadoraApi(token,schema,filter,transportadora) {
  let url = `${BASE_URL}${schema}/transportadora/transportadora`;
  if (filter) {
    url += `?filter=${filter}`;
  }
  if (transportadora) {
    url += `&transp=${transportadora}`;
  }
  return makeRequest(url, "GET", token);
}
