import { useState } from "react";
import {
  getSistemaMedicionApi,
  updateSistemaMedicionApi,
  getSistemaMedicionAdminApi
} from "../../api/A_Parametrizaciones/SistemaMedicion";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useSistemaMedicion() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    SistemaMedicion: null
  });

  const { loading, error, SistemaMedicion } = state;

  const getSistemaMedicion = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getSistemaMedicionApi(auth.token, auth.tenant);
      if (response.status === 401) {
        SessionExpired();
        logout();
      }
      if (response.status === 403) {
        unauthorized();
      }
      setState((prevState) => ({ ...prevState, loading: false, SistemaMedicion: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };
  
  const getSistemaMedicionAdmin = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getSistemaMedicionAdminApi(auth.token, auth.tenant);
      if (response.status === 401) {
        SessionExpired();
        logout();
      }
      if (response.status === 403) {
        unauthorized();
      }
      setState((prevState) => ({ ...prevState, loading: false, SistemaMedicion: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };


  const updateSistemaMedicion = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await updateSistemaMedicionApi(id, data, auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };


  return {
    loading,
    error,
    SistemaMedicion,
    getSistemaMedicion,
    updateSistemaMedicion,
    getSistemaMedicionAdmin
  };
}
