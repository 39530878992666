export const columns = [
    
    {
        header: 'Nombre',
        name: 'nombre',
        maxWidth: 2000, defaultFlex: 1
    },
    
   
  ]

  export const filterValue = [
    
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    
    
  ];