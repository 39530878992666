import React, { useState } from "react";
import { Tab } from 'semantic-ui-react';
import { TanquesAdmin, ProductoTanqueAdmin } from "../index";

export function GroupedTanques() {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (e, { activeIndex }) => {
        setActiveTab(activeIndex);
    };

    const panes = [
        {
            menuItem: 'Tanques',
            render: () => <Tab.Pane><TanquesAdmin /></Tab.Pane>,
        },
        {
            menuItem: 'Relación producto-tanque',
            render: () => <Tab.Pane><ProductoTanqueAdmin /></Tab.Pane>,
        },
    ];

    return (
        <Tab panes={panes} activeIndex={activeTab} onTabChange={handleTabChange} />
    );
}
