import React, { useState } from "react";
import { Tab } from 'semantic-ui-react';
import { AdministracionPage } from '../AdministracionPage/AdministracionPage';
import { UserBaseAdmin } from '../../Client/UserBasePage';
import { MulticlienteTab } from '../MulticlienteTab';

export function GroupedAdminPages() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (e, { activeIndex }) => {
    setActiveTab(activeIndex);
  };

  const panes = [
    {
      menuItem: 'Licencias',
      render: () => <Tab.Pane><MulticlienteTab /></Tab.Pane>,
    },
    {
      menuItem: 'Clientes internos',
      render: () => <Tab.Pane><UserBaseAdmin /></Tab.Pane>,
    },
    {
      menuItem: 'Módulos',
      render: () => <Tab.Pane><AdministracionPage /></Tab.Pane>,
    },
  ];

  return (
    <Tab
      panes={panes}
      activeIndex={activeTab}
      onTabChange={handleTabChange}
    />
  );
}
