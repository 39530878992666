import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from "react";
import { ButtonScript, CardsControladores, HeaderPage } from "../../../Components/Client";
import { ModalBasic } from "../../../Components/Comons";
import { useControladores, useTcs3000 } from "../../../hooks";
import { columns, filterValue } from "./ControladoresFormat";
import { Funciones } from "./Funciones";

export function ControladoresAdmin() {
  const { loading, Controladores, getControladores } = useControladores();
  const { get_save_tcs_prod } = useTcs3000();
  const {
    handleAddControladores,
    handleUpdateControladores,
    handleDeleteControladores,
    handleGetProducto,
    refetch,
    contentModal,
    showModal,
    titleModal,
    openCloseModal,
    handleAssignTo,
    handleAddEditPlacasInTcs,
    setProductMultLd,
    addEditHazards,
    activateCalMode,
  } = Funciones();

  const [filteredCtrl, setFilteredCtrl] = useState([])

  useEffect(() => {
    getControladores();
  }, [refetch]);


  useEffect(() => {
    const filtered = Controladores?.filter(item => item.tipo === "kunbus" || item.tipo === "disp_comb" || item.tipo === "comp_flujo" || item.tipo === "reg_comb")
    setFilteredCtrl(filtered);
  }, [Controladores])

  const MemoizedModalBasic = React.memo(ModalBasic);

  return (
    <>
      <>
        <HeaderPage
          title="Controladores"
          btnTitle="Nuevo controlador"
          icono="plus square icon"
          btnClick={handleAddControladores}
        />

        {/* <ButtonScript /> */}

        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <CardsControladores
              data={filteredCtrl || []}
              title="Controladores"
              id="idIncremental"
              columns={columns}
              filterValue={filterValue}
              updateData={handleUpdateControladores}
              onDeleteData={handleDeleteControladores}
              tcsProdUpdt={get_save_tcs_prod}
              assignTo={handleAssignTo}
              handleGetProducto={handleGetProducto}
              savePlates={handleAddEditPlacasInTcs}
              setMultLdProds={setProductMultLd}
              addEditHazards={addEditHazards}
              activateCalMode={activateCalMode}
            />
          </>
        )}
      </>

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
