import { useState } from "react";
import { getIslasApi, addIslasApi, updateIslasApi, patchIslasApi, deleteIslasApi } from "../../api/A_Plantas/islas";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useIslas() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Islas: null,
    IslasDB: null,
  });

  const { loading, error, Islas, IslasDB } = state;

  const getIslasDB = async () => {
    try {
      const [result, response] = await getIslasApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, IslasDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getIslas = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getIslasApi(auth.token, auth.tenant);

      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
      }

      setState((prevState) => ({ ...prevState, loading: false, Islas: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addIslas = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addIslasApi(data, auth.token, auth.tenant);

      if (response.status === 400) {
        if (result?.nit_Islas) {
          throw new Error("Ya existe un Islas registrado con este NIT");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateIslas = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateIslasApi(id, data, auth.token, auth.tenant);

      if (response.status === 400) {
        if (result?.nit_Islas) {
          throw new Error("Ya existe un Islas registrado con este NIT");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const patchIslas = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await patchIslasApi(id, data, auth.token, auth.tenant);

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  }

  const deleteIslas = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteIslasApi(id, auth.token, auth.tenant);

      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    IslasDB,
    Islas,
    getIslasDB,
    getIslas,
    addIslas,
    updateIslas,
    patchIslas,
    deleteIslas,
  };
}
