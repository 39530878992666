export const MQTTConfcolumns = [
    { title: "Dirección corredor", dataIndex: "broker_address", maxWidth: 1000, defaultFlex: 1 },
    { title: "Puerto", dataIndex: "port", maxWidth: 1000, defaultFlex: 1 },
    { title: "QoS", dataIndex: "qos", maxWidth: 1000, defaultFlex: 1 },
]

export const MODBUSConfcolumns = [
    { title: "IP", dataIndex: "ip", maxWidth: 1000, defaultFlex: 1 },
    { title: "Puerto", dataIndex: "port", maxWidth: 1000, defaultFlex: 1 },
    { title: "Base", dataIndex: "base", maxWidth: 1000, defaultFlex: 1 },
]

export const OPCConfcolumns = [
    { title: "Nombre usuario", dataIndex: "username", maxWidth: 1000, defaultFlex: 1 },
    { title: "Contraseña", dataIndex: "password", maxWidth: 1000, defaultFlex: 1 },
    { title: "Url servidor", dataIndex: "server_url", maxWidth: 1000, defaultFlex: 1 },
    { title: "Id nodo", dataIndex: "node_id", maxWidth: 1000, defaultFlex: 1 },
]

export const variablesColumns = [
    { title: "Tipo dato", dataIndex: "data_type", maxWidth: 1000, defaultFlex: 1 },
    { title: "Divisor", dataIndex: "divisor", maxWidth: 1000, defaultFlex: 1 },
    { title: "Longitud", dataIndex: "length", maxWidth: 1000, defaultFlex: 1 },
    { title: "Nombre", dataIndex: "name", maxWidth: 1000, defaultFlex: 1 },
    { title: "ID Nodo", dataIndex: "node_id", maxWidth: 1000, defaultFlex: 1 },
    { title: "Registro", dataIndex: "register", maxWidth: 1000, defaultFlex: 1 },
    { title: "Tipo registro", dataIndex: "register_type", maxWidth: 1000, defaultFlex: 1 },
    { title: "Tanque", dataIndex: "tanque_rel", maxWidth: 1000, defaultFlex: 1 },
    { title: "Tipo variable", dataIndex: "tipo_variable", maxWidth: 1000, defaultFlex: 1 },
    { title: "Topic", dataIndex: "topic", maxWidth: 1000, defaultFlex: 1 },
]

