import { useState, useCallback } from "react";
import {
  getAccesosApi, addAccesosApi, getConfigTurnosApi, updateConfigTurnosApi, getDataFromBiometricApi, postDataToBiometricApi,
  getPersonDataFromBiometricApi, postPersonDataToBiometricApi, getIslaConfApi, postIslaConfApi, updateIslaConfApi, deleteIslaConfApi,
  start_access_proccess,
} from "../../api/A_Accesos/accesos";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";
import Swal from 'sweetalert2';

export function useAccesos() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Accesos: null,
    ConfigT: null,
    responseUpdt: null,
    loadingConfigT: null,
    bioData: null,
    responseContent: null,
    islaConf: null,
    loading_start_stop_access: null,
  });

  const { loading, error, Accesos, ConfigT, responseUpdt, loadingConfigT, bioData, responseContent, islaConf, loading_start_stop_access } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getAccesos = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getAccesosApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Accesos: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getConfigTurnos = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getConfigTurnosApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, ConfigT: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addAccesos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addAccesosApi(data, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateConfigTurnos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loadingConfigT: true }));
      const [result, response] = await updateConfigTurnosApi(data, auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, loadingConfigT: false, responseUpdt: response.status }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  }

  const getDataFromBiometric = async () => {
    try {
      setState((prevState) => ({ ...prevState, loadingConfigT: true }));
      const [result, response] = await getDataFromBiometricApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, loadingConfigT: false, bioData: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loadingConfigT: false, error }));
      throw error;
    }
  }

  const getPersonDataFromBiometric = async (data) => {
    try {
      const searchData = { "employeeNo": data.toString() }
      setState((prevState) => ({ ...prevState, loadingConfigT: true }))
      const [result, response] = await getPersonDataFromBiometricApi(searchData, auth.token, auth.tenant)

      if (response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error',
          text: 'No se ha podido establecer la conexión con el dispositivo',
        });
      }

      setState((prevState) => ({ ...prevState, loadingConfigT: false, bioData: result }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, loadingConfigT: false, error }));

      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: error.msg || 'No se ha podido establecer la conexión con el dispositivo',
      });

      throw error
    }
  }

  const postPersonDataToBiometric = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }))
      const [result, response] = await postPersonDataToBiometricApi(data, auth.token, auth.tenant)

      if (!response.ok) {
        throw new Error(`Ocurrió un error: ${result.msg}`);
      }

      setState((prevState) => ({ ...prevState, loading: false, responseContent: result }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error
    }
  }

  const postDataToBiometric = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await postDataToBiometricApi(data, auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  }

  const getIslaConf = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getIslaConfApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, islaConf: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const handleAddIslaConf = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await postIslaConfApi(data, auth.token, auth.tenant)
      setState((prevState) => ({ ...prevState, loading: false }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }))
      throw error
    }
  }

  const handleUpdateIslaConf = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateIslaConfApi(id, data, auth.token, auth.tenant)
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }))
      throw error
    }
  }

  const handleDeleteIslaConf = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }))
      const [result, response] = await deleteIslaConfApi(id, auth.token, auth.tenant)
      setState((prevState) => ({ ...prevState, loading: false }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }))
    }
  }

  const handle_start_stop_access = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading_start_stop_access: true }))
      const [result, response] = await start_access_proccess(data, auth.token, auth.tenant)

      if (!response.ok) {
        throw new Error(`Ocurrió un error: ${response.status}`);
      }

      setState((prevState) => ({ ...prevState, loading_start_stop_access: false }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading_start_stop_access: false }));

      // SweetAlert con el mensaje de que la petición falló o hubo un error
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message || 'Ocurrió un error',
      });

      throw error; // vuelve a lanzar el error para qeu sea usado luego si es necesario
    }
  }

  return {
    loading,
    error,
    Accesos,
    getAccesos,
    addAccesos,
    ConfigT,
    getConfigTurnos,
    updateConfigTurnos,
    responseUpdt,
    loadingConfigT,
    getDataFromBiometric,
    bioData,
    postDataToBiometric,
    postPersonDataToBiometric,
    getPersonDataFromBiometric,
    loadingConfigT,
    responseContent,
    getIslaConf,
    handleAddIslaConf,
    handleUpdateIslaConf,
    handleDeleteIslaConf,
    islaConf,
    handle_start_stop_access,
    loading_start_stop_access,
  };
}
