import { useState } from "react";
import { getControlDocTransportadoraApi, addControlDocTransportadoraApi, updateControlDocTransportadoraApi, deleteControlDocTransportadoraApi } from "../../api/A_Transportadora/documentosTransportadoraControl";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export  function useControlDocTransportadora() {
    const { auth, logout } = useAuth();
    const navigate = useNavigate();
    const { unauthorized, SessionExpired, unauthorizedModal } = alertas();
  
    const [state, setState] = useState({
      loading: true,
      error: null,
      ControlDocTransportadora: null,
      ControlDocTransportadoraDB: null,
    });
  
    const handleResponse = (response) => {
      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
        navigate("/");
      }
    };
  
    const { loading, error, ControlDocTransportadora, ControlDocTransportadoraDB } = state;
  
    const getControlDocTransportadoraDB = async () => {
      try {
        const [result, response] = await getControlDocTransportadoraApi(auth.token, auth.tenant);
        setState((prevState) => ({ ...prevState, ControlDocTransportadoraDB: result.results }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, error }));
      }
    };
  
    const getControlDocTransportadora = async (data) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await getControlDocTransportadoraApi(auth.token, auth.tenant, data);
        handleResponse(response);
        setState((prevState) => ({ ...prevState, loading: false, ControlDocTransportadora: result.results }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
      }
    };
  
    const addControlDocTransportadora = async (data) => {
     
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await addControlDocTransportadoraApi(data, auth.token, auth.tenant);
        if (response.status === 403) {
          throw new Error(unauthorizedModal());
        }
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
        throw error;
      }
    };
  
    const updateControlDocTransportadora = async (id, data) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await updateControlDocTransportadoraApi(id, data, auth.token, auth.tenant);
        if (response.status === 403) {
          throw new Error(unauthorizedModal());
        }
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
        throw error;
      }
    };
  
    const deleteControlDocTransportadora = async (id) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await deleteControlDocTransportadoraApi(id, auth.token, auth.tenant);
        if (response.status === 403) {
          throw new Error(unauthorizedModal());
        }
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
      }
    };
  
    return {
      loading,
      error,
      ControlDocTransportadoraDB,
      ControlDocTransportadora,
      getControlDocTransportadoraDB,
      getControlDocTransportadora,
      addControlDocTransportadora,
      updateControlDocTransportadora,
      deleteControlDocTransportadora,
    };
  }
  
