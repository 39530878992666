import React, { useCallback, useEffect, useState } from 'react';
import { useControlDocTransportadora } from '../../../hooks';
import { TablaControl } from '../../Comons';

const suggestions = [
  "RUT (Registro Único Tributario)",
  "Licencia de funcionamiento",
  "Certificado de Cámara de Comercio",
  "Seguro obligatorio",
  "Certificado de antecedentes",
  "Documentación de vehículos (matrícula, SOAT, revisión técnico-mecánica)",
  "Plan de seguridad vial",
  "Autorizaciones especiales",
  "Contratos de servicio",
  "Manifiesto de carga"
];

export function ModuloControlTransportadora() {
  const { ControlDocTransportadora, getControlDocTransportadora, addControlDocTransportadora, updateControlDocTransportadora, deleteControlDocTransportadora } = useControlDocTransportadora();
  const [refetch, setRefetch] = useState(false);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  useEffect(() => {
    getControlDocTransportadora();
  }, [refetch]);
  
  return (
    <>
      <TablaControl 
        documentos={ControlDocTransportadora}
        addDocumentos={addControlDocTransportadora}
        updateDocumentos={updateControlDocTransportadora}
        deleteDocumentos={deleteControlDocTransportadora}
        onRefetch={onRefetch}
        suggestions={suggestions}
      />
    </>
  )
}
