import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from "react";
import {
  AddEditParametrizacionBaseDecimales,
  HeaderPage,
} from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useDecimales } from "../../../hooks";
import { columns, filterValue } from "./ParametrizacionDFormat";

export function ParametrizacionDecimalesBseAdmin() {

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [sizeModal, setsizeModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const { loading,  DecimalesCliente, getDecimalesAdmin } = useDecimales();

  useEffect(() => {
    getDecimalesAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateDatos = (data) => {
    setTitleModal("Actualizar parametrización decimales");
    setContentModal(
      <AddEditParametrizacionBaseDecimales
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Datos={data}
      />
    );
    openCloseModal();
  };

  console.log(DecimalesCliente);
  return (
    <>
      <HeaderPage title="Configuración decimales" icono="plus square icon" />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={ DecimalesCliente}
          columns={columns}
          id="id_parametrizacion"
          filterValue={filterValue}
          title="Parametrización de decimales"
          updateData={updateDatos}
          descarga={1}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
        size={sizeModal}
      />
    </>
  );
}

//Columnas que se envian en forma de props al componente tabla basic para representer sus campos
