import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useConciliaciones } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

const options = [
  { key: "1", text: "Ventas", value: "1" },
  { key: "2", text: "Recargas", value: "2" },
  { key: "3", text: "Evaporacion", value: "3" },
  { key: "4", text: "Derrame", value: "4" },
];

const columnas = [
  "Fecha conciliacion",
  "Fecha inicial",
  "Fecha final",
  "Inicial",
  "Ventas",
  "Recargas",
  "Evaporacion",
  "Derrame",
];

export function ComponentConciliaciones(props) {
  const { Successful } = alertas();
  const { onClose, Conciliacionesu, tanque, producto } = props;
  const [refetch, setRefetch] = useState(false);

  const {
    Conciliaciones,
    addConciliaciones,
    updateConciliaciones,
    getConciliaciones,
  } = useConciliaciones();

  useEffect(() => {
    getConciliaciones();
  }, [refetch]);
  const onRefetch = () => setRefetch((prev) => !prev);

  const formik = useFormik({
    initialValues: initialValues(Conciliacionesu),
    validationSchema: Yup.object(newSchame()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      formValue.id_tanque = tanque;
      formValue.id_producto = producto;
      try {
        if (Conciliacionesu)
          await updateConciliaciones(
            Conciliacionesu.id_conciliaciones,
            formValue
          );
        else await addConciliaciones(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });


  const divStyle = {
    textAlign: "left",
  };



  return (
    <>
      <form
        className="add-edit-persona-form"
        style={divStyle}
        onSubmit={formik.handleSubmit}
      >
        <FormControl fullWidth margin="normal" error={!!formik.errors.tipo}>
          <InputLabel>Opciones</InputLabel>
          <Select
            label="Opciones"
            value={formik.values.tipo}
            onChange={(e) => formik.setFieldValue("tipo", e.target.value)}
          >
            {options.map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
          {formik.errors.tipo && (
            <FormHelperText>{formik.errors.tipo}</FormHelperText>
          )}
        </FormControl>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Desde"
              name="fecha_inicial"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formik.values.fecha_inicial}
              onChange={formik.handleChange}
              error={!!formik.errors.fecha_inicial}
              helperText={formik.errors.fecha_inicial}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Hasta"
              name="fecha_final"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formik.values.fecha_final}
              onChange={formik.handleChange}
              error={!!formik.errors.fecha_final}
              helperText={formik.errors.fecha_final}
            />
          </Grid>
        </Grid>

        {formik?.values?.tipo === "1" && (
          <TextField
            fullWidth
            margin="normal"
            label="Volumen vendido"
            name="volumen_ventas"
            type="number"
            value={formik.values.volumen_ventas}
            onChange={formik.handleChange}
            error={!!formik.errors.volumen_ventas}
            helperText={formik.errors.volumen_ventas}
          />
        )}
        {formik?.values?.tipo === "2" && (
          <TextField
            fullWidth
            margin="normal"
            label="Volumen recargado"
            name="volumen_recargas"
            type="number"
            value={formik.values.volumen_recargas}
            onChange={formik.handleChange}
            error={!!formik.errors.volumen_recargas}
            helperText={formik.errors.volumen_recargas}
          />
        )}
        {formik?.values?.tipo === "3" && (
          <TextField
            fullWidth
            margin="normal"
            label="Perdidas por evaporación"
            name="perdida_evaporación"
            type="number"
            value={formik.values.perdida_evaporación}
            onChange={formik.handleChange}
            error={!!formik.errors.perdida_evaporación}
            helperText={formik.errors.perdida_evaporación}
          />
        )}
        {formik?.values?.tipo === "4" && (
          <TextField
            fullWidth
            margin="normal"
            label="Perdidas por derrame"
            name="derrame"
            type="number"
            value={formik.values.derrame}
            onChange={formik.handleChange}
            error={!!formik.errors.derrame}
            helperText={formik.errors.derrame}
          />
        )}
        <br />
        <br />

        <Button
          type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth
        >
          {Conciliacionesu ? "Actualizar" : "Crear"}
        </Button>
      </form>
      {Conciliaciones && Conciliaciones.results.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              {columnas.map((columna, index) => (
                <TableCell key={index}>{columna}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Conciliaciones.results.map((conciliacion, index) => (
              <TableRow key={index}>
                <TableCell>{conciliacion.fecha_conciliacion}</TableCell>
                <TableCell>{conciliacion.fecha_inicial}</TableCell>
                <TableCell>{conciliacion.fecha_final}</TableCell>
                <TableCell>{conciliacion.volumen_inicial}</TableCell>
                <TableCell>{conciliacion.volumen_ventas}</TableCell>
                <TableCell>{conciliacion.volumen_recargas}</TableCell>
                <TableCell>{conciliacion.perdida_evaporación}</TableCell>
                <TableCell>{conciliacion.derrame}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

function initialValues(data) {
  return {
    fecha_inicial: data?.fecha_inicial || "",
    fecha_final: data?.fecha_final || "",
    id_tanque: data?.id_tanque || "",
    id_producto: data?.id_producto || "",
    volumen_inicial: data?.volumen_inicial || "",
    volumen_ventas: data?.volumen_ventas || "",
    volumen_recargas: data?.volumen_recargas || "",
    perdida_evaporación: data?.perdida_evaporación || "",
    derrame: data?.derrame || "",
    tipo: data?.tipo || "",
  };
}

function newSchame() {
  return {
    fecha_inicial: Yup.date().required(true),
    fecha_final: Yup.date().required(true),
    id_tanque: Yup.number(),
    id_producto: Yup.number(),
    volumen_inicial: Yup.number(),
    volumen_ventas: Yup.number(),
    volumen_recargas: Yup.number(),
    perdida_evaporación: Yup.number(),
    derrame: Yup.number(),
  };
}


