import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from "react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useTransportadora } from "../../../hooks";
import { Funciones } from "./Funciones";
import { filterValue } from "./TransportadoraFormat";


export function TransportadoraAdmin() {
  const { loading, Transportadora, getTransportadora } = useTransportadora();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    columns,
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
  } = Funciones();

  useEffect(() => {
    getTransportadora();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const MemoizedModalBasic = React.memo(ModalBasic);
  
  return (
    <>
      <HeaderPage
        title="Transportadora"
        btnTitle="Nuevo transportadora"
        icono="plus square icon"
        btnClick={handleAdd}
      />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={Transportadora.results}
          title="Transportadora"
          id="id_transportadora"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdate}
          onDeleteData={handleDelete}
        />
      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  );
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos
