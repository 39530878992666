import React, { useEffect } from "react";
//import { Loader } from "semantic-ui-react";
import CircularProgress from '@mui/material/CircularProgress';
import {
  HeaderPage
} from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useUser } from "../../../hooks";

import { columns, filterValue } from "./UserBaseFormat";
import { Funciones } from "./Funciones";
export function UserBaseAdmin() {

  const { loading, users, getUsersAdmin } = useUser();
  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    handleViews,
    openCloseModal,
  } = Funciones();


  useEffect(() => {
    getUsersAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return (
    <>
      <HeaderPage title="Clientes" btnTitle="Nuevo cliente" icono="user plus icon" btnClick={handleAdd} />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={users}
          title="Usuarios"
          id="id_usuario"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdate}
          // onDeleteData={handleDelete}
          visualizacionData={handleViews}
        />
      )}


      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  )
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos


