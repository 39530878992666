import { useState } from "react";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";
import { getVistaTipoTransacciones, getVistaTipoTransaccionesAgnos, getVistaTipoTransaccionesTanques, getVistaPorcentajes, getCantidadProd, getCantidadProdMes } from "../../api/A_Integracion/datosGrafica";

export function useGrafica() {
  const [state, setState] = useState({
    loading: true,
    error: null,
    dataTT: null,
    dataTTT: null,
    dataTTA: null,
    dataCP: null,
    dataPM: null,
    dataPorcentajes: null,
  });

  const { loading, error, dataTT, dataTTT, dataTTA, dataCP, dataPM, dataPorcentajes } = state;
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, unauthorizedModal } = alertas();

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const fetchData = async (requestFn, ...args) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await requestFn(auth.token, auth.tenant, ...args);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false }));
      return result;
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getTipoTipoTransaccion = async (tipoTransaccion, agnoSeleccionado, tanque_id) => {
    const result = await fetchData(getVistaTipoTransacciones, tipoTransaccion, agnoSeleccionado, tanque_id);
    setState((prevState) => ({ ...prevState, dataTT: result }));
  };

  const getTipoTransaccionTanque = async (tipoTransaccion, agnoSeleccionado) => {
    const result = await fetchData(getVistaTipoTransaccionesTanques, tipoTransaccion, agnoSeleccionado);
    setState((prevState) => ({ ...prevState, dataTTT: result }));
  };

  const getTipoTransaccionAgno = async (tipoTransaccion, agnoSeleccionado, tanque_id) => {
    const result = await fetchData(getVistaTipoTransaccionesAgnos, tipoTransaccion, agnoSeleccionado, tanque_id);
    const dataTTA = Object.values(result);
    setState((prevState) => ({ ...prevState, dataTTA }));
  };

  const getPorcentajeTanque = async (tipoTransaccion, agnoSeleccionado) => {
    const result = await fetchData(getVistaPorcentajes, tipoTransaccion, agnoSeleccionado);
    setState((prevState) => ({ ...prevState, dataPorcentajes: result }));
  };

  const getCantidadProducto = async (tipoTransaccion, agnoSeleccionado) => {
    const result = await fetchData(getCantidadProd, tipoTransaccion, agnoSeleccionado);
    setState((prevState) => ({ ...prevState, dataCP: result }));
  };

  const getCantidadProductoMes = async (tipoTransaccion, agnoSeleccionado) => {
    const result = await fetchData(getCantidadProdMes, tipoTransaccion, agnoSeleccionado);
    setState((prevState) => ({ ...prevState, dataPM: result }));
  };

  return {
    loading,
    error,
    dataTT,
    dataTTT,
    dataTTA,
    dataCP,
    dataPM,
    dataPorcentajes,
    getTipoTipoTransaccion,
    getTipoTransaccionTanque,
    getTipoTransaccionAgno,
    getPorcentajeTanque,
    getCantidadProducto,
    getCantidadProductoMes,
  };
}
