import React from 'react'
import logo_syz from "../../../assets/logo_syz.png"
import logo from "../../../assets/logo_syzapi.png"
import { LoginForm } from '../../../Components/Client/LoginForm/LoginForm'
import "./LoginClient.scss"


export  function LoginClient() {
  return(
    <>
    <div className="row-auto">
      <div className="login_logo col">
        <img src={logo} width="100" alt="" />
      </div>
      <section className="sec_container d-flex justify-content-center">
        <div className="contenido_form col-12 col-sm-8 col-md-6 col-lg-5">
          <nav className="navbar  p-0">
            <div className="container-fluid justify-content-center">
              <a className="navbar-brand text-dark fs-2">Bienvenido</a>
            </div>
          </nav>

          <div className="row">
            <div className="col-auto col-sm-5 d-flex align-items-center">
              <img
                className="center-block col-5 col-sm-10 mx-auto d-block"
                src={logo_syz}
                alt="logo"
              />
            </div>

            <div className="col-12 col-sm-7">
              <label className="form-label col-12 text-center">
                Ingrese sus credenciales
              </label>

              <LoginForm />
            </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="d-flex badge bg-dark text-wrap justify-content-center">
          ® SYZ - Colombia 2020
        </div>
      </footer>
    </div>
    </>
  
  )
  
}
