import { Box, FormControl, FormControlLabel, FormGroup, Grid, Switch, TextField, Button } from '@mui/material';
import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDecimales } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditParametrizacionBaseDecimales(props) {
  const { onClose, onRefetch, Datos } = props;

  const { updateDecimales, addDecimales } = useDecimales();
  const { Successful } = alertas();
  console.log("datos: ", Datos)
  const formik = useFormik({
    initialValues: initialValues(Datos),
    validationSchema: Yup.object(Datos ? updateSchema() : newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (Datos) await updateDecimales(Datos.id_parametrizacion, formValue);
        else await addDecimales(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    formik.setFieldValue(name, value);

    // Verificar si el valor excede la restricción
    if (parseInt(value, 10) > decimalRestrictions[name]) {
      formik.setFieldError(name, `El máximo permitido es ${decimalRestrictions[name]}`);
    } else {
      formik.setFieldError(name, null); // Limpiar el error si es válido
    }
  };

  return (
    <Box component="form" className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            label="Nombre Configuración"
            name="nombre"
            placeholder="Nombre"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={formik.touched.nombre && Boolean(formik.errors.nombre)}
            helperText={formik.touched.nombre && formik.errors.nombre}
            fullWidth
          />
        </Grid>
        {[
          { name: 'tov_decimales', label: 'TOV Decimales' },
          { name: 'fw_decimales', label: 'FW Decimales' },
          { name: 'ctsh_decimales', label: 'CTSh Decimales' },
          { name: 'gsv_decimales', label: 'GSV Decimales' },
          { name: 'csw_decimales', label: 'CSW Decimales' },
          { name: 'fra_decimales', label: 'FRA Decimales' },
          { name: 'gov_decimales', label: 'GOV Decimales' },
          { name: 'CTL', label: 'CTL' },
          { name: 'CPL', label: 'CPL' },
          { name: 'CTPL', label: 'CTPL' },
          { name: 'IV', label: 'IV' },
          { name: 'GOV', label: 'GOV' },
          { name: 'GSV', label: 'GSV' },
          { name: 'NSV', label: 'NSV' },
          { name: 'TEMP', label: 'TEMP' },
          { name: 'BSW', label: 'BSW' },
          { name: 'PRES', label: 'PRES' },
          { name: 'API', label: 'API' },
          { name: 'DENS', label: 'DENS' },
          { name: 'DENR', label: 'DENR AV' },
          { name: 'VOLP_RT_B', label: 'VOLP B' },
          { name: 'METER_F', label: 'METER F' },
          { name: 'K_FACTOR', label: 'K FACTOR' },
        ].map((field, index) => (
          <Grid item xs={6} key={index}>
            <TextField
              required
              label={`Número de ${field.label} (Máx. ${decimalRestrictions[field.name]})`}
              name={field.name}
              placeholder={field.label}
              value={formik.values[field.name]}
              onChange={handleChange}
              error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
              helperText={formik.touched[field.name] && formik.errors[field.name]}
              fullWidth
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={12}>
          <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth >
            {Datos ? "Actualizar" : "Crear"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function initialValues(data) {
  return {
    nombre: data?.nombre || "",
    tov_decimales: data?.tov_decimales || "",
    fw_decimales: data?.fw_decimales || "",
    ctsh_decimales: data?.ctsh_decimales || "",
    gsv_decimales: data?.gsv_decimales || "",
    csw_decimales: data?.csw_decimales || "",
    fra_decimales: data?.fra_decimales || "",
    gov_decimales: data?.gov_decimales || "",
    CTL: data?.CTL || "",
    CPL: data?.CPL || "",
    CTPL: data?.CTPL || "",
    IV: data?.IV || "",
    GOV: data?.GOV || "",
    GSV: data?.GSV || "",
    NSV: data?.NSV || "",
    TEMP: data?.TEMP || "",
    BSW: data?.BSW || "",
    PRES: data?.PRES || "",
    API: data?.API || "",
    DENS: data?.DENS || "",
    DENR: data?.DENR || "",
    VOLP_RT_B: data?.VOLP_RT_B || "",
    METER_F: data?.METER_F || "",
    K_FACTOR: data?.K_FACTOR || ""
  };
}

const decimalRestrictions = {
  tov_decimales: 2,
  fw_decimales: 2,
  ctsh_decimales: 2,
  gsv_decimales: 2,
  csw_decimales: 2,
  fra_decimales: 2,
  gov_decimales: 2,
  CTL: 5,
  CPL: 5,
  CTPL: 5,
  IV: 2,
  GOV: 2,
  GSV: 2,
  NSV: 2,
  TEMP: 2,
  BSW: 2,
  PRES: 2,
  API: 1,
  DENS: 2,
  DENR: 2,
  VOLP_RT_B: 2,
  METER_F: 5,
  K_FACTOR: 2  
};

function newSchema() {
  const restrictionKeys = [
    'tov_decimales', 'fw_decimales', 'ctsh_decimales', 'gsv_decimales', 'csw_decimales', 
    'fra_decimales', 'gov_decimales', 'CTL', 'CPL', 'CTPL', 'IV', 
    'GOV', 'GSV', 'NSV', 'TEMP', 'BSW', 'PRES', 'API', 'DENS', 'DENR', 'VOLP_RT_B','METER_F','K_FACTOR'
  ];

  const validationSchema = {
    nombre: Yup.string().required("Nombre es requerido"),
  };

  restrictionKeys.forEach(key => {
    const maxDecimal = decimalRestrictions[key] || 2; // Default to 2 if not in restrictions
    validationSchema[key] = Yup.number()
      .required(`${key} es requerido`)
      .typeError(`${key} debe ser un número`)
      .integer(`${key} debe ser un número entero`)
      .max(maxDecimal, `El máximo es ${maxDecimal}`);
  });

  return validationSchema;
}

function updateSchema() {
  return newSchema(); // Reusing the same schema for update
}
