import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useProductos } from '../../hooks';
import { useProductoBase } from '../../hooks';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, CardContent, Divider, Grid, Typography } from '@mui/material';

// project imports
import MainCard from '../../ui-component/cards/MainCard';
import SkeletonPopularCard from '../../ui-component/cards/Skeleton/PopularCard';
import { gridSpacing } from '../../store/constant';

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const PopularCard = ({ isLoading }) => {
  const theme = useTheme();
  const { ProductoBase, getProductoBase } = useProductoBase();

  useEffect(() => {
    getProductoBase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <SkeletonPopularCard />
      ) : (
        <MainCard content={false}>
          <CardContent style={{ maxHeight: '700px', overflowY: 'auto' }}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <Grid container alignContent="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">Productos Existentes</Typography>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Typography variant="subtitle1" color="inherit">
                          Nombre
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography variant="subtitle1" color="inherit">
                              ¿Vendible?
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
                {ProductoBase?.results && ProductoBase.results.length > 0 ? (
                  ProductoBase.results.map(producto => (
                    <Grid container direction="column" key={producto.id}>
                      <Divider sx={{ my: 1 }} />
                      <Grid item>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography variant="subtitle1" color="inherit">
                              {producto.nombre}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center" justifyContent="space-between">
                              <Grid item>
                                <Typography variant="subtitle1" color="inherit">
                                  {producto.venta ? "Si" : "No"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2" >
                          {producto.codigo_producto}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body1" sx={{ textAlign: 'center', my: 1 }}>No hay productos para mostrar</Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </MainCard>
      )}
    </>
  );
};

PopularCard.propTypes = {
  isLoading: PropTypes.bool
};

export default PopularCard;
