import { useState } from "react";
import { getDocumentosconductoresApi, addDocumentosconductoresApi, updateDocumentosApi, deleteDocumentosconductoresApi } from "../../api/A_Conductor/documentosConductor";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useDocumentosConductores() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, unauthorizedModal,errores } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Documentosconductores: null,
    DocumentosconductoresDB: null,
  });

  const { loading, error, Documentosconductores, DocumentosconductoresDB } = state;



  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getDocumentosconductoresDB = async () => {
    try {
      const [result, response] = await getDocumentosconductoresApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, DocumentosconductoresDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getDocumentosconductores = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getDocumentosconductoresApi(auth.token, auth.tenant, data);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Documentosconductores: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addDocumentosconductores = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addDocumentosconductoresApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleResponse(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateDocumentosconductores = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateDocumentosApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleResponse(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteDocumentosconductores = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteDocumentosconductoresApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    DocumentosconductoresDB,
    Documentosconductores,
    getDocumentosconductoresDB,
    getDocumentosconductores,
    addDocumentosconductores,
    updateDocumentosconductores,
    deleteDocumentosconductores,
  };
}
