import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useProductos, useRemolques, useVehiculos } from "../../../hooks";
import { VehiculosSelect, productoIdSelect } from "../../Comons";
import { alertas } from "../../Comons/Alertas/alertas";
import Tooltip from '@material-ui/core/Tooltip';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { Autocomplete, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

export function AddEditRemolques(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, Remolques } = props;
  const { addRemolques, updateRemolques } = useRemolques();
  const { VehiculosDB, getVehiculosDB } = useVehiculos();
  const { ProductoDB, getProductoDB } = useProductos();
  const [tablasFormatV, setTablasTFormatT] = useState([]);
  const [Productos, setProductos] = useState([]);
  const [errors, setErrors] = React.useState({
    id_vehiculo: false,
    'compartimientos.producto': false,
  });

  useEffect(() => {
    getVehiculosDB();
    getProductoDB();
  }, []);

  useEffect(() => {
    setTablasTFormatT(VehiculosSelect(VehiculosDB));
    setProductos(productoIdSelect(ProductoDB));
  }, [VehiculosDB, ProductoDB]);

  const formik = useFormik({
    initialValues: initialValues(Remolques),
    validationSchema: Yup.object(newSchame()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (Remolques) await updateRemolques(Remolques.id_remolque, formValue);
        else await addRemolques(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const divStyle = {
    textAlign: "left",
  };

  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value?.value || '');
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };

  const calculateTotalCapacity = () => {
    const totalCapacity = formik.values.compartimientos.reduce((sum, compartimineto) => {
      const capacidad = parseFloat(compartimineto.capacidad);
      return sum + (isNaN(capacidad) ? 0 : capacidad);
    }, 0);
    formik.setFieldValue("capacidad", totalCapacity);
  };

  useEffect(() => {
    calculateTotalCapacity();
  }, [formik.values.compartimientos]);

  return (
    <>
      <form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Número de placa remolque"
              name="placa_remolq"
              placeholder="Placa del remolque"
              value={formik.values.placa_remolq}
              onChange={formik.handleChange}
              error={formik.errors.placa_remolq}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Capacidad total del remolque"
              name="capacidad"
              placeholder="Capacidad"
              value={formik.values.capacidad}
              onChange={formik.handleChange}
              error={formik.errors.capacidad}
              type="number"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <div>
              <Typography variant="h6">Compartimentos</Typography>
            </div>
            <br />
            {formik.values.compartimientos.map((compartimineto, index) => (
              <div key={index}>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      required
                      label={`Capacidad compartimiento ${index + 1}`}
                      name={`compartimientos[${index}].capacidad`}
                      placeholder={"Capacidad"}
                      value={compartimineto.capacidad}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setTimeout(calculateTotalCapacity, 0);
                      }}
                      error={formik.errors[`compartimientos[${index}].capacidad`]}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Autocomplete
                      id={`prodc${index}`}
                      options={Productos}
                      getOptionLabel={(option) => option.text || ''}
                      value={Productos.find((option) => option.value === compartimineto.producto) || null}
                      onChange={(event, value) => {
                        formik.setFieldValue(`compartimientos[${index}].producto`, value?.value || '');
                        setErrors((prevErrors) => ({ ...prevErrors, [`compartimientos${index}.producto`]: !value }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Combustible del compartimento ${index + 1}`}
                          variant="outlined"
                          error={errors[`compartimientos${index}.producto`]}
                          helperText={errors[`compartimientos${index}.producto`] ? 'Debe seleccionar el tipo de combustible' : ''}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Tooltip title="Eliminar variable" arrow>
                      <Button
                        variant="outlined"
                        color="secondary"
                        style={{
                          height: '100%', display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '0',
                          margin: '0',
                          color: 'rgba(252, 0, 0, 0.8)',
                          border: '1px solid rgba(252, 0, 0, 0.8)',
                        }}
                        onClick={() => {
                          formik.setFieldValue("compartimientos", formik.values.compartimientos.filter((_, i) => i !== index));
                          setTimeout(calculateTotalCapacity, 0);
                        }}
                      >
                        <DeleteIcon style={{ margin: '0' }} />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
                <br />
              </div>
            ))}
            <br />
            <Tooltip title="Agregar compartimento" arrow>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                style={{
                  color: 'rgb(25, 118, 210)',
                  border: '1px solid rgb(25, 118, 210)',
                  '&:hover': {
                    color: 'rgba(221, 223, 221, 0.62)',
                    border: '1px solid rgba(221, 223, 221, 0.62)',
                  }
                }}
                onClick={() => {
                  formik.setFieldValue("compartimientos", [...formik.values.compartimientos, { capacidad: "", producto: "" }]);
                }}
              >
                <AddIcon />
              </Button>
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Descripción"
              name="descrip"
              placeholder="Descripción opcional"
              value={formik.values.descrip}
              onChange={formik.handleChange}
              error={formik.errors.descrip}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <div>
              <Typography variant="h6">Documentos extras</Typography>
            </div>
            <br />
            {formik.values.documentos.map((variable, index) => (
              <div key={index}>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label={`Tipo ${index + 1}`}
                      name={`documentos[${index}].tipo`}
                      placeholder={"Tipo"}
                      value={variable.tipo}
                      onChange={formik.handleChange}
                      error={formik.errors[`documentos[${index}].tipo`]}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label={`Número de documento ${index + 1}`}
                      name={`documentos[${index}].numeroDoc`}
                      placeholder={"Número"}
                      value={variable.numeroDoc}
                      onChange={formik.handleChange}
                      error={formik.errors[`documentos[${index}].numeroDoc`]}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title="Eliminar variable" arrow>
                      <Button
                        variant="outlined"
                        color="secondary"
                        style={{
                          height: '100%', display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '0',
                          margin: '0',
                          color: 'rgba(252, 0, 0, 0.8)',
                          border: '1px solid rgba(252, 0, 0, 0.8)',
                        }}
                        onClick={() =>
                          formik.setFieldValue("documentos", formik.values.documentos.filter((_, i) => i !== index))
                        }
                      >
                        <DeleteIcon style={{ margin: '0' }} />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
                <br />
              </div>
            ))}
            <br />
            <Tooltip title="Agregar documento" arrow>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                style={{
                  color: 'rgb(25, 118, 210)',
                  border: '1px solid rgb(25, 118, 210)',
                  '&:hover': {
                    color: 'rgba(221, 223, 221, 0.62)',
                    border: '1px solid rgba(221, 223, 221, 0.62)',
                  }
                }}
                onClick={() => formik.setFieldValue("documentos", [...formik.values.documentos, { tipo: "", numeroDoc: "" }])}
              >
                <AddIcon />
              </Button>
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              {Remolques ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function initialValues(data) {
  return {
    placa_remolq: data?.placa_remolq || "",
    capacidad: data?.capacidad || "",
    compartimientos: data?.compartimientos || [],
    descrip: data?.descrip || "",
    documentos: data?.documentos || [],
  };
}

function newSchame() {
  return {
    placa_remolq: Yup.string().required("El campo número de placa es requerido"),
    capacidad: Yup.number().required("El campo capacidad es requerido"),
    descrip: Yup.string(),
    compartimientos: Yup.array().of(
      Yup.object().shape({
        capacidad: Yup.number()
          .transform((value, originalValue) => parseFloat(originalValue.replace(/,/g, '')))
          .required("El campo capacidad es requerido")
          .typeError("Debe ser un número válido"),
        producto: Yup.string().required("El campo producto es requerido"),
      })
    ),
  };
}

