import { Button, Grid } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAuth, useConsumo, useProductoTanque, useProveedores, useTanques } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";


export function AddEditOrdenesSuministros(props) {
    const { auth } = useAuth()
    const { Successful, errores } = alertas();
    const { onClose, onRefetch, ordenesSuministro } = props;
    const { addOrdenesSuministro, updateOrdenesSuministro } = useConsumo()
    const { TanquesCI, getTanquesCI } = useTanques()
    const { ProveedoresDB, getProveedoresDB } = useProveedores();
    const { ProductoTanqueDB, getProductoTanqueDB } = useProductoTanque()

    useEffect(() => {
        getTanquesCI()
        getProveedoresDB()
        getProductoTanqueDB()
    }, []);

    const handleTankChange = (event) => {
        formik.handleChange(event)
    }

    const handleProvChange = (event) => {
        formik.handleChange(event)
    }

    const formik = useFormik({
        initialValues: initialValues(ordenesSuministro),
        validationSchema: Yup.object(ordenesSuministro ? updateScheme() : newScheme()),
        validateOnChange: false,
        onSubmit: async (formValue) => {

            try {
                formValue.id_operador = auth.me.id_usuario
                const selectedRecord = ProductoTanqueDB.filter((item) => item?.data_tanques?.id_tanque == formValue?.id_tanque)
                formValue.id_productos_tanques = selectedRecord[0].id
                formValue.tipo_combustible = selectedRecord[0].data_productos?.nombre
                const formattedDate = new Date(formValue.fecha_vencimiento)
               

                formValue.fecha_vencimiento = formattedDate.getFullYear() + '-' + String(formattedDate.getMonth() + 1).padStart(2, '0') + '-' + String(formattedDate.getDate()).padStart(2, '0');

                // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                if (ordenesSuministro) await updateOrdenesSuministro(ordenesSuministro.id_orden, formValue);
                else await addOrdenesSuministro(formValue);
                onRefetch();
                onClose();
                Successful()
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    })

    const [selectedDate, setSelectedDate] = useState(formik.values.fecha_vencimiento)
    
    return (
        <>
            {!ProductoTanqueDB ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                </>
            ) : (
                <>
                    <Formik initialValues={initialValues(ordenesSuministro)}>
                        <div>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Número de orden"
                                            variant="outlined"
                                            name="n_orden"
                                            value={formik.values.n_orden}
                                            onChange={formik.handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Cantidad de suministro"
                                            variant="outlined"
                                            name="cant_consumo"
                                            value={formik.values.cant_consumo}
                                            onChange={formik.handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Tanque"
                                            variant="outlined"
                                            name="id_tanque"
                                            value={formik.values.id_tanque}
                                            onChange={handleTankChange}
                                            SelectProps={{
                                                value: formik.values.id_tanque
                                            }}
                                            required
                                        >
                                            {ProductoTanqueDB.map((option) => (
                                                <MenuItem key={option.data_tanques?.id_tanque} value={option?.data_tanques?.id_tanque}>
                                                    {option.data_tanques?.nombre_tanque}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Proveedor"
                                            variant="outlined"
                                            name="id_proveedor"
                                            value={formik.values.id_proveedor}
                                            onChange={handleProvChange}
                                            required
                                            SelectProps={{
                                                value: formik.values.id_proveedor
                                            }}
                                        >
                                            {ProveedoresDB?.map((option) => (
                                                <MenuItem key={option.id_proveedor} value={option.id_proveedor}>
                                                    {option.nombre_proveedor}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    

                                    <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="fecha_vencimiento"
                                        placeholder="Fecha"
                                        value={formik.values?.fecha_vencimiento}
                                        onChange={formik.handleChange}
                                        error={formik.errors.fecha_vencimiento}
                                        type="date"
                                        helperText="Fecha de vencimiento de la licencia"
                                    />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <label>
                                            <Checkbox
                                                name="abierta"
                                                checked={formik.values.abierta}
                                                onChange={formik.handleChange}
                                            />
                                            ¿Autorizar?
                                        </label>
                                    </Grid>
                                    <br></br>
                                    <Grid item xs={12} sm={12}>
                                        <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
                                        Crear
                                        </Button>
                                    </Grid>
        
                                
                                
                                </Grid>
                            </form>
                        </div>
                    </Formik>
                </>
            )}
        </>
    )
}

function initialValues(data) {
    return {
        cant_consumo: data?.cant_consumo || "",
        tipo_combustible: data?.tipo_combustible || "",
        n_orden: data?.n_orden || "",
        id_operador: data?.id_operador || "",
        id_productos_tanques: data?.id_productos_tanques || "",
        id_tanque: data?.prod_tanque ? data.prod_tanque?.data_tanques?.id_tanque : "",
        id_proveedor: data?.id_proveedor || "",
        abierta: data?.abierta || false,
        fecha_vencimiento: data?.fecha_vencimiento || ""
    };
}

function newScheme() {
    return {
        cant_consumo: Yup.string(),
        tipo_combustible: Yup.string(),
        n_orden: Yup.string(),
        id_operador: Yup.string(),
        id_productos_tanques: Yup.string(),
        id_proveedor: Yup.string(),
        abierta: Yup.boolean(),
        fecha_vencimiento: Yup.date()
    };
}

function updateScheme() {
    return {
        cant_consumo: Yup.string(),
        tipo_combustible: Yup.string(),
        n_orden: Yup.string(),
        id_operador: Yup.string(),
        id_productos_tanques: Yup.string(),
        id_proveedor: Yup.string(),
        abierta: Yup.boolean(),
        fecha_vencimiento: Yup.date()
    };
}