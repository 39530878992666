import { baseApi } from "./basesApi";

// API Descargadero
export const getReceipt = () =>
  baseApi.get("/publicar_descargadero").then((r) => r);
export const getReceiptId = (id) =>
  baseApi.get(`/publicar_descargadero?bay=${id}`).then((r) => r);
export const getReceiptDatosId = (id) =>
  baseApi
    .get(
      `/syzapi/transacciones/cargue/datos/${
        id !== undefined ? `?n_orden=${id}` : ""
      }`
    )
    .then((r) => r);

// API Cargandero
export const getDispatch = () =>
  baseApi.get("/publicar_cargadero").then((r) => r);
export const getDispatchId = (id) =>
  baseApi.get(`/publicar_cargadero?bay=${id}`).then((r) => r);
export const getDispatchDatosId = (id) =>
  baseApi
    .get(
      `/syzapi/descargadero/descargue/datos/${
        id !== undefined ? `?n_orden=${id}` : ""
      }`
    )
    .then((r) => r);

// http://18.216.94.178:9006/r/syz/descargadero/descargue/datos/?n_orden=0000020988

// API Almacenamento
// http://18.222.141.119:8000/procesos/almacenamiento/
export const getStorage = () => baseApi.get("/publicar_tankes").then((r) => r);
export const getStorageId = (id) =>
  baseApi.get(`/publicar_tankes?id=${id}`).then((r) => r);

// API Turnos

export const getTurns = () => baseApi.get("/syz/turno/random/").then((r) => r);
