import { useState } from "react";
import { getGruposPermisosApi, addGruposPermisosApi, updateGruposPermisosApi, deleteGruposPermisosApi } from "../../api/A_Multicliente/GruposPermisos";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useGruposPermisos() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    GruposPermisos: null,
    GruposPermisosDB: null,
  });

  const { loading, error, GruposPermisos, GruposPermisosDB } = state;

  const getGruposPermisosDB = async () => {
    try {
      const [result, response] = await getGruposPermisosApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, GruposPermisosDB: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getGruposPermisos = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getGruposPermisosApi(auth.token, auth.tenant);

      if (response.status === 401) {
        SessionExpired();
        logout();
      }
      if (response.status === 403) {
        unauthorized();
      }

      setState((prevState) => ({ ...prevState, loading: false, GruposPermisos: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addGruposPermisos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addGruposPermisosApi(data, auth.token, auth.tenant);

      if (response.status === 400) {
        errores();
      }

      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateGruposPermisos = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateGruposPermisosApi(id, data, auth.token, auth.tenant);

      if (response.status === 400) {
        errores();
      }

      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteGruposPermisos = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteGruposPermisosApi(id, auth.token, auth.tenant);

      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    GruposPermisosDB,
    GruposPermisos,
    getGruposPermisosDB,
    getGruposPermisos,
    addGruposPermisos,
    updateGruposPermisos,
    deleteGruposPermisos,
  };
}
