import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import {
  AddEditSeccionesFondoForm, HeaderPage, VisualizacionFondoForm
} from "../../../../Components/Client";
import { ModalBasic, DataGridBasic } from "../../../../Components/Comons";
import { useSecciones } from "../../../../hooks";
import "./SeccionesAdmin.scss";
import Swal from "sweetalert2"
import { filterValue, columns } from "./SeccionesFormat";


export function SeccionesFondoAdmin() {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const { loading, seccionesFondo, getSeccionesFondo, deleteSeccionesFondo } = useSecciones();

  useEffect(() => {
    getSeccionesFondo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const onRefetch = () => setRefetch((prev) => !prev);
  const openCloseModal = () => setShowModal((prev) => !prev);
  /**La función establece el título y el contenido del modal, además de abrirlo. 
     * El título establecido es "Nuevo ..." y el contenido es un componente "AddEditComponent", 
     * que se encarga de agregar un nuevo dato. El componente cuenta con dos props, "onClose" y "onRefetch". 
     * "onClose" es una función que cierra el modal, y "onRefetch" es una función que recarga los datos de la tabla después de agregar un nuevo dato. */
  const addseccionesFondo = () => {
    setTitleModal("Nueva seccion de fondo");
    setContentModal(
      <AddEditSeccionesFondoForm onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };
  /** La función  es similar a la función a la anterior, con la diferencia de que establece el título "Actualizar..." y además recibe un parámetro "data",
   *  que contiene los datos del dato a actualizar. El contenido del modal es el mismo componente "AddEditComponent" que antes, pero en este caso, 
   * el componente recibe la propiedad  con los datos a actualizar. El componente cuenta con dos props, "onClose" y "onRefetch". 
   * "onClose" es una función que cierra el modal, y "onRefetch" es una función que recarga los datos de la tabla después de actualizar un dato. */
  const updateseccionesFondo = (data) => {
    setTitleModal("Actualizar seccion de fondo");
    setContentModal(
      <AddEditSeccionesFondoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        seccionesFondo={data}
      />
    );
    openCloseModal();
  };


  const visualizacionEstado = (data) => {
    setTitleModal("Visualizar datos Aforo");
    setContentModal(
      <VisualizacionFondoForm
        onClose={openCloseModal}
        onRefetch={onRefetch}
        visualizacion={data}
      />
    );
    openCloseModal();
  };
  /** La función "onDelete" es una función que utiliza la librería SweetAlert para mostrar una alerta de confirmación al usuario 
  * antes de eliminar un dato . La función recibe como parámetro "data", que contiene los datos  a eliminar. 
  * Cuando el usuario confirma la eliminación, se llama a la función "delete" pasando como parámetro el id de los datos 
  * a eliminar y se ejecuta la función "onRefetch" para actualizar los datos de la tabla. En caso de cancelar, se muestra un mensaje de error. */
  const onDeleteseccionesFondo = async (data) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estas seguro?',
      text: "No podras revertir esta accion!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSeccionesFondo(data.id_secciones);
        onRefetch();
        swalWithBootstrapButtons.fire(
          'Eliminado!',
          'El dato a sido eliminado.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Operacion cancelada',
          'error'
        )
      }
    })
  };
  /**Este es el código del componente principal que utiliza las funciones anteriores. El componente comienza con un componente "HeaderPage" 
   * que tiene un título "", un botón con el título "Nuevo ..." y un icono "user plus icon", que al ser presionado, 
   * llama a la función "addCalibraciones".
  
  Luego, se verifica si el componente está cargando (loading) y muestra un spinner si es así, si no, se muestra un componente "TablaBasic" 
  que recibe como propiedades los datos de las obtenidos en la peticion, el título, las columnas, y las funciones "updateData" y "onDeleteData" que llaman a las funciones 
  "update..." y "onDelete..." respectivamente.
  
  Finalmente, se renderiza un componente "ModalBasic" que es mostrado o ocultado según el estado de "showModal",
   tiene un título y un contenido que se establecen mediante las funciones "setTitleModal" y "setContentModal" respectivamente. */
  return (
    <>
      <HeaderPage
        title="Secciones de fondo"
        btnTitle="Nueva seccion de fondo"
        icono="user plus icon"
        btnClick={addseccionesFondo}
      />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (

        <DataGridBasic
          data={seccionesFondo.results}
          title="Secciones de fondo"
          id="id_secciones_fondo"
          filterValue={filterValue}
          columns={columns}
          updateData={updateseccionesFondo}
          onDeleteData={onDeleteseccionesFondo}
          visualizacionData={visualizacionEstado}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}
//Columnas que se envian en forma de props al componente tabla basic para representar sus campos
