import { Card, CardContent, Grid, Typography, Box, Paper } from '@mui/material';
import { Table, Tag } from "antd";
import React, { useEffect, useState, useCallback } from 'react';
import LogoSyz from '../../../assets/logo_syzapi.png';
import { ASGI_API } from '../../../vistas/utils/constants';
import { useIslas, useBahias } from '../../../hooks';

const columns = [
  {
    title: "Turno",
    dataIndex: "n_turno",
    key: "n_turno",
  },
  {
    title: "Estado",
    dataIndex: "estado",
    key: "estado",
    render: (text) => {
      const getColor = () => {
        switch (text) {
          case "ingresado":
            return "green-inverse";
          case "Retomando":
            return "red-inverse";
          case "espera":
            return "yellow-inverse";
          case "enturnado":
            return "lime-inverse";
          case "finalizado":
            return "gray"
          case "en cargue":
            return "blue"
          default:
            return "green";
        }
      };

      return (
        <>
          <Tag
            color={getColor()}
            key={"tag"}
            style={{
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {
              text === "enturnado" ? "esperando ingreso" : text
            }
          </Tag>
        </>
      );
    },
  },
  {
    title: "Orden",
    dataIndex: "orden",
    key: "orden",
  },
  {
    title: "Conductor",
    dataIndex: "conductor",
    key: "conductor",
  },

  {
    title: "Vehículo",
    dataIndex: "placa",
    key: "placa",
  },
  {
    title: "Proceso",
    dataIndex: "proceso",
    key: "proceso",
  },
  {
    title: "Bahía",
    dataIndex: "bahia",
    key: "bahia",
  },
  {
    title: "Tipo",
    dataIndex: "tipo_turno",
    key: "tipo_turno",
  }
  // {
  //   title: "Validación por rostro",
  //   dataIndex: "",
  //   key: ""
  // },
  // {
  //   title: "Validación por placa",
  //   dataIndex: "",
  //   key: ""
  // },
  // {
  //   title: "Validación por código",
  //   dataIndex: "",
  //   key: ""
  // }
];

export function TurnosVisualizacion() {
  const [fullscreen, setFullscreen] = useState(false);
  const [datos, setDatos] = useState([])
  const { getIslas, Islas } = useIslas();
  const { Bahias, getBahias } = useBahias();

  useEffect(() => {
    getIslas();
    getBahias();
  }, []);

  const connect_ws = useCallback(() => {
    const ws = new WebSocket(`${ASGI_API}/data/turnos_data`)

    ws.onopen = () => {
      console.log('WebSocket connected.');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      //console.log(data)

      const joinedTurnos = data.reduce((acc, curr) => acc.concat(curr), [])
      setDatos(joinedTurnos)
    };

    // cierra la conexión
    ws.onclose = () => {
      console.log('WebSocket disconnected. Intentando reconectar...');
      setTimeout(connect_ws, 3000); // intenta reconexión después de 3 segundos
    };

    return ws
  }, [])

  useEffect(() => {
    let ws = connect_ws();

    return () => {
      ws.close()
    }
  }, [connect_ws])

  // useEffect(() => {
  //   const socket = socketIOClient('http://localhost:5000/data_turnos');

  //   socket.on('connect', () => {
  //     console.log('Connected to server');
  //   });

  //   socket.on('update_data_turnos', (...args) => {
  //     const joinedTurnos = args.reduce((acc, curr) => acc.concat(curr), [])
  //     setDatos(joinedTurnos)
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  const modifiedData = datos?.map((item) => ({
    ...item,
    proceso: item.proceso === "1" ? "Cargadero" : "Descargadero",
  }));


  const islasData = Islas
    ? Islas.results.map((isla) => ({
      ...isla,
      nombre: isla.nombre,
      tipo_operacion: isla.tipo_operacion,
    }))
    : [];

  const bahiasData = Bahias
    ? Bahias.results.map((bahia) => ({
      ...bahia,
    }))
    : [];


  console.log("modifiedData", modifiedData);
  console.log("islasData", islasData);
  console.log("bahiasData", bahiasData);

// Filtrar los turnos con estado "Espera"
const turnosEspera = modifiedData.filter((data) => data.estado == "espera");

// Filtrar los turnos con estado "En Cargue"
const turnosEnCargue = modifiedData.filter((data) => data.estado == "en cargue");

// Agrupar las bahías dentro de las islas correspondientes, con los turnos asociados
const enrichedIslasData = islasData.map((isla) => {
  // Encontrar las bahías correspondientes a la isla actual
  const bahias = isla.bahias_id.map((bahiaId) =>
    bahiasData.find((bahia) => Number(bahia.id_bahias) === Number(bahiaId.id_bahias))
  ).filter(Boolean); // Eliminar posibles valores nulos

  // Agregar los turnos asociados a cada bahía
  bahias.forEach((bahia) => {
    const associatedData = modifiedData.filter(
      (data) => Number(data.bahia) === Number(bahia.id_bahias) && data.estado == "en cargue"
    );
    bahia.associatedData = associatedData; // Relacionar turnos con la bahía
  });

  return {
    ...isla,
    bahias, // Agregar las bahías con los turnos asociados a la isla
  };
});

// Renderizado
return (
  <>
    <div>
      <Grid item xs={12} sm={12}>
        <div style={{ backgroundColor: 'rgba(109, 101, 105, 0.8)', padding: '10px', textAlign: 'center' }}>
          <img src={LogoSyz} width={100} alt="logo" />
        </div>
      </Grid>

      {/* Mostrar las islas */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          gap: "16px",
          padding: "16px",
        }}
      >
        {enrichedIslasData.map((isla) => (
          <Paper key={isla.id_isla} elevation={3} sx={{ padding: "16px", backgroundColor: "#f5f5f5" }}>
            <Typography variant="h6" fontWeight="bold" sx={{ textAlign: "center" }}>
              {isla.nombre}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              {isla.tipo_operacion}
            </Typography>

            {/* Mostrar las bahías asociadas dentro de la isla */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap", // Esto hace que las bahías se ajusten automáticamente
                gap: "16px",
                marginTop: "16px",
                textAlign: "center",
              }}
            >
              {isla.bahias && isla.bahias.length > 0 ? (
                isla.bahias.map((bahia) => (
                  <Box key={bahia.id_bahias} sx={{ flex: "1 1 calc(33% - 16px)" }}>
                    {/* Mostrar la bahía dentro de la isla */}
                    <Paper
                      elevation={1}
                      sx={{
                        padding: "8px",
                        backgroundColor: `${bahia.E_habilitacion === "Habilitada" ? "green" : "red"}`,
                        color: "#fff",
                      }}
                    >
                      <Typography variant="body2" fontWeight="bold">
                        {bahia.nombre}
                      </Typography>
                      <Typography variant="body2">{bahia.E_proceso}</Typography>
                    </Paper>

                    {/* Mostrar los turnos asociados a esta bahía (solo "En Cargue") */}
                    {bahia.associatedData && bahia.associatedData.length > 0 ? (
                      bahia.associatedData.map((data) => (
                        <Paper
                          key={data.id_turno}
                          elevation={1}
                          sx={{
                            padding: "4px",
                            marginTop: "4px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Typography variant="body2">
                            Turno N° {data.n_turno}
                          </Typography>
                          <Typography variant="body2">
                            {data.placa}
                          </Typography>
                          <Typography variant="body2">
                            {data.estado}
                          </Typography>
                        </Paper>
                      ))
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No hay turnos en cargue.
                      </Typography>
                    )}
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No hay bahías asociadas.
                </Typography>
              )}
            </Box>
          </Paper>
        ))}
      </Box>

      {/* Mostrar turnos con estado "Espera" en la parte superior */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          gap: "16px",
          padding: "16px",
          marginBottom: "32px",
        }}
      >
        {turnosEspera.length > 0 ? (
          turnosEspera.map((turno) => (
            <Paper key={turno.id_turno} elevation={3} sx={{ padding: "16px", backgroundColor: "#f5f5f5" }}>
              <Typography variant="body2" fontWeight="bold">
                Turno N° {turno.n_turno}
              </Typography>
              <Typography variant="body2">{turno.placa}</Typography>
              <Typography variant="body2">{turno.estado}</Typography>
            </Paper>
          ))
        ) : (
          <Typography variant="body2" color="text.secondary">
            No hay turnos en espera.
          </Typography>
        )}
      </Box>
    </div>
  </>
);

}

