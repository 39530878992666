import { useState } from "react";
import { getConductorApi, addConductorApi, updateConductorApi, deleteConductorApi } from "../../api/A_Conductor/conductor";
import { useAuth } from "..";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useNavigate } from "react-router-dom";

export function useConductor() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  
  const [state, setState] = useState({
    loading: true,
    error: null,
    Conductor: null,
    ConductorDB: null,
  });


  const { loading, error, Conductor, ConductorDB } = state;

  const handleAddOrUpdateError = (result) => {
    if (result?.id_pers_cond) {
      throw new Error("El conductor ya cuenta con un registro");
    } else {
      errores();
    }
  };

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getConductorDB = async (filter,conductor) => {
    try {
      const [result, response] = await getConductorApi(auth.token, auth.tenant,filter,conductor);
      setState((prevState) => ({ ...prevState, ConductorDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
      throw error;
    }
  };

  const getConductor = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getConductorApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Conductor: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addConductor = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addConductorApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateConductor = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateConductorApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteConductor = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteConductorApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    ConductorDB,
    Conductor,
    getConductorDB,
    getConductor,
    addConductor,
    updateConductor,
    deleteConductor,
  };
}
