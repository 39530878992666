import { useState } from "react";
import {
  getTipoControladorApi,
  updateTipoControladorApi,
  addTipoControladorApi,
  deleteTipoControladorApi,
} from "../../api/A_Conexiones/tipocontrolador";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useTipoControlador() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    TipoControlador: null,
    TipoControladorDB: null,
  });

  const { loading, error, TipoControlador, TipoControladorDB } = state;

  const handleAddOrUpdateError = (result) => {
    if (result?.nit_TipoControlador) {
      throw new Error("Ya existe un TipoControlador registrado con este NIT");
    } else {
      errores();
    }
  };

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getTipoControladorDB = async () => {
    try {
      const [result, response] = await getTipoControladorApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, TipoControladorDB: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getTipoControlador = async () => {

    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getTipoControladorApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, TipoControlador: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addTipoControlador = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addTipoControladorApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateTipoControlador = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateTipoControladorApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteTipoControlador = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteTipoControladorApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    TipoControladorDB,
    TipoControlador,
    getTipoControladorDB,
    getTipoControlador,
    addTipoControlador,
    updateTipoControlador,
    deleteTipoControlador,
  };
}
