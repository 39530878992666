import { useState } from "react";
import { getTurnosApi, addTurnosApi, limpiarTurnosApi } from "../../api/A_Turnos/turnos";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useTurnos() {
  const { auth, logout } = useAuth();
  const [state, setState] = useState({
    loading: true,
    error: null,
    Turnos: null,
  });

  const { loading, error, Turnos } = state;
  const { unauthorized, SessionExpired, unauthorizedModal } = alertas();

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getTurnos = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getTurnosApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Turnos: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addTurnos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addTurnosApi(data, auth.token, auth.tenant);
      console.log(result)
      console.log(response)
      
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      if (response.status === 400) {
        throw new Error(result.msg)
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const limpiarTurnos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await limpiarTurnosApi(data, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  return {
    loading,
    error,
    Turnos,
    getTurnos,
    addTurnos,
    limpiarTurnos,
  };
}
