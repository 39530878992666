import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import {
    addProductoTanqueApi,
    deleteProductoTanqueApi,
    getProductoTanqueApi,
    updateProductoTanqueApi
} from "../../api/A_Productos/productos";

export function useProductoTanque() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    ProductoTanque: null,
    ProductoTanqueDB: null,
  });

  const { loading, error, ProductoTanque, ProductoTanqueDB } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getProductoTanqueDB = async () => {
    try {
      const [result, response] = await getProductoTanqueApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, ProductoTanqueDB: result.results }));
      
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getProductoTanque = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getProductoTanqueApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, ProductoTanque: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addProductoTanque = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addProductoTanqueApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };



  const updateProductoTanque = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateProductoTanqueApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteProductoTanque = async (id) => {

    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteProductoTanqueApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    ProductoTanqueDB,
    ProductoTanque,
    getProductoTanqueDB,
    getProductoTanque,
    addProductoTanque,
    updateProductoTanque,
    deleteProductoTanque,
  };
}
