import styled from "styled-components";

export const ContainerData = styled.div`
  margin-top: 4px;
  border: 1px solid ${({ theme }) => theme.primary.black};
  border-radius: 2px;
  padding: 6px;
`;

export const ContainerDataInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Ajusta el número de columnas aquí */
  gap: 4px;
  align-items: center;
  margin-top: 10px;
`;



export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2px; /* Reduce el espacio entre elementos */
  height: auto; /* Ajusta la altura según el contenido */
  border-right: 1px solid ${({ theme }) => theme.primary.black};
  padding-right: 4px; /* Reduce el padding derecho */

  &:last-child {
    border-right: none;
  }
`;



export const ContainerText = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr; /* Ajusta las proporciones según sea necesario */
  grid-gap: 2px; /* Reduce el espacio entre columnas */
`;


export const BadgeDataCard = styled.div`
  background-color: ${({ theme }) => theme.primary.white};
  display: block;
  border-radius: 2px;
  color: ${({ theme }) => theme.primary.black};
  font-weight: 700;
  font-size: 14px;
  width: 48px;
  text-align: center;
  position: relative;
  border: 1px solid ${({ theme }) => theme.primary.gray3};
`;