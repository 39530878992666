import { useFormik, Formik, FieldArray } from "formik";
import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import * as Yup from "yup";
import { useMulticliente } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditMulticliente(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Operadores
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */

  const { onClose, onRefetch, multicliente } = props;
  const { addMulticliente, updateMulticliente } = useMulticliente();
  const { Successful } = alertas();
  const [previewImage, setPreviewImage] = useState(multicliente?.foto || null);
  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(multicliente),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(multicliente ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        //console.log(formValue)
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (multicliente) await updateMulticliente(multicliente.id, formValue);
        else await addMulticliente(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("foto", file);
    setPreviewImage(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const divStyle = {
    textAlign: "left",
  };
  const imagenStyle = {
    width: "300px",
    margin: "0 auto",
  };

  return (
    <>
      <form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Nombre del cliente"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            {!multicliente && (
              <TextField
                fullWidth
                name="start_licencia"
                type="datetime-local"
                helperText="Fecha registro de la licencia"
                value={formik.values.start_licencia}
                onChange={formik.handleChange}
                error={formik.errors.start_licencia}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              name="end_licencia"
              type="date"
              helperText="Fecha finalización de la licencia"
              value={formik.values.end_licencia}
              onChange={formik.handleChange}
              error={formik.errors.end_licencia}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Button
                  variant="outlined"
                  color={formik.errors.foto && "red"}
                  fullWidth
                  style={{
                    color: 'rgb(208, 48, 43)',
                    border: '1px solid rgb(208, 48, 43)',
                    '&:hover': {
                      color: 'rgba(208, 48, 43, 0.62)',
                      border: '1px solid rgba(221, 223, 221, 0.62)',
                    }
                  }}
                  {...getRootProps()}
                >
                  {previewImage ? "Cambiar imagen" : "Subir imagen"}
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <input {...getInputProps()} />
                <img style={{
                    ...imagenStyle,
                    maxWidth: '100%',
                    maxHeight: '250px',
                  }}
                  src={previewImage} fluid
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              color='error'
              className="btn btn-danger"
              fullWidth
            >
              {multicliente ? 'Actualizar' : 'Crear'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function initialValues(data) {
  return {
    nombre: data?.nombre || "",
    foto: null,
    start_licencia: data?.start_licencia || "",
    end_licencia: data?.end_licencia || "",
  };
}

function newSchame() {
  return {
    nombre: Yup.string().required("El Nombre es requerido"),
    foto: Yup.mixed(),
    start_licencia: Yup.date().required("Fecha inicio de licencia"),
    end_licencia: Yup.date().required("Fecha inicio de licencia"),
  };
}

function updateSchame() {
  return {
    nombre: Yup.string().required("El Nombre es requerido"),
    foto: Yup.mixed(),
    start_licencia: Yup.date().required("Fecha inicio de licencia"),
    end_licencia: Yup.date().required("Fecha inicio de licencia"),
  };
}
