import React from 'react';
import { Container, Grid, Typography, Paper } from '@mui/material';

export function VisualizacionPlantaData(props) {
  const { visualizacion } = props;

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Información de la Planta
      </Typography>
      <Paper elevation={3} style={{ padding: 20, borderRadius: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Razón Social:</b> {visualizacion?.razon_social}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>NIT:</b> {visualizacion?.nit_myemp}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Nombre de contacto:</b> {visualizacion?.id_empresa?.nombre}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Email:</b> {visualizacion?.id_empresa?.email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Celular:</b> {visualizacion?.id_empresa?.celular}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Teléfono:</b> {visualizacion?.id_empresa?.telefono}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Dirección:</b> {visualizacion?.id_direcFc?.street}, {visualizacion?.id_direcFc?.city_name}, {visualizacion?.id_direcFc?.department_name}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
