import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import { AddEditSensores, AddEditEtiquetas, DataVisualizer } from "../../../Components/Client"
import { useSensores } from "../../../hooks"

export function Funciones() {
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

    const { deleteSensores, deleteEtiquetaSensores } = useSensores()

    const handleAddSensores = useCallback(() => {
        setTitleModal("Nuevo sensor");
        setContentModal(
            <AddEditSensores onClose={openCloseModal} onRefetch={onRefetch} />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch])

    const handleUpdateSensores = useCallback((data) => {
        setTitleModal("Actualizar sensor");
        setContentModal(
            <AddEditSensores
                onClose={openCloseModal}
                onRefetch={onRefetch}
                Sensores={data}
            />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleDeleteSensores = useCallback(async (data) => {
        const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "¡No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            await deleteSensores(data.id);
            Swal.fire("¡Eliminado!", "El dato ha sido eliminado.", "success");
            onRefetch();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelado", "Operación cancelada", "error");
        }
    }, [deleteSensores, onRefetch]);

    const handleAddEtiquetas = useCallback(() => {
        setTitleModal("Nueva etiqueta");
        setContentModal(
            <AddEditEtiquetas onClose={openCloseModal} onRefetch={onRefetch} />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch])

    const handleUpdateEtiquetas = useCallback((data) => {
        setTitleModal("Actualizar etiqueta");
        setContentModal(
            <AddEditEtiquetas
                onClose={openCloseModal}
                onRefetch={onRefetch}
                Etiquetas={data}
            />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleDeleteEtiquetaSensores = useCallback(async (data) => {
        const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "¡No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            await deleteEtiquetaSensores(data.id_sensor);
            Swal.fire("¡Eliminado!", "El dato ha sido eliminado.", "success");
            onRefetch();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelado", "Operación cancelada", "error");
        }
    }, [deleteEtiquetaSensores, onRefetch]);
    
    const handleDataVisualizer = useCallback((data) => {
        setTitleModal("Visualización de datos");
        setContentModal(
            <DataVisualizer onClose={openCloseModal} onRefetch={onRefetch} Sensores={data} />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch])

    return {
        openCloseModal,
        refetch,
        contentModal,
        titleModal,
        showModal,
        handleAddSensores,
        handleUpdateSensores,
        handleDeleteSensores,
        handleAddEtiquetas,
        handleUpdateEtiquetas,
        handleDeleteEtiquetaSensores,
        handleDataVisualizer
    }
}