import React, { useEffect, useState } from "react";
import { Dimmer, Loader, Tab } from 'semantic-ui-react';
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useOperador, useUser } from "../../../hooks";
import { Funciones as op_func } from "../OperadoresPage/Funciones";
import { filterValue as opFilterValue } from "../OperadoresPage/operadoresFormat";
import { Funciones as user_func } from "./Funciones";
import { filterValue as userFilterValue } from "./UserFormat";

export function GroupedUserOp() {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (e, { activeIndex }) => {
        setActiveTab(activeIndex);
    };

    const { loading: userLoading, users, getUsers } = useUser();
    const { loading: opLoading, Operador, getOperador } = useOperador();

    const {
        refetch: userRefetch,
        contentModal: userContentModal,
        titleModal: userTitleModal,
        showModal: userShowModal,
        columns: userColumns,
        handleAdd: userHandleAdd,
        handleViews: userHandleViews,
        handleDelete: userHandleDelete,
        handleUpdate: userHandleUpdate,
        openCloseModal: userOpenCloseModal,
        handlePermisos: userHandlePermisos,
    } = user_func();

    const {
        refetch: opRefetch,
        contentModal: opContentModal,
        titleModal: opTitleModal,
        showModal: opShowModal,
        handleAdd: opHandleAdd,
        handleDelete: opHandleDelete,
        handleUpdate: opHandleUpdate,
        handleViews: opHandleViews,
        openCloseModal: opOpenCloseModal,
        columns: opColumns,
    } = op_func();
    const MemoizedModalBasic = React.memo(ModalBasic);
    useEffect(() => {
        getUsers();
    }, [userRefetch]);

    useEffect(() => {
        getOperador();
    }, [opRefetch]);

    const panes = [
        {
            menuItem: 'Usuario administrador',
            render: () => (
                <Tab.Pane>
                    {userLoading ? (
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    ) : (
                        <DataGridBasic
                            data={users}
                            title="Usuarios"
                            id="id_usuario"
                            filterValue={userFilterValue}
                            columns={userColumns}
                            updateData={userHandleUpdate}
                            onDeleteData={userHandleDelete}
                            visualizacionData={userHandleViews}
                            handlePermisos={userHandlePermisos}
                        />
                    )}

                    {userShowModal && (
                            <MemoizedModalBasic
                            show={userShowModal}
                            onClose={userOpenCloseModal}
                            title={userTitleModal}
                            children={userContentModal}
                            />
                        )}
                    
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Usuario operador',
            render: () => (
                <Tab.Pane>
                    {opLoading ? (
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    ) : (
                        <DataGridBasic
                            data={Operador.results}
                            title="Operador"
                            id="id_operador"
                            filterValue={opFilterValue}
                            columns={opColumns}
                            updateData={opHandleUpdate}
                            onDeleteData={opHandleDelete}
                            visualizacionData={opHandleViews}
                            msjVis="Visualizar datos del operador"
                        />
                    )}

                        {opShowModal && (
                            <MemoizedModalBasic
                            show={opShowModal}
                            onClose={opOpenCloseModal}
                            title={opTitleModal}
                            children={opContentModal}
                            />
                        )}
                    
                </Tab.Pane>
            ),
        },
    ];

    return (
        <>
            <HeaderPage 
                title="Usuarios" 
                btnTitle={activeTab === 0 ? "Nuevo usuario administrador" : activeTab === 1 ? "Nuevo usuario operador" : ""} 
                icono="user plus icon" 
                btnClick={activeTab === 0 ? userHandleAdd : activeTab === 1 ? opHandleAdd : null} 
            />

            <br />

            <Tab panes={panes} activeIndex={activeTab} onTabChange={handleTabChange} />
        </>
    );
}
