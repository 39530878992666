import { useState } from "react";
import { getDocumentosVehiculosApi, addDocumentosVehiculosApi, updateDocumentosApi, deleteDocumentosVehiculosApi } from "../../api/A_vehiculos/documentosVehiculos";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useDocumentosVehiculos() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    DocumentosVehiculos: null,
    DocumentosVehiculosDB: null,
  });

  const { loading, error, DocumentosVehiculos, DocumentosVehiculosDB } = state;

  const getDocumentosVehiculosDB = async () => {
    try {
      const [result, response] = await getDocumentosVehiculosApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, DocumentosVehiculosDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getDocumentosVehiculos = async (data) => {
    try {
      
      setState((prevState) => ({ ...prevState, loading: true }));
      
      const [result, response] = await getDocumentosVehiculosApi(auth.token, auth.tenant, data);

      setState((prevState) => ({ ...prevState, loading: false, DocumentosVehiculos: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addDocumentosVehiculos = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addDocumentosVehiculosApi(data, auth.token, auth.tenant);

      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateDocumentosVehiculos = async (id, data) => {
    console.log(data)
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateDocumentosApi(id, data, auth.token, auth.tenant);

      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteDocumentosVehiculos = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteDocumentosVehiculosApi(id, auth.token, auth.tenant);

      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    DocumentosVehiculosDB,
    DocumentosVehiculos,
    getDocumentosVehiculosDB,
    getDocumentosVehiculos,
    addDocumentosVehiculos,
    updateDocumentosVehiculos,
    deleteDocumentosVehiculos,
  };
}
