import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";

import { useFracciones, useSecciones, useTablaAforo } from "../../../../hooks";

import { fraccionesSelect, seccionesSelect, seccionesFondoSelect } from "../../../Comons";
import { alertas } from "../../../Comons/Alertas/alertas";

export function AddEditTablaAforoForms(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, TablaAforo } = props;
  const { addTablasAforo, updateTablasAforo } = useTablaAforo();
  const { FraccionesDB, getFraccionesDB } = useFracciones();
  const { SeccionesPrincipalDB, getSeccionesPrincipalDB, seccionesFondo, getSeccionesFondoDB } = useSecciones();
  const [tablasFormatF, setTablasTFormatF] = useState([]);
  const [tablasFormatS, setTablasTFormatS] = useState([]);
  const [tablasFormatSF, setTablasTFormatSF] = useState([]);

  useEffect(() => {
    getSeccionesPrincipalDB();
    getFraccionesDB();
    getSeccionesFondoDB();
  }, []);


  useEffect(() => {
    setTablasTFormatF(fraccionesSelect(FraccionesDB));
  }, [FraccionesDB]);

  useEffect(() => {
    setTablasTFormatS(seccionesSelect(SeccionesPrincipalDB));
  }, [SeccionesPrincipalDB]);

  useEffect(() => {
    setTablasTFormatSF(seccionesFondoSelect(seccionesFondo));
  }, [seccionesFondo]);

  const formik = useFormik({
    initialValues: initialValues(TablaAforo),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (TablaAforo) await updateTablasAforo(TablaAforo.id_aforo, formValue);
        else await addTablasAforo(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <>
      <form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              required
              label="Nombre de la tabla"
              name="nombre_tabla"
              placeholder="Nombre de la tabla"
              value={formik.values.nombre_tabla}
              onChange={formik.handleChange}
              error={formik.errors?.nombre_tabla}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              id="id_secciones_fondo"
              options={tablasFormatSF}
              getOptionLabel={(option) => `${option.text} `}
              value={
                tablasFormatSF?.find(
                  (opt) => opt.key === formik.values?.id_secciones_fondo
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_secciones_fondo", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Secciones fondo asociadas"
                  variant="outlined"
                  error={
                    formik.touched.id_secciones_fondo &&
                    Boolean(formik.errors?.id_secciones_fondo)
                  }
                  helperText={
                    formik.touched.id_secciones_fondo && formik.errors?.id_secciones_fondo
                  }
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              id="id_secciones"
              options={tablasFormatS}
              getOptionLabel={(option) => `${option.text} `}
              value={
                tablasFormatS?.find(
                  (opt) => opt.key === formik.values?.id_secciones
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_secciones", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Secciones principales asociadas"
                  variant="outlined"
                  error={
                    formik.touched.id_secciones &&
                    Boolean(formik.errors?.id_secciones)
                  }
                  helperText={
                    formik.touched.id_secciones && formik.errors?.id_secciones
                  }
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              id="id_fracciones"
              options={tablasFormatF}
              getOptionLabel={(option) => `${option.text} `}
              value={
                tablasFormatF?.find(
                  (opt) => opt.key === formik.values?.id_fracciones
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_fracciones", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Fracciones asociadas"
                  variant="outlined"
                  error={
                    formik.touched.id_fracciones &&
                    Boolean(formik.errors?.id_fracciones)
                  }
                  helperText={
                    formik.touched.id_fracciones && formik.errors?.id_fracciones
                  }
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Documento del calibrador "
              placeholder="# documento"
              name="id_calibracion.id_calibrador"
              onChange={formik.handleChange}
              value={formik.values?.id_calibracion.id_calibrador}
              
              error={
                formik.touched.id_calibracion?.id_calibrador && Boolean(formik.errors?.id_calibracion?.id_calibrador)
              }
              helperText={formik.touched.id_calibracion?.id_calibrador && formik.errors?.id_calibracion?.id_calibrador}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Documento de quien aprueba"
              placeholder="# documento"
              name="id_calibracion.id_aprovador"
              onChange={formik.handleChange}
              value={formik.values?.id_calibracion?.id_aprovador}
              error={
                formik.touched.id_calibracion?.id_aprovador && Boolean(formik.errors?.id_calibracion?.id_aprovador)
              }
              helperText={formik.touched.id_calibracion?.id_aprovador && formik.errors?.id_calibracion?.id_aprovador}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Fecha calibración"
              name="id_calibracion.fecha_calibracion"
              placeholder="Fecha calibración"
              value={formik.values?.id_calibracion?.fecha_calibracion}
              onChange={formik.handleChange}
              error={
                formik.touched.id_calibracion?.fecha_calibracion && Boolean(formik.errors?.id_calibracion?.fecha_calibracion)
              }
              helperText={formik.touched.id_calibracion?.fecha_calibracion && formik.errors?.id_calibracion?.fecha_calibracion}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Fecha calibración anterior"
              name="id_calibracion.fecha_calibracion_anterior"
              placeholder="Fecha calibración anterior"
              value={formik.values?.id_calibracion.fecha_calibracion_anterior}
              onChange={formik.handleChange}
              error={
                formik.touched.id_calibracion?.fecha_calibracion_anterior && Boolean(formik.errors?.id_calibracion?.fecha_calibracion_anterior)
              }
              helperText={formik.touched.id_calibracion?.fecha_calibracion_anterior && formik.errors?.id_calibracion?.fecha_calibracion_anterior}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Fecha vencimiento"
              name="id_calibracion.fecha_vencimiento"
              placeholder="Fecha de vencimiento"
              value={formik.values?.id_calibracion.fecha_vencimiento}
              onChange={formik.handleChange}
              
              error={
                formik.touched?.id_calibracion?.fecha_vencimiento && Boolean(formik.errors?.id_calibracion?.fecha_vencimiento)
              }
              helperText={formik.touched?.id_calibracion?.fecha_vencimiento && formik.errors?.id_calibracion?.fecha_vencimiento}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Incertidumbre extendida"
              name="id_incertidumbre.Incertidumbre_extendida"
              placeholder="Incertidumbre extendida"
              value={formik.values?.id_incertidumbre?.Incertidumbre_extendida}
              onChange={formik.handleChange}
              error={formik.errors?.id_incertidumbre?.Incertidumbre_extendida}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Factor de cobertura"
              name="id_incertidumbre.factor_cobertura"
              placeholder="Factor de cobertura"
              value={formik.values?.id_incertidumbre?.factor_cobertura}
              onChange={formik.handleChange}
              error={formik.errors?.id_incertidumbre?.factor_cobertura}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Nivel de confianza"
              name="id_incertidumbre.nivel_confianza"
              placeholder="Nivel de confianza"
              value={formik.values?.id_incertidumbre?.nivel_confianza}
              onChange={formik.handleChange}
              error={formik.errors?.id_incertidumbre?.nivel_confianza}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              color="error"
              className="btn btn-danger"
              fullWidth
            >
              {TablaAforo ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function initialValues(data) {
  return {
    nombre_tabla: data?.nombre_tabla || "",
    id_incertidumbre: {
      Incertidumbre_extendida: data?.id_incertidumbre?.Incertidumbre_extendida || "",
      factor_cobertura: data?.id_incertidumbre?.factor_cobertura || "",
      nivel_confianza: data?.id_incertidumbre?.nivel_confianza || "",
    },
    id_calibracion: {
      id_calibrador: data?.id_calibracion?.id_calibrador || "",
      id_aprovador: data?.id_calibracion?.id_aprovador || "",
      fecha_calibracion: data?.id_calibracion?.fecha_calibracion || "",
      fecha_calibracion_anterior: data?.id_calibracion?.fecha_calibracion_anterior || "",
      fecha_vencimiento: data?.id_calibracion?.fecha_vencimiento || "",
    },
    id_fracciones: data?.id_fracciones || "",
    id_secciones: data?.id_secciones || "",
    id_secciones_fondo: data?.id_secciones_fondo || ""
  };
}


function newSchema() {
  return {
    nombre_tabla: Yup.string().required("El campo es requerido"),
    id_fracciones: Yup.number().required("El campo es requerido"),
    id_secciones: Yup.number().required("El campo es requerido"),
    id_secciones_fondo: Yup.number().required("El campo es requerido"),
    id_calibracion: Yup.object().shape({
      id_calibrador: Yup.string().required("El campo es requerido"),
      id_aprovador: Yup.string().required("El campo es requerido"),
      fecha_calibracion: Yup.date().required("El campo es requerido").nullable(),
      fecha_calibracion_anterior: Yup.date().required("El campo es requerido").nullable()
        .when('fecha_calibracion', (fecha_calibracion, schema) => fecha_calibracion && schema.max(fecha_calibracion, 'Debe ser anterior a la fecha de calibración')),
      fecha_vencimiento: Yup.date().required("El campo es requerido").nullable()
        .when(['fecha_calibracion', 'fecha_calibracion_anterior'], (fecha_calibracion, fecha_calibracion_anterior, schema) => 
          schema.min(fecha_calibracion, 'Debe ser posterior a la fecha de calibración')
                .min(fecha_calibracion_anterior, 'Debe ser posterior a la fecha de calibración anterior'))
    }),
    id_incertidumbre: Yup.object().shape({
      Incertidumbre_extendida: Yup.number().required("El campo es requerido"),
      factor_cobertura: Yup.number().required("El campo es requerido"),
      nivel_confianza: Yup.number().required("El campo es requerido")
    })
  };
}
