import { IconButton } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HistoryIcon from '@mui/icons-material/History';
import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { AddEditVehiculos, DocumentComponent } from "../../../Components/Client";
import { ViewVehicleChecklist } from "../../../Components/Client/Vehiculos/ViewVehicleChecklist";
import { ViewVehicleChecklistHistoric } from "../../../Components/Client/Vehiculos/ViewVehicleChecklistHistoric";
import { useVehiculos } from "../../../hooks";

export function Funciones() {
  const { deleteVehiculos } = useVehiculos();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo vehículo");
    setContentModal(
      <AddEditVehiculos
        onClose={openCloseModal}
        onRefetch={onRefetch}

      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback((data) => {
    setTitleModal("Actualizar vehículo");
    setContentModal(
      <AddEditVehiculos
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Vehiculos={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  // const handleAddChecklist = useCallback(async (data) => {
  //   setTitleModal("Realizar inspección de carro tanque/tracto camión")
  //   setContentModal(
  //     <AddVehicleChecklist
  //       onClose={openCloseModal}
  //       onRefetch={onRefetch}
  //       Vehiculos={data}
  //     />
  //   )
  //   openCloseModal();
  // }, [openCloseModal, onRefetch])

  const handleAddChecklist = useCallback(async (data) => {
    const newWindow = window.open('/vehicleinspection', '_blank');

    // You can pass data to the new window using localStorage or postMessage
    if (newWindow) {
      newWindow.localStorage.setItem('checklistData', JSON.stringify(data));
    } else {
      // Handle scenarios where pop-ups are blocked
      // You can redirect the user to the page instead
      window.location.href = '/vehicleinspection';
    }
  }, [onRefetch])

  const handleViewChecklist = useCallback(async (data) => {
    setTitleModal("Ver inspección actual")
    setContentModal(
      <ViewVehicleChecklist
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Vehiculos={data}
      />
    )
    openCloseModal();
  }, [openCloseModal, onRefetch])

  const handleViewChecklistHistoric = useCallback(async (data) => {
    setTitleModal("Ver historial de inspecciones")
    setContentModal(
      <ViewVehicleChecklistHistoric
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Vehiculos={data}
      />
    )
    openCloseModal();
  }, [openCloseModal, onRefetch])

  const handleDelete = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      await deleteVehiculos(data.id_vehiculo);
      Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [deleteVehiculos, onRefetch]);


  const documentosUpload = (data) => {
    setTitleModal("Documentos vehiculo");
    setContentModal(
      <DocumentComponent data={data} />
    );
    openCloseModal();
  };


  const columnsToTractoTanque = [
    {
      header: "Placa",
      name: "placa_veh",
      maxWidth: 1000,
      defaultFlex: 1
    },
    {
      header: "Marca",
      name: "marca",
      maxWidth: 1000,
      defaultFlex: 1
    },
    {
      header: "Modelo",
      name: "modelo",
      maxWidth: 1000,
      defaultFlex: 1
    },
    {
      header: "Capacidad",
      name: "capacidad",
      maxWidth: 1000,
      defaultFlex: 1
    },
    {
      header: "Operatividad",
      name: "operativo",
      maxWidth: 1000,
      defaultFlex: 1,
    },
    {
      header: "Tipo de vehículo",
      name: "vehicle_type",
      maxWidth: 1000,
      defaultFlex: 1,
    }
  ]

  const columns = [
    {
      header: "Placa",
      name: "placa_veh",
      maxWidth: 1000,
      defaultFlex: 1,
      // style: ({ data }) => ({
      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Marca",
      name: "marca",
      maxWidth: 1000,
      defaultFlex: 1,
      // style: ({ data }) => ({
      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Modelo",
      name: "modelo",
      maxWidth: 1000,
      defaultFlex: 1,
      // style: ({ data }) => ({
      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Capacidad",
      name: "capacidad",
      maxWidth: 1000,
      defaultFlex: 1,
      // style: ({ data }) => ({
      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Conductor",
      name: "conductor",
      maxWidth: 1000,
      defaultFlex: 1,
      // style: ({ data }) => ({
      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Transportadora",
      name: "transportadora",
      maxWidth: 1000,
      defaultFlex: 1,
      render: ({ data }) => data?.data_trans?.nit_transportadora
    },
    {
      header: "Extras",
      render: ({ data }) => (
        <>
          <Tooltip title="Documentos" arrow>
            <IconButton style={{ padding: '2px' }} onClick={() => documentosUpload(data.id_vehiculo)}>
              <AdfScannerIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Realizar inspección" arrow>
            <IconButton style={{ padding: '2px' }} onClick={() => { handleAddChecklist(data) }}>
              <DoneAllIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Ver inspección actual" arrow>
            <IconButton style={{ padding: '2px' }} onClick={() => handleViewChecklist(data)}>
              <ChecklistIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Ver historial de inspecciones" arrow>
            <IconButton style={{ padding: '2px' }} onClick={() => handleViewChecklistHistoric(data)}>
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    }
  ];

  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    documentosUpload,
    columns,
    refetch,
    contentModal,
    titleModal,
    showModal,
    columnsToTractoTanque,
    handleAddChecklist,
    handleViewChecklist,
    handleViewChecklistHistoric
  };

}
