import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { Autocomplete } from '@mui/material';
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';

import * as Yup from "yup";
import { alertas } from "../../Comons/Alertas/alertas";

import { useSensores } from "../../../hooks";

const options = [
    { key: '1', value: '1', text: 'Análogos' },
    { key: '2', value: '2', text: 'Comunicaciones' }
]

export function AddEditEtiquetas(props) {

    const { onClose, onRefetch, Etiquetas } = props;
    const { addEtiquetaSensores, updateEtiquetaSensores, SensorCategories, getSensorCategories, SensorUnits, getSensorUnits } = useSensores()
    const { Successful } = alertas();

    const [errors, setErrors] = React.useState({
        sensor_category_rel: false,
        tipo: false,
        sensor_unit_rel: false
    });

    useEffect(() => {
        getSensorCategories()
        getSensorUnits()
    }, [])

    const formik = useFormik({
        // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
        initialValues: initialValues(Etiquetas),
        // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
        validationSchema: Yup.object(newSchema()),
        // Desactiva la validación al cambiar los valores del formulario
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                console.log(formValue);
                // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                if (Etiquetas) await updateEtiquetaSensores(Etiquetas.id_tag, formValue);
                else await addEtiquetaSensores(formValue);
                Successful();
                onRefetch();
                onClose();
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    });

    const handleAutocompleteChange = (name, value) => {
        formik.setFieldValue(name, value?.value || '');
        setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
    };

    const handleAutocompleteChange2 = (name, value) => {
        formik.setFieldValue(name, value?.id_cat  || '');
        setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
    };

    const handleAutocompleteChange3 = (name, value) => {
        formik.setFieldValue(name, value?.id_unit || '');
        setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
    };

    if (!SensorCategories || !SensorUnits) {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            </>
        )
    }

    return (
        <>
            <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    {/* fila 1 */}
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            id="cat"
                            name="sensor_category_rel"
                            label="Categoría"
                            fullWidth
                            value={SensorCategories.find((option) => option.id_cat === formik.values.sensor_category_rel) || null}
                            onChange={(event, value) => handleAutocompleteChange2('sensor_category_rel', value)}
                            options={SensorCategories || []}
                            getOptionLabel={(option) => option.cat_name || ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Categoría"
                                    error={errors.sensor_category_rel}
                                    helperText={errors.sensor_category_rel ? 'Debe seleccionar una categoría' : ''}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            id="tipo"
                            fullWidth
                            value={options.find((option) => option.value === formik.values.tipo) || null}
                            onChange={(event, value) => handleAutocompleteChange('tipo', value)}
                            options={options}
                            getOptionLabel={(option) => option.text || ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Tipo"
                                    error={errors.tipo}
                                    helperText={errors.tipo ? 'Debe seleccionar un tipo' : ''}
                                    variant="outlined"
                                />
                            )}
                        />
                        
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            id="unit"
                            name="sensor_unit_rel"
                            label="Unidad"
                            fullWidth
                            value={SensorUnits.find((option) => option.id_unit === formik.values.sensor_unit_rel) || null}
                            onChange={(event, value) => handleAutocompleteChange3('sensor_unit_rel', value)}
                            groupBy={(option) => option.tipo}
                            options={SensorUnits || []}
                            getOptionLabel={(option) => option.unit_name || ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Unidad"
                                    error={errors.sensor_unit_rel}
                                    helperText={errors.sensor_unit_rel ? 'Debe seleccionar una unidad' : ''}
                                    variant="outlined"
                                />
                            )}
                        />

                        
                    </Grid>

                    {/* fila 2 */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Marca"
                            name="marca"
                            placeholder="Ej: GE"
                            value={formik.values.marca}
                            error={formik.errors.marca}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Modelo"
                            name="modelo"
                            placeholder="Ej: A100"
                            value={formik.values.modelo}
                            
                            error={
                                formik.touched.modelo && Boolean(formik.errors.modelo)
                              }
                              helperText={formik.touched.modelo && formik.errors.modelo}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>{Etiquetas ? "Actualizar" : "Crear"}</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

function initialValues(data) {
    return {
        tipo: data?.tipo === 'Análogos' ? '1' : '2',
        sensor_category_rel: data?.sensor_category_rel || 1,
        sensor_unit_rel: data?.sensor_unit_rel || 1,
        marca: data?.marca || "",
        modelo: data?.modelo || "",
    };
}

function newSchema() {
    return {
        tipo: Yup.number(),
        descripcion: Yup.string(),
        unidad: Yup.string(),
        marca: Yup.string(),
        modelo: Yup.string().max(10, 'El modelo debe tener como máximo 10 caracteres'),
    };
}
