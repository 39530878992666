import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import { Formik, useFormik } from "formik";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "semantic-ui-css/semantic.min.css";
import { Button, Form, Input, Label, Segment, Select } from "semantic-ui-react";
import * as Yup from "yup";
import { useAditivos, useProductos } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import "./style.css";

export function AddEditMezclas(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, mezclas } = props;
  const { addProducto, updateProducto } = useProductos();
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [showAllDays, setShowAllDays] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { ProductoDB, getProductoDB } = useProductos();
  const { AditivosDB, getAditivosDB } = useAditivos();
  const [productos, setProductos] = useState([]);
  const [aditivos, setAditivos] = useState([]);
  const [aditivosMarcador, setAditivosMarcador] = useState([]);
  const [mostrarMensaje, setMostrarMensaje] = useState(false);

  useEffect(() => {
    getProductoDB();
    getAditivosDB();
  }, []);

  useEffect(() => {
    setProductos(formatDropdownDataProducto(ProductoDB));
    setAditivos(formatDropdownDataAditivos(AditivosDB));
    setAditivosMarcador(formatDropdownDataAditivosMarcador(AditivosDB));
  }, [ProductoDB, AditivosDB]);

  useEffect(() => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
    const dateTime = `${formattedDate} ${formattedTime}`;
    setCurrentDateTime(dateTime);
  }, []);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(mezclas),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(mezclas ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    validate: (values) => validate(values),
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (mezclas) await updateProducto(mezclas.id, formValue);
        else await addProducto(formValue);
        Successful();
        onClose();
        onRefetch();
      } catch (error) {
        if (error?.message) {
          // Muestra un mensaje de error si ocurre algún problema
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const divStyle = {
    textAlign: "left",
  };


  return (
    <Formik initialValues={initialValues()}>
      <Form
        className="add-edit-secciones-form"
        style={divStyle}
        onSubmit={formik.handleSubmit}
      >
        <Form.Group widths="equal">
          <Form.Field
            required
            fluid
            control={Input}
            label="Código del producto"
            name="codigo_producto"
            placeholder="Código del producto"
            value={formik.values.codigo_producto}
            onChange={formik.handleChange}
            error={formik.errors.codigo_producto}
          />

          <Form.Field
            required
            fluid
            control={Input}
            label="Nombre de producto"
            name="nombre"
            placeholder="Nombre del producto"
            value={formik.values.nombre}
            onChange={formik.handleChange}
            error={formik.errors.nombre}
          />
        </Form.Group>
        <Segment padded>
          <Label attached="top left">API - BSW</Label>
          {formik.values.api_bsw?.length > 0 && (
            <div className="datepicker-container">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="yyyy-MM-dd"
              />
            </div>
          )}

          

          {formik.values.api_bsw
            .filter((variable) => {
              const variableDate = new Date(variable.date);
              const currentDate = new Date(currentDateTime);
              return (
                showAllDays ||
                variableDate.toISOString().split("T")[0] ===
                  selectedDate.toISOString().split("T")[0]
              );
            })

            .reverse()
            .map((variable, index) => {
              const reverseIndex = formik.values.api_bsw.length - index - 1;
              return (
                <Form.Group widths="equal" key={reverseIndex}>
                  <Form.Field
                    required
                    fluid
                    control={Input}
                    label={`API ${reverseIndex + 1}`}
                    name={`api_bsw[${reverseIndex}].api`}
                    placeholder="Valor API"
                    value={variable.api}
                    onChange={formik.handleChange}
                    error={formik.errors[`api_bsw[${reverseIndex}].api`]}
                  />
                  <Form.Field
                    required
                    fluid
                    control={Input}
                    label={`BSW ${reverseIndex + 1}`}
                    name={`api_bsw[${reverseIndex}].bsw`}
                    placeholder="Valor BSW"
                    value={variable.bsw}
                    onChange={formik.handleChange}
                    error={formik.errors[`api_bsw[${reverseIndex}].bsw`]}
                  />

                  <Button
                    type="button"
                    className="btn btn-danger"
                    onClick={() =>
                      formik.setFieldValue(
                        "api_bsw",
                        formik.values.api_bsw.filter(
                          (_, i) => i !== reverseIndex
                        )
                      )
                    }
                    content="Eliminar"
                  />
                </Form.Group>
              );
            })}

          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              formik.setFieldValue("api_bsw", [
                ...formik.values.api_bsw,
                { api: "", bsw: "", date: currentDateTime },
              ])
            }
            content="Agregar"
          />
        </Segment>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.venta}
              onChange={formik.handleChange}
              name="venta"
              color="primary"
            />
          }
          label="Mezcla"
        />
        <Tooltip title="genera la configuracio para una mezcla">
          <HelpIcon style={{ color: "gray", marginLeft: "5px" }} />
        </Tooltip>

        {formik.values.venta && (
          <>
        <Segment padded>
          <Label attached="top left">Productos</Label>

          {formik.values.producto_mezcla.map((variable, index) => {
            return (
              <Form.Group widths="equal">
                <Form.Field
                  control={Select}
                  label="Producto"
                  placeholder="Selecciona"
                  fluid
                  selection
                  search
                  options={productos}
                  value={variable.producto_mezcla}
                  error={
                    formik.errors[`producto_mezcla[${index}].producto_mezcla`]
                  }
                  onChange={(_, data) => {
                    formik.setFieldValue(
                      `producto_mezcla[${index}].producto_mezcla`,
                      data.value
                    );

                    // Busca el producto seleccionado en la lista de productos
                    const productoSeleccionado = productos.find(
                      (producto) => producto.value === data.value
                    );

                    if (productoSeleccionado) {
                      if (
                        productoSeleccionado.extra &&
                        productoSeleccionado.extra.tanques_relacionados &&
                        productoSeleccionado.extra.tanques_relacionados.length >
                          0
                      ) {
                        // Si hay tanques relacionados, toma el primer tanque
                        const primerTanqueRelacionado =
                          productoSeleccionado.extra.tanques_relacionados[0];
                        formik.setFieldValue(
                          `producto_mezcla[${index}].tanque`,
                          primerTanqueRelacionado.nombre_tanque
                        );

                        // Si hay tanques relacionados, no mostramos el mensaje
                        setMostrarMensaje(false);
                      } else {
                        // Si no hay tanques relacionados, mostramos el mensaje
                        formik.setFieldValue(
                          `producto_mezcla[${index}].tanque`,
                          ""
                        );
                        setMostrarMensaje(true);
                      }
                    }
                  }}
                />

                <Form.Field
                  fluid
                  control={Input}
                  label="Tanque"
                  name={`producto_mezcla[${index}].tanque`}
                  placeholder="Tanque"
                  value={
                    mostrarMensaje
                      ? "No hay tanques asignados"
                      : variable.tanque
                  }
                  onChange={formik.handleChange}
                  error={formik.errors[`producto_mezcla[${index}].tanque`]}
                  style={{ color: mostrarMensaje ? "red" : "inherit" }}
                />

                <Form.Field
                  fluid
                  control={Input}
                  label="Porcentaje de producto"
                  name={`producto_mezcla[${index}].porcentaje_producto`}
                  placeholder="Porcentaje de producto "
                  value={variable.porcentaje_producto}
                  onChange={formik.handleChange}
                  error={
                    formik.errors[
                      `producto_mezcla[${index}].porcentaje_producto`
                    ]
                  }
                />
                <Button
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    formik.setFieldValue(
                      "producto_mezcla",
                      formik.values.producto_mezcla.filter(
                        (_, i) => i !== index
                      )
                    )
                  }
                  content="Eliminar"
                />
              </Form.Group>
            );
          })}

          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              formik.setFieldValue("producto_mezcla", [
                ...formik.values.producto_mezcla,
                { producto_mezcla: "", porcentaje_producto: "", tanque: "" },
              ])
            }
            content="Agregar"
          />
        </Segment>

        <Segment padded>
          <Label attached="top left">Aditivos</Label>

          {formik.values.aditivo.map((variable, index) => {
            return (
              <Form.Group widths="equal">
                <Form.Field
                  control={Select}
                  label="Aditivo"
                  placeholder="Selecciona"
                  fluid
                  selection
                  search
                  options={aditivos}
                  value={variable.aditivo}
                  error={formik.errors[`aditivo[${index}].aditivo`]}
                  onChange={(_, data) =>
                    formik.setFieldValue(
                      `aditivo[${index}].aditivo`,
                      data.value
                    )
                  }
                />
                <Form.Field
                  fluid
                  control={Input}
                  label="Porcentaje de aditivo"
                  name={`aditivo[${index}].aditivo_porcentaje`}
                  placeholder="Porcentaje de aditivo "
                  value={variable.aditivo_porcentaje}
                  onChange={formik.handleChange}
                  error={formik.errors[`aditivo[${index}].aditivo_porcentaje`]}
                />
                <Button
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    formik.setFieldValue(
                      "aditivo",
                      formik.values.aditivo.filter((_, i) => i !== index)
                    )
                  }
                  content="Eliminar"
                />
              </Form.Group>
            );
          })}

          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              formik.setFieldValue("aditivo", [
                ...formik.values.aditivo,
                { aditivo: "", aditivo_porcentaje: "" },
              ])
            }
            content="Agregar"
          />
        </Segment>

        <Segment padded>
          <Label attached="top left">Aditivos marcadores</Label>

          {formik.values.aditivo_marcador.map((variable, index) => {
            return (
              <Form.Group widths="equal">
                <Form.Field
                  control={Select}
                  label="Aditivo marcador"
                  placeholder="Selecciona"
                  fluid
                  selection
                  search
                  options={aditivosMarcador}
                  value={variable.aditivo_marcador}
                  error={
                    formik.errors[`aditivo_marcador[${index}].aditivo_marcador`]
                  }
                  onChange={(_, data) =>
                    formik.setFieldValue(
                      `aditivo_marcador[${index}].aditivo_marcador`,
                      data.value
                    )
                  }
                />
                <Form.Field
                  fluid
                  control={Input}
                  label="Porcentaje de aditivo marcador"
                  name={`aditivo_marcador[${index}].aditivo_porcentaje_marcador`}
                  placeholder="Porcentaje de aditivo marcador"
                  value={variable.aditivo_porcentaje_marcador}
                  onChange={formik.handleChange}
                  error={
                    formik.errors[
                      `aditivo_marcador[${index}].aditivo_porcentaje_marcador`
                    ]
                  }
                />
                <Button
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    formik.setFieldValue(
                      "aditivo_marcador",
                      formik.values.aditivo_marcador.filter(
                        (_, i) => i !== index
                      )
                    )
                  }
                  content="Eliminar"
                />
              </Form.Group>
            );
          })}

          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              formik.setFieldValue("aditivo_marcador", [
                ...formik.values.aditivo_marcador,
                { aditivo_marcador: "", aditivo_porcentaje_marcador: "" },
              ])
            }
            content="Agregar"
          />
        </Segment>

        <Form.Group widths="equal">
          <Form.Field
            fluid
            control={Input}
            label="Muestra"
            name="muestra"
            placeholder="Muestra"
            value={formik.values.muestra}
            onChange={formik.handleChange}
            error={formik.errors.muestra}
          />
        </Form.Group>
</>
)}
        <Segment padded>
          <Label attached="top left">Variables extras</Label>

          {formik.values.variables.map((variable, index) => {
            return (
              <Form.Group widths="equal">
                <Form.Field
                  required
                  fluid
                  control={Input}
                  label={`Tipo de variable ${index + 1}`}
                  name={`variables[${index}].tipo`}
                  placeholder="Tipo de variable"
                  value={variable.tipo}
                  onChange={formik.handleChange}
                  error={formik.errors[`variables[${index}].tipo`]}
                />
                <Form.Field
                  required
                  fluid
                  control={Input}
                  label={`Valor de variable ${index + 1}`}
                  name={`variables[${index}].valorVariable`}
                  placeholder="Valor de variable"
                  value={variable.valorVariable}
                  onChange={formik.handleChange}
                  error={formik.errors[`variables[${index}].valorVariable`]}
                />
                <Button
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    formik.setFieldValue(
                      "variables",
                      formik.values.variables.filter((_, i) => i !== index)
                    )
                  }
                  content="Eliminar"
                />
              </Form.Group>
            );
          })}

          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() =>
              formik.setFieldValue("variables", [
                ...formik.values.variables,
                { tipo: "", valorVariable: "" },
              ])
            }
            content="Agregar"
          />
        </Segment>

        

        <Button
          type="submit"
          className="btn btn-danger"
          fluid
          content={mezclas ? "Actualizar" : "Crear"}
        />
      </Form>
    </Formik>
  );
}

// Función para establecer valores iniciales para un objeto
function initialValues(data) {
  return {
    codigo_producto: data?.codigo_producto || "",
    nombre: data?.nombre || "",
    venta: data?.venta ? true : false,
    api_bsw: data?.api_bsw || [],
    variables: data?.variables || [],
    producto_mezcla: data?.producto_mezcla || [],
    aditivo: data?.aditivo || [],
    aditivo_marcador: data?.aditivo_marcador || [],
    muestra: data?.muestra || "",
  };
}
function formatDropdownDataProducto(data) {
  return map(data, (item) => ({
    key: item.id,
    text: item.nombre,
    value: item.id,
    extra: item,
  }));
}

function formatDropdownDataAditivos(data) {
  if (!data) return []; // Si data es null o undefined, retornar un arreglo vacío
  const filteredData = data.filter((item) => !item.tipo); // Filtrar los elementos donde tipo es False
  return filteredData.map((item) => ({
    key: item.id,
    text: item.nombre,
    value: item.id,
  }));
}

function formatDropdownDataAditivosMarcador(data) {
  if (!data) return []; // Si data es null o undefined, retornar un arreglo vacío
  return data
    .filter((item) => item.tipo)
    .map((item) => ({
      key: item.id,
      text: item.nombre,
      value: item.id,
    }));
}

function validate(values) {
  const errors = {};
  // Validar que los porcentajes no sumen más de 100%
  const porcentajes = [
    parseInt(values.producto_uno_porcentaje),
    parseInt(values.producto_dos_porcentaje),
    parseInt(values.producto_tres_porcentaje),
  ];
  const sumaPorcentajes = porcentajes.reduce(
    (acumulador, porcentaje) => acumulador + (porcentaje || 0),
    0
  );

  if (sumaPorcentajes > 100) {
    errors.producto_uno_porcentaje =
      "La suma de porcentajes no puede ser mayor a 100%";
  }

  return errors;
}

// Función para crear un nuevo esquema de validación
function newSchame() {
  // Devuelve un objeto con las propiedades 'codigo_producto' y 'nombre'
  // Ambos atributos son requeridos y deben ser un número y una cadena respectivamente
  return {
    codigo_producto: Yup.number().required(true),
    nombre: Yup.string().required(true),

    api_bsw: Yup.array().of(
      Yup.object().shape({
        api: Yup.string()
          .matches(/^\d+\.\d+$/, "Debe ser un número decimal")
          .required(true),
        bsw: Yup.string()
          .matches(/^\d+\.\d+$/, "Debe ser un número decimal")
          .required(true),
      })
    ),
  };
}

// Función para actualizar un esquema de validación existente
function updateSchame() {
  // Devuelve un objeto con las propiedades 'codigo_producto' y 'nombre'
  // Ambos atributos son requeridos y deben ser un número y una cadena respectivamente
  return {
    codigo_producto: Yup.number().required(true),
    nombre: Yup.string().required(true),

    api_bsw: Yup.array().of(
      Yup.object().shape({
        api: Yup.string()
          .matches(/^\d+\.\d+$/, "Debe ser un número decimal")
          .required(true),
        bsw: Yup.string()
          .matches(/^\d+\.\d+$/, "Debe ser un número decimal")
          .required(true),
      })
    ),
  };
}

