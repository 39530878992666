export const columns = [
    // {
    //   name: 'id_sistema',
    //   header: 'ID',
    //   defaultWidth: 80,
    //   type: 'number',
    //   maxWidth: 1000, defaultFlex: 1
    // },
    {
      name: 'longitud',
      header: 'Unidad de longitud',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      name: 'volumen',
      header: 'Unidad de volumen',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      name: 'temperatura',
      header: 'Unidad de temperatura',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      name: 'presion',
      header: 'Unidad de presión',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      name: 'flujo',
      header: 'Unidad de flujo',
      maxWidth: 1000, defaultFlex: 1
    },
    {
      name: 'densidad',
      header: 'Unidad de densidad',
      maxWidth: 1000, defaultFlex: 1
    }
  ];
  
  
    export const filterValue = [
      { name: 'longitud', operator: 'startsWith', type: 'string', value: '' },
      { name: 'volumen', operator: 'startsWith', type: 'string', value: '' },
      { name: 'temperatura', operator: 'startsWith', type: 'string', value: '' }
    ];