import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { HeaderPage, PrincipalHeader } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { usePlantas } from "../../../hooks";
import { Funciones } from "./Funciones";
import { columns, filterValue } from "./plantasFormat";
import CircularProgress from '@mui/material/CircularProgress';

export function PlantasAdmin() {

  const { loading, Plantas, getPlantas } = usePlantas();

  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    handleViews,
    openCloseModal,
  } = Funciones();


  useEffect(() => {
    getPlantas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const plantaPrincipal = Plantas?.results.find(planta => planta.principal);
  const MemoizedModalBasic = React.memo(ModalBasic);
  return (
    <>
      <HeaderPage
        title="Ubicaciones"
        btnTitle="Nueva ubicación"
        icono="plus square icon"
        btnClick={handleAdd}
      />
      <PrincipalHeader planta={plantaPrincipal} />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={Plantas.results}
          title="Ubicaciones"
          id="id_myemp"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdate}
          onDeleteData={handleDelete}
          visualizacionData={handleViews}
        />
      )}
      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}

    </>
  )
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos


