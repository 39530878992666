import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Autocomplete } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Paper, Container } from '@material-ui/core'
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

import * as Yup from "yup";
import { useBahias, useControladores, useMapping, usePlantas, useTipoControlador } from "../../../hooks";
import { bahiasSelect, mapeoSelect, plantaSelect } from "../../Comons";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditControlador(props) {

  const { PlantasDB, getPlantasDB } = usePlantas();
  const { BahiasDB, getBahiasDB } = useBahias();
  const { TipoControladorDB, getTipoControladorDB } = useTipoControlador();

  const { Successful } = alertas();
  const { onClose, onRefetch, Controladores } = props;
  const { addControladores, updateControladores } = useControladores();
  const [tablasFormatB, setTablasTFormatB] = useState([]);
  const [tablasFormatP, setTablasTFormatP] = useState([]);
  const { MappingDB, getMappingDB } = useMapping();
  const [tablasFormatMapping, setTablasTFormatMapping] = useState([]);
  const [tipoCtrl, setTipoCtrl] = useState(null);

  const [errors, setErrors] = useState({
    tipo_controlador: false,
    proceso: false,
    
    'ubicacion_id.planta_id': false
  });

  const tipoProceso = [
    { key: "1", value: "1", text: 'Entrada' },
    { key: "2", value: "2", text: 'Salida' },
  ]

  useEffect(() => {
    getBahiasDB();
    getPlantasDB();
    getMappingDB();
    getTipoControladorDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTablasTFormatMapping(mapeoSelect(MappingDB));
  }, [MappingDB]);


  useEffect(() => {
    setTablasTFormatP(plantaSelect(PlantasDB));
  }, [PlantasDB]);

  useEffect(() => {
    setTablasTFormatB(bahiasSelect(BahiasDB));
  }, [BahiasDB]);

  // const tipoOptions = [
  //   { key: 'reg_comb', text: 'Registradora', value: 'reg_comb' },
  //   { key: 'disp_comb', text: 'Dispensadora', value: 'disp_comb' },
  //   { key: 'comp_flujo', text: 'Comp. flujo', value: 'comp_flujo' },
  //   { key: 'kunbus', text: 'Kunbus', value: 'kunbus' }
  // ]

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Controladores),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto GruposPermisos se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Controladores ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        

        // formValue.arms = formValue.arms?.map(item => ({
        //   ...item,
        //   products: {}
        // }))

        if (!formValue.used_for_access) {
          formValue.proceso = ""
        }

        formValue.tipo = "tipo"

        // // Si se proporciona un objeto Controladores se actualiza, de lo contrario se crea uno nuevo
        if (Controladores)
          await updateControladores(Controladores.id_controlador, formValue);
        else await addControladores(formValue);

        // Llama a una función Successful si se completa correctamente
        Successful();
        // Llama a la función onRefetch para actualizar la lista de GruposPermisos
        onRefetch();
        // Llama a la función onClose para cerrar el formulario
        onClose();
      } catch (error) {
        if (error?.message) {
          // Muestra un mensaje de error si ocurre algún problema
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>

        {/* fila 1 */}
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Modelo"
            name="modelo"
            value={formik.values.modelo}
            onChange={formik.handleChange}
            error={formik.errors.modelo}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Marca"
            name="marca"
            value={formik.values.marca}
            onChange={formik.handleChange}
            error={formik.errors.marca}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Ip"
            name="ip"
            value={formik.values.ip}
            onChange={formik.handleChange}
            error={formik.errors.ip}
          />
        </Grid>

        {/* fila 2 */}
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Serial equipo"
            name="serial_equipo"
            value={formik.values.serial_equipo}
            onChange={formik.handleChange}
            error={formik.errors.serial_equipo}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Serial SYZ"
            name="serial_syz"
            value={formik.values.serial_syz}
            onChange={formik.handleChange}
            error={formik.errors.serial_syz}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            fullWidth
            id="tipo_controlador"
            options={TipoControladorDB || []}
            getOptionLabel={(option) => `${option?.nombre_tipo}  `}
            value={TipoControladorDB?.find((tipo) => tipo.id === formik.values?.tipo_controlador) || null}
            onChange={(event, value) => (
              // formik.setFieldValue("tipo_controlador", value?.id || ""),
              handleAutocompleteChange('tipo_controlador', value?.id),
              setTipoCtrl(value?.tipo)
            )}

            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de Controlador"
                variant="outlined"
                error={errors.tipo_controlador}
                helperText={errors.tipo_controlador ? 'Debe seleccionar un tipo de controlador' : ''}
                sx={{ background: "#fcfcfc" }}
              />
            )}
          />
        </Grid>
        

        {/* fila 3 */}
        {formik.values.tipo === "kunbus" || tipoCtrl == "kunbus" ? (
          <>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="used_for_access"
                      checked={formik.values.used_for_access}
                      onChange={formik.handleChange}
                      color="primary"
                    />
                  }
                  label="¿Usado para acceso?"
                />
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Indica que el controlador alberga el proceso de acceso" placement="top">
                <HelpIcon style={{ color: "gray" }} />
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Autocomplete
                id="proceso"
                options={tipoProceso}
                getOptionLabel={(option) => option.text || ''}
                value={tipoProceso.find((option) => option.value === formik.values.proceso) || null}
                onChange={(event, value) => (
                  console.log(value),
                  handleAutocompleteChange('proceso', value?.key)
                )
                
                }
                disabled={!formik.values.used_for_access}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Proceso"
                    variant="outlined"
                    error={errors.proceso}
                    helperText={errors.proceso ? 'La selección del proceso es necesaria' : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Indica si el proceso de acceso es de entrada o de salida" placement="top">
                <HelpIcon style={{ color: "gray" }} />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                required
                fullWidth
                type="number"
                name="porteria"
                label="Portería"
                variant="outlined"
                disabled={!formik.values.used_for_access}
                value={formik.values.porteria}
                error={formik.errors.porteria}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Indica la ubicación del controlador cuando es usado para acceso" placement="top">
                <HelpIcon style={{ color: "gray" }} />
              </Tooltip>
            </Grid>
          </>
        ) : (<></>)}

        {/* fila 4 */}
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">Ubicacion controlador</Typography>

          <Container maxWidth="lg">
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Tag"
                    name="ubicacion_id.tag"
                    value={formik.values.ubicacion_id.tag}
                    onChange={formik.handleChange}
                    error={formik.errors.ubicacion_id?.tag}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    id="planta"
                    options={tablasFormatP}
                    getOptionLabel={(option) => option.text || ''}
                    value={tablasFormatP.find((option) => option.value === formik.values.ubicacion_id.planta_id) || null}
                    onChange={(event, value) => (
                      handleAutocompleteChange('ubicacion_id.planta_id', value?.value)
                    )

                      // (_, value) => {
                      //   formik.setFieldValue("ubicacion_id.planta_id", value?.value || "");
                      //   setError1(!value || !value.value); // Check if selection is empty and set error accordingly
                      // }
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Planta"
                        variant="outlined"
                        error={errors["ubicacion_id.planta_id"]}
                        required
                        helperText={errors["ubicacion_id.planta_id"] ? 'Es necesario una planta' : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    id="bahias"
                    options={tablasFormatB}
                    getOptionLabel={(option) => option.text || ''}
                    value={tablasFormatB.find((option) => option.value === formik.values.ubicacion_id.bahias_id) || null}
                    onChange={(_, value) => {
                      formik.setFieldValue("ubicacion_id.bahias_id", value?.value || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="No. de Bahía"
                        variant="outlined"
                      // error={error2}
                      // helperText={error2 ? 'Es necesario una selección' : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Observaciones"
                    name="ubicacion_id.observaciones"
                    value={formik.values.ubicacion_id.observaciones}
                    onChange={(event) => {
                      formik.handleChange(event);
                      formik.setFieldValue("used_for_access", event.target.checked);
                    }}
                    error={formik.errors.ubicacion_id?.observaciones}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Grid>

        <Grid item xs={12} sm={5}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox
                name="usa_mapeo"
                checked={formik.values.usa_mapeo}
                onChange={formik.handleChange}
                color="primary"
              />}
              label="¿Usa mapa de registros?"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Tooltip title="Indica si el controlador tiene comunicación modbus" placement="top">
            <HelpIcon style={{ color: "gray" }} />
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={5}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox
                name="estado"
                checked={formik.values.estado}
                onChange={formik.handleChange}
                color="primary"
              />}
              label="¿Está activo?"
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Indica si el controlador se encuentra funcional actualmente" placement="top">
            <HelpIcon style={{ color: "gray" }} />
          </Tooltip>
        </Grid>
      </Grid>

      <br />

      <Grid container spacing={3}>
        
      </Grid>

      
      <br />

      
      <br />

      <Button type="submit" variant="contained" className="btn btn-danger" fullWidth color="error" >
        {/* Use Controladores to conditionally set the button label */}
        {Controladores ? "Actualizar" : "Crear"}
      </Button>
    </form>
  );
}


function initialValues(data) {
  return {
    modelo: data?.modelo || "",
   
    tipo_controlador: data?.tipo_controlador || "",
    ubicacion_id: {
      tag: data?.ubicacion_id.tag || "",
      planta_id: data?.ubicacion_id.planta_id || "",
      bahias_id: data?.ubicacion_id.bahias_id || "",
      observaciones: data?.ubicacion_id.observaciones || "",
    },
    marca: data?.marca || "",
    ip: data?.ip || "",
    serial_equipo: data?.serial_equipo || "",
    serial_syz: data?.serial_syz || "",
    usa_mapeo: data?.usa_mapeo || false,
    tipo: data?.tipo || "",
    estado: data?.estado || false,
    usa_brazos: data?.usa_brazos || false,
    arms: data?.arms || [],
    used_for_access: data?.used_for_access || false,
    proceso: data?.proceso || "",
    porteria: data?.porteria || 0,
  };
}

function newSchame() {
  return {
    modelo: Yup.string().required(true),
    tipo_controlador: Yup.string().required(true),
    ubicacion_id: Yup.object().shape({
      tag: Yup.string().required(true),
      planta_id: Yup.string().required(true),
      bahias_id: Yup.string(),
      observaciones: Yup.string(),
    }),
    marca: Yup.string().required(true),
    ip: Yup.string()
      .required(true),
    serial_equipo: Yup.string().required(true),
    serial_syz: Yup.string(),
    usa_mapeo: Yup.bool(),
    tipo: Yup.string(),
    used_for_access: Yup.bool(),
    proceso: Yup.string(),
    porteria: Yup.number(),
  };
}

function updateSchame() {
  return {
    modelo: Yup.string().required(true),
    tipo_controlador: Yup.string().required(true),
    ubicacion_id: Yup.object().shape({
      tag: Yup.string().required(true),
      planta_id: Yup.string().required(true),
      bahias_id: Yup.string(),
      observaciones: Yup.string(),
    }),
    marca: Yup.string().required(true),
    ip: Yup.string()
      .required(true),
    // .matches(
    //   /^(([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6}|:)|:((:[0-9a-fA-F]{1,4}){1,7}|:))$|^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    //   "Ingrese una dirección IP válida"
    // ),
    serial_equipo: Yup.string().required(true),
    serial_syz: Yup.string().required(true),
    usa_mapeo: Yup.bool(),
    tipo: Yup.string(),
    used_for_access: Yup.bool(),
    proceso: Yup.string(),
    porteria: Yup.number(),
  };
}
