import React, { useState } from "react";
import { Tab } from 'semantic-ui-react';
import { SeccionesFondoAdmin } from "./SeccionesFondoAdmin";
import { SeccionesPrincipalAdmin } from "./SeccionesPrincipalAdmin";
export function GroupedSecciones() {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (e, { activeIndex }) => {
        setActiveTab(activeIndex);
    };

    const panes = [
        {
            menuItem: 'Secciones de fondo',
            render: () => <Tab.Pane><SeccionesFondoAdmin /></Tab.Pane>,
        },
        {
            menuItem: 'Secciones principales',
            render: () => <Tab.Pane><SeccionesPrincipalAdmin /></Tab.Pane>,
        },
    ];

    return (
        <Tab panes={panes} activeIndex={activeTab} onTabChange={handleTabChange} />
    );
}