import { useState } from "react";

import {
    getConsumoApi, getConsumoByMonthApi, postConsumoApi, postDataWTCSapi, updateConsumoApi, deleteConsumoApi,
    getConsumoInternoApi, postConsumoInternoApi, updateConsumoInternoApi, deleteConsumoInternoApi,
    getDataFromTCSApi, getAvgConsumoLastMonthApi, getTicketDataApi, getOrdenesSuministroApi,
    postOrdenesSuministroApi, updateOrdenesSuministroApi, deleteOrdenesSuministroApi, getExtraDataApi
} from "../../api/A_Consumo/consumo";

import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useNavigate } from "react-router-dom";
import { useAuth } from "..";

export function useConsumo() {
    const { auth, logout } = useAuth();
    const navigate = useNavigate();
    const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

    const [state, setState] = useState({
        loading: true,
        loadingTcsTickets: false,
        loadingTcsOp: false,
        error: null,
        Consumo: null,
        ConsumoInterno: null,
        ConsumoByMonth: null,
        AvgConsumoLastMonth: null,
        TCSData: null,
        respTicket: null,
        ticketData: null,
        ordenesSuministro: null,
        extraData: null,
    });

    const { loading, loadingTcsTickets, loadingTcsOp, error, Consumo, ConsumoInterno, ConsumoByMonth,
        TCSData, AvgConsumoLastMonth, respTicket, ticketData, ordenesSuministro, extraData } = state;

    const handleAddOrUpdateError = (result) => {
        if (result?.msg) {
            throw new Error(result.msg);
        } else {
            errores();
        }
    };

    const handleResponse = (response) => {
        if (response.status === 401) {
            SessionExpired();
            logout();
        } else if (response.status === 403) {
            unauthorized();
            navigate("/");
        }
    };

    const getConsumo = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getConsumoApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, Consumo: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const getConsumoByMonth = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getConsumoByMonthApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, ConsumoByMonth: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const getAvgConsumoLastMonth = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getAvgConsumoLastMonthApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, AvgConsumoLastMonth: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const getDataFromTCS = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getDataFromTCSApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, TCSData: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    const addConsumo = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await postConsumoApi(data, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            if (response.status === 400) {
                handleAddOrUpdateError(result)
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const addConsumoWTcs = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loadingTcsOp: true }));
            const [result, response] = await postDataWTCSapi(data, auth.token, auth.tenant);

            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            if (response.status === 400) {
                handleAddOrUpdateError(result)
            }

            setState((prevState) => ({ ...prevState, loadingTcsOp: false, respTicket: result.data }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loadingTcsOp: false, error }));
            throw error;
        }
    };

    const updateConsumo = async (id_table, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updateConsumoApi(id_table, data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const deleteConsumo = async (id_table) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteConsumoApi(id_table, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    const getConsumoInterno = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getConsumoInternoApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, ConsumoInterno: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const addConsumoInterno = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await postConsumoInternoApi(data, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            if (response.status === 400) {
                handleAddOrUpdateError(result)
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const updateConsumoInterno = async (id_table, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updateConsumoInternoApi(id_table, data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const deleteConsumoInterno = async (id_table) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteConsumoInternoApi(id_table, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    const getTicketData = async () => {
        try {
            setState((prevState) => ({ ...prevState, loadingTcsTickets: true }));
            const [result, response] = await getTicketDataApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loadingTcsTickets: false, ticketData: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loadingTcsTickets: false, error }));
        }
    }

    const getOrdenesSuministro = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getOrdenesSuministroApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, ordenesSuministro: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const addOrdenesSuministro = async (data) => {
        
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await postOrdenesSuministroApi(data, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            if (response.status === 400) {
                handleAddOrUpdateError(result)
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const updateOrdenesSuministro = async (id_table, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updateOrdenesSuministroApi(id_table, data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const deleteOrdenesSuministro = async (id_table) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteOrdenesSuministroApi(id_table, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    const getExtraData = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }))
            const [result, response] = await getExtraDataApi(auth.token, auth.tenant)
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false, extraData: result }))
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }))
        }
    }

    return {
        getConsumo,
        getAvgConsumoLastMonth,
        addConsumo,
        addConsumoWTcs,
        updateConsumo,
        deleteConsumo,
        getConsumoInterno,
        addConsumoInterno,
        updateConsumoInterno,
        deleteConsumoInterno,
        loading,
        loadingTcsTickets,
        loadingTcsOp,
        Consumo,
        ConsumoInterno,
        error,
        getConsumoByMonth,
        ConsumoByMonth,
        getDataFromTCS,
        TCSData,
        AvgConsumoLastMonth,
        respTicket,
        getTicketData,
        ticketData,
        ordenesSuministro,
        getOrdenesSuministro,
        addOrdenesSuministro,
        updateOrdenesSuministro,
        deleteOrdenesSuministro,
        extraData,
        getExtraData,
    }
}
