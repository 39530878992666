import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { useAuth } from "../../../hooks";
import { BASE_API } from "../../../utils/constants";
import Formato from "./cargue (version 2).xlsx";

export function ExcelUploader(props) {
  const { onRefetch,onClose } = props;
  const [data, setData] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { auth } = useAuth();

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const json = XLSX.utils.sheet_to_json(worksheet, { range: 1 });
      console.log(json);
      setData(json);
    };
    reader.readAsBinaryString(file);
  };

  const handleUpload = () => {
    // Verificar si los datos están presentes
    if (!data) {
      Swal.fire('Error', 'No se han cargado datos', 'error');
      return;
    }
  
    // Verificar si los datos necesarios están presentes en cada objeto
    const missingData = data.some((obj) => {
      return !(
        obj["Documento conductor"] &&
        obj["Horas Vigencia"] &&
        obj["Producto"] &&
        obj["Razon social cliente"] &&
        obj["Razon social planta cliente"] &&
        obj["Razon social transportadora"] &&
        obj["Placa Remolque"] &&
        obj["Placa Vehiculo"] &&
        obj["Volumen"]
      );
    });
  
    // Si falta algún dato, mostrar mensaje de error
    if (missingData) {
      Swal.fire('Error', 'Falta uno o más datos', 'error');
      return;
    }
  
    // Si todos los datos están presentes, realizar el envío POST
    const url = `${BASE_API}/${auth.tenant}/transacciones/multiples/`;
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        // Si hay un mensaje de error, mostrarlo utilizando SweetAlert2
        Swal.fire('Error', data.error, 'error');
      } else {
        // Operación exitosa
        Swal.fire('Éxito', 'La operación se realizó correctamente', 'success')
        .then(() => {
          // Llamar a la siguiente función aquí
          onClose();
          onRefetch();
        });
      }
    })
    .catch((error) => {
      // Manejar errores de conexión u otros errores inesperados
      Swal.fire('Error', 'Ocurrió un error en el servidor', 'error');
    });
  };




  const handleRowClick = (documentNumber) => {
    setSelectedDocument(documentNumber);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFile(acceptedFiles[0]);
    },
  });

  const estiloLink = {
    display: "inline-block",
    aling:"right",
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "#fff",
    textDecoration: "none",
    textAlign: "right",
    borderRadius: "5px",
    fontSize: "16px",
    boxShadow: "0px 3px 6px rgba(0,0,0,0.0)",
  };

  return (
    <Grid container spacing={2}>
      <a a className="download-link" href={Formato} download style={estiloLink}>
      Descargar formato
    </a>
      <Grid item xs={12}>
        <div {...getRootProps()} style={{ backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "5px", border: "1px dashed #ccc", textAlign: "center" }}>
          <input {...getInputProps()} />
          <p style={{ fontSize: "16px", fontWeight: "bold", margin: "0" }}>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
          <p style={{ fontSize: "14px", margin: "10px 0 0" }}>Solo se permiten archivos en formato Excel (xlsx, xls).</p>
        </div>
      </Grid>
      <Grid item xs={12}>
        {data && ( // Mostrar la tabla solo si 'data' no es nulo
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Producto</TableCell>
                  <TableCell>Volumen</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Planta cliente</TableCell>
                  <TableCell>Documento conductor</TableCell>
                  <TableCell>Placa Vehiculo</TableCell>
                  <TableCell>Placa Remolque</TableCell>
                  <TableCell>Transportadora</TableCell>
                  <TableCell>Horas de vigencia </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(row["Documento conductor"])}
                  >
                    {" "}
                    {/* Agregar evento de clic en la fila */}
                    <TableCell>{row["Producto"]}</TableCell>
                    <TableCell>{row["Volumen"]}</TableCell>
                    <TableCell>{row["Razon social cliente"]}</TableCell>
                    <TableCell>{row["Razon social planta cliente"]}</TableCell>
                    <TableCell>{row["Documento conductor"]}</TableCell>
                    <TableCell>{row["Placa Vehiculo"]}</TableCell>
                    <TableCell>{row["Placa Remolque"]}</TableCell>
                    <TableCell>{row["Razon social transportadora"]}</TableCell>
                    <TableCell>{row["Horas Vigencia"]}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid item xs={12}>
        {selectedDocument && ( // Mostrar la lista solo si 'selectedDocument' no es nulo
          <List>
            {data
              .filter((row) => row["Documento conductor"] === selectedDocument)
              .map((row, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={row["Cliente"]}
                    secondary={row["Codigo producto"]}
                  />
                </ListItem>
              ))}
          </List>
        )}
      </Grid>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUpload}
          disabled={!data}
        >
          Subir a la plataforma
        </Button>
      </div>
    </Grid>
  );
}
