import React from "react";
import { Table } from "semantic-ui-react";
import { useAuth } from "../../../../hooks";

export function VisualizacionFondoForm(props) {
  /*
Componente

-componente de visualizacion del modal de visualizacion para datos de secciones
 
 
-se valida que no llegue vacio para que no haya errores
-se obtiene los datos  mediante props desde su PageAdmin
 */
  const { onClose, onRefetch, visualizacion } = props;

  const { auth } = useAuth();
  const renderUnidadLongitud = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      return `${auth.me.SistemasMedicion.longitud} `;
    }
    return "No especificado";
  };

  const renderUnidadVolumen = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.volumen) {
      return `${auth.me.SistemasMedicion.volumen} `;
    }
    return "No especificado";
  };

  return (
    <Table definition>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nivel  <strong>( {renderUnidadLongitud()})</strong></Table.HeaderCell>
          <Table.HeaderCell>Volumen <strong>( {renderUnidadVolumen()})</strong></Table.HeaderCell>
          <Table.HeaderCell>Incremento <strong>({renderUnidadLongitud()}/{renderUnidadVolumen()})</strong></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {visualizacion.data_secciones.map((dta) => (
          <Table.Row key={JSON.stringify(dta)}>
            <Table.Cell>
              {"Nivel" in dta ? dta.Nivel : dta["1"]}
            </Table.Cell>
            <Table.Cell>
              {"Volumen" in dta ? dta.Volumen : dta["1_1"]} 
            </Table.Cell>
            <Table.Cell>
              {"Incremento" in dta ? dta.Incremento : dta["1_2"]}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export function VisualizacionPrincipalForm(props) {
  /*
Componente

-componente de visualizacion del modal de visualizacion para datos de secciones


-se valida que no llegue vacio para que no haya errores
-se obtiene los datos  mediante props desde su PageAdmin
 */
  const { onClose, onRefetch, visualizacion } = props;

  const { auth } = useAuth();
  const renderUnidadLongitud = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      return `${auth.me.SistemasMedicion.longitud} `;
    }
    return "No especificado";
  };

  const renderUnidadVolumen = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.volumen) {
      return `${auth.me.SistemasMedicion.volumen} `;
    }
    return "No especificado";
  };

  return (
    <Table definition>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nivel  <strong>( {renderUnidadLongitud()})</strong></Table.HeaderCell>
          <Table.HeaderCell>Volumen <strong>( {renderUnidadVolumen()})</strong></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {visualizacion.data_secciones.map((dta) => (
          <Table.Row key={JSON.stringify(dta)}>
            <Table.Cell>
              {"Nivel" in dta ? dta.Nivel : dta["1"]}
            </Table.Cell>
            <Table.Cell>
              {"Volumen" in dta ? dta.Volumen : dta["1_1"]} 
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
