import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import Select from "@mui/material/Select";
import FormControl from '@mui/material/FormControl';
import { Label, Visibility, VisibilityOff } from '@mui/icons-material';

import { Card, CardContent, Typography, InputAdornment, IconButton, Autocomplete, Tooltip, Collapse } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import markerIcon from "../../../assets/marker.png";

import * as Yup from "yup";
import { alertas } from "../../Comons/Alertas/alertas";
import { protocol_choises, mqtt_qos_list, variable_choises, tipo_variable_choices, unidad_medida_choices, register_type_choices } from "./json/listas_sensores";

import { useControladores, useSensores, useTanques } from "../../../hooks";

const inputRangeOpts = [
    { key: '1', value: '1', text: '-10 - 20V' },
    { key: '2', value: '2', text: '0 - 10V' },
    { key: '3', value: '3', text: '0 - 5V' },
    { key: '4', value: '4', text: '-5 - 5V' },
    { key: '5', value: '5', text: '0 - 20mA' },
    { key: '6', value: '6', text: '0 - 24mA' },
    { key: '7', value: '7', text: '4 - 20mA' },
    { key: '8', value: '8', text: '-25 - 25mA' },
]

const moduloOpts = [
    { key: '1', value: '1', text: 'Entrada 1 - InputValue1' },
    { key: '2', value: '2', text: 'Entrada 2 - InputValue2' },
    { key: '3', value: '3', text: 'Entrada 3 - InputValue3' },
    { key: '4', value: '4', text: 'Entrada 4 - InputValue4' },
    { key: '5', value: '5', text: 'Entrada 5 - InputValue5' },
]

export function AddEditSensores(props) {

    const { onClose, onRefetch, Sensores } = props;
    const { addSensores, updateSensores, EtiquetaSensores, getEtiquetaSensores } = useSensores()
    const { tanques, getTanques } = useTanques()
    const { Controladores, getControladores } = useControladores()
    const { Successful } = alertas();
    const [selectedCtrl, setSelectedCtrl] = useState()

    // campo de búsqueda para la ubicación del mapa
    const [initialValuesMap, setInitialValuesMap] = useState({
        searchQuery: '',
    })

    const [tipoSensor, setTipoSensor] = useState("")
    const [tagData, setTagData] = useState()
    const [showPassword, setShowPassword] = useState(false);

    const [open, setOpen] = useState(false);
    const handleToggle = () => { setOpen(prevOpen => !prevOpen) };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [mapKey, setMapKey] = useState(0)

    const customIcon = L.icon({
        iconUrl: markerIcon,
        iconSize: [32, 32], // tamaño del icono
        iconAnchor: [16, 32], // anclaje del icono
    });

    useEffect(() => {
        // getEtiquetaSensores()
        getControladores()
        getTanques()
    }, [])

    useEffect(() => {
        if (Sensores?.etiquetaSensorData) {
            setTipoSensor(Sensores.etiquetaSensorData.tipo)
        } else {
            setTipoSensor("1")
        }
    }, [Sensores])

    useEffect(() => {
        if (Sensores) {
            setSelectedCtrl(Sensores.controlador_data)
        }
    }, [Controladores])

    const formik = useFormik({
        // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
        initialValues: initialValues(Sensores),
        // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                formValue.latitud = parseFloat(formValue.latitud);
                formValue.longitud = parseFloat(formValue.longitud);

                // Extrae el valor del protocolo
                const { protocol } = formValue;

                // Elimina las claves no deseadas según el protocolo
                if (protocol === "MODBUS") {
                    delete formValue.mqtt_config;
                    delete formValue.opcua_config;
                } else if (protocol === "MQTT") {
                    delete formValue.modbus_config;
                    delete formValue.opcua_config;
                } else if (protocol === "OPC") {
                    delete formValue.modbus_config;
                    delete formValue.mqtt_config;
                }

                // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
                if (Sensores) await updateSensores(Sensores.id, formValue);
                else await addSensores(formValue);
                Successful();
                onRefetch();
                onClose();
            } catch (error) {
                // Muestra un mensaje de error si ocurre algún problema
                if (error?.message) {
                    toast.error(error.message, {
                        position: "top-center",
                    });
                } else {
                    onClose();
                }
            }
        },
    });

    // función para manejar el cambio de posición del marcador cuando se arrastra
    const handleMarkerMove = async (event) => {
        const newPosition = event.target.getLatLng(); // obtiene la nueva posición de maracador

        setMarkerPosition([newPosition.lat, newPosition.lng]); // catualiza el estado de la posición de maracador
        formik.setFieldValue('latitud', newPosition.lat); // actualiza el campo de la latitud
        formik.setFieldValue('longitud', newPosition.lng); // actualiza el campo de la longitud

        await handleSearchByLatLon(newPosition.lat, newPosition.lng);
    }

    // función para la búsqueda de la dirección
    const handleSearch = async (values) => {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(values)}`
        );
        const data = await response.json();

        if (data && data.length > 0) {
            formik.setFieldValue('latitud', data[0].lat)
            formik.setFieldValue('longitud', data[0].lon)

            setMarkerPosition([parseFloat(data[0].lat), parseFloat(data[0].lon)]);

            // await handleSearchByLatLon(parseFloat(data[0].lat), parseFloat(data[0].lon))
        }
    }

    // función para determinar los datos de la dirección a buscar, por ahora pone el país de la ubicación seleccionada
    const handleSearchByLatLon = async (lat, lon) => {

        const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
        )

        const data = await response.json();
    }

    // manejador que controla el cambio en la barra de búsqueda
    const handleInputChange = (event) => {
        const { value } = event.target;
        setInitialValuesMap({ ...initialValuesMap, searchQuery: value });
    };

    // función de búsqueda cuando se le da click al botón
    const handleSearchClick = async () => {
        await handleSearch(initialValuesMap.searchQuery);
    }

    const [markerPosition, setMarkerPosition] = useState([formik.values?.latitud, formik.values?.longitud]);

    useEffect(() => {
        setMapKey(prevKey => prevKey + 1);
    }, [formik.values?.latitud, formik.values?.longitud])

    const handleFieldChange = async (e) => {
        const { name, value } = e.target;

        formik.setFieldValue(name, value);

        if (name === "latitud" || name === "longitud") {
            const newLat = name === "latitud" ? parseFloat(value) : formik.values.latitud;
            const newLon = name === "longitud" ? parseFloat(value) : formik.values.longitud;

            if (!isNaN(newLat) && !isNaN(newLon)) {
                setMarkerPosition([newLat, newLon]);
                await handleSearchByLatLon(newLat, newLon);
            }
        }
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Modelo"
                            name="modelo"
                            placeholder="Ej: XMT"
                            required
                            value={formik.values.modelo}
                            error={formik.errors.modelo}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Número de serie"
                            name="num_serie"
                            required
                            placeholder="Ej: NAR252"
                            value={formik.values.num_serie}
                            error={formik.errors.num_serie}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Latitud"
                            name="latitud"
                            type="number"
                            required
                            placeholder="Ej: -54,34444"
                            slotProps={{
                                inputLabel: {
                                    shrink: true,
                                },
                            }}
                            value={formik.values.latitud}
                            error={formik.errors.latitud}
                            onChange={handleFieldChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Longitud"
                            name="longitud"
                            type="number"
                            required
                            placeholder="Ej: -13,4566"
                            slotProps={{
                                inputLabel: {
                                    shrink: true,
                                },
                            }}
                            value={formik.values.longitud}
                            error={formik.errors.longitud}
                            onChange={handleFieldChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Frequencia"
                            name="frequency"
                            type="number"
                            required
                            placeholder="Ej: 20"
                            value={formik.values.frequency}
                            error={formik.errors.frequency}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Tag"
                            name="tag"
                            required
                            placeholder="Ej: ####"
                            value={formik.values.tag}
                            error={formik.errors.tag}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Descripción"
                            name="descripcion"
                            placeholder="Descripción del sensor"
                            value={formik.values.descripcion}
                            error={formik.errors.descripcion}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Protocolo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formik.values.protocol}
                                error={formik.errors.protocol}
                                onChange={formik.handleChange}
                                label="Protocolo"
                                required
                                name="protocol"
                            >
                                {protocol_choises.map((choice) => (
                                    <MenuItem key={choice.value} value={choice.value}>
                                        {choice.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {formik.values.protocol && (
                        <Grid item xs={12} sm={12}>
                            <Card sx={{ background: '#fff4f4' }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Configuración {formik.values?.protocol}
                                    </Typography>
                                    <Grid container spacing={formik.values?.protocol == "OPC" ? 2 : 3}>
                                        {formik.values?.protocol == "MODBUS" && (
                                            <>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        fullWidth
                                                        label="IP"
                                                        name="modbus_config.ip"
                                                        required
                                                        placeholder="Ej: 192.168.0.0"
                                                        value={formik.values.modbus_config?.ip}
                                                        error={!!formik.errors.modbus_config?.ip}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        fullWidth
                                                        label="Puerto"
                                                        name="modbus_config.port"
                                                        required
                                                        placeholder="Ej: 502"
                                                        value={formik.values.modbus_config?.port}
                                                        error={!!formik.errors.modbus_config?.port}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*$/.test(value)) { // Verifica que solo sean dígitos
                                                                formik.setFieldValue("modbus_config.port", value);
                                                            }
                                                        }}
                                                        type="text" // Cambiado a texto para evitar decimales en algunos navegadores
                                                        inputProps={{
                                                            inputMode: "numeric", // Activa teclado numérico en móviles
                                                            pattern: "[0-9]*", // Solo permite números enteros
                                                        }}

                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        fullWidth
                                                        label="Base"
                                                        name="modbus_config.base"
                                                        required
                                                        placeholder="Ej: 0"
                                                        value={formik.values.modbus_config?.base}
                                                        error={!!formik.errors.modbus_config?.base}
                                                        onChange={formik.handleChange}
                                                        type="number"
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {formik.values?.protocol == "MQTT" && (
                                            <>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        fullWidth
                                                        label="Broker Address"
                                                        name="mqtt_config.broker_address"
                                                        placeholder="Ej: 192.168.0.0"
                                                        required
                                                        value={formik.values.mqtt_config?.broker_address}
                                                        error={!!formik.errors.mqtt_config?.broker_address}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        fullWidth
                                                        label="Puerto"
                                                        name="mqtt_config.port"
                                                        placeholder="Ej: 502"
                                                        required
                                                        value={formik.values.mqtt_config?.port}
                                                        error={!!formik.errors.mqtt_config?.port}
                                                        onChange={formik.handleChange}
                                                        type="number"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="qos-simple-select-label">QoS</InputLabel>
                                                        <Select
                                                            labelId="qos-simple-select-label"
                                                            id="qos-simple-select"
                                                            required
                                                            value={formik.values.mqtt_config?.qos}
                                                            error={formik.errors.mqtt_config?.qos}
                                                            onChange={formik.handleChange}
                                                            label="QoS"
                                                            name="mqtt_config.qos"
                                                        >
                                                            {mqtt_qos_list.map((choice) => (
                                                                <MenuItem key={choice.value} value={choice.value}>
                                                                    {choice.text}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        )}
                                        {formik.values?.protocol == "OPC" && (
                                            <>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Url Servidor"
                                                        name="opcua_config.server_url"
                                                        required
                                                        placeholder="Ej: http://192.168.0.2"
                                                        value={`http://${formik.values.opcua_config?.server_url ? formik.values.opcua_config?.server_url : ""}`}
                                                        error={!!formik.errors.opcua_config?.server_url}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Id Nodo"
                                                        name="opcua_config.node_id"
                                                        required
                                                        placeholder="Ej: sfAdd2"
                                                        value={formik.values.opcua_config?.node_id}
                                                        error={!!formik.errors.opcua_config?.node_id}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Nombre de usuario"
                                                        name="opcua_config.username"
                                                        required
                                                        placeholder="Ej: jose garnacho"
                                                        value={formik.values.opcua_config?.username}
                                                        error={!!formik.errors.opcua_config?.username}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Contraseña"
                                                        name="opcua_config.password"
                                                        required
                                                        value={formik.values.opcua_config?.password}
                                                        error={!!formik.errors.opcua_config?.password}
                                                        onChange={formik.handleChange}
                                                        type={showPassword ? 'text' : 'password'}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        autoComplete="new-password"
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </CardContent>

                                <Card sx={{ background: '#fff4f4' }}>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Variables {formik.values?.protocol}
                                        </Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Button variant="outlined" onClick={handleToggle}>
                                                    {open ? 'Ocultar' : 'Ver'}
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Collapse in={open}>
                                                    {/* Campos para modbus */}
                                                    {formik.values?.protocol == "MODBUS" && (
                                                        <>
                                                            {formik.values.variables.map((variable, index) => (
                                                                <><Grid container spacing={2} key={index}>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Nombre variable"
                                                                            name={`variables[${index}].name`}
                                                                            placeholder="Ej: nivel"
                                                                            required
                                                                            value={variable.name}
                                                                            error={formik.errors?.variables?.[index]?.name}
                                                                            onChange={formik.handleChange} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={4}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Registro"
                                                                            name={`variables[${index}].register`}
                                                                            required
                                                                            placeholder="Ej: 7"
                                                                            value={variable.register}
                                                                            error={formik.errors?.variables?.[index]?.register}
                                                                            onChange={formik.handleChange}
                                                                            type="number"
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={4}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Longitud"
                                                                            name={`variables[${index}].length`}
                                                                            required
                                                                            placeholder="Ej: 1"
                                                                            value={variable.length}
                                                                            error={formik.errors?.variables?.[index]?.length}
                                                                            onChange={formik.handleChange}
                                                                            type="number"
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={4}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Divisor"
                                                                            name={`variables[${index}].divisor`}
                                                                            required
                                                                            placeholder="Ej: 1"
                                                                            value={variable.divisor}
                                                                            error={formik.errors?.variables?.[index]?.divisor}
                                                                            onChange={formik.handleChange}
                                                                            type="number"
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={4}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id={`td-simple-select-label-${index}`}>Tipo dato</InputLabel>
                                                                            <Select
                                                                                labelId={`td-simple-select-label-${index}`}
                                                                                id={`td-simple-select-${index}`}
                                                                                value={variable.data_type}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.data_type}
                                                                                onChange={formik.handleChange}
                                                                                label="Tipo dato"
                                                                                name={`variables[${index}].data_type`}
                                                                            >
                                                                                {variable_choises.map((choice) => (
                                                                                    <MenuItem key={choice.value} value={choice.value}>
                                                                                        {choice.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={4}>
                                                                        <Autocomplete
                                                                            fullWidth
                                                                            id={`tanque_rel-${index}`}
                                                                            options={tanques?.results}
                                                                            required
                                                                            getOptionLabel={(option) => `${option.nombre_tanque} `}
                                                                            value={tanques?.results?.find(
                                                                                (opt) => opt.id_tanque === variable.tanque_rel
                                                                            ) || null}
                                                                            onChange={(_, value) => {
                                                                                formik.setFieldValue(
                                                                                    `variables[${index}].tanque_rel`,
                                                                                    value?.id_tanque || ""
                                                                                );
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Tanque"
                                                                                    variant="outlined"
                                                                                    required
                                                                                    error={formik.touched?.variables?.[index]?.tanque_rel &&
                                                                                        Boolean(formik.errors?.variables?.[index]?.tanque_rel)}
                                                                                    helperText={formik.touched?.variables?.[index]?.tanque_rel &&
                                                                                        formik.errors?.variables?.[index]?.tanque_rel}
                                                                                    sx={{ background: "#fcfcfc" }} />
                                                                            )} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={4}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id={`tv-simple-select-label-${index}`}>Tipo variable</InputLabel>
                                                                            <Select
                                                                                labelId={`tv-simple-select-label-${index}`}
                                                                                id={`tv-simple-select-${index}`}
                                                                                value={variable.tipo_variable}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.tipo_variable}
                                                                                onChange={(event) => {
                                                                                    formik.handleChange(event);
                                                                                    formik.setFieldValue(`variables[${index}].unidad_medida`, ''); // Reiniciar unidad de medida
                                                                                }}
                                                                                label="Tipo variable"
                                                                                name={`variables[${index}].tipo_variable`}
                                                                            >
                                                                                {tipo_variable_choices.map((choice) => (
                                                                                    <MenuItem key={choice.value} value={choice.value}>
                                                                                        {choice.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <FormControl fullWidth disabled={!variable.tipo_variable}>
                                                                            <InputLabel id={`um-simple-select-label-${index}`}>Unidad de medida</InputLabel>
                                                                            <Select
                                                                                labelId={`um-simple-select-label-${index}`}
                                                                                id={`um-simple-select-${index}`}
                                                                                value={variable.unidad_medida}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.unidad_medida}
                                                                                onChange={formik.handleChange}
                                                                                label="Unidad de medida"
                                                                                name={`variables[${index}].unidad_medida`}
                                                                            >
                                                                                {(unidad_medida_choices[variable.tipo_variable] || []).map((unidad) => (
                                                                                    <MenuItem key={unidad.value} value={unidad.value}>
                                                                                        {unidad.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id={`rt-simple-select-label-${index}`}>Tipo registro</InputLabel>
                                                                            <Select
                                                                                labelId={`rt-simple-select-label-${index}`}
                                                                                id={`rt-simple-select-${index}`}
                                                                                value={variable.register_type}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.register_type}
                                                                                onChange={formik.handleChange}
                                                                                label="Tipo registro"
                                                                                name={`variables[${index}].register_type`}
                                                                            >
                                                                                {register_type_choices.map((choice) => (
                                                                                    <MenuItem key={choice.value} value={choice.value}>
                                                                                        {choice.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={1}>
                                                                        <Tooltip title="Eliminar variable" arrow>
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                onClick={() => {
                                                                                    const newVariables = formik.values.variables.filter(
                                                                                        (_, i) => i !== index
                                                                                    );
                                                                                    formik.setFieldValue("variables", newVariables);
                                                                                }}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid><br /></>
                                                            ))}
                                                            <Grid item xs={12}>
                                                                <Tooltip title="Agregar variable" arrow>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        fullWidth
                                                                        onClick={() =>
                                                                            formik.setFieldValue("variables", [
                                                                                ...formik.values.variables,
                                                                                {
                                                                                    name: "",
                                                                                    register: null,
                                                                                    length: null,
                                                                                    divisor: null,
                                                                                    data_type: "",
                                                                                    tanque_rel: null,
                                                                                    tipo_variable: null,
                                                                                    unidad_medida: null,
                                                                                    register_type: ""
                                                                                },
                                                                            ])
                                                                        }
                                                                    >
                                                                        <AddIcon />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Grid>
                                                        </>
                                                    )}

                                                    {/* Campos para MQTT */}
                                                    {formik.values?.protocol == "MQTT" && (
                                                        <>
                                                            {formik.values.variables.map((variable, index) => (
                                                                <><Grid container spacing={2} key={index}>
                                                                    <Grid item xs={12} sm={3}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Nombre variable"
                                                                            required
                                                                            name={`variables[${index}].name`}
                                                                            placeholder="Ej: nivel"
                                                                            value={variable.name}
                                                                            error={formik.errors?.variables?.[index]?.name}
                                                                            onChange={formik.handleChange} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Tema"
                                                                            name={`variables[${index}].topic`}
                                                                            required
                                                                            placeholder="Ej: saddd222"
                                                                            value={variable.topic}
                                                                            error={formik.errors?.variables?.[index]?.topic}
                                                                            onChange={formik.handleChange}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id={`td-simple-select-label-${index}`}>Tipo dato</InputLabel>
                                                                            <Select
                                                                                labelId={`td-simple-select-label-${index}`}
                                                                                id={`td-simple-select-${index}`}
                                                                                value={variable.data_type}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.data_type}
                                                                                onChange={formik.handleChange}
                                                                                label="Tipo dato"
                                                                                name={`variables[${index}].data_type`}
                                                                            >
                                                                                {variable_choises.map((choice) => (
                                                                                    <MenuItem key={choice.value} value={choice.value}>
                                                                                        {choice.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <Autocomplete
                                                                            fullWidth
                                                                            id={`tanque_rel-${index}`}
                                                                            options={tanques?.results}
                                                                            required
                                                                            getOptionLabel={(option) => `${option.nombre_tanque} `}
                                                                            value={tanques?.results?.find(
                                                                                (opt) => opt.id_tanque === variable.tanque_rel
                                                                            ) || null}
                                                                            onChange={(_, value) => {
                                                                                formik.setFieldValue(
                                                                                    `variables[${index}].tanque_rel`,
                                                                                    value?.id_tanque || ""
                                                                                );
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Tanque"
                                                                                    variant="outlined"
                                                                                    required
                                                                                    error={formik.touched?.variables?.[index]?.tanque_rel &&
                                                                                        Boolean(formik.errors?.variables?.[index]?.tanque_rel)}
                                                                                    helperText={formik.touched?.variables?.[index]?.tanque_rel &&
                                                                                        formik.errors?.variables?.[index]?.tanque_rel}
                                                                                    sx={{ background: "#fcfcfc" }} />
                                                                            )} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={4}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id={`tv-simple-select-label-${index}`}>Tipo variable</InputLabel>
                                                                            <Select
                                                                                labelId={`tv-simple-select-label-${index}`}
                                                                                id={`tv-simple-select-${index}`}
                                                                                value={variable.tipo_variable}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.tipo_variable}
                                                                                onChange={(event) => {
                                                                                    formik.handleChange(event);
                                                                                    formik.setFieldValue(`variables[${index}].unidad_medida`, ''); // Reiniciar unidad de medida
                                                                                }}
                                                                                label="Tipo variable"
                                                                                name={`variables[${index}].tipo_variable`}
                                                                            >
                                                                                {tipo_variable_choices.map((choice) => (
                                                                                    <MenuItem key={choice.value} value={choice.value}>
                                                                                        {choice.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <FormControl fullWidth disabled={!variable.tipo_variable}>
                                                                            <InputLabel id={`um-simple-select-label-${index}`}>Unidad de medida</InputLabel>
                                                                            <Select
                                                                                labelId={`um-simple-select-label-${index}`}
                                                                                id={`um-simple-select-${index}`}
                                                                                value={variable.unidad_medida}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.unidad_medida}
                                                                                onChange={formik.handleChange}
                                                                                label="Unidad de medida"
                                                                                name={`variables[${index}].unidad_medida`}
                                                                            >
                                                                                {(unidad_medida_choices[variable.tipo_variable] || []).map((unidad) => (
                                                                                    <MenuItem key={unidad.value} value={unidad.value}>
                                                                                        {unidad.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id={`rt-simple-select-label-${index}`}>Tipo registro</InputLabel>
                                                                            <Select
                                                                                labelId={`rt-simple-select-label-${index}`}
                                                                                id={`rt-simple-select-${index}`}
                                                                                value={variable.register_type}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.register_type}
                                                                                onChange={formik.handleChange}
                                                                                label="Tipo registro"
                                                                                name={`variables[${index}].register_type`}
                                                                            >
                                                                                {register_type_choices.map((choice) => (
                                                                                    <MenuItem key={choice.value} value={choice.value}>
                                                                                        {choice.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={1}>
                                                                        <Tooltip title="Eliminar variable" arrow>
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                onClick={() => {
                                                                                    const newVariables = formik.values.variables.filter(
                                                                                        (_, i) => i !== index
                                                                                    );
                                                                                    formik.setFieldValue("variables", newVariables);
                                                                                }}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid><br /></>
                                                            ))}
                                                            <Grid item xs={12}>
                                                                <Tooltip title="Agregar variable" arrow>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        fullWidth
                                                                        onClick={() =>
                                                                            formik.setFieldValue("variables", [
                                                                                ...formik.values.variables,
                                                                                {
                                                                                    name: "",
                                                                                    topic: "",
                                                                                    data_type: "",
                                                                                    tanque_rel: null,
                                                                                    tipo_variable: null,
                                                                                    unidad_medida: null,
                                                                                    register_type: "",
                                                                                },
                                                                            ])
                                                                        }
                                                                    >
                                                                        <AddIcon />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Grid>
                                                        </>
                                                    )}

                                                    {/* Campos para OPC */}
                                                    {formik.values?.protocol == "OPC" && (
                                                        <>
                                                            {formik.values.variables.map((variable, index) => (
                                                                <><Grid container spacing={2} key={index}>
                                                                    <Grid item xs={12} sm={3}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Nombre variable"
                                                                            name={`variables[${index}].name`}
                                                                            placeholder="Ej: nivel"
                                                                            value={variable.name}
                                                                            required
                                                                            error={formik.errors?.variables?.[index]?.name}
                                                                            onChange={formik.handleChange} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <Autocomplete
                                                                            fullWidth
                                                                            id={`tanque_rel-${index}`}
                                                                            options={tanques?.results}

                                                                            getOptionLabel={(option) => `${option.nombre_tanque} `}
                                                                            value={tanques?.results?.find(
                                                                                (opt) => opt.id_tanque === variable.tanque_rel
                                                                            ) || null}
                                                                            onChange={(_, value) => {
                                                                                formik.setFieldValue(
                                                                                    `variables[${index}].tanque_rel`,
                                                                                    value?.id_tanque || ""
                                                                                );
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Tanque"
                                                                                    variant="outlined"
                                                                                    required
                                                                                    error={formik.touched?.variables?.[index]?.tanque_rel &&
                                                                                        Boolean(formik.errors?.variables?.[index]?.tanque_rel)}
                                                                                    helperText={formik.touched?.variables?.[index]?.tanque_rel &&
                                                                                        formik.errors?.variables?.[index]?.tanque_rel}
                                                                                    sx={{ background: "#fcfcfc" }} />
                                                                            )} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Id nodo"
                                                                            name={`variables[${index}].node_id`}
                                                                            required
                                                                            placeholder="Ej: nivel"
                                                                            value={variable.node_id}
                                                                            error={formik.errors?.variables?.[index]?.node_id}
                                                                            onChange={formik.handleChange} />
                                                                    </Grid>
                                                                 
                                                                    <Grid item xs={12} sm={3}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id={`td-simple-select-label-${index}`}>Tipo dato</InputLabel>
                                                                            <Select
                                                                                labelId={`td-simple-select-label-${index}`}
                                                                                id={`td-simple-select-${index}`}
                                                                                value={variable.data_type}
                                                                                error={formik.errors?.variables?.[index]?.data_type}
                                                                                onChange={formik.handleChange}
                                                                                label="Tipo dato"
                                                                                required
                                                                                name={`variables[${index}].data_type`}
                                                                            >
                                                                                {variable_choises.map((choice) => (
                                                                                    <MenuItem key={choice.value} value={choice.value}>
                                                                                        {choice.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id={`tv-simple-select-label-${index}`}>Tipo variable</InputLabel>
                                                                            <Select
                                                                                labelId={`tv-simple-select-label-${index}`}
                                                                                id={`tv-simple-select-${index}`}
                                                                                value={variable.tipo_variable}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.tipo_variable}
                                                                                onChange={(event) => {
                                                                                    formik.handleChange(event);
                                                                                    formik.setFieldValue(`variables[${index}].unidad_medida`, ''); // Reiniciar unidad de medida
                                                                                }}
                                                                                label="Tipo variable"
                                                                                name={`variables[${index}].tipo_variable`}
                                                                            >
                                                                                {tipo_variable_choices.map((choice) => (
                                                                                    <MenuItem key={choice.value} value={choice.value}>
                                                                                        {choice.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <FormControl fullWidth disabled={!variable.tipo_variable}>
                                                                            <InputLabel id={`um-simple-select-label-${index}`}>Unidad de medida</InputLabel>
                                                                            <Select
                                                                                labelId={`um-simple-select-label-${index}`}
                                                                                id={`um-simple-select-${index}`}
                                                                                value={variable.unidad_medida}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.unidad_medida}
                                                                                onChange={formik.handleChange}
                                                                                label="Unidad de medida"
                                                                                name={`variables[${index}].unidad_medida`}
                                                                            >
                                                                                {(unidad_medida_choices[variable.tipo_variable] || []).map((unidad) => (
                                                                                    <MenuItem key={unidad.value} value={unidad.value}>
                                                                                        {unidad.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={4}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id={`rt-simple-select-label-${index}`}>Tipo registro</InputLabel>
                                                                            <Select
                                                                                labelId={`rt-simple-select-label-${index}`}
                                                                                id={`rt-simple-select-${index}`}
                                                                                value={variable.register_type}
                                                                                required
                                                                                error={formik.errors?.variables?.[index]?.register_type}
                                                                                onChange={formik.handleChange}
                                                                                label="Tipo registro"
                                                                                name={`variables[${index}].register_type`}
                                                                            >
                                                                                {register_type_choices.map((choice) => (
                                                                                    <MenuItem key={choice.value} value={choice.value}>
                                                                                        {choice.text}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={1}>
                                                                        <Tooltip title="Eliminar variable" arrow>
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                onClick={() => {
                                                                                    const newVariables = formik.values.variables.filter(
                                                                                        (_, i) => i !== index
                                                                                    );
                                                                                    formik.setFieldValue("variables", newVariables);
                                                                                }}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid><br /></>
                                                            ))}
                                                            <Grid item xs={12}>
                                                                <Tooltip title="Agregar variable" arrow>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        fullWidth
                                                                        onClick={() =>
                                                                            formik.setFieldValue("variables", [
                                                                                ...formik.values.variables,
                                                                                {
                                                                                    name: "",
                                                                                    node_id: "",
                                                                                    data_type: "",
                                                                                    tanque_rel: null,
                                                                                    tipo_variable: null,
                                                                                    unidad_medida: null,
                                                                                    register_type: "",
                                                                                },
                                                                            ])
                                                                        }
                                                                    >
                                                                        <AddIcon />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Collapse>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Card>
                        </Grid>
                    )}

                    <Grid item xs={12} sm={10}>
                        <TextField
                            fullWidth
                            label="Buscar ubicación"
                            name="searchQuery"
                            value={initialValuesMap.searchQuery}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Button fullWidth variant="outlined" style={{ height: '100%' }} onClick={handleSearchClick}>
                            <SearchIcon />
                        </Button>
                    </Grid>

                    {/* zona del mapa - fila 4 */}
                    <Grid item xs={12} sm={12}>
                        <MapContainer key={mapKey} center={markerPosition} zoom={13} style={{ height: '400px' }}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={markerPosition} icon={customIcon} draggable={true} eventHandlers={{
                                dragend: handleMarkerMove
                            }}>
                                <Popup>
                                    Ubicación
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Button type="submit" variant="contained" className="btn btn-danger" color="error" fullWidth>{Sensores ? "Actualizar" : "Crear"}</Button>
                    </Grid>

                    {/* fila no visible */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Latitud"
                            name="id_direcFc.latitud"
                            value={formik.values?.latitud}
                            onChange={formik.handleChange}
                            type="text"
                            hidden
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Longitud"
                            name="id_direcFc.longitud"
                            placeholder="Datos extra"
                            value={formik.values?.longitud}
                            onChange={formik.handleChange}
                            type="text"
                            hidden
                            disabled
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

function initialValues(data) {
    return {
        modelo: data?.modelo || "",
        num_serie: data?.num_serie || "",
        latitud: data?.latitud || 4.60971,
        longitud: data?.longitud || -74.08175,
        protocol: data?.protocol || "",
        frequency: data?.frequency || null,
        tag: data?.tag || "",
        descripcion: data?.descripcion || "",
        modbus_config: data?.modbus_config || null,
        mqtt_config: data?.mqtt_config || null,
        opcua_config: data?.opcua_config || null,

        variables: data?.variables || [],
    };
}