import { TOKEN } from "../utils/constants";

const TENANT = "tenant";
// Esta función establece un token en el almacenamiento local del navegador utilizando el nombre de la constante TOKEN
export function setToken(token) {
  localStorage.setItem(TOKEN, token);
}

export function setTenant(tenant) {
  localStorage.setItem(TENANT, tenant)
}

// Esta función devuelve el token almacenado en el almacenamiento local del navegador utilizando el nombre de la constante TOKEN
export function getToken() {
  return localStorage.getItem(TOKEN);
}

export function getTenant() {
  return localStorage.getItem(TENANT)
}

// Esta función elimina el token almacenado en el almacenamiento local del navegador utilizando el nombre de la constante TOKEN
export function removeToken() {
  localStorage.removeItem(TOKEN);
}

export function removeTenant() {
  localStorage.removeItem(TENANT)
}