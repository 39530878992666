import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { AddEditOperadores, DocumentOperador, AddEditHuellasOp } from "../../../Components/Client";
import { VisualizacionGeneralDocumentosForm } from "../../../Components/Comons";
import { useOperador } from "../../../hooks";
import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
//import Avatar from '@mui/material/Avatar';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

export function Funciones() {
  const { deleteOperador } = useOperador();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

  const handleViews = useCallback(
    (data) => {
      setTitleModal("");
      setContentModal(
        <VisualizacionGeneralDocumentosForm
          nombre={"Información del operador"}
          visualizacion={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleAdd = useCallback(() => {
    setTitleModal("Nuevo operador");
    setContentModal(
      <AddEditOperadores onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback(
    (data) => {
      setTitleModal("Actualizar operador");
      setContentModal(
        <AddEditOperadores
          onClose={openCloseModal}
          onRefetch={onRefetch}
          Operador={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(
    async (data) => {
      const result = await Swal.fire({
        title: "Estas seguro?",
        text: "No podrás revertir esta acción!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        await deleteOperador(data.id_operador);
        Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
        onRefetch();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Operación cancelada", "error");
      }
    },
    [deleteOperador, onRefetch]
  );

  const handleAddEditBiometricData = useCallback((data) => {
    setTitleModal("Información del conductor en el dispositivo de acceso");
    setContentModal(
      <AddEditHuellasOp
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Operador={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const documentosUpload = (data) => {
    setTitleModal("documentos operador");
    setContentModal(
      <DocumentOperador data={data} />
    );
    openCloseModal();
  };

  const columns = [
    // {
    //   header: "Foto",
    //   name: "foto",

    //   render: ({ data }) => {
    //     return (
    //       <>
    //         <Avatar alt="nn" src={data.foto} />
    //       </>
    //     );
    //   },
    //   maxWidth: 70,
    //   defaultFlex: 1,
    // },
    {
      header: "Nombres",
      name: "nombre",
      maxWidth: 1000,
      defaultFlex: 1,
      // style: ({ data }) => ({

      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Apellidos",
      name: "apellidos",
      maxWidth: 1000,
      defaultFlex: 1,
      // style: ({ data }) => ({

      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Correo",
      name: "email",
      maxWidth: 1000,
      defaultFlex: 1,
      // style: ({ data }) => ({

      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Numero de documento",
      name: "n_documento",
      maxWidth: 1000,
      defaultFlex: 1,
      // style: ({ data }) => ({

      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Celular",
      name: "celular",
      maxWidth: 1000,
      defaultFlex: 1,
      // style: ({ data }) => ({

      //   color: data.cumplimiento_documentacion === false ? "red" : "inherit",
      // }),
    },
    {
      header: "Extras",
      render: ({ data }) => (
        <>
          <Tooltip title="Documentos" arrow>
            <IconButton style={{ padding: '2px' }} onClick={() => documentosUpload(data.id_operador)}>
              <AdfScannerIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Agregar conductor o editar información en el dispositivo biométrico" arrow>
            <IconButton style={{ padding: '2px' }} onClick={() => handleAddEditBiometricData(data)}>
              <GroupAddIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
      maxWidth: 74,
      defaultFlex: 1,
    },
  ];

  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    handleViews,
    refetch,
    contentModal,
    titleModal,
    showModal,
    columns,
    handleAddEditBiometricData,
    documentosUpload,
  };
}
