import { useState } from "react";
import { useAuth } from "../";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { getDocumentosoperadoresApi, updateDocumentosApi } from "../../api/A_Operador/documentosOperador";

export  function useDocumentosOperadores() {
  
  const { auth } = useAuth();
  
  const { unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    DocumentosOperadores: null,
  });

  const { loading, error, DocumentosOperadores} = state;

  const getDocumentosOperadores = async (data) => {
    try {
      
      setState((prevState) => ({ ...prevState, loading: true }));
      
      const [result, response] = await getDocumentosoperadoresApi(auth.token, auth.tenant, data);

      setState((prevState) => ({ ...prevState, loading: false, DocumentosOperadores: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };


  const updateDocumentosOperadores = async (id, data) => {
    console.log(data)
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateDocumentosApi(id, data, auth.token, auth.tenant);

      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  
  return {
    loading,
    error,
    DocumentosOperadores,
    getDocumentosOperadores,
    updateDocumentosOperadores,
  };
}
