import { useState } from "react";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";
import {
    addSensoresApi, getSensoresApi, updateSensoresApi, deleteSensoresApi,
    addEtiquetaSensoresApi, getEtiquetaSensoresApi, updateEtiquetaSensoresApi, deleteEtiquetaSensoresApi,
    addSensorCategoriesApi, getSensorCategoriesApi, updateSensorCategoriesApi, deleteSensorCategoriesApi,
    addSensorUnitsApi, getSensorUnitsApi, updateSensorUnitsApi, deleteSensorUnitsApi,
} from "../../api/A_Sensores/sensores"

export function useSensores() {
    const { auth, logout } = useAuth();
    const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

    const [state, setState] = useState({
        loading: true,
        error: null,
        Sensores: null,
        EtiquetaSensores: null,
        SensorCategories: null,
        SensorUnits: null
    })

    const { loading, error, Sensores, EtiquetaSensores, SensorCategories, SensorUnits } = state

    const handleResponse = (response) => {
        if (response.status === 401) {
            SessionExpired();
            logout();
        } else if (response.status === 403) {
            unauthorized();
        }
    };

    const handleAddOrUpdateError = (result) => {
        if (result?.nit_Controladores) {
            throw new Error("Ya existe un Controladores registrado con este NIT");
        } else {
            errores();
        }
    }

    const getSensores = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getSensoresApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, Sensores: result }))
        } catch (e) {
            setState((prevState) => ({ ...prevState, loading: false, error }))
        }
    }

    const addSensores = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await addSensoresApi(data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const updateSensores = async (id, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updateSensoresApi(id, data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const deleteSensores = async (id) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteSensoresApi(id, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };


    const getEtiquetaSensores = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getEtiquetaSensoresApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, EtiquetaSensores: result }))
        } catch (e) {
            setState((prevState) => ({ ...prevState, loading: false, error }))
        }
    }

    const addEtiquetaSensores = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await addEtiquetaSensoresApi(data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const updateEtiquetaSensores = async (id, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updateEtiquetaSensoresApi(id, data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const deleteEtiquetaSensores = async (id) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteEtiquetaSensoresApi(id, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const getSensorCategories = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getSensorCategoriesApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, SensorCategories: result }))
        } catch (e) {
            setState((prevState) => ({ ...prevState, loading: false, error }))
        }
    }

    const addSensorCategories = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await addSensorCategoriesApi(data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const updateSensorCategories = async (id, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updateSensorCategoriesApi(id, data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const deleteSensorCategories = async (id) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteSensorCategoriesApi(id, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const getSensorUnits = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getSensorUnitsApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, SensorUnits: result }))
        } catch (e) {
            setState((prevState) => ({ ...prevState, loading: false, error }))
        }
    }

    const addSensorUnits = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await addSensorUnitsApi(data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const updateSensorUnits = async (id, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updateSensorUnitsApi(id, data, auth.token, auth.tenant);
            if (response.status === 400) {
                handleAddOrUpdateError(result);
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const deleteSensorUnits = async (id) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteSensorUnitsApi(id, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    return {
        loading,
        error,
        getSensores,
        addSensores,
        updateSensores,
        deleteSensores,
        Sensores,
        getEtiquetaSensores,
        addEtiquetaSensores,
        updateEtiquetaSensores,
        deleteEtiquetaSensores,
        EtiquetaSensores,
        SensorCategories,
        addSensorCategories,
        getSensorCategories,
        updateSensorCategories,
        deleteSensorCategories,
        SensorUnits,
        getSensorUnits,
        addSensorUnits,
        updateSensorUnits,
        deleteSensorUnits,
    }
}