


import { BASE_API } from "../utils/constants";
import { makeRequest } from "./Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getTanquesApi(token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tanqueC`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addTanquesApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tanqueC/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateTanquesApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tanqueC/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

export async function updateOperativeApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tanqueC/${id}/`;
  return makeRequest(url, "PATCH", token, data);
}

// Función para actualizar los datos 
export async function updatePartialApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tanqueA/${id}/`;
  return makeRequest(url, "PATCH", token, data);
}

// Función para eliminar 
export async function deleteTanquesApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tanqueC/${id}/`;
  return makeRequest(url, "DELETE", token);
}



// Función para obtener los datos 
export async function getAsignacionTanquesApi(token, schema, id) {
  const url = `${BASE_URL}${schema}/tanques/asignacionclientes?id_tanque=${id}`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addAsignacionTanquesApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/asignacionclientes/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateAsignacionTanquesApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/asignacionclientes/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteAsignacionTanquesApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/asignacionclientes/${id}/`;
  return makeRequest(url, "DELETE", token);
}

export async function getTankVerApi(token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tankchecklist`
  return makeRequest(url, "GET", token)
}

export async function addTankVerApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tankchecklist/`
  return makeRequest(url, "POST", token, data)
}

export async function updateTankVerApi(id_tanque, data, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tankchecklist/${id_tanque}/`;
  return makeRequest(url, "PUT", token, data);
}

export async function getSingleTankChecklistApi(id_tanque, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/singletankchecklist?id_tanque=` + id_tanque
  return makeRequest(url, "GET", token)
}

export async function getChecklistByTankApi(id_tanque, token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tankchecklist?id_tanque=` + id_tanque
  return makeRequest(url, "GET", token)
}

export async function getTanquesConsumoInternoApi(token, schema) {
  const url = `${BASE_URL}${schema}/tanques/tankci`
  return makeRequest(url, "GET", token)
}