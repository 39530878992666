import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { AddEditFracciones, HeaderPage } from "../../../../Components/Client";
import { ModalBasic, DataGridBasic } from "../../../../Components/Comons";
import { useFracciones } from "../../../../hooks";
import Swal from "sweetalert2";
import { filterValue, columns } from "./FraccionesFormat";
import CircularProgress from '@mui/material/CircularProgress';

export function FraccionesAdmin() {
  const { loading, fracciones, getFracciones, deleteFracciones } =
    useFracciones();
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);

  useEffect(() => {
    getFracciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const onRefetch = () => setRefetch((prev) => !prev);
  const openCloseModal = () => setShowModal((prev) => !prev)
  const addFracciones = () => {
    setTitleModal("Nuevas fracciones");
    setContentModal(
      <AddEditFracciones onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  const updateFracciones = (data) => {
    setTitleModal("Actualizar fracciones");
    setContentModal(
      <AddEditFracciones
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Fracciones={data}
      />
    );
    openCloseModal();
  };

  const onDeleteFracciones = async (data) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Estas seguro?",
        text: "No podras revertir esta accion!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteFracciones(data.id_fracciones);
          onRefetch();
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "El dato a sido eliminado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Operacion cancelada",
            "error"
          );
        }
      });
  };

  return (
    <>
      <HeaderPage
        title="Fracciones"
        btnTitle="Nuevo fracciones"
        icono="plus square icon"
        btnClick={addFracciones}
      />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <DataGridBasic
            data={fracciones.results}
            title="Fracciones"
            columns={columns}
            id="id_fracciones"
            filterValue={filterValue}
            updateData={updateFracciones}
            onDeleteData={onDeleteFracciones}
          />

        </>
      )}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  );
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos
