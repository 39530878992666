import Avatar from '@mui/material/Avatar';
export const columns = [
    // {
    //   header: "ID",
    //   name: "id",
    // },
    { header: 'Imagen', name: 'foto',
    maxWidth: 1000, defaultFlex: 1,
    render: ({ data }) => {
        return (
          <>
            <Avatar alt="nn" src={data.foto} />
          </>
        )
      }, 
},
    {
      header: "Nombre cliente",
      name: "nombre",
      maxWidth: 1000, defaultFlex: 1
    },
    
    {
      header: "Key",
      name: "key_licencia",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Fecha de registro de licencia",
      name: "start_licencia",
      maxWidth: 1000, defaultFlex: 1
    },
    {
      header: "Fecha de vencimineto de licencia",
      name: "end_licencia",
      maxWidth: 1000, defaultFlex: 1
    },
    
  ];

  export const filterValue = [
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    { name: 'foto', operator: 'startsWith', type: 'string', value: '' },
    { name: 'key_licencia', operator: 'startsWith', type: 'string', value: '' },
    {
      name: 'start_licencia',
      operator: 'before',
      type: 'date',
      value: ''
    },
    {
        name: 'end_licencia',
        operator: 'before',
        type: 'date',
        value: ''
      },
    
  ];