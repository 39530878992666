export const columns = [
    { header: "Nombre sensor", name: "modelo", maxWidth: 1000, defaultFlex: 1 },
    { header: "Protocolo", name: "protocol", maxWidth: 1000, defaultFlex: 1 },
    { header: "Número de serie", name: "num_serie", maxWidth: 1000, defaultFlex: 1 },
    { header: "Latitud", name: "latitud", maxWidth: 1000, defaultFlex: 1 },
    { header: "Longitud", name: "longitud", maxWidth: 1000, defaultFlex: 1 },
    { header: "tag", name: "tag", maxWidth: 1000, defaultFlex: 1 },
    { header: "Frecuencia", name: "frequency", maxWidth: 1000, defaultFlex: 1 },
    { header: "Descripción", name: "descripcion", maxWidth: 1000, defaultFlex: 1 },
]

export const tagColumns = [
    { header: "Tipo", name: "tipo", maxWidth: 1000, defaultFlex: 1 },
    { header: "Categoría", name: "categoria", maxWidth: 1000, defaultFlex: 1 },
    { header: "Unidad", name: "unidad", maxWidth: 1000, defaultFlex: 1 },
    { header: "Marca", name: "marca", maxWidth: 1000, defaultFlex: 1 },
    { header: "Modelo", name: "modelo", maxWidth: 1000, defaultFlex: 1 },
]

export const filterValue = [
    { name: 'puerto', operator: 'startsWith', type: 'string', value: '' },
    { name: 'moduloText', operator: 'startsWith', type: 'string', value: '' },
    { name: 'inputRangeText', operator: 'startsWith', type: 'string', value: '' },
    { name: 'tipo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'categoria', operator: 'startsWith', type: 'string', value: '' },
    { name: 'unidad', operator: 'startsWith', type: 'string', value: '' },
]

export const tagFilterValue = [
    { name: 'tipo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'categoria', operator: 'startsWith', type: 'string', value: '' },
    { name: 'unidad', operator: 'startsWith', type: 'string', value: '' },
    { name: 'modelo', operator: 'startsWith', type: 'string', value: '' },
    { name: 'marca', operator: 'startsWith', type: 'string', value: '' },
]