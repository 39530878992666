import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from "react";
import { HeaderPage } from "../../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../../Components/Comons";
import { useOrdenes } from "../../../../hooks";
import { Funciones } from "./Funciones";
import { ExcelColumns, filterValue, groups } from "./OrdenesFormat";

export function OrdenesAdmin() {
  const { loading, Ordenes, getOrdenes } = useOrdenes();
  const [showExtraColumns, setShowExtraColumns] = useState(false); // Estado para mostrar las columnas adicionales
  const { columns, refetch, contentModal, titleModal, showModal, extraColumns,
    addMultipleOrdenes, addOrdenes, updateOrdenes, CargaConductorCamara, onDeleteOrdenes, vistaTransacciones, openCloseModal, handleSlateData } = Funciones();


  useEffect(() => {
    getOrdenes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  const handleToggleColumns = () => {
    // Cambiar el estado showExtraColumns al hacer clic en el botón de recargar o reorganizar
    setShowExtraColumns((prevShowExtraColumns) => !prevShowExtraColumns);
  };

  return (
    <>
      <HeaderPage
        title="Órdenes Despacho"
        btnTitle="Nueva orden cargue"
        icono="plus square icon"
        btnClick={addOrdenes}
      />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          data={Ordenes?.results}
          title="Ordenes"
          id="id_orden"
          filterValue={filterValue}
          ExcelColumns={ExcelColumns}
          columns={showExtraColumns ? extraColumns : columns} // Selecciona las columnas a mostrar
          updateData={updateOrdenes}
          onDeleteData={onDeleteOrdenes}
          visualizacionData={vistaTransacciones}
          cambioTabla={handleToggleColumns}
          groups={groups}
          cargaMasiva={addMultipleOrdenes}
          viewSlateData={handleSlateData}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}

      />


    </>
  );
}
//Columnas que se envian en forma de props al componente tabla basic para representer sus campos
