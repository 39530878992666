import React from "react";
import { Container, Grid, Typography, Avatar } from "@mui/material";
import DefaultAvatar from './default.jpg'; // Asegúrate de tener una imagen predeterminada

export function VisualizacionGeneralForm(props) {
  const { visualizacion } = props;

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Información del usuario
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} sm={4} align="center">
          <Avatar
            src={visualizacion?.foto || DefaultAvatar}
            alt={`${visualizacion?.nombres || "Usuario"} ${visualizacion?.apellidos || ""}`}
            style={{ width: '150px', height: '150px' }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          {visualizacion && (
            <>
              <Typography variant="body1">
                <b>Nombres:</b> {visualizacion.nombres}
              </Typography>
              <Typography variant="body1">
                <b>Apellidos:</b> {visualizacion.apellidos}
              </Typography>
              <Typography variant="body1">
                <b>Identificación:</b> {visualizacion.identificacion_pers}
              </Typography>
              <Typography variant="body1">
                <b>Email:</b> {visualizacion.email}
              </Typography>
              <Typography variant="body1">
                <b>Fecha de nacimiento:</b> {visualizacion.fecha_nacimiento}
              </Typography>
              <Typography variant="body1">
                <b>Teléfono:</b> {visualizacion.telefono}
              </Typography>
              <Typography variant="body1">
                <b>Celular:</b> {visualizacion.celular}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
