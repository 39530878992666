
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;


// Función para obtener los datos de un cliente
export async function getConciliacionesApi(token, schema,id) {
  let url = `${BASE_URL}${schema}/conciliaciones/conciliaciones`;
  if (id) {  
    url += `?tanque=${id}`;
  }
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo cliente
export async function addConciliacionesApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/conciliaciones/conciliaciones/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos de un cliente existente
export async function updateConciliacionesApi(id, data, token, schema) {
  const url = `${BASE_URL}${schema}/conciliaciones/conciliaciones/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar un cliente
export async function deleteConciliacionesApi(id, token, schema) {
  const url = `${BASE_URL}${schema}/conciliaciones/conciliaciones/${id}/`;
  return makeRequest(url, "DELETE", token);
}
// Función para eliminar un cliente
export async function ultimasConciliacionesApi(token, schema,id) {
  let url = `${BASE_URL}${schema}/conciliaciones/conciliacion/ultimos`;
  
  if (id) { 
    url += `?tanque=${id}`;
  }
  return makeRequest(url, "GET", token);
}
// Función para eliminar un cliente
export async function ConciliacionesSum(token, schema,id) {
  let url = `${BASE_URL}${schema}/conciliaciones/conciliaciones/suma`;
  if (id) {  
    url += `?tanque=${id}`;
  }
  return makeRequest(url, "GET", token);
}

export async function ConciliacionesSumTotal(token, schema,id) {
  let url = `${BASE_URL}${schema}/conciliaciones/conciliaciones/total-sum/`;
  if (id) {
      
    url += `?tanque=${id}`;
    
  }
  return makeRequest(url, "GET", token);
}

export async function Conciliacionescierre(token, schema,id) {
  let url = `${BASE_URL}${schema}/conciliaciones/conciliacion/cierre`;
  if (id) {
    url += `?tanque=${id}`; 
  }
  return makeRequest(url, "GET", token);
}

// Función para obtener los datos de un cliente
export async function getConciliacioneDataCierreApi(token, schema, id) {
  let url = `${BASE_URL}${schema}/conciliaciones/conciliacion/DataCierre?id_tanque=${id}`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo cliente
export async function addConciliacionesCierreApi(data, token, schema) {
  const url = `${BASE_URL}${schema}/conciliaciones/conciliacion/cierre/`;
  return makeRequest(url, "POST", token, data);
}