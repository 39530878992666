import { useState } from "react";
import {
  getControladoresApi,
  addControladoresApi,
  deleteControladoresApi,
  updateControladoresApi,
  patchControladoresApi,
  getControladorRegCombApi,
  getControladorCompFlujoApi,
} from "../../api/A_Conexiones/controladores";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export function useControladores() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    Controladores: null,
    ControladoresDB: null,
    CtrlRegCom: null,
    CtrlCompFlujo: null,
  });

  const { loading, error, Controladores, ControladoresDB, CtrlRegCom, CtrlCompFlujo } = state;

  const handleAddOrUpdateError = (result) => {
    if (result?.nit_Controladores) {
      throw new Error("Ya existe un Controladores registrado con este NIT");
    } else {
      errores();
    }
  };

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    }
  };

  const getControladoresDB = async () => {
    try {
      const [result, response] = await getControladoresApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, ControladoresDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getControladores = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getControladoresApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, Controladores: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getControladorRegComb = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getControladorRegCombApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, CtrlRegCom: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getControladorCompFlujo = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getControladorCompFlujoApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, CtrlCompFlujo: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  }

  const addControladores = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addControladoresApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateControladores = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateControladoresApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const patchControladores = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await patchControladoresApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        handleAddOrUpdateError(result);
      }
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteControladores = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteControladoresApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    ControladoresDB,
    Controladores,
    getControladoresDB,
    getControladores,
    addControladores,
    updateControladores,
    patchControladores,
    deleteControladores,
    CtrlRegCom,
    getControladorRegComb,
    CtrlCompFlujo,
    getControladorCompFlujo,
  };
}
