import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { loginDjango } from "../../../api/user";
import { useAuth } from "../../../hooks";
import { Navigate } from 'react-router-dom';
import { BASE_API_PUBLIC } from "../../../utils/constants";

export function LoginForm() {
  const { login } = useAuth();

  const captcha = useRef(null);
  const [tokenRc, setToken] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [tenants, setTenants] = useState([]);


  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formValue) => {

      try {
        const response = await loginDjango(formValue);
        const { token, tenant } = response;
        login(token, tenant );
        setRedirect(true);
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
  });

  useEffect(() => {
    const fetchTenant = async () => {
      if (formik.values.email) {
        try {
          const [result] = await getEmailTenantApi({ email: formik.values.email });
          if (result) {
            formik.setFieldValue("tenant", result.tenant);
            setTenants(result.tenant)
          }
        } catch (error) {
          console.error("Error fetching tenant:", error);
        }
      }
    };
  
    fetchTenant();
  }, [formik.values.email, getEmailTenantApi]);

  const handleBlur = async () => {
    if (formik.values.email) {
      try {
        const [result] = await getEmailTenantApi({ email: formik.values.email });
          if (result) {
            formik.setFieldValue("tenant", result.tenant);
          }
      } catch (error) {
        console.error("Error fetching tenant:", error);
      }
    }
  };


  if (redirect) {
    return <Navigate to="/" />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-2">
        <label>Email</label>
        <input
          name="email"
          placeholder="Correo Electrónico"
          type="email"
          required
          className="form-control"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={handleBlur}
          autoFocus
        />
      </div>

      <div className="mb-2">
        <label>Contraseña</label>
        <input
          name="password"
          type="password"
          required
          className="form-control"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
      </div>

      <div className="mb-2">
      <label>Cliente</label>
      <select
        name="tenant"
        className="form-control"
        onChange={(e) => formik.setFieldValue('tenant', e.target.value)}
      >
        <option value="">Seleccione algun cliente</option>
        {Array.isArray(tenants) && tenants.map((opcion) => (
          <option key={opcion} value={opcion}>
            {opcion}
          </option>
        ))}
      </select>
    </div>

      <div className="mb-3">
        <ReCAPTCHA
          ref={captcha}
          sitekey="6Le3RnsiAAAAAA3yF7tEaf1JSxi54OUB819tq_OL"
          onChange={(value) => setToken(value)}
        />
      </div>

      <div className="d-grid gap-2">
        <button
          className={`btn ${tokenRc ? 'btn-danger' : 'btn-dark'} rounded-pill`}
          type="submit"
        >
          Ingresar
        </button>
        <a className="nav-link text-danger text-center" href="/reset_password">
          Olvidé mis credenciales
        </a>
      </div>
    </form>
  );
}

function initialValues() {
  return {
    email: "",
    password: "",
    tenant: ""
  };
}

function validationSchema() {
  return {
    email: Yup.string().email(true).required(true),
    password: Yup.string().required(true),
    tenant: Yup.string().required(true),
  };
}

// Funciones de API

async function getEmailTenantApi(data) {
  const url = `${BASE_API_PUBLIC}/UsuariosLogin/tenantEmails/`;
  return makeRequest(url, "POST", data);
}

async function makeRequest(url, method, data) {
  try {
    const params = {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, params);
    const result = await response.json();

    return [result, response];
  } catch (error) {
    throw error;
  }
}