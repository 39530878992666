import { useFormik } from "formik";
import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Swal from "sweetalert2";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as Yup from "yup";
import { useIslas } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import { useBahias } from "../../../hooks";
import { id } from "date-fns/locale";


const opType = [
  { key: "Cargadero", value: "Cargadero", text: "Cargadero" },
  { key: "Descarga", value: "Descarga", text: "Descarga" },
];

export function AddEditIslas(props) {
  const { onClose, onRefetch, Islas } = props;
  const { addIslas, updateIslas } = useIslas();
  const { deleteBahias, code, message, loading } = useBahias();
  const { errores, Successful, warning } = alertas();
  const [error, setError] = useState(false);
  const [spinning, setSpinning] = useState(false);

  const [bahias_id, setBahias] = useState(Islas?.bahias_id || []);
  const [tempBahias, setTempBahias] = useState([]);

  const formik = useFormik({
    initialValues: {
      nombre: Islas?.nombre || "",
      N_bahias: bahias_id.length, // Inicializar con la cantidad de bahías
      tipo_operacion: Islas?.tipo_operacion || "",
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("Este campo es obligatorio"),
      N_bahias: Yup.number().required("Este campo es obligatorio"),
      tipo_operacion: Yup.string().required("Este campo es obligatorio"),
    }),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (Islas) {
          await updateIslas(Islas.id_isla, { ...formValue, bahias_id });
        } else {
          await addIslas({ ...formValue, bahias_id });
        }
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const handleAddBahia = () => {
    const newBahias = [...bahias_id, { nombre: "" }];
    setBahias(newBahias);
    formik.setFieldValue('N_bahias', newBahias.length);
  };

  const handleDeleteBahias = useCallback(async (index, id_bahia) => {
    const result = await Swal.fire({
      title: "¿Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setSpinning(true);
      if (id_bahia) await deleteBahias(id_bahia);
      
      if (!id_bahia) setSpinning(false);

      const newBahias = [...bahias_id.slice(0, index), ...bahias_id.slice(index + 1)];
      setTempBahias(newBahias);

    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [deleteBahias, onRefetch, bahias_id]);

  const handleBahiaChange = (index, value) => {
    setBahias([
      ...bahias_id.slice(0, index),
      { nombre: value },
      ...bahias_id.slice(index + 1),
    ]);
  };

  useEffect(() => {
    const ejecutarPatch = async () => {
      if (code !== null) {

        if (code === 400) {
          setSpinning(false);
          errores(message);
        }

        if (code === 200) {
          setBahias(tempBahias);
          setSpinning(false);
          Successful(message);
          formik.setFieldValue('N_bahias', bahias_id.length);
        }

        if (code === 204) {
          setSpinning(false);
          warning(message);
        }
      }
    };

    ejecutarPatch();
  }, [loading]);


  return (
    <>
      <form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Nombre"
              name="nombre"
              placeholder="Nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Número de bahías"
              name="N_bahias"
              placeholder="Número de bahías"
              value={formik.values.N_bahias}
              onChange={formik.handleChange}
              error={formik.errors.N_bahias}
              disabled // Deshabilitar el campo para evitar cambios manuales
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Autocomplete
              fullWidth
              id="tipo_operacion"
              options={opType}
              getOptionLabel={(option) => `${option.text} `}
              value={
                opType?.find(
                  (opt) => opt.key === formik.values?.tipo_operacion
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("tipo_operacion", value?.key || "");
                setError(!value || !value.value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de operación"
                  variant="outlined"
                  required
                  error={error}
                  helperText={error ? 'Es necesario una selección' : ''}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h5">
                Bahías
              </Typography>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        Nombre
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: "50px" }}>
                      <Tooltip title="Agregar" arrow>
                        <Button
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          onClick={handleAddBahia}
                          style={{
                            color: 'rgb(25, 118, 210)',
                            border: '1px solid rgb(25, 118, 210)',
                            '&:hover': {
                              color: 'rgba(221, 223, 221, 0.62)',
                              border: '1px solid rgba(221, 223, 221, 0.62)',
                            }
                          }}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bahias_id.map((bahia, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          fullWidth
                          required
                          placeholder="Nombre de la bahía"
                          value={bahia.nombre}
                          onChange={(e) => handleBahiaChange(index, e.target.value)}
                        />
                      </TableCell>
                      <TableCell style={{ width: "50px" }}>
                        <Tooltip title="Eliminar" arrow>
                          <Button
                            variant="outlined"
                            color="secondary"
                            style={{
                              height: '100%', display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '0',
                              margin: '0',
                              color: 'rgba(252, 0, 0, 0.8)',
                              border: '1px solid rgba(252, 0, 0, 0.8)',
                            }}
                            onClick={() => handleDeleteBahias(index, bahia.id_bahias)}
                          >
                            <DeleteIcon style={{ margin: '0' }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              {Islas ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Backdrop open={spinning}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
