export const columns = [
  // {
  //   name: "id_parametrizacion",
  //   header: "ID Parametrización",
  //   maxWidth: 1000,
  //   defaultFlex: 1
  // },
  {
    name: "nombre",
    header: "Nombre",
    maxWidth: 1000,
    defaultFlex: 1
  },
  {
    name: "tov_decimales",
    header: "TOV Decimales",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "fw_decimales",
    header: "FW Decimales",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "ctsh_decimales",
    header: "CTSh Decimales",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "gsv_decimales",
    header: "GSV Decimales",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "csw_decimales",
    header: "CSW Decimales",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "fra_decimales",
    header: "FRA Decimales",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "gov_decimales",
    header: "GOV Decimales",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "CTL",
    header: "CTL",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "CPL",
    header: "CPL",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "CTPL",
    header: "CTPL",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "IV",
    header: "IV",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "GOV",
    header: "GOV",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "GSV",
    header: "GSV",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "NSV",
    header: "NSV",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "TEMP",
    header: "TEMP",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "BSW",
    header: "BSW",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "PRES",
    header: "PRES",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "API",
    header: "API",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "DENS",
    header: "DENS",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "DENR",
    header: "DENR",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  // nuevas columnas
  {
    name: "VOLP_RT_B",
    header: "VOLP B",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "METER_F",
    header: "METER F",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
  {
    name: "K_FACTOR",
    header: "K FACTOR",
    maxWidth: 1000,
    defaultFlex: 1,
    type: "number"
  },
];

  export const filterValue = [
    { name: 'nombre', operator: 'startsWith', type: 'string', value: '' },
    
  ];