import React from 'react'
import {  Tab } from 'semantic-ui-react'
import { DashboardDefault } from '../CorreccionesPage/index'
import { ConciliacionesAdmin } from './ConciliacionesAdmin';
import { CierreConciliacionPage } from '../CierreConciliacionPage/CierreConciliacionPage';


export function ConciliacionesPage() {
    const panes = [
        {
          menuItem: { key: 'Conciliaciones',content: <> Movimientos</> },
          render: () => <Tab.Pane><ConciliacionesAdmin /></Tab.Pane>,
        },
        {
          menuItem: { key: 'Medicion manual',content: <>Cierre conciliaciones</> },
          render: () => <Tab.Pane><CierreConciliacionPage /></Tab.Pane>,
        },
        {
          menuItem: { key: 'Control',content: <>Control</> },
          render: () => <Tab.Pane><DashboardDefault /></Tab.Pane>,
        },
        // {
        //   menuItem: { key: 'Control',content: <>Balance y control</> },
        //   render: () => <Tab.Pane><DashboardDefault /></Tab.Pane>,
        // },
      ]
    
      return (
        <Tab panes={panes} />
      )
}
