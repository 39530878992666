import React from 'react';
import { Tab } from 'semantic-ui-react';
import { SistemaMedicionBaseAdmin } from '../../Client';
import unidades from "./Icons/28.-UNIDADES-DE-NEDICÓN-NEGRO.png";

export  function ParametrizacionInicial() {
    const panes = [
        
        {
          menuItem: { key: 'SistemaMedicionBaseAdmin',content: <><img src={unidades} alt="Configuracion unidades de medida" /> Configuracion unidades de medida</>  },
          render: () => <Tab.Pane><SistemaMedicionBaseAdmin /></Tab.Pane>,
        },
        
      //   {
      //     menuItem: 'Sensores',
      //     render: () => <Tab.Pane><SensoresAdmin /></Tab.Pane>,
      // },
      // {
      //     menuItem: 'Etiquetas',
      //     render: () => <Tab.Pane><EtiquetaSensoresAdmin /></Tab.Pane>,
      // },
      ]
    
      return (
        <Tab panes={panes} />
      )
}
