import React, { useEffect } from "react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useSensores } from "../../../hooks";
import { Funciones } from "./Funciones";
import { columns, filterValue } from "./SensoresFormat";
import CircularProgress from '@mui/material/CircularProgress';

export function SensoresAdmin() {
    const { loading, Sensores, getSensores } = useSensores()

    const MemoizedModalBasic = React.memo(ModalBasic);

    const {
        refetch,
        contentModal,
        showModal,
        openCloseModal,
        titleModal,
        handleAddSensores,
        handleUpdateSensores,
        handleDeleteSensores,
        handleDataVisualizer
    } = Funciones();

    const moduloMap = {
        '1': 'Input 1',
        '2': 'Input 2',
        '3': 'Input 3',
        '4': 'Input 4',
        '5': 'Input 5',
    };

    const inputRangeMap = {
        '1': '-10 - 20V',
        '2': '0 - 10V',
        '3': '0 - 5V',
        '4': '-5 - 5V',
        '5': '0 - 20mA',
        '6': '0 - 24mA',
        '7': '4 - 20mA',
        '8': '-25 - 25mA',
    };

    const tipoMap = {
        '1': 'Análogo',
        '2': 'Comunicación'
    }

    useEffect(() => {
        getSensores();
    }, [refetch])
        
    return (
        <>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <HeaderPage title="Sensores" btnTitle="Nuevo sensor" icono="plus square icon" btnClick={handleAddSensores} />

                    <DataGridBasic
                        data={Sensores}
                        title="Sensores"
                        id="id_sensor"
                        filterValue={filterValue}
                        columns={columns}
                        updateData={handleUpdateSensores}
                        onDeleteData={handleDeleteSensores}
                        handleDataVisualizer={handleDataVisualizer}
                    />
                </>
            )}

            {showModal && (
                <MemoizedModalBasic
                    show={showModal}
                    onClose={openCloseModal}
                    title={titleModal}
                    children={contentModal}
                />
            )}
        </>
    )
}
