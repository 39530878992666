import React, { useState } from "react";
import { Tab } from 'semantic-ui-react';
import { AditivosAdmin } from "../../index";
import { ProductoBaseAdmin } from "../../index";
import { ProductosMezclaAdmin } from "../../index";

export function GroupedProductos() {
    const [mainActiveTab, setMainActiveTab] = useState(0);

    const handleMainTabChange = (e, { activeIndex }) => {
        setMainActiveTab(activeIndex);
    };

    const panes = [
        {
            menuItem: 'Componentes',
            render: () => <Tab.Pane><ProductoBaseAdmin /></Tab.Pane>,
        },
        {
            menuItem: 'Aditivos',
            render: () => <Tab.Pane><AditivosAdmin /></Tab.Pane>,
        },
        {
            menuItem: 'Productos',
            render: () => <Tab.Pane><ProductosMezclaAdmin /></Tab.Pane>,
        },
        
        
    ];

    return (
        <Tab 
            panes={panes} 
            activeIndex={mainActiveTab} 
            onTabChange={handleMainTabChange} 
        />
    );
}
