import { useState, useCallback } from "react";
import { get_save_tcs_prod_api, get_tcs_prod_api, get_plates_in_tcs_api, save_plates_in_db_api } from '../../api/A_Tcs3000/tcs3000'
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useNavigate } from "react-router-dom";
import { useAuth } from "..";
import Swal from "sweetalert2"

export function useTcs3000() {
    const { auth, logout } = useAuth();
    const navigate = useNavigate();
    const { unauthorized, SessionExpired, unauthorizedModal, errores } = alertas();
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
    const [refetch, setRefetch] = useState(false);

    const [state, setState] = useState({
        loading: true,
        error: null,
        products: null,
        tcs_list: null,
        tcs_plates: null,
        response: null,
    });

    const { loading, error, products, tcs_plates, response } = state

    const handleResponse = (response) => {
        if (response.status === 401) {
            SessionExpired();
            logout();
        } else if (response.status === 403) {
            unauthorized();
            navigate("/");
        }
    };

    const getTcsProd = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await get_tcs_prod_api(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, products: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const get_save_tcs_prod = useCallback(async (id_controlador, data) => {
        const result = await Swal.fire({
            title: "¿Traer productos de la TCS3000?",
            text: "Esta acción actualizará la BD de los productos con los registros más recientes de la TCS3000",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, actualizar!",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            await get_save_tcs_prod_api(id_controlador, data, auth.token, auth.tenant)
            Swal.fire("Actualizar", "La tabla ha sido actualizada", "success");
            onRefetch();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelar", "Operación cancelada", "error");
        }
    }, [get_save_tcs_prod_api, onRefetch]);

    const get_plates_in_tcs = useCallback(async (id) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await get_plates_in_tcs_api(id, auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, tcs_plates: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    })

    const save_plates_in_db = useCallback(async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await save_plates_in_db_api(data.tcs_model, data, auth.token, auth.tenant);
            handleResponse(response);
            console.log(response)
            setState((prevState) => ({ ...prevState, loading: false, response: response}));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    })

    return {
        get_save_tcs_prod,
        get_plates_in_tcs,
        save_plates_in_db,
        getTcsProd,
        products,
        error,
        loading,
        tcs_plates,
        response
    }
}
