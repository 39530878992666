import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid"
import TextField from '@mui/material/TextField'
import * as Yup from "yup";
import { useIngresoDatos, useTanques, useAuth } from "../../../hooks";
import "./Styles/AddEditIngresoDatosForm.scss";
import { alertas } from "../../Comons/Alertas/alertas";
import { tanqueSelect } from "../../Comons";

export function AddEditIngresoDatosForm(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, Datos } = props;
  const { addIngresoDatos, updateIngresoDatos } = useIngresoDatos();
  const { tanquesDB, getTanquesDB } = useTanques();
  const [tablasFormat, setTablasTFormat] = useState([]);
  const { auth } = useAuth();

  const renderUnidadLongitud = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      return `${auth.me.SistemasMedicion.longitud} `;
    }
    return "No especificado";
  };

  const renderUnidadTemperatura = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.temperatura) {
      return `${auth.me.SistemasMedicion.temperatura} `;
    }
    return "No especificado";
  };
  const renderUnidadmin = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      if (auth.me?.SistemasMedicion.longitud === "cm") {
        return `mm`;
      } else if (auth.me?.SistemasMedicion.longitud === "ft") {
        return `in`;
      }
    }
    return "No especificado";
  };

  const renderUnidMay = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      if (auth.me?.SistemasMedicion.longitud === "cm") {
        return `m`;
      } else if (auth.me?.SistemasMedicion.longitud === "ft") {
        return `yd`;
      }
    }
    return "No especificado";
  };

  useEffect(() => {
    getTanquesDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTablasTFormat(tanqueSelect(tanquesDB));
  }, [tanquesDB]);



  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Datos),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Datos ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,

    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (Datos) await updateIngresoDatos(Datos.id_datos, formValue);
        else await addIngresoDatos(formValue);

        Successful()

        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });
console.log(formik)

  return (
    <>
      <form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* fila 1 */}
          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              id="id_tanque"
              options={tablasFormat}
              getOptionLabel={(option) => `${option.text} `}
              value={
                tablasFormat?.find(
                  (opt) => opt.key === formik.values?.id_tanque
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("id_tanque", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Tanque asociado"
                  variant="outlined"
                  error={formik.touched.id_tanque && Boolean(formik.errors.id_tanque)}
                  helperText={formik.touched.id_tanque && formik.errors.id_tanque}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              fullWidth
              name="fecha_captura"
              placeholder="Fecha captura datos"
              value={formik.values.fecha_captura}
              onChange={formik.handleChange}
              error={formik.errors.fecha_captura}
              type="date"
              helperText="Fecha captura datos"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              fullWidth
              name="hora_captura"
              placeholder="Hora de captura"
              value={formik.values.hora_captura}
              onChange={formik.handleChange}
              error={formik.errors.hora_captura}
              type="time"
              helperText="Hora de captura datos"
            />
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label={"Nivel de producto (" + renderUnidMay() + ")"}
              name="nivel_producto_m"
              placeholder={"Nivel de producto (" + renderUnidMay() + ")"}
              value={formik.values.nivel_producto_m}
              onChange={formik.handleChange}
               error={
                formik.touched.nivel_producto_m && Boolean(formik.errors.nivel_producto_m)
              }
              helperText={formik.touched.nivel_producto_m && formik.errors.nivel_producto_m}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label={"Nivel de producto (" + renderUnidadLongitud() + ")"}
              name="nivel_producto_cm"
              placeholder={"Nivel de producto (" + renderUnidadLongitud() + ")"}
              value={formik.values.nivel_producto_cm}
              onChange={formik.handleChange}
              error={
                formik.touched.nivel_producto_cm && Boolean(formik.errors.nivel_producto_cm)
              }
              helperText={formik.touched.nivel_producto_cm && formik.errors.nivel_producto_cm}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label={"Nivel de producto (" + renderUnidadmin() + ")"}
              name="nivel_producto_mm"
              placeholder={"Nivel de producto(" + renderUnidadmin() + ")"}
              value={formik.values.nivel_producto_mm}
              onChange={formik.handleChange}
              error={
                formik.touched.nivel_producto_mm && Boolean(formik.errors.nivel_producto_mm)
              }
              helperText={formik.touched.nivel_producto_mm && formik.errors.nivel_producto_mm}
              type="number"
            />
          </Grid>

          {/* fila 3 */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label={"Nivel agua libre(" + renderUnidMay() + ")"}
              name="nivel_agua_libre_m"
              placeholder={"Nivel de Agua libre (" + renderUnidMay() + ")"}
              value={formik.values.nivel_agua_libre_m}
              onChange={formik.handleChange}
              error={
                formik.touched.nivel_agua_libre_m && Boolean(formik.errors.nivel_agua_libre_m)
              }
              helperText={formik.touched.nivel_agua_libre_m && formik.errors.nivel_agua_libre_m}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label={"Nivel de agua libre (" + renderUnidadLongitud() + ")"}
              name="nivel_agua_libre_cm"
              placeholder={"Nivel de agua libre (" + renderUnidadLongitud() + ")"}
              value={formik.values.nivel_agua_libre_cm}
              onChange={formik.handleChange}
              error={
                formik.touched.nivel_agua_libre_cm && Boolean(formik.errors.nivel_agua_libre_cm)
              }
              helperText={formik.touched.nivel_agua_libre_cm && formik.errors.nivel_agua_libre_cm}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label={"Nivel de agua libre (" + renderUnidadmin() + ")"}
              name="nivel_agua_libre_mm"
              placeholder={"Nivel de agua libre (" + renderUnidadmin() + ")"}
              value={formik.values.nivel_agua_libre_mm}
              onChange={formik.handleChange}
              error={
                formik.touched.nivel_agua_libre_mm && Boolean(formik.errors.nivel_agua_libre_mm)
              }
              helperText={formik.touched.nivel_agua_libre_mm && formik.errors.nivel_agua_libre_mm}
              type="number"
            />
          </Grid>

          {/* fila 4 */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="TSh"
              name="tsh"
              placeholder="TSh"
              value={formik.values.tsh}
              onChange={formik.handleChange}
              error={
                formik.touched.tsh && Boolean(formik.errors.tsh)
              }
              helperText={formik.touched.tsh && formik.errors.tsh}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Contenido de agua y sedimento"
              name="contenido_agua_sedimento"
              placeholder="Contenido de agua y sedimento"
              value={formik.values.contenido_agua_sedimento}
              onChange={formik.handleChange}
              error={
                formik.touched.contenido_agua_sedimento && Boolean(formik.errors.contenido_agua_sedimento)
              }
              helperText={formik.touched.contenido_agua_sedimento && formik.errors.contenido_agua_sedimento}
            />
          </Grid>

          {/* fila 5 */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label={"Temperatura del líquido (" + renderUnidadTemperatura() + ")"}
              name="temperatura_liquido"
              placeholder={
                "Temperatura del líquido (" + renderUnidadTemperatura() + ")"
              }
              value={formik.values.temperatura_liquido}
              onChange={formik.handleChange}
              error={
                formik.touched.temperatura_liquido && Boolean(formik.errors.temperatura_liquido)
              }
              helperText={formik.touched.temperatura_liquido && formik.errors.temperatura_liquido}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label={"Temperatura ambiente (" + renderUnidadTemperatura() + ")"}
              name="temperatura_ambiente"
              placeholder={
                "Temperatura ambiente (" + renderUnidadTemperatura() + ")"
              }
              value={formik.values.temperatura_ambiente}
              onChange={formik.handleChange}
              error={
                formik.touched.temperatura_ambiente && Boolean(formik.errors.temperatura_ambiente)
              }
              helperText={formik.touched.temperatura_ambiente && formik.errors.temperatura_ambiente}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Coeficiente de expansión térmica"
              name="coeficiente_expansion_termica"
              placeholder="Coeficiente de expansión térmica"
              value={formik.values.coeficiente_expansion_termica}
              onChange={formik.handleChange}
              
              error={
                formik.touched.oeficiente_expansion_termica && Boolean(formik.errors.oeficiente_expansion_termica)
              }
              helperText={formik.touched.oeficiente_expansion_termica && formik.errors.oeficiente_expansion_termica}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              {Datos ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}



function initialValues(data) {
  return {
    nivel_producto_m: data?.nivel_producto_m || "",
    nivel_producto_cm: data?.nivel_producto_cm || "",
    nivel_producto_mm: data?.nivel_producto_mm || "",
    nivel_agua_libre_m: data?.nivel_agua_libre_m || "",
    nivel_agua_libre_cm: data?.nivel_agua_libre_cm || "",
    nivel_agua_libre_mm: data?.nivel_agua_libre_mm || "",
    temperatura_liquido: data?.temperatura_liquido || "",
    temperatura_ambiente: data?.temperatura_ambiente || "",
    contenido_agua_sedimento: data?.contenido_agua_sedimento || "",
    fecha_captura: data?.fecha_captura || "",
    hora_captura: data?.hora_captura || "",
    id_tanque: data?.id_tanque || "",
    tsh: data?.tsh || "",
    coeficiente_expansion_termica: data?.coeficiente_expansion_termica || "",
    id_tipo_ingreso: 1,
  };
}


// Validaciones

// Validaciones iindividuales para la vista de calculos manuales
function newSchame() {
  return {
    nivel_producto_m: Yup.number(),
    nivel_producto_cm: Yup.number().required(true),
    nivel_producto_mm: Yup.number().required(true),
    nivel_agua_libre_m: Yup.number().required(true),
    nivel_agua_libre_cm: Yup.number().required(true),
    nivel_agua_libre_mm: Yup.number().required(true),
    temperatura_liquido: Yup.string().matches(/^\d+\.\d+$/, 'Debe ser un número decimal válido').required(true),
    temperatura_ambiente: Yup.string().matches(/^\d+\.\d+$/, 'Debe ser un número decimal válido').required(true),
    contenido_agua_sedimento: Yup.string().matches(/^\d+\.\d+$/, 'Debe ser un número decimal válido').required(true),
    coeficiente_expansion_termica: Yup.string().matches(/^\d+\.\d+$/, 'Debe ser un número decimal válido').required(true),
    fecha_captura: Yup.date().required(true),
    hora_captura: Yup.string().required(true),
    id_tanque: Yup.number().required(true),
    tsh: Yup.string().matches(/^\d+\.\d+$/, 'Debe ser un número decimal válido').required(true),
  };
}



function updateSchame() {
  return {
    nivel_producto_m: Yup.number(),
    nivel_producto_cm: Yup.number().required(true),
    nivel_producto_mm: Yup.number().required(true),
    nivel_agua_libre_m: Yup.number(),
    nivel_agua_libre_cm: Yup.number().required(true),
    nivel_agua_libre_mm: Yup.number().required(true),
    temperatura_liquido: Yup.string().matches(/^\d+\.\d+$/, 'Debe ser un número decimal válido').required(true),
    temperatura_ambiente: Yup.string().matches(/^\d+\.\d+$/, 'Debe ser un número decimal válido').required(true),
    contenido_agua_sedimento: Yup.string().matches(/^\d+\.\d+$/, 'Debe ser un número decimal válido').required(true),
    coeficiente_expansion_termica: Yup.string().matches(/^\d+\.\d+$/, 'Debe ser un número decimal válido').required(true),
    fecha_captura: Yup.date().required(true),
    hora_captura: Yup.string().required(true),
    id_tanque: Yup.number().required(true),
    tsh: Yup.string().matches(/^\d+\.\d+$/, 'Debe ser un número decimal válido').required(true),
  };
}
