import { useState } from "react";
import { getMaquinasApi, postMaquinaApi, updtMaquinaApi, deleteMaquinaApi } from "../../api/A_Maquina/maquinas";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useMaquinas() {
    const { auth, logout } = useAuth();
    const navigate = useNavigate();
    const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

    const [state, setState] = useState({
        loading: true,
        error: null,
        Maquinas: null,
    });

    const { loading, error, Maquinas } = state

    const handleResponse = (response) => {
        if (response.status === 401) {
            SessionExpired();
            logout();
        } else if (response.status === 403) {
            unauthorized();
            navigate("/");
        }
    }

    const getMaquinas = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getMaquinasApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, Maquinas: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    const addMaquina = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await postMaquinaApi(data, auth.token, auth.tenant);
            if (response.status === 400) {
                if (result?.placa) {
                    throw new Error("La placa de la máquina ya cuenta con un registro activo");
                } else {
                    errores();
                }
            } else if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const updtMaquina = async (id_maquina, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updtMaquinaApi(id_maquina, data, auth.token, auth.tenant);
            if (response.status === 400) {
                if (result?.placa) {
                    throw new Error("La placa de la maquina ya cuenta con un registro activo");
                } else {
                    errores();
                }
            } else if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const deleteMaquina = async (id_maquina) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteMaquinaApi(id_maquina, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    return {
        loading,
        error,
        deleteMaquina,
        getMaquinas,
        addMaquina,
        updtMaquina,
        Maquinas
    }
}