import { ImpresionT } from "../Components/Client";
import {
  LengthConverter,
  TemperatureConverter,
  VolumeConverter,
} from "../Components/Comons";
import { BasicLayout } from "../layouts";
// import { AdminLayout } from "../layouts";
import {
  GroupedAdminPages,
  GruposPermisosAdmin,
  MulticlienteAdmin,
  MulticlienteTab,
  ParametrizacionInicial
} from "../pages/Admin";

import { AdministracionPage } from "../pages/Admin/AdministracionPage/AdministracionPage";
import {
  AccesossAdmin,
  AditivosAdmin,
  BahiasAdmin,
  CargaderoAdmin,
  ClientesAdmin,
  ConciliacionesPage,
  ConductorAdmin,
  ConfigTurnosAdmin,
  ConsumoInterno,
  ControlDocumentalAdmin,
  DocumentosGUTAdmin,
  EmisionesAdmin,
  FraccionesAdmin,
  FuelAdmin,
  GestCombOrdenesAdm,
  GroupedAccesoConfig,
  GroupedClientPages,
  GroupedControladores,
  GroupedProductos,
  GroupedSensores,
  GroupedTanques,
  GroupedUserOp,
  GroupedSecciones,
  Home,
  IngresoDatosPage,
  IntegracionAdmin,
  IslasAdmin,
  MapeosAdmin,
  MapeosAlmacenamientoAdmin,
  MappingAdmin,
  MaquinariaAdmin,
  MezclasAdmin,
  OperadoresAdmin,
  OrdenDescargaderoAdmin,
  ParamatrizacionDecimalesAdmin,
  ParametrizacionDecimalesBseAdmin,

  PlantasAdmin,
  ProductoBaseAdmin,
  ProductoTanqueAdmin,
  ProveedoresAdmin,
  RemolquesAdmin,
  SensoresGraphs,
  SistemaMedicionAdmin,
  SistemaMedicionBaseAdmin,
  SuministroStatus,
  TablaAforoAdmin,
  TankCustVerAdm,
  TipoControladorAdmin,
  TransaccionesAdmin,
  TransaccionesDescargaderoAdmin,
  TransportadoraAdmin,
  TurnosAdmin,
  TurnosVisualizacion,
  UpdateMe,
  Validaciones,
  VehCustVerAdm,
  VehiculosAdmin
} from "../pages/Client";

import { AddEditChecklist } from "../Components/Client/Tanques/AddEditChecklist";
import { AddVehicleChecklist } from "../Components/Client/Vehiculos/AddVehicleChecklist";
import { UserBaseAdmin } from "../pages/Client/UserBasePage/UserBaseAdmin";
import { FacturacionPages } from "../pages/Facturacion/FacturacionPages";
import { GenerarLicencias, Licencia } from "../pages/Licencia";
import { RenovarLicencias } from "../pages/Licencia/RenovarLicencias";
import { LayoutComponent } from "../vistas/components/Layout";
import { Calendar } from "../vistas/modules/calendar";
import { Despatch } from "../vistas/modules/despatch";
import { CardDetailDespatch } from "../vistas/modules/despatch/components/CardDetailDespatch";
import { Receipt } from "../vistas/modules/receipt";
import { CardDetailReceipt } from "../vistas/modules/receipt/components/CardDetailReceipt";
import { Storage } from "../vistas/modules/storage";
import { DetailTank } from "../vistas/modules/storage/components/DetailTank";
import { Turns } from "../vistas/modules/turns";

const routesClient = [
  {
    path: "/",
    /*La ruta principal para acceder a la página principal del sitio web */
    layout: LayoutComponent,
    /*Se utiliza el layout específico para clientes */
    component: Home,
    /* El componente que se renderiza en la página es el contenido de la página principal */
  },
  {
    path: "/usuarios",
    /*La ruta para acceder a la página de administración de usuarios */
    layout: LayoutComponent,
    /*Se utiliza el layout específico para clientes */
    //component: UserAdmin,
    component: GroupedUserOp,
    /* El componente que se renderiza en la página es la tabla de administración de usuarios */
  },
  {
    path: "/usuarios/administradores",
    layout: LayoutComponent,
    component: UserBaseAdmin,
  },
  {
    path: "/usuarios/DatosBase",
    layout: LayoutComponent,
    component: MulticlienteAdmin,
  },
  {
    path: "/usuarios/UpdateMe",
    layout: LayoutComponent,
    component: UpdateMe,
  },
  {
    path: "/decimales/base",
    layout: LayoutComponent,
    component: ParametrizacionDecimalesBseAdmin,
  },
  
  
  {
    path: "/validaciones/computador",
    layout: LayoutComponent,
    component: Validaciones,
  },
  {
    path: "/sistema-medicion/base",
    layout: LayoutComponent,
    component: SistemaMedicionBaseAdmin,
  },
  // {
  //   path: "/tanques",
  //   layout: LayoutComponent,
  //   component: TanquesAdmin,
  // },
  {
    path: "/tanques",
    layout: LayoutComponent,
    component: GroupedTanques,
  },
  {
    path: "/aforo/fracciones",
    layout: LayoutComponent,
    component: FraccionesAdmin,
  },
  {
    path: "/aforo/secciones",
    layout: LayoutComponent,
    component: GroupedSecciones,
  },
  {
    path: "/tabla_aforo",
    layout: LayoutComponent,
    component: TablaAforoAdmin,
  },
  {
    path: "/data-ingreso",
    layout: LayoutComponent,
    component: IngresoDatosPage,
  },
  {
    path: "/clientes",
    layout: LayoutComponent,
    component: ClientesAdmin,
  },
  {
    path: "/plantas",
    layout: LayoutComponent,
    component: PlantasAdmin,
  },
  {
    path: "/proveedores",
    layout: LayoutComponent,
    component: ProveedoresAdmin,
  },
  {
    path: "/transportadora",
    layout: LayoutComponent,
    component: TransportadoraAdmin,
  },
  {
    path: "/operadores",
    layout: LayoutComponent,
    component: OperadoresAdmin,
  },
  {
    path: "/conductor",
    layout: LayoutComponent,
    component: ConductorAdmin,
  },
  {
    path: "/aditivos",
    layout: LayoutComponent,
    component: AditivosAdmin,
  },
  {
    path: "/conciliaciones",
    layout: LayoutComponent,
    component: ConciliacionesPage,
  },
  {
    path: "/ordenes",
    layout: LayoutComponent,
    component: CargaderoAdmin,
  },
  {
    path: "/transacciones",
    layout: LayoutComponent,
    component: TransaccionesAdmin,
  },
  {
    path: "/vehiculos",
    layout: LayoutComponent,
    component: VehiculosAdmin,
  },
  {
    path: "/vehicleinspection",
    layout: BasicLayout,
    component: AddVehicleChecklist,
  },
  {
    path: "/tankinspection",
    layout: BasicLayout,
    component: AddEditChecklist,
  },
  {
    path: "/remolques",
    layout: LayoutComponent,
    component: RemolquesAdmin,
  },
  {
    path: "/documentosGut",
    layout: LayoutComponent,
    component: DocumentosGUTAdmin,
  },
  {
    path: "/decimales",
    layout: LayoutComponent,
    component: ParamatrizacionDecimalesAdmin,
  },
  {
    path: "/sistemaMedicion",
    layout: LayoutComponent,
    component: SistemaMedicionAdmin,
  },
  {
    path: "/accesoManual",
    layout: LayoutComponent,
    component: AccesossAdmin,
  },
  {
    path: "/configDisp",
    layout: LayoutComponent,
    component: ConfigTurnosAdmin,
  },
  {
    path: "/configAcceso",
    layout: LayoutComponent,
    component: GroupedAccesoConfig,
  },
  {
    path: "/OrdenesDescargadero",
    layout: LayoutComponent,
    component: OrdenDescargaderoAdmin,
  },
  {
    path: "/TransaccionDescargadero",
    layout: LayoutComponent,
    component: TransaccionesDescargaderoAdmin,
  },
  {
    path: "/GruposDePermisos",
    layout: LayoutComponent,
    component: GruposPermisosAdmin,
  },
  {
    path: "/aviso-licencia",
    layout: LayoutComponent,
    component: Licencia,
  },
  {
    path: "/impresionesPDF",
    layout: LayoutComponent,
    component: ImpresionT,
  },
  {
    path: "/turnos",
    layout: LayoutComponent,
    component: TurnosAdmin,
  },
  {
    path: "/islas",
    layout: LayoutComponent,
    component: IslasAdmin,
  },
  {
    path: "/bahias",
    layout: LayoutComponent,
    component: BahiasAdmin,
  },
  // {
  //   path: "/Controlador",
  //   layout: LayoutComponent,
  //   component: ControladoresAdmin,
  // },
  {
    path: "/Controlador",
    layout: LayoutComponent,
    component: GroupedControladores,
  },
  {
    path: "/Mapping",
    layout: LayoutComponent,
    component: MappingAdmin,
  },
  {
    path: "/conversor-volumen",
    layout: LayoutComponent,
    component: VolumeConverter,
  },
  {
    path: "/conversor-longitud",
    layout: LayoutComponent,
    component: LengthConverter,
  },
  {
    path: "/conversor-temperatura",
    layout: LayoutComponent,
    component: TemperatureConverter,
  },
  {
    path: "/turnos/visualizacion",
    layout: BasicLayout,
    component: TurnosVisualizacion,
  },
  // {
  //   path: "/turnos/visualizacion",
  //   layout: AdminLayout,
  //   component: TurnosVisualizacion,
  // },

  {
    path: "/inquilinos",
    layout: LayoutComponent,
    component: MulticlienteTab,
  },
  {
    path: "/parametros/inicial",
    layout: LayoutComponent,
    component: ParametrizacionInicial,
  },
  {
    path: "/generar/licencia",
    layout: LayoutComponent,
    component: GenerarLicencias,
  },
  {
    path: "/renovar/licencia",
    layout: LayoutComponent,
    component: RenovarLicencias,
  },
  // {
  //   path: "/tiemporeal",
  //   layout: AdminLayout,
  //   component: PruebasTiempoReal,
  // },
  {
    path: "/conciliacion/modulos",
    layout: LayoutComponent,
    component: IntegracionAdmin,
  },
  {
    path: "/receipt",
    layout: LayoutComponent,
    component: Receipt,
  },
  {
    path: "/storage",
    layout: LayoutComponent,
    component: Storage,
  },
  {
    path: "/dispatch",
    layout: LayoutComponent,
    component: Despatch,
  },
  {
    path: "/receipt/:id",
    layout: LayoutComponent,
    component: CardDetailReceipt,
  },
  {
    path: "/dispatch/:id",
    layout: LayoutComponent,
    component: CardDetailDespatch,
  },
  {
    path: "/storage/:id",
    layout: LayoutComponent,
    component: DetailTank,
  },
  {
    path: "/turns",
    layout: LayoutComponent,
    component: Turns,
  },
  {
    path: "/calendar",
    layout: LayoutComponent,
    component: Calendar,
  },
  {
    path: "/producto/base",
    layout: LayoutComponent,
    component: ProductoBaseAdmin,
  },
  {
    path: "/producto/mezclas",
    layout: LayoutComponent,
    component: GroupedProductos,
  },
  {
    path: "/producto/tanque",
    layout: LayoutComponent,
    component: ProductoTanqueAdmin,
  },
  {
    path: "/mezclas",
    layout: LayoutComponent,
    component: MezclasAdmin,
  },
  {
    path: "/fuelmgm",
    layout: LayoutComponent, // header y sidebar
    component: FuelAdmin, // pages
  },
  {
    path: "/maquinariamgm",
    layout: LayoutComponent, // header y sidebar
    component: MaquinariaAdmin, // pages
  },
  {
    path: "/consumointerno",
    layout: LayoutComponent, // header y sidebar
    component: ConsumoInterno, // pages
  },
  {
    path: "/tankcustveradm",
    layout: LayoutComponent, // header y sidebar
    component: TankCustVerAdm, // pages
  },
  {
    path: "/vehcustveradm",
    layout: LayoutComponent, // header y sidebar
    component: VehCustVerAdm, // pages
  },
  {
    path: "/admin/page",
    layout: LayoutComponent,
    component: AdministracionPage,
  },
  {
    path: "/control/documentos",
    layout: LayoutComponent,
    component: ControlDocumentalAdmin,
  },
  {
    path: "/sensoresadmin",
    layout: LayoutComponent,
    component: GroupedSensores,
  },
  {
    path: "/sensoresgraphs",
    layout: LayoutComponent,
    component: SensoresGraphs,
  },
  {
    path: "/ordenessuministro",
    layout: LayoutComponent,
    component: GestCombOrdenesAdm
  },
  {
    path: "/emisiones",
    layout: LayoutComponent,
    component: EmisionesAdmin
  },
  {
    path: "/configadmin",
    layout: LayoutComponent,
    component: GroupedAdminPages
  },
  {
    path: "/configclient",
    layout: LayoutComponent,
    component: GroupedClientPages
  },
  {
    path: "/facturaciones",
    layout: LayoutComponent,
    component: FacturacionPages,
  },
  {
    path: "/tiposcontroladores",
    layout: LayoutComponent,
    component: TipoControladorAdmin,
  },
  {
    path: "/suministrostatus",
    layout: LayoutComponent,
    component: SuministroStatus,
  },
  
];
export default routesClient;
