import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from "react";
import { HeaderPage } from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useTipoControlador } from "../../../hooks";
import { Funciones } from "./Funciones";
import { columns, filterValue } from "./TipoControladorFormat";


export function TipoControladorAdmin() {
  const { loading, TipoControlador, getTipoControlador } = useTipoControlador();

  const {
    refetch,
    contentModal,
    titleModal,
    showModal,
    handleAdd,
    handleDelete,
    handleUpdate,
    openCloseModal,
    handleMapeo,
  } = Funciones();

  
  useEffect(() => {
    getTipoControlador();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  
  const MemoizedModalBasic = React.memo(ModalBasic);
  return (
    <>
      <HeaderPage title="Tipo Controlador" btnTitle="Nuevo controlador" icono="plus square icon" btnClick={handleAdd} />
     
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (

        <DataGridBasic
          data={TipoControlador}
          title="Tipo Controlador"
          id="id_myemp"
          filterValue={filterValue}
          columns={columns}
          updateData={handleUpdate}
          
          onDeleteData={handleDelete}
        />

      )}

      {showModal && (
        <MemoizedModalBasic
          show={showModal}
          onClose={openCloseModal}
          title={titleModal}
          children={contentModal}
        />
      )}
    </>
  )
}

//Columnas que se envian en forma de props al componente tabla basic para representer sus campos


