import React, { useCallback, useState, useEffect } from "react";
import Swal from "sweetalert2";
import { AddEditTankVer, AddEditVehVer } from "../../../Components/Client";
import { useVerificaciones } from "../../../hooks/VerificacionesHooks/useVerificaciones";

export function Funciones() {
    const { deleteBaseVer,deleteBaseVehVer } = useVerificaciones()
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState(null);
    const [contentModal, setContentModal] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
    const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);

    const handleAdd = useCallback(() => {
        setTitleModal("Nuevo formulario de inspección");
        setContentModal(
            <AddEditTankVer onClose={openCloseModal} onRefetch={onRefetch} />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleUpdate = useCallback((data) => {
        setTitleModal("Actualizar formulario de inspección");
        setContentModal(
            <AddEditTankVer
                onClose={openCloseModal}
                onRefetch={onRefetch}
                Data={data}
            />
        );
        openCloseModal();

    }, [openCloseModal, onRefetch]);

    const handleDelete = useCallback(async (data) => {
        const result = await Swal.fire({
            title: "Estas seguro?",
            text: "No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            await deleteBaseVer(data.id_ver_group);
            Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
            onRefetch();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelado", "Operación cancelada", "error");
        }
    }, [deleteBaseVer, onRefetch]);

    const handleAddVehVer = useCallback(() => {
        setTitleModal("Nuevo formulario de inspección");
        setContentModal(
            <AddEditVehVer onClose={openCloseModal} onRefetch={onRefetch} />
        );
        openCloseModal();
    }, [openCloseModal, onRefetch]);

    const handleUpdateVehVer = useCallback((data) => {
        setTitleModal("Actualizar formulario de inspección");
        setContentModal(
            <AddEditVehVer
                onClose={openCloseModal}
                onRefetch={onRefetch}
                Data={data}
            />
        );
        openCloseModal();

    }, [openCloseModal, onRefetch]);

    const handleDeleteVehVer = useCallback(async (data) => {
        const result = await Swal.fire({
            title: "Estas seguro?",
            text: "No podrás revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar!",
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            await deleteBaseVehVer(data.id_ver_group);
            Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
            onRefetch();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelado", "Operación cancelada", "error");
        }
    }, [deleteBaseVer, onRefetch]);

    return {
        handleAdd,
        handleDelete,
        handleUpdate,
        openCloseModal,
        refetch,
        onRefetch,
        contentModal,
        titleModal,
        showModal,
        handleAddVehVer,
        handleUpdateVehVer,
        handleDeleteVehVer,
    };
}