import { useFormik } from "formik";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import markerIcon from "../../../assets/marker.png";
//import { Typography, Switch, Box } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useDirecciones, usePlantas } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    borderRadius: 10,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    marginBottom: theme.spacing(2), // Agrega un margen inferior de 16px (2 * 8px)
  },
}));

export function AddEditPlantas(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Plantas
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  // Importar las alertas de éxito
  const { Successful } = alertas();

  const classes = useStyles();

  // id para el mapa con el objetivo de que cargue primero
  const [mapKey, setMapKey] = useState(0);

  // campo de búsqueda para la ubicación del mapa
  const [initialValuesMap, setInitialValuesMap] = useState({
    searchQuery: "",
  });

  // Destructurar las propiedades recibidas del componente padre
  const { onClose, onRefetch, Plantas } = props;

  // Destructurar las funciones de manejo de Plantas
  const { addPlantas, updatePlantas } = usePlantas();

  // ícono del marcador presonalizado
  const customIcon = L.icon({
    iconUrl: markerIcon,
    iconSize: [32, 32], // tamaño del ícono
    iconAnchor: [16, 32], // anclaje del ícono
  });

  // Destructurar las funciones de manejo de direcciones
  const {
    getCountries,
    getDepartmentsByCountry,
    getCitiesByDepartment,
    countries,
    departments,
    cities,
  } = useDirecciones();

  // Estado para almacenar el país seleccionado
  const [selectedCountry, setSelectedCountry] = useState("");

  // Estado para almacenar el departamento seleccionado
  const [selectedDepartment, setSelectedDepartment] = useState("");

  // Primer useEffect para obtener los países
  useEffect(() => {
    getCountries();
  }, []);

  // Segundo useEffect para obtener los departamentos del país seleccionado
  useEffect(() => {
    // si viene un pais previamente seleccionado
    if (Plantas?.id_direcFc?.country) {
      getDepartmentsByCountry(Plantas.id_direcFc?.country);
    }
    // si se selecciona un pais
    if (selectedCountry) {
      getDepartmentsByCountry(selectedCountry);
    }
  }, [selectedCountry]);

  // Tercer useEffect para obtener las ciudades del departamento seleccionado
  useEffect(() => {
    // si viene un departamento previamente seleccionado
    if (Plantas?.id_direcFc?.department) {
      getCitiesByDepartment(Plantas.id_direcFc?.department);
    }
    // si se selecciona un departamento
    if (selectedDepartment) {
      getCitiesByDepartment(selectedDepartment);
    }
  }, [selectedDepartment]);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Plantas),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(newSchema),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (Plantas) await updatePlantas(Plantas.id_myemp, formValue);
        else await addPlantas(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const [markerPosition, setMarkerPosition] = useState([
    formik.values?.id_direcFc?.latitud,
    formik.values?.id_direcFc?.longitud,
  ]);

  // actualiza los componentes del mapa
  useEffect(() => {
    setMapKey((prevKey) => prevKey + 1);
  }, [formik.values?.id_direcFc?.latitud, formik.values?.id_direcFc?.longitud]);

  const handlePrincipalChange = (event) => {
    const isChecked = event.target.checked;
    formik.setFieldValue("principal", isChecked);

    if (isChecked) {
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: "Si seleccionas esta planta como principal, los documentos y operaciones realizadas en el software lo tomarán como base.",
      });
    }
  };

  // función para manejar el cambio de posición del marcador cuando se arrastra
  const handleMarkerMove = async (event) => {
    const newPosition = event.target.getLatLng(); // obtiene la nueva posición de maracador

    setMarkerPosition([newPosition.lat, newPosition.lng]); // catualiza el estado de la posición de maracador
    formik.setFieldValue("id_direcFc.latitud", newPosition.lat); // actualiza el campo de la latitud
    formik.setFieldValue("id_direcFc.longitud", newPosition.lng); // actualiza el campo de la longitud

    await handleSearchByLatLon(newPosition.lat, newPosition.lng);
  };

  // función para la búsqueda de la dirección
  const handleSearch = async (values) => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
        values
      )}`
    );
    const data = await response.json();

    console.log(data);
    if (data && data.length > 0) {
      formik.setFieldValue("id_direcFc.latitud", data[0].lat);
      formik.setFieldValue("id_direcFc.longitud", data[0].lon);

      setMarkerPosition([parseFloat(data[0].lat), parseFloat(data[0].lon)]);

      // await handleSearchByLatLon(parseFloat(data[0].lat), parseFloat(data[0].lon))
    }
  };

  // función para determinar los datos de la dirección a buscar, por ahora pone el país de la ubicación seleccionada
  const handleSearchByLatLon = async (lat, lon) => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
    );

    const data = await response.json();

    console.log(data);

    const foundCountry = countries.find(
      (country) => country.name === data.address.country.toUpperCase()
    );
    console.log(foundCountry);

    if (foundCountry) {
      formik.setFieldValue("id_direcFc.country", foundCountry.id);
      setSelectedCountry(foundCountry.id);
    }

    // formik.setFieldValue('id_direcFc.country', data.address.country ? data.address.country : "");
    // formik.setFieldValue('id_direcFc.department', data.address.state ? data.address.state : "");
    // formik.setFieldValue('id_direcFc.city', data.address.county ? data.address.county : data.address.municipality ? data.address.municipality : "");
  };

  // manejador que controla el cambio en la barra de búsqueda
  const handleInputChange = (event) => {
    const { value } = event.target;
    setInitialValuesMap({ ...initialValuesMap, searchQuery: value });
  };

  // función de búsqueda cuando se le da click al botón
  const handleSearchClick = async () => {
    await handleSearch(initialValuesMap.searchQuery);
  };

  return (
    <>
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        {/* contenedor con el switch */}
        <Box className={classes.root}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginRight: "10px" }}
          >
            Seleccionar como principal
          </Typography>
          <Switch
            checked={formik.values.principal}
            onChange={handlePrincipalChange}
            color="secondary"
          />
        </Box>

        <Grid container spacing={3}>
          {/* Primera fila */}
          <Grid item xs={12} sm={3}>
            <TextField
              required
              fullWidth
              label="NIT de planta"
              name="nit_myemp"
              placeholder="NIT"
              value={formik.values.nit_myemp}
              error={
                formik.touched.nit_myemp && Boolean(formik.errors.nit_myemp)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              fullWidth
              label="Razón social del la empresa"
              name="razon_social"
              placeholder="Razón social"
              value={formik.values.razon_social}
              error={
                formik.touched.razon_social &&
                Boolean(formik.errors.razon_social)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              fullWidth
              label="Email"
              name="id_empresa.email"
              placeholder="Empresa@mail.com"
              value={formik.values?.id_empresa.email}
              error={
                formik.touched.id_empresa?.email &&
                Boolean(formik.errors.id_empresa?.email)
              }
              onChange={formik.handleChange}
              type="email"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Cargo"
              name="id_empresa.area"
              placeholder="Cargo"
              value={formik.values?.id_empresa.area}
              error={
                formik.touched.id_empresa?.area &&
                Boolean(formik.errors.id_empresa?.area)
              }
              onChange={formik.handleChange}
            />
          </Grid>

          {/* Segunda fila */}
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Celular de contacto"
              name="id_empresa.celular"
              placeholder="celular"
              value={formik.values?.id_empresa.celular}
              error={
                formik.touched.id_empresa?.celular &&
                Boolean(formik.errors.id_empresa?.celular)
              }
              onChange={formik.handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Teléfono de contacto"
              name="id_empresa.telefono"
              placeholder="Teléfono"
              value={formik.values?.id_empresa.telefono}
              error={
                formik.touched.id_empresa?.telefono &&
                Boolean(formik.errors.id_empresa?.telefono)
              }
              onChange={formik.handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Nombre de contacto"
              name="id_empresa.nombre"
              placeholder="Nombre"
              value={formik.values?.id_empresa.nombre}
              error={
                formik.touched.id_empresa?.nombre &&
                Boolean(formik.errors.id_empresa?.nombre)
              }
              onChange={formik.handleChange}
            />
          </Grid>

          {/* Tercera fila */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={countries}
              getOptionLabel={(option) => option.name}
              value={
                countries.find(
                  (country) => country.id === formik.values?.id_direcFc.country
                ) || null
              }
              onChange={(event, newValue) => {
                const countryId = newValue ? newValue.id : "";
                formik.setFieldValue("id_direcFc.country", countryId);
                setSelectedCountry(countryId);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="País"
                  error={
                    formik.touched.id_direcFc?.country &&
                    Boolean(formik.errors.id_direcFc?.country)
                  }
                  helperText={
                    formik.touched.id_direcFc?.country &&
                    formik.errors.id_direcFc?.country
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={departments}
              getOptionLabel={(option) => option.name}
              value={
                departments.find(
                  (department) =>
                    department.id === formik.values?.id_direcFc.department
                ) || null
              }
              onChange={(event, newValue) => {
                const departmentId = newValue ? newValue.id : "";
                formik.setFieldValue("id_direcFc.department", departmentId);
                setSelectedDepartment(departmentId);
                const selectedCountry = countries.find(
                  (country) => country.id === formik.values.id_direcFc.country
                );
                const dept = departments.find(
                  (dept) => dept.id === departmentId
                );
                handleSearch(selectedCountry.name + " " + dept.name);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Departamento"
                  error={
                    formik.touched.id_direcFc?.department &&
                    Boolean(formik.errors.id_direcFc?.department)
                  }
                  helperText={
                    !formik.values.id_direcFc.country
                      ? "Debe seleccionar un país"
                      : ""
                  }
                />
              )}
              disabled={!formik.values.id_direcFc.country}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={cities}
              getOptionLabel={(option) => option.name}
              value={
                cities.find(
                  (city) => city.id === formik.values?.id_direcFc.city
                ) || null
              }
              onChange={(event, newValue) => {
                const cityId = newValue ? newValue.id : "";
                formik.setFieldValue("id_direcFc.city", cityId);
                const selectedCountry = countries.find(
                  (country) => country.id === formik.values.id_direcFc.country
                );
                const selectedDepartment = departments.find(
                  (department) =>
                    department.id === formik.values.id_direcFc.department
                );
                const city = cities.find((c) => c.id === cityId);
                handleSearch(
                  selectedCountry.name +
                    " " +
                    selectedDepartment.name +
                    " " +
                    city.name
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ciudad"
                  error={
                    formik.touched.id_direcFc?.city &&
                    Boolean(formik.errors.id_direcFc?.city)
                  }
                  helperText={
                    !formik.values.id_direcFc.department
                      ? "Debe seleccionar un departamento"
                      : ""
                  }
                />
              )}
              disabled={!formik.values.id_direcFc.department}
            />
          </Grid>

          {/* Cuarta fila */}
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Dirección"
              name="id_direcFc.street"
              placeholder="Dirección"
              value={formik.values?.id_direcFc?.street}
              error={
                formik.touched.id_direcFc?.street &&
                Boolean(formik.errors.id_direcFc?.street)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Código postal"
              name="id_direcFc.zip_code"
              placeholder="Código postal"
              value={formik.values?.id_direcFc.zip_code}
              error={
                formik.touched.id_direcFc?.zip_code &&
                Boolean(formik.errors.id_direcFc?.zip_code)
              }
              onChange={formik.handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Datos extra de la dirección"
              name="id_direcFc.extra"
              placeholder="Datos extra"
              value={formik.values?.id_direcFc?.extra}
              error={
                formik.touched.id_direcFc?.extra &&
                Boolean(formik.errors.id_direcFc?.extra)
              }
              onChange={formik.handleChange}
              type="text"
            />
          </Grid>

          {/* Quinta fila */}
          <Grid item xs={12} sm={10}>
            <TextField
              fullWidth
              label="Buscar ubicación"
              name="searchQuery"
              value={initialValuesMap.searchQuery}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Button
              fullWidth
              variant="outlined"
              style={{ height: "100%" }}
              onClick={handleSearchClick}
            >
              <SearchIcon />
            </Button>
          </Grid>

          {/* zona del mapa - sexta fila */}
          <Grid item xs={12} sm={12}>
            <MapContainer
              key={mapKey}
              center={markerPosition}
              zoom={13}
              style={{ height: "400px" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                position={markerPosition}
                icon={customIcon}
                draggable={true}
                eventHandlers={{
                  dragend: handleMarkerMove,
                }}
              >
                <Popup>
                  {formik.values?.razon_social
                    ? formik.values?.razon_social
                    : "Planta"}
                </Popup>
              </Marker>
            </MapContainer>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              className="btn btn-danger"
              color="error"
              fullWidth
            >
              {Plantas ? "Actualizar" : "Crear"}
            </Button>
          </Grid>

          {/* fila no visible */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Latitud"
              name="id_direcFc.latitud"
              value={formik.values?.id_direcFc?.latitud}
              onChange={formik.handleChange}
              type="text"
              hidden
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Longitud"
              name="id_direcFc.longitud"
              placeholder="Datos extra"
              value={formik.values?.id_direcFc?.longitud}
              onChange={formik.handleChange}
              type="text"
              hidden
              disabled
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function initialValues(data) {
  return {
    nit_myemp: data?.nit_myemp || "",
    razon_social: data?.razon_social || "",
    id_direcFc: {
      street: data?.id_direcFc?.street || "",
      city: data?.id_direcFc?.city || "",
      department: data?.id_direcFc?.department || "",
      country: data?.id_direcFc?.country || "",
      zip_code: data?.id_direcFc?.zip_code || "",
      extra: data?.id_direcFc?.extra || "",
      latitud: data?.id_direcFc?.latitud
        ? parseFloat(data?.id_direcFc?.latitud)
        : 4.60971,
      longitud: data?.id_direcFc?.longitud
        ? parseFloat(data?.id_direcFc?.longitud)
        : -74.08175,
    },

    id_empresa: {
      email: data?.id_empresa?.email || "",
      celular: data?.id_empresa?.celular || "",
      telefono: data?.id_empresa?.telefono || "",
      area: data?.id_empresa?.area || "",
      nombre: data?.id_empresa?.nombre || "",
    },
    principal: data?.principal ? true : false,
  };
}

function newSchema() {
  return {
    nit_myemp: Yup.string().required("El NIT de la planta es requerido"),
    razon_social: Yup.string().required(
      "La razón social de la empresa es requerida"
    ),
    id_empresa: Yup.object({
      // nombre: Yup.string().required("El nombre de al empresa es requerido"),
      email: Yup.string().email("Ingrese un Email valido"),
      celular: Yup.string()

        .matches(/^[0-9]+$/, "Deben ser digitos")
        .min(8, "La longitud debe ser mayor que 8")
        .max(16, "La longitud debe ser menor que 16"),
      telefono: Yup.number("El dato debe ser numerico"),
      nombre: Yup.string(),
      area: Yup.string(),
    }),

    id_direcFc: Yup.object().shape({
      street: Yup.string().required("La calle es requerida"),
      city: Yup.string().required("La ciudad es requerida"),
      department: Yup.string().required("El departamento es requerido"),
      country: Yup.string().required("El país es requerido"),
      zip_code: Yup.string(),
    }),
  };
}
