import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Grid,
  IconButton,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@material-ui/core";
import React, { useState } from "react";

export function DocumentosBase(props) {
  const { documentos, update, onRefetch } = props;

  const [editingIndices, setEditingIndices] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleEditClick = (index) => {
    setEditingIndices((prevIndices) => [...prevIndices, index]);
  };

  const handleCancelClick = (index) => {
    setEditingIndices((prevIndices) => prevIndices.filter((i) => i !== index));
  };

  const handleSaveClick = async (index) => {
    if (!selectedFiles[index]) {
      alert("Por favor, carga un documento antes de guardar.");
      return;
    }

    const documento = documentos[index];
    const id = documento.id;

    const formValues = {
      numero_documento: numeroDocumentoRefs[index].current.value,
      fecha_vencimiento: fechaVencimientoRefs[index].current.value,
      archivo: selectedFiles[index],
    };

    try {
      await update(id, formValues);
      setEditingIndices((prevIndices) =>
        prevIndices.filter((i) => i !== index)
      );
      onRefetch();
    } catch (error) {
      console.log(error?.message);
    }
  };

  if (!documentos) {
    return <Typography variant="subtitle1">Cargando datos...</Typography>;
  }

  if (documentos.length === 0) {
    return (
      <Typography variant="subtitle1">
        No hay documentos disponibles.
      </Typography>
    );
  }

  const numeroDocumentoRefs = documentos.map(() => React.createRef());
  const fechaVencimientoRefs = documentos.map(() => React.createRef());

  const handleFileChange = (event, index) => {
    const files = event.target.files;
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[index] = files[0];
      return updatedFiles;
    });
  };

  const handleDownloadClick = (archivo) => {
    // Generar el enlace de descarga
    const downloadLink = document.createElement("a");
    downloadLink.href = archivo; // Establece el enlace al archivo
    downloadLink.download = "documento"; // Establece el nombre de descarga
    downloadLink.click(); // Simula el clic en el enlace de descarga
  };

  return (
    <Grid container spacing={2}>
      {documentos.map((documento, index) => (
        <Grid item xs={12} key={index}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: 1,
              p: 2,
              borderRadius: 1,
              borderColor: documento.obligatorio ? "red" : "gray",
            }}
          >
            <Box sx={{ flex: 1 }}>
              {editingIndices.includes(index) ? (
                // Formulario de edición
                <>
                  <TextField
                    label="Número de Documento"
                    name={`numero_documento-${index}`}
                    defaultValue={documento.numero_documento}
                    inputRef={numeroDocumentoRefs[index]}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    name={`fecha_vencimiento-${index}`}
                    type="date"
                    defaultValue={documento.fecha_vencimiento}
                    inputRef={fechaVencimientoRefs[index]}
                    fullWidth
                    helperText="Fecha de vencimiento"
                    sx={{ mb: 2 }}
                  />
                  <Input
                    type="file"
                    inputProps={{
                      multiple: false,
                      accept: "image/*, .pdf, .doc, .docx",
                    }}
                    onChange={(event) => handleFileChange(event, index)}
                    sx={{
                      display: "none", // Oculta el botón de carga
                    }}
                    id={`file-upload-${index}`}
                  />
                  <label htmlFor={`file-upload-${index}`}>
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        marginTop: "10px", // Ajusta el margen superior según tus necesidades
                        borderColor: "rgba(0, 0, 0, 0.23)", // Establece el color del borde
                        "&:hover": {
                          borderColor: "rgba(0, 0, 0, 0.5)", // Establece el color del borde al pasar el cursor por encima
                        },
                      }}
                    >
                      Subir archivo
                    </Button>
                  </label>
                  {selectedFiles[index] && (
                    <Typography variant="subtitle1" gutterBottom>
                      Archivo seleccionado: {selectedFiles[index].name}
                    </Typography>
                  )}
                  <Stack direction="row" spacing={2} mt={2}>
                    <Button
                      variant="contained"
                      className="btn btn-danger"
                      onClick={() => handleSaveClick(index)}
                    >
                      Guardar
                    </Button>
                    <Button
                      type="submit"
                      className="btn btn-danger"
                      variant="outlined"
                      onClick={() => handleCancelClick(index)}
                    >
                      Cancelar
                    </Button>
                  </Stack>
                </>
              ) : (
                // Modo de solo lectura
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ fontFamily: "Arial", fontSize: 16 }}
                    >
                      Nombre de Documento:
                      <strong>{documento.nombre_documento}</strong>
                    </Typography>
                    {documento.archivo && (
                      <IconButton
                        onClick={() => handleDownloadClick(documento.archivo)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    )}
                  </Box>

                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ fontFamily: "Arial", fontSize: 14 }}
                  >
                    Número de Documento: {documento.numero_documento}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ fontFamily: "Arial", fontSize: 14 }}
                    >
                      Fecha de Vencimiento: {documento.fecha_vencimiento}
                    </Typography>
                    <Button
                      type="Button"
                      className="btn btn-danger"
                      variant="outlined"
                      onClick={() => handleEditClick(index)}
                      sx={{ fontFamily: "Arial", fontSize: 14, ml: "auto" }}
                    >
                      Editar
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
