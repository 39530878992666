import React, { useEffect, useState } from 'react';
import { AddEditTablaAforoForms, HeaderPage, } from "../../../../Components/Client";
import { ModalBasic, DataGridBasic } from "../../../../Components/Comons";
import { useTablaAforo } from "../../../../hooks";
import Swal from "sweetalert2"
import { filterValue, columns } from "./TablaAforoFormat";
import CircularProgress from '@mui/material/CircularProgress';

export function TablaAforoAdmin() {
  const { loading, tablasAforo, getTablasAforo, deleteTablasAforo } = useTablaAforo();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);


  useEffect(() => {
    getTablasAforo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const addTablaAforo = () => {
    setTitleModal("Nueva tabla aforo");

    setContentModal(
      <AddEditTablaAforoForms onClose={openCloseModal} onRefetch={onRefetch} />

    );
    openCloseModal();
  };

  const updateTablaAforo = (data) => {
    setTitleModal("Actualizar Tabla aforo");
    setContentModal(
      <AddEditTablaAforoForms
        onClose={openCloseModal}
        onRefetch={onRefetch}
        TablaAforo={data}
      />
    );
    openCloseModal();
  };

  const onDeleteTablaAforo = async (data) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estas seguro?',
      text: "No podras revertir esta accion!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTablasAforo(data.id_aforo);
        onRefetch();
        swalWithBootstrapButtons.fire(
          'Eliminado!',
          'El dato a sido eliminado.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Operacion cancelada',
          'error'
        )
      }
    })
  };

  return (
    <>
      <HeaderPage title="Creacion tabla de aforo" btnTitle="Nueva tabla" icono="plus square icon" btnClick={addTablaAforo} />


      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>

          <DataGridBasic
            data={tablasAforo.results}
            title="Tabla aforo"
            id="id_aforo"
            filterValue={filterValue}
            columns={columns}
            updateData={updateTablaAforo}
            onDeleteData={onDeleteTablaAforo}
          />
        </>

      )}
      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}

      />
    </>
  )
}

