import { format } from 'date-fns';


// para desarrollo local
// export const BASE_API = "http://localhost:8000/r"
// export const BASE_API_PUBLIC = "http://localhost:8000"
//export const BASE_API_FLASK = "ws://127.0.0.1:5005"

// para despliegue multitenant
// export const BASE_API = "http://192.168.50.220:9012/r"
// export const BASE_API_PUBLIC = "http://192.168.50.220:9012"

// export const ASGI_API = 'http://127.0.0.1:5000/data';

// PARA DESPLIEGUE DE DEMOSTRACIONES
// export const BASE_API = "http://192.168.50.220:9006/r"
// export const BASE_API_PUBLIC = "http://192.168.50.220:9006"
// export const BASE_API_FLASK = "ws://192.168.50.220:5005"
//export const BASE_API_FLASK = "ws://192.168.50.220:86"

// PARA DESPLIEGUE DE PRODUCCIÓN (AWS)
export const BASE_API = "https://syzapiback.syzapi.com/r"
export const BASE_API_PUBLIC = "https://syzapiback.syzapi.com"

// PARA DESPLIEGUE DE DESARROLLO
// export const BASE_API = "http://192.168.50.220:9008/r"
// export const BASE_API_PUBLIC = "http://192.168.50.220:9008"

// PARA DESPLIEGUE DE SLATE
// export const BASE_API = "http://192.168.50.220:9011/r"
// export const BASE_API_PUBLIC = "http://192.168.50.220:9011"

// export const BASE_API = "http://18.223.156.86/r"
// export const BASE_API_PUBLIC = "http://18.223.156.86"

export const TOKEN = "token";
export const TENANT = "tenant";

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';
  return date ? format(new Date(date), fm) : '';
}
