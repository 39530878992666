import Typography from "@mui/material/Typography";
import { Formik, useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import * as Yup from "yup";
import { useConductor, useTransportadora } from "../../../hooks";
import { TransportadoraSelect } from "../../Comons";
import { alertas } from "../../Comons/Alertas/alertas";
export function AddEditConductores(props) {
  /*
  Componente

  -componente de visualización del modal de edicion y creacion para Conductores
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { onClose, onRefetch, Conductores } = props;
  const { Successful } = alertas();
  const { addConductor, updateConductor } = useConductor();
  const { TransportadoraDB, getTransportadoraDB } = useTransportadora();

  const [previewImage, setPreviewImage] = useState(null);

  // establece la imagen para que sea mostrada en el formulario
  useEffect(() => {
    setPreviewImage(Conductores?.foto || null);
  }, [Conductores]);

  // diccionario para establecer los errores de los campos Autocomplete
  const [errors, setErrors] = React.useState({
    tipo_id: false,
    id_transp: false
  });

  const tablasFormatT = useMemo(
    () => TransportadoraSelect(TransportadoraDB),
    [TransportadoraDB]
  );

  // trae los registros de la trasnportadora
  useEffect(() => {
    getTransportadoraDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Conductores),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Conductores ? updateSchame() : newSchema()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,

    onSubmit: async (formValue) => {
      try {
        const formattedDate = new Date(formValue.fecha_nacimiento)
        const formattedDate2 = new Date(formValue.fecha_vencimineto_licencia)

        formValue.fecha_nacimiento = formattedDate.getFullYear() + '-' + String(formattedDate.getMonth() + 1).padStart(2, '0') + '-' + String(formattedDate.getDate()).padStart(2, '0');
        formValue.fecha_vencimineto_licencia = formattedDate2.getFullYear() + '-' + String(formattedDate2.getMonth() + 1).padStart(2, '0') + '-' + String(formattedDate2.getDate()).padStart(2, '0');

        console.log(formValue)

        if (Conductores)
          await updateConductor(Conductores.id_conductor, formValue);
        else await addConductor(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          // Muestra un mensaje de error si ocurre algún problema
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  // función que establece la imagen en el formulario
  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0] ? acceptedFile[0] : acceptedFile; // cuando se selecciona la imagen el archivo viende dentro de un array, cuando se toma la foto no
    console.log('este archivo es',file);
    await formik.setFieldValue("foto", file);
    setPreviewImage(URL.createObjectURL(file)); // crea una url del archivo para mostrarlo en el navegador
  }, []);

  // se usa cuando la imagen es seleccionada
  const { getRootProps, getInputProps } = useDropzone({
    accept: "foto/jpeg, foto/png, foto/jpg",
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  // const onDrop = useCallback(async (acceptedFile) => {
  //   const file = acceptedFile[0];
  //   await formik.setFieldValue("foto", file);
  //   setPreviewImage(URL.createObjectURL(file));
  // }, []);

  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: "image/jpeg, image/png, image/jpg",
  //   noKeyboard: true,
  //   multiple: false,
  //   onDrop,
  // });

  // const imagenStyle = {
  //   width: "300px",
  //   margin: "0 auto",
  // };

  let nitTrans = "";

  if (formik?.values && tablasFormatT.length != 0) {
    const trans = tablasFormatT.find(
      (t) => t.extra.id_transportadora === formik?.values.id_transp
    );
    nitTrans = trans?.extra.nit_transportadora;
  }

  const divStyle = {
    textAlign: "left",
  };
  const imagenStyle = {
    width: "300px",
    margin: "0 auto",
  };

  // maneja el cambio en el autocomplete
  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value?.value || '');
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };

  // genera un nombre aleatorio para ponerle al archivo generado cuando se toma la foto
  const generateRandomName = () => {
    // genera un string aleatorio
    const randomString = Math.random().toString(36).substring(2, 8);
    // genera una marca de tiempo 
    const timestamp = Date.now();
    // combina ambas cosas para generar el nombre del archivo
    const fileName = `${randomString}_${timestamp}.png`;
    return fileName;
  };

  // función para tomar la foto, abre un stream de video y toma la foto
  const takeSnapshot = () => {
    // crea un elemento de video
    const video = document.createElement('video');

    navigator.mediaDevices.getUserMedia({ video: true }) // accede a la cámara del pc y pide autorización para iniciar la transmisión del video
      .then((stream) => {
        video.srcObject = stream; // crea el objeto de video
        video.play(); // lo inicia, es decir inicia la cámara para que empiece a transmitir imágenes

        // aplica las propiedades posteriores cuando el video empieza a transmitirse
        video.onloadedmetadata = () => { 
          // crea un área plana
          const canvas = document.createElement('canvas'); 
          const context = canvas.getContext('2d');
          
          // el área se ajusta a los valores de altura y ancho del video
          canvas.width = video.videoWidth; 
          canvas.height = video.videoHeight;

          // toma el "pantallazo" de la transmisión, es decir toma la foto
          context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

          // convierte la imagen tomada en un archivo y se pasa a la función para que se establezca como la foto del formulario
          canvas.toBlob((blob) => {
            const fileName = generateRandomName();
            const file = new File([blob], fileName, { type: 'image/png' });

            formik.setFieldValue('foto', file);
            onDrop(file);
          }, 'image/png');

          //setPreviewImage(imageBase64);

          // detiene la transmisión y apaga la foto
          video.srcObject.getTracks().forEach((track) => track.stop());
        };
      })
      .catch((error) => { // si no se autoriza entonces arroja el siguiente error
        toast.error('Error: No se pudo acceder a la cámara. Asegúrese de permitir el acceso a la cámara.');
      });
  };

  // meétodo para toma la foto desde el botón
  const handleClick = () => {
    takeSnapshot();
  };
console.log(formik)
  return (
    <>
      <Formik initialValues={initialValues()}>
        <form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {/* fila 1 */}
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="tipo"
                options={IdentificacionOptions}
                getOptionLabel={(option) => option.text || ''}
                value={IdentificacionOptions.find((option) => option.value === formik.values.tipo_id) || null}
                onChange={(_, value) => handleAutocompleteChange('tipo_id', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo identificación"
                    variant="outlined"
                    error={errors.tipo_id}
                    helperText={errors.tipo_id ? 'Debe seleccionar el tipo de documento' : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                required
                fullWidth
                label="Numero de identificación"
                name="n_documento"
                placeholder="Identificacion"
                value={formik.values?.n_documento}
                onChange={formik.handleChange}
                error={formik.errors.n_documento}
                type="number"
              />
            </Grid>

            {/* fila 2 */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Nombres"
                name="nombre"
                placeholder="Nombres"
                value={formik.values?.nombre}
                onChange={formik.handleChange}
                error={formik.errors.nombre}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Apellidos"
                name="apellidos"
                placeholder="Apellidos"
                value={formik.values?.apellidos}
                onChange={formik.handleChange}
                error={formik.errors.apellidos}
              />
            </Grid>

            {/* fila 3 */}
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                label="Email"
                name="email"
                placeholder="JohnDoe@mail.com"
                value={formik.values?.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
              />
            </Grid>

            {/* fila 4 */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Numero Licencia"
                name="n_licencia"
                placeholder="Identificacion"
                value={formik.values?.n_licencia}
                onChange={formik.handleChange}
                error={formik.errors.n_licencia}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="fecha_vencimineto_licencia"
                placeholder="Fecha"
                value={formik.values?.fecha_vencimineto_licencia}
                onChange={formik.handleChange}
                error={formik.errors.fecha_vencimineto_licencia}
                type="date"
                helperText="Fecha de vencimiento de la licencia"
              />
            </Grid>

            {/* fila 5 */}
            <Grid item xs={12} sm={4}>
              <Button
                type="button"
                fullWidth
                color={formik.errors.foto && 'red'}
                style={{
                  color: 'rgb(208, 48, 43)',
                  border: '1px solid rgb(208, 48, 43)',
                  '&:hover': {
                    color: 'rgba(208, 48, 43, 0.62)',
                    border: '1px solid rgba(221, 223, 221, 0.62)',
                  }
                }}
                {...getRootProps()}
              >
                {previewImage ? 'Cambiar imagen' : 'Subir imagen'}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                type="button"
                fullWidth

                style={{
                  color: 'rgb(208, 48, 43)',
                  border: '1px solid rgb(208, 48, 43)',
                  '&:hover': {
                    color: 'rgba(208, 48, 43, 0.62)',
                    border: '1px solid rgba(221, 223, 221, 0.62)',
                  }
                }}
                // {...getRootProps()}
                onClick={() => {
                  handleClick()
                }}
              >
                Tomar foto
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper variant="outlined" fullWidth>
                <input {...getInputProps()} />
                <img
                  style={{
                    ...imagenStyle,
                    maxWidth: '100%',
                    maxHeight: '250px',
                  }}
                  src={previewImage}
                  fluid
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" >
                * La imagen es requerida
              </Typography>
            </Grid>

            
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                name="fecha_nacimiento"
                placeholder="Fecha de nacimiento"
                value={formik.values?.fecha_nacimiento}
                onChange={formik.handleChange}
                error={
                  formik.touched.fecha_nacimiento && Boolean(formik.errors.fecha_nacimiento)
                }
                helperText={formik.touched.fecha_nacimiento && formik.errors.fecha_nacimiento}
                type="date"
                
              />
            </Grid>

            {/* fila 7 */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Celular"
                name="celular"
                placeholder="celular"
                value={formik.values?.celular}
                onChange={formik.handleChange}
                error={formik.errors.celular}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Teléfono"
                name="telefono"
                placeholder="Teléfono"
                value={formik.values?.telefono}
                onChange={formik.handleChange}
                error={formik.errors.telefono}
                type="number"
              />
            </Grid>

            {/* fila 8 */}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="trans"
                name="id_transp"
                label="Trasportadora"
                fullWidth
                value={tablasFormatT.find((option) => option.value === formik.values.id_transp) || null}
                onChange={(event, value) => handleAutocompleteChange('id_transp', value)}
                options={tablasFormatT || []}
                getOptionLabel={(option) => option.text || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trasportadora"
                    error={errors.id_transp}
                    helperText={errors.id_transp ? 'Debe seleccionar una transportadora' : ''}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            {/* fila 5 */}
            <Grid item xs={12} sm={6}>
              <TextField
                disabled
                fullWidth
                label="NIT transportadora"
                placeholder="Documento"
                value={nitTrans}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button type="submit" variant="contained" className="btn btn-danger" color="error" fullWidth >
                {Conductores ? "Actualizar" : "Crear"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Formik>
    </>
  );
}





function initialValues(data) {
  return {
    foto: null,
    tipo_id: data?.tipo_id || "",
    nombre: data?.nombre || "",
    apellidos: data?.apellidos || "",
    n_documento: data?.n_documento || "",
    n_licencia: data?.n_licencia || "",
    fecha_vencimineto_licencia: data?.fecha_vencimineto_licencia || "",
    seguro: data?.seguro || "",
    celular: data?.celular || "",
    telefono: data?.telefono || "",
    genero: data?.genero || "",
    email: data?.email || "",
    fecha_nacimiento: data?.fecha_nacimiento || "",
    id_transp: data?.id_transp || "",
  };
}

function newSchema() {
  return {
    tipo_id: Yup.number().required(
      "El campo de número tipo identificación es requerido"
    ),
    nombre: Yup.string().required("El campo Nombre es requerido"),
    apellidos: Yup.string().required("El campo Apellidos es requerido"),
    n_documento: Yup.string()
      .required("El campo es requerido")
      .matches(/^[0-9]+$/, "Deben ser dígitos"),
    n_licencia: Yup.string()
      .required("El campo es requerido")
      .matches(/^[0-9]+$/, "Deben ser dígitos"),
      fecha_vencimineto_licencia: Yup.date().required(),
    seguro: Yup.string(),
    celular: Yup.string()
      .required("El campo Celular es requerido")
      .matches(/^[0-9]+$/, "Deben ser dígitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    telefono: Yup.string("El dato debe ser numérico")
      .matches(/^[0-9]+$/, "Deben ser dígitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    fecha_nacimiento: Yup.date()
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "Debe ser mayor de edad"
      )
      .transform((value, originalValue) => {
        if (originalValue instanceof Date) {
          return originalValue;
        }
        return new Date(originalValue); // Parsea el valor en un objeto de fecha
      }),

    genero: Yup.string(),
    foto: Yup.mixed()
      .test("fileSize", "La foto no debe ser mayor a 200 KB", function (value) {
        if (!value) return true; // permitir que el campo sea vacío
        if (value.size > 900000) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'La foto no debe ser mayor a 200 KB',
          });
          return false;
        }
        return true;
      })
      .required(),
    email: Yup.string()
      .email("Ingrese un Email válido")
      .required("El Email es requerido"),
    id_transp: Yup.number().required(true),
  };
}


function updateSchame() {
  return {
    tipo_id: Yup.number().required(
      "El campo de numero tipo identificacion es requerido"
    ),
    nombre: Yup.string().required("El campo Nombre es requerido"),
    apellidos: Yup.string().required("El campo Apellidos es requerido"),
    n_documento: Yup.string()
      .required("El campo es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos"),
    n_licencia: Yup.string()
      .required("El campo es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos"),
      fecha_vencimineto_licencia: Yup.date().required(),
    seguro: Yup.string(),
    celular: Yup.string()
      .required("El campo Celular es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    telefono: Yup.string("El dato debe ser numerico")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    fecha_nacimiento: Yup.date()
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "Debe ser mayor de edad"
      )
      .transform((value, originalValue) => {
        if (originalValue instanceof Date) {
          return originalValue;
        }
        return new Date(originalValue); // Parsea el valor en un objeto de fecha
      }),
    
    genero: Yup.string(),
    foto: Yup.mixed()
      .test("fileSize", "La foto no debe ser mayor a 200 KB", function (value) {
        if (!value) return true; // permitir que el campo sea vacío
        if (value.size > 900000) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'La foto no debe ser mayor a 200 KB',
          });
          return false;
        }
        return true;
      }),
    email: Yup.string()
      .email("Ingrese un Email valido")
      .required("El Email es requerido"),
    id_transp: Yup.number().required(true),
  };
}




const IdentificacionOptions = [
  {
    key: 1,
    text: "Cédula de Ciudadanía",
    value: 1,
  },
  {
    key: 2,
    text: "Pasaporte",
    value: 2,
  },
 
  {
    key: 4,
    text: "Cédula de Extranjería",
    value: 4,
  },
  {
    key: 5,
    text: "Permiso Especial de Permanencia (PEP)",
    value: 5,
  },
  {
    key: 6,
    text: "Documento de Identidad Extranjero",
    value: 6,
  },
  {
    key: 7,
    text: "Salvoconducto de Permanencia",
    value: 7,
  },
];
