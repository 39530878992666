import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { HeaderPage } from "../../../Components/Client";
import { AddEditGruposPermisos } from "../../../Components/Admin";
import { ModalBasic, VistasCamposDinamicos, DataGridBasic } from "../../../Components/Comons";
import { useGruposPermisos } from "../../../hooks";
import { columns, filterValue } from "./GruposFormat";

import CircularProgress from '@mui/material/CircularProgress';

export function GruposPermisosAdmin() {
  const { loading, GruposPermisos, getGruposPermisos, deleteGruposPermisos } = useGruposPermisos();

  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);

  useEffect(() => {
    getGruposPermisos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);


  const onRefetch = () => setRefetch((prev) => !prev);
  const openCloseModal = () => setShowModal((prev) => !prev);

  const vistaDatosDinamicos = (data) => {
    setContentModal(
      <VistasCamposDinamicos visualizacion={data} onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }

  const addGruposPermisos = () => {
    setTitleModal("Nuevo grupo de permisos");
    setContentModal(
      <AddEditGruposPermisos onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  const updateGruposPermisos = (data) => {
    setTitleModal("Actualizar grupo de permisos");
    setContentModal(
      <AddEditGruposPermisos
        onClose={openCloseModal}
        onRefetch={onRefetch}
        GruposPermisos={data}
      />
    );
    openCloseModal();
  };


  const onDeleteGruposPermisos = async (data) => {
    const result = window.confirm(`¿Eliminar el Grupo de permisos ${data.name}?`);
    if (result) {
      try {
        await deleteGruposPermisos(data.id);
        onRefetch();
      } catch (error) {
        console.error(error);
      }
    }
  };



  return (
    <>

      <HeaderPage
        title="Grupo de permisos"
        btnTitle="Nuevo Grupo"
        icono="plus square icon"
        btnClick={addGruposPermisos}
      />

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          descarga={1}
          data={GruposPermisos}
          title="GruposPermisos"
          id="id"
          filterValue={filterValue}
          columns={columns}
          updateData={updateGruposPermisos}
          onDeleteData={onDeleteGruposPermisos}
        />
      )}


      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  )
}

