import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos de un cliente
export async function getSensoresApi(token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorConfig/`;
    return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo cliente
export async function addSensoresApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorConfig/`;
    return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos de un cliente existente
export async function updateSensoresApi(id, data, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorConfig/${id}/`;
    return makeRequest(url, "PUT", token, data);
}

// Función para eliminar un cliente
export async function deleteSensoresApi(id, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorConfig/${id}/`;
    return makeRequest(url, "DELETE", token);
}

export async function getEtiquetaSensoresApi(token, schema) {
    const url = `${BASE_URL}${schema}/sensores/etiquetas/`;
    return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo cliente
export async function addEtiquetaSensoresApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/etiquetas/`;
    return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos de un cliente existente
export async function updateEtiquetaSensoresApi(id, data, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/etiquetas/${id}/`;
    return makeRequest(url, "PUT", token, data);
}

// Función para eliminar un cliente
export async function deleteEtiquetaSensoresApi(id, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/etiquetas/${id}/`;
    return makeRequest(url, "DELETE", token);
}

export async function getSensorUnitsApi(token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorunits/`;
    return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo cliente
export async function addSensorUnitsApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorunits/`;
    return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos de un cliente existente
export async function updateSensorUnitsApi(id, data, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorunits/${id}/`;
    return makeRequest(url, "PUT", token, data);
}

// Función para eliminar un cliente
export async function deleteSensorUnitsApi(id, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorunits/${id}/`;
    return makeRequest(url, "DELETE", token);
}

export async function getSensorCategoriesApi(token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorcategories/`;
    return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo cliente
export async function addSensorCategoriesApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorcategories/`;
    return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos de un cliente existente
export async function updateSensorCategoriesApi(id, data, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorcategories/${id}/`;
    return makeRequest(url, "PUT", token, data);
}

// Función para eliminar un cliente
export async function deleteSensorCategoriesApi(id, token, schema) {
    const url = `${BASE_URL}${schema}/sensores/sensorcategories/${id}/`;
    return makeRequest(url, "DELETE", token);
}