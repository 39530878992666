import { Autocomplete } from '@mui/material';
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import TextField from '@mui/material/TextField';
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useCliente, useUser } from "../../../hooks";
import { clienteSelect } from "../../Comons";
import { alertas } from "../../Comons/Alertas/alertas";
import "./AddEditUserForm.scss";


export function AddEditUserForm(props) {
 
  const { onClose, onRefetch, user } = props;
  const { Successful } = alertas();
  const { addUser, updateUser } = useUser();
  const [previewImage, setPreviewImage] = useState(user ? user?.foto : null);
  const [tablasFormatC, setTablasTFormatC] = useState([]);
  const { ClienteDB, getClienteDB } = useCliente();

  const [errors, setErrors] = React.useState({
    tipo_id_pers: false,
  });

  useEffect(() => {
    getClienteDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTablasTFormatC(clienteSelect(ClienteDB));
  }, [ClienteDB]);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(user),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(user ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (user) await updateUser(user.id_usuario, formValue);
        else await addUser(formValue);
        Successful()
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema

        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {

          onClose();
        }
      }
    },
  });

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("foto", file);
    setPreviewImage(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const imagenStyle = {
    width: "300px",
    margin: "0 auto",
  };

  const onCheckboxChange = (fieldName, checked) => {
    if (fieldName === "is_facturador") {
      formik.setFieldValue("is_facturador", checked);
      formik.setFieldValue("is_normal", !checked);
      formik.setFieldValue("is_active", true);
      formik.setFieldValue("is_superuser", true);
      formik.setFieldValue("is_admin", false);
    } else if (fieldName === "is_normal") {
      formik.setFieldValue("is_normal", checked);
      formik.setFieldValue("is_facturador", !checked);
      formik.setFieldValue("is_active", checked);
      formik.setFieldValue("is_superuser", !checked);
      formik.setFieldValue("is_admin", !checked);
    }
  };

  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value?.value || '');
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };

  console.log('useer',user)

  return (
    <>
      <form className="add-edit-user-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* fila 1 */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              options={IdentificacionOptions}
              getOptionLabel={(option) => option.text || ''}
              value={IdentificacionOptions.find((option) => option.value === formik.values.tipo_id_pers) || null}
              onChange={(event, value) => handleAutocompleteChange('tipo_id_pers', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de documento"
                  variant="outlined"
                  placeholder="Tipo de documento"
                  error={errors.tipo_id_pers}
                  helperText={errors.tipo_id_pers ? 'Debe seleccionar el tipo de documento' : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              required
              fullWidth
              label="Número de identificación"
              name="identificacion_pers"
              placeholder="Identificación"
              value={formik.values.identificacion_pers}
              onChange={formik.handleChange}
              error={formik.touched.identificacion_pers && Boolean(formik.errors.identificacion_pers)}
              type="number"
            />
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Nombres"
              name="nombres"
              placeholder="Nombres"
              value={formik.values.nombres}
              onChange={formik.handleChange}
              error={formik.touched.nombres && Boolean(formik.errors.nombres)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Apellidos"
              name="apellidos"
              placeholder="Apellidos"
              value={formik.values.apellidos}
              onChange={formik.handleChange}
              error={formik.touched.apellidos && Boolean(formik.errors.apellidos)}
            />
          </Grid>

          {/* fila 3 */}
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Email"
              name="email"
              placeholder="JohnDoe@mail.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              type="email"
            />
          </Grid>

          {/* fila 4 */}
          <Grid item xs={12} sm={8}>
            <Button
              type="button"
              fullWidth
              color={formik.errors.foto && 'red'}
              style={{
                color: 'rgb(208, 48, 43)',
                border: '1px solid rgb(208, 48, 43)',
                '&:hover': {
                  color: 'rgba(208, 48, 43, 0.62)',
                  border: '1px solid rgba(221, 223, 221, 0.62)',
                }
              }}
              {...getRootProps()}
            >
              {previewImage ? 'Cambiar imagen' : 'Subir imagen'}
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper variant="outlined">
              <input {...getInputProps()} />
              <img
                style={{
                  ...imagenStyle,
                  maxWidth: "100%",
                  maxHeight: "250px",
                }}
                src={previewImage} // <-- Agrega una imagen por defecto
                alt="Vista previa"
              />
            </Paper>
          </Grid>



          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label='Fecha de nacimiento'
              name="fecha_nacimiento"
              value={formik.values.fecha_nacimiento}
              onChange={formik.handleChange}
              error={
                formik.touched.fecha_nacimiento && Boolean(formik.errors.fecha_nacimiento)
              }
              helperText={formik.touched.fecha_nacimiento && formik.errors.fecha_nacimiento}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Celular"
              name="celular"
              placeholder="Celular"
              value={formik.values.celular}
              onChange={formik.handleChange}
              error={formik.touched.celular && Boolean(formik.errors.celular)}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Teléfono"
              name="telefono"
              placeholder="Teléfono"
              value={formik.values.telefono}
              onChange={formik.handleChange}
              error={formik.touched.telefono && Boolean(formik.errors.telefono)}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Contraseña"
              name="password"
              type="password"
              placeholder="Contraseña"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
            />
          </Grid>


          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.is_normal}
                  onChange={(_, checked) => onCheckboxChange("is_normal", checked)}
                  color="primary"
                />
              }
              label="Usuario Interno"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.is_facturador}
                  onChange={(_, checked) => onCheckboxChange("is_facturador", checked)}
                  color="primary"
                />
              }
              label="Usuario Facturador"
            />
          </Grid>


          <Grid item xs={12}>
            {formik.values.is_facturador && (

              <Autocomplete
                id="cliente_asociado"
                options={tablasFormatC}
                getOptionLabel={(option) => option.text}
                value={
                  tablasFormatC?.find(
                    (opt) => opt.key === formik.values?.cliente_asociado
                  ) || null
                }
                onChange={(_, value) => {
                  formik.setFieldValue("cliente_asociado", value?.key);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente asociado"
                    fullWidth
                    variant="outlined"
                    error={formik.errors.cliente_asociado && Boolean(formik.errors.cliente_asociado)}
                    helperText={formik.touched.cliente_asociado && formik.errors.cliente_asociado}
                    sx={{ background: "#fcfcfc" }}
                  />
                )}
              />

            )}
          </Grid>



          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.is_active}
                  onChange={(_, checked) =>
                    formik.setFieldValue("is_active", checked)
                  }
                  color="primary"
                />
              }
              label="Usuario activo"
            />
          </Grid>
          {!formik.values.is_facturador && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.is_superuser}
                      onChange={(_, checked) =>
                        formik.setFieldValue("is_superuser", checked)
                      }
                      color="primary"
                    />
                  }
                  label="Super usuario"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.is_admin}
                      onChange={(_, checked) =>
                        formik.setFieldValue("is_admin", checked)
                      }
                      color="primary"
                    />
                  }
                  label="Usuario administrador"
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              className="btn btn-danger"
              color="error"
              fullWidth
            >
              {user ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>

      </form>
    </>
  );
}

function initialValues(data) {
  const isFacturador = data?.is_facturador || false;
  const isAdmin = isFacturador ? false : data?.is_admin || false;
  const isSuperuser = isFacturador ? true : data?.is_superuser || false;

  return {
    tipo_id_pers: data?.tipo_id_pers || "",
    identificacion_pers: data?.identificacion_pers || "",
    nombres: data?.nombres || "",
    apellidos: data?.apellidos || "",
    foto: null,
    email: data?.email || "",
    cliente_asociado: parseInt(data?.cliente_asociado) || "",
    fecha_nacimiento: data?.fecha_nacimiento || "",
    celular: data?.celular || "",
    telefono: data?.telefono || "",
    password: "",
    is_admin: isAdmin,
    is_facturador: isFacturador,
    is_normal: !isFacturador,
    is_staff: data?.is_staff ? true : false,
    is_active: isFacturador ? true : data?.is_active || false,
    is_superuser: isSuperuser,
    is_superadmin: data?.is_superadmin ? true : false,
  };
}

function newSchame() {
  return {
    tipo_id_pers: Yup.number("El dato debe ser numérico").required(
      "El tipo de identificacion es obligatorio"
    ),
    identificacion_pers: Yup.string()
      .required("El Numero de identificacion es requerido")
      .matches(/^[0-9]+$/, "Deben ser dígitos")
      .min(6, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    nombres: Yup.string().required("El Nombre es requerido"),
    apellidos: Yup.string().required("Los Apellidos son requeridos"),
    cliente_asociado: Yup.string(),
    foto: Yup.mixed(),
    email: Yup.string()
      .email("Ingrese un Email valido")
      .required("El Email es requerido"),

    fecha_nacimiento: Yup.date()
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "Debe ser mayor de edad"
      )
      .transform((value, originalValue) => {
        if (originalValue instanceof Date) {
          return originalValue;
        }
        return new Date(originalValue); // Parsea el valor en un objeto de fecha
      }),
    celular: Yup.string()
      .required("El celular es requerido")
      .matches(/^[0-9]+$/, "Deben ser dígitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    telefono: Yup.string()
      .matches(/^[0-9]+$/, "Deben ser dígitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    password: Yup.string().required("Contraseña requerida"),
    is_admin: Yup.bool().required("El campo es requerido"),
    is_active: Yup.bool().required("El campo es requerido"),

    // is_admin: Yup.bool().required(true),
    is_superuser: Yup.bool().required(true),
  };
}

function updateSchame() {
  return {
    tipo_id_pers: Yup.number("El dato debe ser numerico").required(
      "El tipo de identificacion es obligatorio"
    ),
    identificacion_pers: Yup.string()
      .required("El Numero de identificacion es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(6, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    nombres: Yup.string().required("El Nombre es requerido"),
    apellidos: Yup.string().required("Los Apellidos son requeridos"),
    foto: Yup.mixed(),
    email: Yup.string()
      .email("Ingrese un Email valido")
      .required("El Email es requerido"),

      fecha_nacimiento: Yup.date()
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "Debe ser mayor de edad"
      )
      .transform((value, originalValue) => {
        if (originalValue instanceof Date) {
          return originalValue;
        }
        return new Date(originalValue); // Parsea el valor en un objeto de fecha
      }),
    celular: Yup.string()
      .required("El celular es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    telefono: Yup.string()
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    password: Yup.string(),
    is_active: Yup.bool().required("El campo es requerido"),
    is_admin: Yup.bool().required("El campo es requerido"),

    // is_admin: Yup.bool(),
    is_superuser: Yup.bool(),
  };
}

const IdentificacionOptions = [
  {
    key: 1,
    text: "Cédula de Ciudadanía",
    value: 1,
  },
  {
    key: 2,
    text: "Pasaporte",
    value: 2,
  },
  
  {
    key: 4,
    text: "Cédula de Extranjería",
    value: 4,
  },
  {
    key: 5,
    text: "Permiso Especial de Permanencia (PEP)",
    value: 5,
  },
  {
    key: 6,
    text: "Documento de Identidad Extranjero",
    value: 6,
  },
  {
    key: 7,
    text: "Salvoconducto de Permanencia",
    value: 7,
  },
];

