import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import {
  AddEditUserAdmin
} from "../../../Components/Client";
import { useUser } from "../../../hooks";
import { VisualizacionGeneralForm } from "../../../Components/Comons";

export function Funciones() {
  const { deleteUserAdmin } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);



  const handleAdd = useCallback(() => {
    setTitleModal("Nueva cuenta de cliente");
    setContentModal(
      <AddEditUserAdmin onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleUpdate = useCallback((data) => {

    setTitleModal("Actualizar cuenta de cliente");
    setContentModal(
      <AddEditUserAdmin
        onClose={openCloseModal}
        onRefetch={onRefetch}
        user={data}
      />
    );
    openCloseModal();
  }, [openCloseModal, onRefetch]);

  const handleViews = useCallback(
    (data) => {
      setTitleModal("");
      setContentModal(
        <VisualizacionGeneralForm
          onClose={openCloseModal}
          onRefetch={onRefetch}
          visualizacion={data}
        />
      );
      openCloseModal();
    },
    [openCloseModal, onRefetch]
  );

  const handleDelete = useCallback(async (data) => {
    const result = await Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      await deleteUserAdmin(data.id_usuario);
      Swal.fire("Eliminado!", "El dato ha sido eliminado.", "success");
      onRefetch();
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Operación cancelada", "error");
    }
  }, [deleteUserAdmin, onRefetch]);

  return {
    handleAdd,
    handleDelete,
    handleUpdate,
    handleViews,
    openCloseModal,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
