import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useConciliaciones, useProductoBase, useTanques } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditConciliaciones(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, Conciliaciones } = props;
  const { addConciliaciones, updateConciliaciones } = useConciliaciones();
  const { ProductoBaseDB, getProductoBaseDB } = useProductoBase();
  const { tanquesDB, getTanquesDB } = useTanques();

  useEffect(() => {
    getProductoBaseDB();
    getTanquesDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: initialValues(Conciliaciones),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (Conciliaciones)
          await updateConciliaciones(
            Conciliaciones.id_conciliaciones,
            formValue
          );
        else await addConciliaciones(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  useEffect(() => {
    const selectedTank = tanquesDB?.find(tank => tank.id_tanque === formik.values.id_tanque);
    if (selectedTank) {
      formik.setFieldValue("id_producto", selectedTank.id_producto);
    }
  }, [formik.values.id_tanque, tanquesDB]);

  return (
    <>
      <form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              name="fecha_inicial"
              placeholder="Fecha inicial"
              value={formik.values.fecha_inicial}
              onChange={(value) => {
                formik.setFieldValue("fecha_inicial", value.target.value);
                formik.setFieldValue("fecha_final", value.target.value);
              }}
              error={formik.errors.fecha_inicial}
              type="date"
              helperText="Fecha de incidente"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              id="id_tanque"
              options={tanquesDB}
              getOptionLabel={(option) => `${option?.nombre_tanque}`}
              value={
                tanquesDB?.find(
                  (data) => data.id_tanque === formik.values?.id_tanque
                ) || null
              }
              onChange={(_, value) =>
                formik.setFieldValue("id_tanque", value?.id_tanque || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tanque"
                  variant="outlined"
                  error={
                    formik.touched.id_tanque && Boolean(formik.errors.id_tanque)
                  }
                  helperText={
                    formik.touched.id_tanque && formik.errors.id_tanque
                  }
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              required
              label="Producto"
              name="id_producto"
              value={ProductoBaseDB?.find(producto => producto.id === formik.values.id_producto)?.nombre || ''}
              InputProps={{
                readOnly: true,
              }}
              error={
                formik.touched.id_producto && Boolean(formik.errors.id_producto)
              }
              helperText={
                formik.touched.id_producto && formik.errors.id_producto
              }
              sx={{ background: "#fcfcfc" }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Perdidas por evaporación"
              name="perdida_evaporación"
              placeholder="Perdidas por evaporación"
              value={formik.values.perdida_evaporación}
              onChange={formik.handleChange}
              error={formik.errors.perdida_evaporación}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Perdidas por derrame"
              name="derrame"
              placeholder="Perdidas por derrame"
              value={formik.values.derrame}
              onChange={formik.handleChange}
              error={formik.errors.derrame}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              color="error"
              className="btn btn-danger"
              fullWidth
            >
              {Conciliaciones ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function initialValues(data) {
  return {
    fecha_inicial: data?.fecha_inicial || "",
    fecha_final: data?.fecha_inicial || "",
    id_tanque: data?.id_tanque || "",
    id_producto: data?.id_producto || "",
    perdida_evaporación: data?.perdida_evaporación || "",
    derrame: data?.derrame || "",
  };
}

function newSchema() {
  return {
    fecha_inicial: Yup.date().required(true),
    id_tanque: Yup.number().required(true),
    id_producto: Yup.number().required(true),
    perdida_evaporación: Yup.number().required(true),
    derrame: Yup.number().required(true),
  };
}

