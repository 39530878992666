import React from "react";
import { Tab } from 'semantic-ui-react';
import { ControladoresAdmin, OtrosDispAdmin, TipoControladorAdmin } from "../index";

export function GroupedControladores() {
  const panes = [
    {
      menuItem: 'Tipos de controladores',
      render: () => <Tab.Pane><TipoControladorAdmin /></Tab.Pane>,
    },
    {
      menuItem: 'Controladores',
      render: () => <Tab.Pane><ControladoresAdmin /></Tab.Pane>,
    },
    {
      menuItem: 'Otros dispositivos',
      render: () => <Tab.Pane><OtrosDispAdmin /></Tab.Pane>,
    }
  ];

  return <Tab panes={panes} />;
}
