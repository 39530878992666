import React, { useEffect, useState } from "react";
import { useTransacciones } from "../../../../hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import moment from 'moment';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

export function VistaTransacciones(props) {
  const { Transacciones } = props;
  const { retrieveTransacciones, TransaccionesRTR } = useTransacciones();
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    retrieveTransacciones(Transacciones.n_orden.toString());
  }, [Transacciones.n_orden]);

  const handleExpandClick = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const renderDetails = (label, value) => (
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="subtitle2" color="textSecondary">{label}</Typography>
      <Typography variant="body2">{value}</Typography>
    </Grid>
  );

  const formatValue = (value, decimals) => {
    if (!value) return value;
    value = value.toString();
    let intPart = value.slice(0, -decimals);
    let decimalPart = value.slice(-decimals);
    return `${intPart},${decimalPart}`;
  };

  const formatTransaction = (transaction) => {
    const decimals = parseInt(transaction.decimales_activos);
    const formattedTransaction = {
      ...transaction,
     
      componentes: transaction.componentes.map((componente) => ({
        ...componente,
        vol_gsv: componente.vol_gsv,
        vol_gov: componente.vol_gov,
        pres_promedio: componente.pres_promedio,
        temp_promedio: componente.temp_promedio,
        nsv: componente.nsv,
        iv: componente.iv,
        v_restante: componente.v_restante,
        v_entregado: componente.v_entregado,
        meter_factor: componente.meter_factor,
        ctl: componente.ctl,
        cpl: componente.cpl,
        ctpl: componente.ctpl,
        bsw: componente.bsw,
        gravedad_api: componente.gravedad_api,
      })),
      aditivos: transaction.aditivos.map((aditivo) => ({
        ...aditivo,
        vol_gsv: aditivo.vol_gsv,
        vol_gov: aditivo.vol_gov,
        pres_promedio: aditivo.pres_promedio,
        temp_promedio: aditivo.temp_promedio,
        nsv: aditivo.nsv,
        iv: aditivo.iv,
        v_restante: aditivo.v_restante,
        v_entregado: aditivo.v_entregado,
        meter_factor: aditivo.meter_factor,
        ctl: aditivo.ctl,
        cpl: aditivo.cpl,
        ctpl: aditivo.ctpl,
        bsw: aditivo.bsw,
        gravedad_api: aditivo.gravedad_api,
      })),
    };
    return formattedTransaction;
  };

  const formattedTransacciones = TransaccionesRTR?.map(formatTransaction);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Número de Transacción</TableCell>
            <TableCell>Creación</TableCell>
            <TableCell>Inicio del cargue</TableCell>
            <TableCell>Fin del cargue</TableCell>
            <TableCell>Volumen Restante</TableCell>
            <TableCell>Volumen Entregado</TableCell>
            <TableCell>Volumen GOV</TableCell>
            <TableCell>Volumen GSV</TableCell>
            <TableCell>NSV</TableCell>
            <TableCell>IV</TableCell>
            <TableCell>Número Bahía</TableCell>
            <TableCell>Presión Promedio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedTransacciones?.map((transaccion) => (
            <React.Fragment key={transaccion.id_transaccion}>
              <TableRow>
                <TableCell>
                  <IconButton onClick={() => handleExpandClick(transaccion.id_transaccion)}>
                    {expanded[transaccion.id_transaccion] ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </TableCell>
                <TableCell>{transaccion.num_transaccion}</TableCell>
                <TableCell>
                  <div style={{ whiteSpace: 'nowrap' }}>{moment(transaccion.time_created).format('MM-DD-YYYY')}</div>
                  <div>{moment(transaccion.time_created).format('HH:mm:ss')}</div>
                </TableCell>
                <TableCell>
                <div style={{ whiteSpace: 'nowrap' }}>{moment(transaccion.fecha_initcargue).format('MM-DD-YYYY')}</div>
                  <div>{moment(transaccion.fecha_initcargue).format('HH:mm:ss')}</div>
                </TableCell>
                <TableCell>
                <div style={{ whiteSpace: 'nowrap' }}>{moment(transaccion.fecha_endcargue).format('MM-DD-YYYY')}</div>
                  <div>{moment(transaccion.fecha_endcargue).format('HH:mm:ss')}</div>
                </TableCell>
                <TableCell>{transaccion.v_restante}</TableCell>
                <TableCell>{transaccion.v_entregado}</TableCell>
                <TableCell>{transaccion.vol_gov}</TableCell>
                <TableCell>{transaccion.vol_gsv}</TableCell>
                <TableCell>{transaccion.nsv}</TableCell>
                <TableCell>{transaccion.iv}</TableCell>
                <TableCell>{transaccion.num_bahia}</TableCell>
                <TableCell>{transaccion.pres_promedio}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={10} style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Collapse in={expanded[transaccion.id_transaccion]} timeout="auto" unmountOnExit>
                    <Box margin={2}>
                      <Typography variant="h5" gutterBottom>Vendible:  {transaccion.p_code} </Typography>
                      <Divider style={{ marginBottom: '16px' }} />
                      <Grid container spacing={2}>

                        {renderDetails("Volumen Restante", transaccion.v_restante)}
                        {renderDetails("Volumen Entregado", transaccion.v_entregado)}
                        {renderDetails("Volumen GOV", transaccion.vol_gov)}
                        {renderDetails("Volumen GSV", transaccion.vol_gsv)}
                        {renderDetails("NSV", transaccion.nsv)}
                        {renderDetails("IV", transaccion.iv)}
                        {renderDetails("Presión Promedio", transaccion.pres_promedio)}
                        {renderDetails("Temperatura Promedio", transaccion.temp_promedio)}
                        {renderDetails("Densidad Promedio", transaccion.densidad_promedio)}
                        {renderDetails("Factor Medidor", transaccion.meter_factor)}
                        {renderDetails("MK Factor", transaccion.mk_factor)}
                        {renderDetails("CTL", transaccion.ctl)}
                        {renderDetails("CPL", transaccion.cpl)}
                        {renderDetails("CTPL", transaccion.ctpl)}
                        
                        {renderDetails("Gravedad API", transaccion.gravedad_api)}
                      </Grid>

                      {transaccion.componentes.length > 0 && (
                        <Box marginTop={4}>
                          <Typography variant="h5" gutterBottom>Componentes base</Typography>
                          <Divider style={{ marginBottom: '16px' }} />
                          {transaccion.componentes.map((componente, index) => (
                            <React.Fragment key={index}>
                              <Typography variant="h6" gutterBottom>Componente: {componente.p_code}</Typography>
                              <Grid container spacing={2}>
                              
                                
                                {renderDetails("Volumen Entregado", componente.v_entregado)}
                                {renderDetails("Volumen GOV", componente.vol_gov)}
                                {renderDetails("Volumen GSV", componente.vol_gsv)}
                                {renderDetails("NSV", componente.nsv)}
                                {renderDetails("IV", componente.iv)}
                                {renderDetails("Presión Promedio", componente.pres_promedio)}
                                {renderDetails("Temperatura Promedio", componente.temp_promedio)}
                                {renderDetails("Densidad Promedio", componente.densidad_promedio)}
                                {renderDetails("Factor Medidor", componente.meter_factor)}
                                {renderDetails("MK Factor", componente.mk_factor)}
                                {renderDetails("CTL", componente.ctl)}
                                {renderDetails("CPL", componente.cpl)}
                                {renderDetails("CTPL", componente.ctpl)}
                                
                                {renderDetails("Gravedad API", componente.gravedad_api)}
                              </Grid>
                              <Divider style={{ margin: '30px 0', height: '2px', backgroundColor: '#0a0a0a' }} />
                            </React.Fragment>
                          ))}
                        </Box>
                      )}

                      {transaccion.aditivos.length > 0 && (
                        <Box marginTop={4}>
                          <Typography variant="h6" gutterBottom>Aditivos</Typography>
                          <Divider style={{ marginBottom: '16px' }} />
                          {transaccion.aditivos.map((aditivo, index) => (
                            <React.Fragment key={index}>
                              <Grid container spacing={2}>
                              <Typography variant="h5" gutterBottom>Aditivo: {aditivo.p_code}</Typography>
                                {renderDetails("Codigo Del Producto", aditivo.p_code)}
                                {renderDetails("Volumen Entregado", aditivo.v_entregado)}
                                {renderDetails("Volumen GOV", aditivo.vol_gov)}
                                {renderDetails("Volumen GSV", aditivo.vol_gsv)}
                                {renderDetails("NSV", aditivo.nsv)}
                                {renderDetails("IV", aditivo.iv)}
                                {renderDetails("Presión Promedio", aditivo.pres_promedio)}
                                {renderDetails("Temperatura Promedio", aditivo.temp_promedio)}
                                {renderDetails("Densidad Promedio", aditivo.densidad_promedio)}
                                {renderDetails("Factor Medidor", aditivo.meter_factor)}
                                {renderDetails("MK Factor", aditivo.mk_factor)}
                                {renderDetails("CTL", aditivo.ctl)}
                                {renderDetails("CPL", aditivo.cpl)}
                                {renderDetails("CTPL", aditivo.ctpl)}
                                
                                {renderDetails("Gravedad API", aditivo.gravedad_api)}
                              </Grid>
                              <Divider style={{ margin: '30px 0', height: '2px', backgroundColor: '#279EFF' }} />
                            </React.Fragment>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={10}>
                  <Divider style={{ margin: '20px 0', height: '2px', backgroundColor: '#279EFF' }} />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
