import { useState } from "react";
import { getControlDocOperadorApi, addControlDocOperadorApi, updateControlDocOperadorApi, deleteControlDocOperadorApi } from "../../api/A_Operador/documentosOperadorControl";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";

export  function useControlDocOperador() {
    const { auth, logout } = useAuth();
    const navigate = useNavigate();
    const { unauthorized, SessionExpired, unauthorizedModal } = alertas();
  
    const [state, setState] = useState({
      loading: true,
      error: null,
      ControlDocOperador: null,
      ControlDocOperadorDB: null,
    });
  
    const handleResponse = (response) => {
      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
        navigate("/");
      }
    };
  
    const { loading, error, ControlDocOperador, ControlDocOperadorDB } = state;
  
    const getControlDocOperadorDB = async () => {
      try {
        const [result, response] = await getControlDocOperadorApi(auth.token, auth.tenant);
        setState((prevState) => ({ ...prevState, ControlDocOperadorDB: result.results }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, error }));
      }
    };
  
    const getControlDocOperador = async () => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await getControlDocOperadorApi(auth.token, auth.tenant);
        handleResponse(response);
        setState((prevState) => ({ ...prevState, loading: false, ControlDocOperador: result.results }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
      }
    };
  
    const addControlDocOperador = async (data) => {
     
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await addControlDocOperadorApi(data, auth.token, auth.tenant);
        if (response.status === 403) {
          throw new Error(unauthorizedModal());
        }
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
        throw error;
      }
    };
  
    const updateControlDocOperador = async (id, data) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await updateControlDocOperadorApi(id, data, auth.token, auth.tenant);
        if (response.status === 403) {
          throw new Error(unauthorizedModal());
        }
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
        throw error;
      }
    };
  
    const deleteControlDocOperador = async (id) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const [result, response] = await deleteControlDocOperadorApi(id, auth.token, auth.tenant);
        if (response.status === 403) {
          throw new Error(unauthorizedModal());
        }
        setState((prevState) => ({ ...prevState, loading: false }));
      } catch (error) {
        setState((prevState) => ({ ...prevState, loading: false, error }));
      }
    };
  
    return {
      loading,
      error,
      ControlDocOperadorDB,
      ControlDocOperador,
      getControlDocOperadorDB,
      getControlDocOperador,
      addControlDocOperador,
      updateControlDocOperador,
      deleteControlDocOperador,
    };
  }
  
