import { useState } from "react";
import {getModulosApi, updateModulosApi,updateModuloDocumentalApi } from "../../api/A_Multicliente/Modulos";
import { useAuth } from "../";
import { alertas } from "../../Components/Comons/Alertas/alertas";


export  function useModulos() {

    const { auth, logout } = useAuth();
    const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();
  
    const [state, setState] = useState({
      loading: true,
      error: null,
      Modulos: null,
 
    });
    
    const { loading, error, Modulos} = state;
    const handleResponse = (response) => {
      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
      }
    };

    const getModulos = async () => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await getModulosApi(auth.token, auth.tenant);
          handleResponse(response);
          setState((prevState) => ({ ...prevState, loading: false, Modulos: result }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
        }
      };
  
    const updateModulos = async (id, data) => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await updateModulosApi(id, data, auth.token, auth.tenant);
          if (response.status === 400) {
            errores();
          } else if (response.status === 403) {
            throw new Error(unauthorizedModal());
          }
          setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
          throw error;
        }
      };

      const updateModuloDocumental = async (id, data) => {
        try {
          setState((prevState) => ({ ...prevState, loading: true }));
          const [result, response] = await updateModuloDocumentalApi(id, data, auth.token, auth.tenant);
          if (response.status === 400) {
            errores();
          } else if (response.status === 403) {
            throw new Error(unauthorizedModal());
          }
          setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
          setState((prevState) => ({ ...prevState, loading: false, error }));
          throw error;
        }
      };


  return {
    loading,
    Modulos,
    getModulos,
    updateModuloDocumental,
    updateModulos
  }
    
  
}
