import React from 'react';
import { Tab } from 'semantic-ui-react';
import { DocumentosGUTAdmin } from '../GUTPage/DocumentosGUTAdmin';
import { OrdenesAdmin } from '../OrdenVenta';
import GUT from "./Icons/10.DOCUMENTOS-GUT-NEGRO.png";
import Ordenes from "./Icons/8.ORDENES.png";


export function CargaderoAdmin() {
  const panes = [
    {
      menuItem: { key: 'OrdenesAdmin', content: <><img src={Ordenes} alt="ordenes" /> Ordenes</> },
      render: () => <Tab.Pane><OrdenesAdmin /></Tab.Pane>,
    },
    {
      menuItem: { key: 'DocumentosGUTAdmin', content: <><img src={GUT} alt="gut" /> GUT</> },
      render: () => <Tab.Pane><DocumentosGUTAdmin /></Tab.Pane>,
    },
    
  ]

  return (
    <Tab panes={panes} />
  )
}
