import React, { useEffect, useState } from "react";
//import { Loader } from "semantic-ui-react";
import CircularProgress from '@mui/material/CircularProgress';
import { AddEditMulticliente, HeaderPage } from "../../../Components/Client";
import { ModalBasic, DataGridBasic } from "../../../Components/Comons";
import { useMulticliente } from "../../../hooks";
import Swal from "sweetalert2"
import { columns, filterValue } from "./MulticlienteFormat";

export function MulticlienteAdmin() {
  const { loading, Multicliente, getMulticliente, deleteMulticliente } = useMulticliente();
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);


  useEffect(() => {
    getMulticliente();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);


  const onRefetch = () => setRefetch((prev) => !prev);
  const openCloseModal = () => setShowModal((prev) => !prev);



  const addMulticliente = () => {
    setTitleModal("Nueva licencia");
    setContentModal(
      <AddEditMulticliente onClose={openCloseModal} onRefetch={onRefetch} />
    );
    openCloseModal();
  };

  const updateMulticliente = (data) => {
    setTitleModal("Actualizar licencia");
    setContentModal(
      <AddEditMulticliente
        onClose={openCloseModal}
        onRefetch={onRefetch}
        multicliente={data}
      />
    );
    openCloseModal();
  };


  const onDeleteMulticliente = async (data) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Estas seguro?',
      text: "No podras revertir esta accion!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMulticliente(data.id)
        onRefetch();
        swalWithBootstrapButtons.fire(
          'Eliminado!',
          'El dato a sido eliminado.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelado',
          'Operacion cancelada',
          'error'
        )
      }
    })
  };
  
  return (
    <>

      {/* <HeaderPage
        title="Licencias"
        btnTitle="Nueva licencia"
        icono="plus square icon"
        btnClick={addMulticliente}
      /> */}

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (

        <DataGridBasic
          data={Multicliente}
          title="Licencias de clientes"
          id="id"
          filterValue={filterValue}
          columns={columns}
          updateData={updateMulticliente}
          onDeleteData={onDeleteMulticliente}
        />
      )}



      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}
      />
    </>
  )
}


