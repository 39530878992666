import { useState } from "react";
import { getOrdenesDescargaderoApi, addOrdenesDescargaderoApi, updateOrdenesDescargaderoApi, deleteOrdenesDescargaderoApi, getOrdenesDescargaderoApiSlate, addOrdenesDescargaderoApiSlate } from "../../api/A_OrdenDescargadero/ordenDescargadero";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";

export function useOrdenesDescargadero() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    OrdenesDescargadero: null,
    OrdenesDescargaderoDB: null,
    OrdenesDescargaderoSlate: null,
  });

  const { loading, error, OrdenesDescargadero, OrdenesDescargaderoDB, OrdenesDescargaderoSlate  } = state;

  const getOrdenesDescargaderoDB = async () => {
    try {
      const [result, response] = await getOrdenesDescargaderoApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, OrdenesDescargaderoDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getOrdenesDescargadero = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getOrdenesDescargaderoApi(auth.token, auth.tenant);
      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
        navigate("/");
      }
      setState((prevState) => ({ ...prevState, loading: false, OrdenesDescargadero: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const getOrdenesDescargaderoFilterSlate = async (start_date, end_date) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getOrdenesDescargaderoApiSlate(start_date, end_date, auth.token, auth.tenant);
      console.log("Mis resultados de ordenes ",result);
      if (response.status === 401) {
        SessionExpired();
        logout();
      } else if (response.status === 403) {
        unauthorized();
        navigate("/");
      }
      setState((prevState) => ({ ...prevState, loading: false, OrdenesDescargaderoSlate: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addOrdenesDescargadero = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addOrdenesDescargaderoApi(data, auth.token, auth.tenant);
      if (response.status === 400) {
        if (result?.errors.n_orden) {
          throw new Error("El número de orden se ha registrado con anterioridad");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const addOrdenesDescargaderoSlate = async (data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addOrdenesDescargaderoApiSlate(data, auth.token, auth.tenant);
      if (response.status === 400) {
        if (result) {
          throw new Error("Ha ocurrido un problema");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateOrdenesDescargadero = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateOrdenesDescargaderoApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        if (result?.errors.n_orden) {
          throw new Error("El número de orden se ha registrado con anterioridad");
        } else {
          errores();
        }
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteOrdenesDescargadero = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteOrdenesDescargaderoApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    OrdenesDescargaderoDB,
    OrdenesDescargadero,
    OrdenesDescargaderoSlate,
    getOrdenesDescargaderoDB,
    getOrdenesDescargadero,
    getOrdenesDescargaderoFilterSlate,
    addOrdenesDescargadero,
    addOrdenesDescargaderoSlate,
    updateOrdenesDescargadero,
    deleteOrdenesDescargadero,
  };
}
