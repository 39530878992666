import React, { useState } from "react";
import { Tab } from 'semantic-ui-react';
import { ConfigTurnosAdmin, StartStopAccess } from "../index";

export function GroupedAccesoConfig() {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (e, { activeIndex }) => {
        setActiveTab(activeIndex);
    };

    const panes = [
        {
            menuItem: 'Configuración de acceso',
            render: () => <Tab.Pane><ConfigTurnosAdmin /></Tab.Pane>,
        },
        {
            menuItem: 'Proceso de acceso',
            render: () => <Tab.Pane><StartStopAccess /></Tab.Pane>,
        },
    ];

    return (
        <Tab 
            panes={panes} 
            activeIndex={activeTab} 
            onTabChange={handleTabChange} 
        />
    );
}
