import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

export async function getMapeosAlmacenamientoApi(token, schema) {
    const url = `${BASE_URL}${schema}/datos/almacenamientomap`;
    return makeRequest(url, "GET", token);
}

export async function postMapeosAlmacenamientoApi(data, token, schema) {
    const url = `${BASE_URL}${schema}/datos/almacenamientomap/`;
    return makeRequest(url, "POST", token, data);
}

export async function updtMapeosAlmacenamientoApi(id_maquina, data, token, schema) {
    const url = `${BASE_URL}${schema}/datos/almacenamientomap/${id_maquina}/`;
    return makeRequest(url, "PATCH", token, data)
}

export async function deleteMapeosAlmacenamientoApi(id_maquina, token, schema) {
    const url = `${BASE_URL}${schema}/datos/almacenamientomap/${id_maquina}/`;
    return makeRequest(url, "DELETE", token);
}