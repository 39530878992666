import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";
import {
    getBaseVerApi, addBaseVerApi, updateBaseVerApi, deleteBaseVerApi, getBaseVehVerApi,
    addBaseVehVerApi, updateBaseVehVerApi, deleteBaseVehVerApi
} from "../../api/A_Verificaciones/verificaciones"

export function useVerificaciones() {
    const { auth, logout } = useAuth();
    const navigate = useNavigate();
    const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

    const [state, setState] = useState({
        loading: true,
        error: null,
        Data: null,
        DataVeh: null,
        FirstData: null,
    });

    const { loading, error, Data, DataVeh, FirstData } = state;

    const handleResponse = (response) => {
        if (response.status === 401) {
            SessionExpired();
            logout();
        } else if (response.status === 403) {
            unauthorized();
            navigate("/");
        }
    };

    // const handleAddOrUpdateError = (result) => {
    //     if (result?.msg) {
    //         throw new Error(result.msg);
    //     } else {
    //         errores();
    //     }
    // };

    const getBaseVer = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getBaseVerApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, Data: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const getFirstBaseVer = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getBaseVerApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, FirstData: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    const addBaseVer = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await addBaseVerApi(data, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            if (response.status === 400) {
                throw new Error(errores())
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const updateBaseVer = async (id_ver_group, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updateBaseVerApi(id_ver_group, data, auth.token, auth.tenant);
            if (response.status === 400) {
                throw new Error(errores())
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const deleteBaseVer = async (id_ver_group) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteBaseVerApi(id_ver_group, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    const getBaseVehVer = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await getBaseVehVerApi(auth.token, auth.tenant);
            handleResponse(response);
            setState((prevState) => ({ ...prevState, loading: false, DataVeh: result }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    };

    const addBaseVehVer = async (data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await addBaseVehVerApi(data, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            if (response.status === 400) {
                throw new Error(errores())
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    };

    const updateBaseVehVer = async (id_ver_group, data) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await updateBaseVehVerApi(id_ver_group, data, auth.token, auth.tenant);
            if (response.status === 400) {
                throw new Error(errores())
            }
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
            throw error;
        }
    }

    const deleteBaseVehVer = async (id_ver_group) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const [result, response] = await deleteBaseVehVerApi(id_ver_group, auth.token, auth.tenant);
            if (response.status === 403) {
                throw new Error(unauthorizedModal());
            }
            setState((prevState) => ({ ...prevState, loading: false }));
        } catch (error) {
            setState((prevState) => ({ ...prevState, loading: false, error }));
        }
    }

    return {
        loading,
        error,
        getBaseVer,
        addBaseVer,
        updateBaseVer,
        deleteBaseVer,
        Data,
        getBaseVehVer,
        addBaseVehVer,
        updateBaseVehVer,
        deleteBaseVehVer,
        DataVeh,
        getFirstBaseVer,
        FirstData,
    }
}
