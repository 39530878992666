export const protocol_choises = [
    { text: 'Modbus TCP/IP---', value: 'MODBUS' },
    { text: 'OPC UA---', value: 'OPC' },
    { text: 'MQTT', value: 'MQTT' },
]

export const mqtt_qos_list = [
    { text: 'QoS 0---', value: 0 },
    { text: 'QoS 1---', value: 1 },
    { text: 'QoS 2', value: 2 },
]

export const variable_choises = [
    { text: 'Entero 16 Bits---', value: "int32" },
    { text: 'Flotante---', value: "float" },
    { text: 'Double', value: "double" },
]

export const tipo_variable_choices = [
    { text: 'Nivel---', value: 1 },
    { text: 'Temperatura---', value: 2 },
    { text: 'Presión---', value: 3 },
    { text: 'Densidad---', value: 4 },
    { text: 'Volumen---', value: 5 },
    { text: 'Flujo---', value: 6 },
    { text: 'Masa---', value: 7 },
    { text: 'Energía---', value: 8 },
    { text: 'Calidad---', value: 9 },
    { text: 'Nivel_Agua---', value: 10 },
    { text: 'Otros', value: 11 },
]

export const unidad_medida_choices = {
    1: [ { value: 1, text: 'm--' }, { value: 2, text: 'cm--' }, { value: 3, text: 'mm--' }, { value: 4, text: 'ft' } ],
    2: [ { value: 1, text: 'K--' }, { value: 2, text: '°C--' }, { value: 3, text: '°F' } ],
    3: [ { value: 1, text: 'Pa--' }, { value: 2, text: 'bar--' }, { value: 3, text: 'psi--' }, { value: 4, text: 'atm' } ],
    4: [ { value: 1, text: 'kg/m³--' }, { value: 2, text: 'g/cm³--' }, { value: 3, text: 'lb/ft³' } ],
    5: [ { value: 1, text: 'm³--' }, { value: 2, text: 'L--' }, { value: 3, text: 'gal' } ],
    6: [ { value: 1, text: 'm³/s--' }, { value: 2, text: 'L/min--' }, { value: 3, text: 'gal/min' } ],
    7: [ { value: 1, text: 'kg--' }, { value: 2, text: 'g--' }, { value: 3, text: 'lb' } ],
    8: [ { value: 1, text: 'J--' }, { value: 2, text: 'kWh--' }, { value: 3, text: 'cal' } ],
    9: [ { value: 1, text: '%--' }, { value: 2, text: 'ppm--' }, { value: 3, text: 'mg/L' } ],
    10: [ { value: 1, text: 'm--' }, { value: 2, text: 'cm--' }, { value: 3, text: 'mm' } ],
    11: [ { value: 1, text: 'ud' } ]
};

export const register_type_choices = [
    { text: 'Holding Register---', value: "holding" },
    { text: 'Input Register---', value: "input" },
    { text: 'Coil---', value: "coil" },
    { text: 'Discrete Input', value: "discrete" },
]