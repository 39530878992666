import { useFormik } from "formik";
import { map } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useDecimales,
  useGruposPermisos,
  useMulticliente,
  useSistemaMedicion,
  useTenants,
  useUser,
} from "../../../hooks";

import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { alertas } from "../../Comons/Alertas/alertas";

export function AddEditUserAdmin(props) {

  const { onClose, onRefetch, user } = props;
  const { Successful } = alertas();
  const { addUserAdmin, updateUserAdmin } = useUser();
  const { GruposPermisosDB, getGruposPermisosDB } = useGruposPermisos();
  const { MulticlienteDB, getMulticlienteDB } = useMulticliente();
  const { Decimales, getDecimales } = useDecimales();
  const { SistemaMedicion, getSistemaMedicion } = useSistemaMedicion();
  const { TenantsDB, getTenantsDB } = useTenants();
  const [tablasFormatPr, setTablasTFormatPr] = useState([]);
  const [multicliente, setMulticliente] = useState([]);
  const [decimales, setDecimales] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [sistemaMedicion, setSistemaMedicion] = useState([]);
  const [previewImage, setPreviewImage] = useState(user ? user?.foto : null);

  const [errors, setErrors] = useState({
    owner: false,
    tenant: false,
    SistemasMedicion: false,
    configuracion: false,
    tipo_id_pers: false,
  });

  useEffect(() => {
    getGruposPermisosDB();
    getSistemaMedicion();
    getDecimales();
    getMulticlienteDB();
    getTenantsDB();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTablasTFormatPr(formatDropdownDataPr(GruposPermisosDB));
  }, [GruposPermisosDB]);

  useEffect(() => {
    setMulticliente(formatDropdownDataM(MulticlienteDB));
  }, [MulticlienteDB]);

  useEffect(() => {
    setDecimales(formatDropdownDataD(Decimales));
  }, [Decimales]);

  useEffect(() => {
    setSistemaMedicion(formatDropdownDataSM(SistemaMedicion));
  }, [SistemaMedicion]);
  useEffect(() => {
    setTenants(formatDropdownDataTenants(TenantsDB));
  }, [TenantsDB]);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(user),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(user ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // console.log(formValue)
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (user) await updateUserAdmin(user.id_usuario, formValue);
        else await addUserAdmin(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        console.error("Error submitting form:", error); // <-- Agrega esta línea para capturar el error en consola
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const onDrop = useCallback(async (acceptedFile) => {
    const file = acceptedFile[0];
    await formik.setFieldValue("foto", file);
    setPreviewImage(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const imagenStyle = {
    width: "300px",
    margin: "0 auto",
  };

  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value?.value || "");
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };
console.log('formik', user)
  return (
    <>
      <form className="add-edit-user-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* fila 1 */}
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              options={multicliente}
              getOptionLabel={(option) => option.text || ""}
              value={
                multicliente.find(
                  (option) => option.value === formik.values.owner
                ) || null
              }
              onChange={(event, value) =>
                handleAutocompleteChange("owner", value)
              }
              renderInput={(params) => (
                <div>
                  <TextField
                    {...params}
                    label="Cliente base asociado"
                    variant="outlined"
                    placeholder="Selecciona un cliente base"
                    error={errors.owner}
                    helperText={
                      errors.owner ? "Debe seleccionar un cliente base" : ""
                    }
                  />
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              options={tenants}
              getOptionLabel={(option) => option.text || ""}
              value={
                tenants.find(
                  (option) => option.value === formik.values.tenant
                ) || null
              }
              onChange={(event, value) =>
                handleAutocompleteChange("tenant", value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Esquema de creación"
                  variant="outlined"
                  placeholder="Selecciona"
                  error={errors.tenant}
                  helperText={
                    errors.tenant
                      ? "Debe seleccionar un esquema de creación"
                      : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              options={sistemaMedicion}
              getOptionLabel={(option) => option.text || ""}
              value={
                sistemaMedicion.find(
                  (option) => option.value === formik.values.SistemasMedicion
                ) || null
              }
              onChange={(event, value) =>
                handleAutocompleteChange("SistemasMedicion", value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Unidades de medida"
                  variant="outlined"
                  placeholder="Selecciona las unidades de medida"
                  error={errors.SistemasMedicion}
                  helperText={
                    errors.SistemasMedicion
                      ? "Debe seleccionar las unidades de medida"
                      : ""
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Autocomplete
              fullWidth
              options={decimales}
              getOptionLabel={(option) => option.text || ""}
              value={
                decimales.find(
                  (option) => option.value === formik.values.configuracion
                ) || null
              }
              onChange={(event, value) =>
                handleAutocompleteChange("configuracion", value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Configuración base de decimales"
                  variant="outlined"
                  placeholder="Selecciona decimales base"
                  error={errors.configuracion}
                  helperText={
                    errors.configuracion
                      ? "Debe seleccionar la configuración base de decimales"
                      : ""
                  }
                />
              )}
            />
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              fullWidth
              options={IdentificacionOptions}
              getOptionLabel={(option) => option.text || ""}
              value={
                IdentificacionOptions.find(
                  (option) => option.value === formik.values.tipo_id_pers
                ) || null
              }
              onChange={(event, value) =>
                handleAutocompleteChange("tipo_id_pers", value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de documento"
                  variant="outlined"
                  placeholder="Tipo de documento"
                  error={errors.tipo_id_pers}
                  helperText={
                    errors.tipo_id_pers
                      ? "Debe seleccionar el tipo de documento"
                      : ""
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              required
              fullWidth
              label="Número de identificación"
              name="identificacion_pers"
              placeholder="Identificación"
              value={formik.values.identificacion_pers}
              onChange={formik.handleChange}
              error={
                formik.touched.identificacion_pers &&
                Boolean(formik.errors.identificacion_pers)
              }
              type="number"
            />
          </Grid>

          {/* fila 3 */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Nombres"
              name="nombres"
              placeholder="Nombres"
              value={formik.values.nombres}
              onChange={formik.handleChange}
              error={formik.touched.nombres && Boolean(formik.errors.nombres)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Apellidos"
              name="apellidos"
              placeholder="Apellidos"
              value={formik.values.apellidos}
              onChange={formik.handleChange}
              error={
                formik.touched.apellidos && Boolean(formik.errors.apellidos)
              }
            />
          </Grid>

          {/* fila 4 */}
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Email"
              name="email"
              placeholder="JohnDoe@mail.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              type="email"
            />
          </Grid>

          {/* fila 5 */}
          <Grid item xs={12} sm={8}>
            <Button
              type="button"
              fullWidth
              style={{
                color: formik.errors.foto ? "red" : "rgb(208, 48, 43)",
                border: "1px solid rgb(208, 48, 43)",
                "&:hover": {
                  color: "rgba(208, 48, 43, 0.62)",
                  border: "1px solid rgba(221, 223, 221, 0.62)",
                },
              }}
              {...getRootProps()}
            >
              {previewImage ? "Cambiar imagen" : "Subir imagen"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper variant="outlined">
              <input {...getInputProps()} />
              <img
                style={{
                  ...imagenStyle,
                  maxWidth: "100%",
                  maxHeight: "250px",
                }}
                src={previewImage} // <-- Agrega una imagen por defecto
                alt="Vista previa"
              />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="fecha_nacimiento"
              placeholder="Fecha de nacimiento"
              value={formik.values.fecha_nacimiento}
              onChange={formik.handleChange}
              error={
                formik.touched.fecha_nacimiento &&
                Boolean(formik.errors.fecha_nacimiento)
              }
              type="date"
              helperText="Fecha de nacimiento"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Celular"
              name="celular"
              placeholder="Celular"
              value={formik.values.celular}
              onChange={formik.handleChange}
              error={formik.touched.celular && Boolean(formik.errors.celular)}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Teléfono"
              name="telefono"
              placeholder="Teléfono"
              value={formik.values.telefono}
              onChange={formik.handleChange}
              error={formik.touched.telefono && Boolean(formik.errors.telefono)}
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Contraseña"
              name="password"
              type="password"
              placeholder="Contraseña"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_active"
                  checked={formik.values.is_active}
                  onChange={(event) =>
                    formik.setFieldValue("is_active", event.target.checked)
                  }
                  color="primary"
                />
              }
              label="Usuario activo"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_superuser"
                  checked={formik.values.is_superuser}
                  onChange={(event) =>
                    formik.setFieldValue("is_superuser", event.target.checked)
                  }
                  color="primary"
                />
              }
              label="Permisos de superusuario"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_superadmin"
                  checked={formik.values.is_superadmin}
                  onChange={(event) =>
                    formik.setFieldValue("is_superadmin", event.target.checked)
                  }
                  color="primary"
                />
              }
              label="Permisos de super administrador"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              className="btn btn-danger"
              fullWidth
              color="error"
            >
              {user ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function formatDropdownDataPr(data) {
  return map(data, (item) => ({
    key: item.id,
    text: item.name,
    value: item.id,
  }));
}
function formatDropdownDataM(data) {
  return map(data, (item) => ({
    key: item.id,
    text: item.nombre,
    value: item.id,
    image: { avatar: true, src: item.foto },
  }));
}
function formatDropdownDataD(data) {
  return map(data, (item) => ({
    key: item.id_parametrizacion,
    text: item.nombre,
    value: item.id_parametrizacion,
  }));
}
function formatDropdownDataSM(data) {
  return map(data, (item) => ({
    key: item.id_sistema,
    text: item.nombre,
    value: item.id_sistema,
  }));
}
function formatDropdownDataTenants(data) {
  return map(data, (item) => ({
    key: item.id,
    text: item.name,
    value: item.id,
  }));
}

function initialValues(data) {
  return {
    tipo_id_pers: data?.tipo_id_pers || "",
    identificacion_pers: data?.identificacion_pers || "",
    nombres: data?.nombres || "",
    apellidos: data?.apellidos || "",
    foto: null,
    email: data?.email || "",

    fecha_nacimiento: data?.fecha_nacimiento || "",
    celular: data?.celular || "",
    telefono: data?.telefono || "",
    groups: data?.groups || "",
    owner: data?.owner || "",
    tenant: data?.tenant || "",
    SistemasMedicion: data?.SistemasMedicion || "",
    configuracion: data?.configuracion || "",
    password: "",
    is_staff: data?.is_staff ? true : false,
    is_superuser: data?.is_superuser ? true : false,
    is_active: data?.is_active ? true : false,
    is_admin: data?.is_admin ? true : false,
    is_superadmin: data?.is_superadmin ? true : false,
  };
}

function newSchame() {
  return {
    tipo_id_pers: Yup.number("El dato debe ser numérico").required(
      "El tipo de identificación es obligatorio"
    ),
    identificacion_pers: Yup.string()
      .required("El Numero de identificacin es requerido")
      .matches(/^[0-9]+$/, "Deben ser dígitos")
      .min(6, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    nombres: Yup.string().required("El Nombre es requerido"),
    apellidos: Yup.string().required("Los Apellidos son requeridos"),
    foto: Yup.mixed(),
    email: Yup.string()
      .email("Ingrese un Email valido")
      .required("El Email es requerido"),

    fecha_nacimiento: Yup.date("Ingrese una fecha correcta"),

    celular: Yup.string()
      .required("El celular es requerido")
      .matches(/^[0-9]+$/, "Deben ser dígitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),

    telefono: Yup.string()
      .matches(/^[0-9]+$/, "Deben ser dígitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    owner: Yup.number().required("Campo requerido"),
    tenant: Yup.number().required("Campo requerido"),
    SistemasMedicion: Yup.number().required("Campo requerido"),
    configuracion: Yup.number().required("Campo requerido"),

    password: Yup.string().required("Contraseña requerida"),
    // is_staff: Yup.bool().required("El campo es requerido"),
    is_active: Yup.bool().required("El campo es requerido"),
    groups: Yup.array(),
    // is_admin: Yup.bool(),
    is_superuser: Yup.bool(),
    is_superadmin: Yup.bool(),
  };
}

function updateSchame() {
  return {
    tipo_id_pers: Yup.number("El dato debe ser numerico").required(
      "El tipo de identificacion es obligatorio"
    ),
    identificacion_pers: Yup.string()
      .required("El Numero de identificacion es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(6, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    nombres: Yup.string().required("El Nombre es requerido"),
    apellidos: Yup.string().required("Los Apellidos son requeridos"),
    foto: Yup.mixed(),
    email: Yup.string()
      .email("Ingrese un Email valido")
      .required("El Email es requerido"),

    fecha_nacimiento: Yup.date("Ingrese una fecha correcta"),
    celular: Yup.string()
      .required("El celular es requerido")
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    telefono: Yup.string()
      .matches(/^[0-9]+$/, "Deben ser digitos")
      .min(5, "La longitud debe ser mayor que 5")
      .max(16, "La longitud debe ser menor que 16"),
    owner: Yup.number().required("Campo requerido"),
    tenant: Yup.number().required("Campo requerido"),
    SistemasMedicion: Yup.number().required("Campo requerido"),
    configuracion: Yup.number().required("Campo requerido"),
    password: Yup.string(),
    // is_staff: Yup.bool().required("El campo es requerido"),
    is_active: Yup.bool().required("El campo es requerido"),
    groups: Yup.array(),
    // is_admin: Yup.bool(),
    is_superuser: Yup.bool(),
    is_superadmin: Yup.bool(),
  };
}

const IdentificacionOptions = [
  {
    key: 1,
    text: "Cédula de Ciudadanía",
    value: 1,
  },
  {
    key: 2,
    text: "Pasaporte",
    value: 2,
  },
 
  {
    key: 4,
    text: "Cédula de Extranjería",
    value: 4,
  },
  {
    key: 5,
    text: "Permiso Especial de Permanencia (PEP)",
    value: 5,
  },
  {
    key: 6,
    text: "Documento de Identidad Extranjero",
    value: 6,
  },
  {
    key: 7,
    text: "Salvoconducto de Permanencia",
    value: 7,
  },
];
