


    
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getIslasApi(token,schema) {
  const url = `${BASE_URL}${schema}/plantas/islas`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addIslasApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/plantas/islas/`;
  return makeRequest(url, "POST", token, data);
}


// Función para actualizar los datos 
export async function updateIslasApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/plantas/islas/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

export async function patchIslasApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/plantas/islas/${id}/`;
  return makeRequest(url, "PATCH", token, data);
}


// Función para eliminar 
export async function deleteIslasApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/plantas/islas/${id}/`;
  return makeRequest(url, "DELETE", token);
}