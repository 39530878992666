import {
  Box,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import MainCard from '../../../Components/Comons/MainCard';
import { GenerarLicencias } from '../../Licencia';
import { TenantAdmin } from '../TenantAdmin';
import { TablaModulosPage } from './TablaModulosPage';

export  function AdministracionPage() {
    

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          {/* row 1 */}
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Administración de módulos</Typography>
          </Grid>
          <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />
    
          {/* row 2 */}
          <Grid item xs={12} md={12} lg={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item></Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
              <Box sx={{ pt: 1, pr: 2 }}>
                {/* Establece el ancho de la tabla como 100% */}
                <TablaModulosPage sx={{ width: '100%' }} />
              </Box>
            </MainCard>
          </Grid>
          {/* <Grid container alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
              <Grid item xs={12} md={6}>
                <MainCard content={false}>
                  <Box sx={{ p: 3, pb: 0 }}>
                    <Stack spacing={2}>
                      <Typography variant="h6" color="textSecondary">
                        Schemas
                      </Typography>
                    </Stack>
                  </Box>
                  <TenantAdmin />
                </MainCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <MainCard content={false}>
                  <Box sx={{ p: 3, pb: 0 }}>
                    <Stack spacing={2}>
                      <Typography variant="h6" color="textSecondary">
                        Licencias
                      </Typography>
                    </Stack>
                  </Box>
                  <GenerarLicencias />
                </MainCard>
              </Grid>
            </Grid> */}
        </Grid>
      );
}
