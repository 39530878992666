import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "../";
import { getDataImpresionApi, getDataImpresionMatrizApi } from "../../api/impresiones/impresion";
import { useState } from 'react';
import Swal from 'sweetalert2';

export function useImpresiones() {
  const { auth, logout } = useAuth();
  const { unauthorized, SessionExpired } = alertas();

  const [state, setState] = useState({
    error: null,
    Impresiones: null,
    ImpresionesMatriz: null,
  });

  const { error, Impresiones, ImpresionesMatriz } = state;

  const handleResponse = (response, result) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
    } else {
      if (result.error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: result.error,
        });
      }

      if (result.message) {
        Swal.fire({
          icon: 'error',
          title: 'Transaccion',
          text: result.message,
        });
      }
    }
  };

  const getImpresiones = async (id) => {
    try {
      const [result, response] = await getDataImpresionApi(id, auth.token, auth.tenant);
      handleResponse(response, result);
      setState((prevState) => ({ ...prevState, Impresiones: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getImpresionesMatriz = async (id) => {
    try {
      const [result, response] = await getDataImpresionMatrizApi(id, auth.token, auth.tenant);
      handleResponse(response, result);
      setState((prevState) => ({ ...prevState, ImpresionesMatriz: result }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  return {
    error,
    Impresiones,
    ImpresionesMatriz,
    getImpresiones,
    getImpresionesMatriz
  };
}
