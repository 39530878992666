import React, { useCallback, useState } from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';


export function Funciones() {

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const openCloseModal = useCallback(() => setShowModal((prev) => !prev), []);
  const onRefetch = useCallback(() => setRefetch((prev) => !prev), []);
  const renderButton = (rowData, abrirExcel) => (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        onClick={() => abrirExcel(rowData.data.documento)}
        color="primary"
        style={{
          width: "40px",
          height: "40px",
          padding: "5px"
        }}
      >
        <DownloadIcon style={{ fontSize: "20px", color: "black" }} />
      </IconButton>
    </Box>
  );
  
  const abrirExcel = (link) => {
    window.open(link, "_blank");
  };
  


  const columns = [
   
    {
      header: "Numero de orden",
      name: "numero_orden",
      maxWidth: 1000, defaultFlex: 1
    },
    
    {
      header: "GUT",
      render: (rowData) => renderButton(rowData, abrirExcel),
      maxWidth: 1000,
      defaultFlex: 1
    },
    
    
    
  ];



  

  return {
    abrirExcel,
    openCloseModal,
    columns,
    refetch,
    contentModal,
    titleModal,
    showModal,
  };
}
