// Description: Componet for display detail information of the cargadero and descargadero

import React from "react";

import { Text } from "../../modules/despatch/components/CardDetailDespatch/styles";
import {
  BadgeDataCard,
  ContainerData,
  ContainerDataInfo,
  ContainerInfo,
  ContainerText,
} from "./styles";
import { theme } from "../../theme";

export const DataCard = ({
  title,
  dataOneTitle,
  dataOneInfo,
  dataOneUnit,
  dataTwoTitle,
  dataTwoInfo,
  dataTwoUnit,
  dataThreeTitle,
  dataThreeInfo,
  dataThreeUnit,
  dataFourTitle,
  dataFourInfo,
  dataFourUnit,
  dataFiveTitle,
  dataFiveInfo,
  dataFiveUnit,
  dataSixTitle,
  dataSixInfo,
  dataSixUnit,
  dataSevenTitle,
  dataSevenInfo,
  dataSevenUnit,
  dataEighthTitle,
  dataEighthInfo,
  dataEighthUnit,
}) => {
  return (
    <ContainerData>
      <Text
        uppercase
        color={theme.default.primary.gray3}
        margin={"4px 0"}
        fontSize={18}
      >
        {title}
      </Text>
      <ContainerDataInfo>
        <ContainerInfo>
          {dataOneTitle && (
            <ContainerText>
              <Text
                uppercase
                color={theme.default.primary.black2}
                fontSize={14}
                margin={"0"}
                textAlign={"end"}
              >
                {dataOneTitle}
              </Text>
              <BadgeDataCard>{dataOneInfo}</BadgeDataCard>
              <Text margin={"0"} fontSize={14}>
                {dataOneUnit}
              </Text>
            </ContainerText>
          )}
        </ContainerInfo>

        <ContainerInfo>
          {dataTwoTitle && (
            <ContainerText>
              <Text
                uppercase
                color={theme.default.primary.black2}
                fontSize={14}
                margin={"0"}
                textAlign={"end"}
              >
                {dataTwoTitle}
              </Text>
              <BadgeDataCard>{dataTwoInfo}</BadgeDataCard>
              <Text margin={"0"} fontSize={14}>
                {dataTwoUnit}
              </Text>
            </ContainerText>
          )}
        </ContainerInfo>

        <ContainerInfo>
          {dataThreeTitle && (
            <ContainerText>
              <Text
                uppercase
                color={theme.default.primary.black2}
                fontSize={14}
                margin={"0"}
                textAlign={"end"}
              >
                {dataThreeTitle}
              </Text>
              <BadgeDataCard>{dataThreeInfo}</BadgeDataCard>
              <Text margin={"0"} fontSize={14}>
                {dataThreeUnit}
              </Text>
            </ContainerText>
          )}
        </ContainerInfo>

        <ContainerInfo>
          {dataFourTitle && (
            <ContainerText>
              <Text
                uppercase
                color={theme.default.primary.black2}
                fontSize={14}
                margin={"0"}
                textAlign={"end"}
              >
                {dataFourTitle}
              </Text>
              <BadgeDataCard>{dataFourInfo}</BadgeDataCard>
              <Text margin={"0"} fontSize={14}>
                {dataFourUnit}
              </Text>
            </ContainerText>
          )}
        </ContainerInfo>

        <ContainerInfo>
          {dataFiveTitle && (
            <ContainerText>
              <Text
                uppercase
                color={theme.default.primary.black2}
                fontSize={14}
                margin={"0"}
                textAlign={"end"}
              >
                {dataFiveTitle}
              </Text>
              <BadgeDataCard>{dataFiveInfo}</BadgeDataCard>
              <Text margin={"0"} fontSize={14}>
                {dataFiveUnit}
              </Text>
            </ContainerText>
          )}
        </ContainerInfo>

        <ContainerInfo>
          {dataSixTitle && (
            <ContainerText>
              <Text
                uppercase
                color={theme.default.primary.black2}
                fontSize={14}
                margin={"0"}
                textAlign={"end"}
              >
                {dataSixTitle}
              </Text>
              <BadgeDataCard>{dataSixInfo}</BadgeDataCard>
              <Text margin={"0"} fontSize={14}>
                {dataSixUnit}
              </Text>
            </ContainerText>
          )}
        </ContainerInfo>

        <ContainerInfo>
          {dataSevenTitle && (
            <ContainerText>
              <Text
                uppercase
                color={theme.default.primary.black2}
                fontSize={14}
                margin={"0"}
                textAlign={"end"}
              >
                {dataSevenTitle}
              </Text>
              <BadgeDataCard>{dataSevenInfo}</BadgeDataCard>
              <Text margin={"0"} fontSize={14}>
                {dataSevenUnit}
              </Text>
            </ContainerText>
          )}
        </ContainerInfo>
        <ContainerInfo>
          {dataEighthTitle && (
            <ContainerText>
              <Text
                uppercase
                color={theme.default.primary.black2}
                fontSize={14}
                margin={"0"}
                textAlign={"end"}
              >
                {dataEighthTitle}
              </Text>
              <BadgeDataCard>{dataEighthInfo}</BadgeDataCard>
              <Text margin={"0"} fontSize={14}>
                {dataEighthUnit}
              </Text>
            </ContainerText>
          )}
        </ContainerInfo>
      </ContainerDataInfo>
    </ContainerData>
  );
};