import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useConciliaciones, useProductoBase, useTanques } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import dayjs from 'dayjs';

export function AddEditCorrecciones(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, Conciliaciones } = props;
  const { addConciliacionesCierre, updateConciliaciones, getConciliacionesDataCierre, ConciliacionesDataCierre } = useConciliaciones();
  const { ProductoBaseDB, getProductoBaseDB } = useProductoBase();
  const { tanquesDB, getTanquesDB } = useTanques();

  useEffect(() => {
    getProductoBaseDB();
    getTanquesDB();
  }, []);

  const formik = useFormik({
    initialValues: initialValues(Conciliaciones),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (Conciliaciones)
          await updateConciliaciones(
            Conciliaciones.id_conciliaciones,
            formValue
          );
        else await addConciliacionesCierre(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });


  useEffect(() => {
    const selectedTank = tanquesDB?.find(tank => tank.id_tanque === formik.values.id_tanque);

    if (selectedTank) {
      formik.setFieldValue("id_producto", selectedTank.id_producto);
    } else {
      formik.resetForm();
    }
  }, [formik.values.id_tanque, tanquesDB]);


  useEffect(() => {
    if (formik.values.id_tanque) {
      getConciliacionesDataCierre(formik.values.id_tanque);
    }
  }, [formik.values.id_tanque]);

  useEffect(() => {
    if (ConciliacionesDataCierre) {
      formik.setFieldValue("fecha_inicial", dayjs(ConciliacionesDataCierre[0]?.fecha_inicio).format('YYYY-MM-DD') || "");
      formik.setFieldValue("volumen_inicial", ConciliacionesDataCierre[0]?.volumen_inicial || "");
      formik.setFieldValue("volumen_ventas", ConciliacionesDataCierre[0]?.despachado || "");
      formik.setFieldValue("volumen_recargas", ConciliacionesDataCierre[0]?.recibido || "");
      formik.setFieldValue("correcciones", ConciliacionesDataCierre[0]?.correcciones || "");
    }
  }, [ConciliacionesDataCierre]);

  return (
    <>
      <form className="add-edit-persona-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              id="id_tanque"
              options={tanquesDB}
              getOptionLabel={(option) => `${option?.nombre_tanque}`}
              value={
                tanquesDB?.find(
                  (data) => data.id_tanque === formik.values?.id_tanque
                ) || null
              }
              onChange={(_, value) =>
                formik.setFieldValue("id_tanque", value?.id_tanque || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tanque"
                  variant="outlined"
                  error={
                    formik.touched.id_tanque && Boolean(formik.errors.id_tanque)
                  }
                  helperText={
                    formik.touched.id_tanque && formik.errors.id_tanque
                  }
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="Producto"
              name="id_producto"
              value={ProductoBaseDB?.find(producto => producto.id === formik.values.id_producto)?.nombre || ''}
              InputProps={{
                readOnly: true,
              }}
              error={
                formik.touched.id_producto && Boolean(formik.errors.id_producto)
              }
              helperText={
                formik.touched.id_producto && formik.errors.id_producto
              }
              sx={{ background: "#fcfcfc" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              name="fecha_inicial"
              placeholder="Fecha inicial"
              value={formik.values.fecha_inicial}
              onChange={formik.handleChange}
              error={formik.errors.fecha_inicial}
              type="date"
              disabled
              helperText="Fecha inicial de la conciliación"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              name="fecha_final"
              placeholder="Fecha final"
              value={formik.values.fecha_final}
              onChange={formik.handleChange}
              error={formik.errors.fecha_final}
              type="date"
              helperText="Fecha final de la conciliación"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Volumen inicial"
              name="volumen_inicial"
              placeholder="Volumen inicial"
              value={formik.values.volumen_inicial}
              onChange={formik.handleChange}
              error={formik.errors.volumen_inicial}
              type="number"
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Volumen vendido"
              name="volumen_ventas"
              placeholder="Volumen ventas"
              value={formik.values.volumen_ventas}
              onChange={formik.handleChange}
              error={formik.errors.volumen_ventas}
              type="number"
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              label="Volumen recargado"
              name="volumen_recargas"
              placeholder="Volumen recargas"
              value={formik.values.volumen_recargas}
              onChange={formik.handleChange}
              error={formik.errors.volumen_recargas}
              type="number"
              readOnly
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              required
              label="Correcciones"
              name="correcciones"
              placeholder="Correcciones"
              value={formik.values.correcciones}
              onChange={formik.handleChange}
              error={formik.errors.correcciones}
              type="number"
              readOnly
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              color="error"
              className="btn btn-danger"
              fullWidth
            >
              {Conciliaciones ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function initialValues(data) {
  return {
    fecha_inicial: data?.fecha_inicial || "",
    fecha_final: data?.fecha_final || "",
    id_tanque: data?.id_tanque || "",
    id_producto: data?.id_producto || "",
    volumen_inicial: data?.volumen_inicial || "",
    volumen_ventas: data?.volumen_ventas || "",
    volumen_recargas: data?.volumen_recargas || "",
    correcciones: data?.correcciones || "",
  };
}

function newSchema() {
  return {
    fecha_inicial: Yup.date().required(true),
    fecha_final: Yup.date().required(true),
    id_tanque: Yup.number().required(true),
    id_producto: Yup.number().required(true),
    volumen_inicial: Yup.number().required(true),
    volumen_ventas: Yup.number().required(true),
    volumen_recargas: Yup.number().required(true),
    correcciones: Yup.number().required(true),
  };
}