import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { TextField, Button, Grid, Box } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import format from 'date-fns/format';
import { useOrdenes } from '../../../../hooks';
import { DataGridBasic } from '../../../Comons';
import CircularProgress from '@mui/material/CircularProgress';
import { Funciones } from '../../../../pages/Client/OrdenVenta/OrdenesPage/Funciones';

const columns = [
  {
    header: "Numero de orden",
    name: "orden_no",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Volumen preset",
    name: "volum_preset",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Volumen acumulado",
    name: "volum_accumulated",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Volumen remanente",
    name: "volum_remaining",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Index del producto",
    name: "product_index",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Index del preset",
    name: "preset_index",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Compartimento",
    name: "compartment",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "BSW",
    name: "bsw",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "API",
    name: "api",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "¿Está autorizado?",
    name: "authorize",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Razon de autorización",
    name: "authorize_reason",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Acceso desde",
    name: "access_from",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Acceso a",
    name: "access_to",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Fecha de acceso desde",
    name: "access_date_from",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Fecha de acceso a",
    name: "access_date_to",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Dias de acceso",
    name: "access_days",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Estado de la orden",
    name: "o_state",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Número de placa",
    name: "vehicle",
    minWidth: 150,
    defaultFlex: 1,
  },
  {
    header: "Cédula del conductor",
    name: "driver_no",
    minWidth: 150,
    defaultFlex: 1,
  }
];

const DateRangeForm = () => {
  const { loading, getOrdenesCargaderoFilterSlate, OrdenesCargaderoSlate } = useOrdenes();
  const { handleExportSlateData } = Funciones();


  const formik = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
    },
    onSubmit: async (values) => {
      const formattedStartDate = values.startDate ? format(values.startDate, "yyyy-MM-dd HH:mm:ss") : null;
      const formattedEndDate = values.endDate ? format(values.endDate, "yyyy-MM-dd HH:mm:ss") : null;
      await getOrdenesCargaderoFilterSlate(formattedStartDate, formattedEndDate);
    }

  });

  return (
  <>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            selected={formik.values.startDate}
            onChange={(date) => formik.setFieldValue('startDate', date)}
            showTimeSelect
            dateFormat="yyyy-MM-dd HH:mm:ss"
            customInput={
              <TextField
                label="Fecha Inicial"
                fullWidth
                value={formik.values.startDate ? format(formik.values.startDate, "yyyy-MM-dd HH:mm:ss") : ''}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            selected={formik.values.endDate}
            onChange={(date) => formik.setFieldValue('endDate', date)}
            showTimeSelect
            dateFormat="yyyy-MM-dd HH:mm:ss"
            customInput={
              <TextField
                label="Fecha Final"
                fullWidth
                value={formik.values.endDate ? format(formik.values.endDate, "yyyy-MM-dd HH:mm:ss") : ''}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" >
              Filtrar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>

    {loading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    ) : (
      
      <DataGridBasic
        title="Ordenes Cargadero"
        data={OrdenesCargaderoSlate}
        columns={columns}
        exportSlateData={handleExportSlateData}
      />
    )}
  </>
  );
};

export default DateRangeForm;