import { useFormik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useTransportadora } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";

import { Button } from '@material-ui/core';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';

export function AddEditTransportadora(props) {
  /*
  Componente

  -componente de visualizacion del modal de edicion y creacion para Transportadora
  
  -se crea y valida mediante formik y yup
  -Se obtienen datos para dropdown mediante hooks
  -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
   */
  const { onClose, onRefetch, Transportadora } = props;
  const { Successful } = alertas();
  const { addTransportadora, updateTransportadora } = useTransportadora();

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Transportadora),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(newSchema()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (Transportadora)
          await updateTransportadora(
            Transportadora.id_transportadora,
            formValue
          );
        else await addTransportadora(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });


  const divStyle = {
    textAlign: "left",
  };

  return (
    <>
      <form className="add-edit-secciones-form" style={divStyle} onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* fila 1 */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="NIT de Transportadora"
              name="nit_transportadora"
              placeholder="NIT"
              value={formik.values.nit_transportadora}
              onChange={formik.handleChange}
              error={
                formik.errors.nit_transportadora &&
                Boolean(formik.errors.nit_transportadora)
              }
              helperText={
                formik.touched.nit_transportadora && formik.errors.nit_transportadora
              }
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Razón social transportadora"
              name="nombre_transportadora"
              placeholder="Razón social"
              value={formik.values.nombre_transportadora}
              onChange={formik.handleChange}
              
              error={
                formik.errors.nombre_transportadora &&
                Boolean(formik.errors.nombre_transportadora)
              }
              helperText={
                formik.touched.nombre_transportadora && formik.errors.nombre_transportadora
              }
              required
            />
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Email"
              name="id_empresa.email"
              placeholder="Empresa@mail.com"
              value={formik.values?.id_empresa.email}
              onChange={formik.handleChange}
            
              error={
                formik.errors.email &&
                Boolean(formik.errors.email)
              }
              helperText={
                formik.touched.email && formik.errors.email
              }
              required
            />
          </Grid>

          {/* fila 2 */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Celular de contacto"
              name="id_empresa.celular"
              placeholder="celular"
              value={formik.values?.id_empresa?.celular}
              onChange={formik.handleChange}
              
              error={
                formik.errors?.id_empresa?.celular &&
                Boolean(formik.errors?.id_empresa?.celular)
              }
              helperText={
                formik.touched?.id_empresa?.celular && formik.errors?.id_empresa?.celular
              }
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Teléfono de contacto"
              name="id_empresa.telefono"
              placeholder="Teléfono"
              value={formik.values?.id_empresa.telefono}
              onChange={formik.handleChange}
              error={formik.errors.id_empresa?.telefono}
              
              type="number"
             
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Área"
              name="id_empresa.area"
              placeholder="Área"
              value={formik.values?.id_empresa.area}
              onChange={formik.handleChange}
              error={formik.errors.id_empresa?.area}
              
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              variant="contained"
              className="btn btn-danger"
              fullWidth
            >
              {Transportadora ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}



function initialValues(data) {
  return {
    nit_transportadora: data?.nit_transportadora || "",
    nombre_transportadora: data?.nombre_transportadora || "",
    id_empresa: {
      // nombre: data?.id_empresa.nombre || "",
      email: data?.id_empresa?.email || "",
      celular: data?.id_empresa?.celular || "",
      telefono: data?.id_empresa?.telefono || "",
      area: data?.id_empresa?.area || "",
    },
  };
}

function newSchema() {
  return {
    nit_transportadora: Yup.number().required(true),
    nombre_transportadora: Yup.string().required(true),
    id_empresa: Yup.object({
      email: Yup.string()
        .email("Ingrese un Email valido")
        .required("El campo Email es requerido"),
      celular: Yup.string()
        .required("El campo Celular es requerido")
        .matches(/^[0-9]+$/, "Deben ser digitos")
        .min(8, "La longitud debe ser mayor que 8")
        .max(16, "La longitud debe ser menor que 16"),
      telefono: Yup.number("El dato debe ser numerico"),
      area: Yup.string(),
    }),
  };
}


