import { useState } from "react";
import { getControlDocVehiculosApi, addControlDocVehiculosApi, updateControlDocVehiculosApi, deleteControlDocVehiculosApi } from "../../api/A_vehiculos/documentosVehiculosControl";
import { useNavigate } from "react-router-dom";
import { alertas } from "../../Components/Comons/Alertas/alertas";
import { useAuth } from "..";


export function useControlDocVehiculos() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    ControlDocVehiculos: null,
    ControlDocVehiculosDB: null,
  });

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const { loading, error, ControlDocVehiculos, ControlDocVehiculosDB } = state;

  const getControlDocVehiculosDB = async () => {
    try {
      const [result, response] = await getControlDocVehiculosApi(auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, ControlDocVehiculosDB: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };

  const getControlDocVehiculos = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await getControlDocVehiculosApi(auth.token, auth.tenant);
      handleResponse(response);
      setState((prevState) => ({ ...prevState, loading: false, ControlDocVehiculos: result.results }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  const addControlDocVehiculos = async (data) => {
   
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await addControlDocVehiculosApi(data, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const updateControlDocVehiculos = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateControlDocVehiculosApi(id, data, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  const deleteControlDocVehiculos = async (id) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await deleteControlDocVehiculosApi(id, auth.token, auth.tenant);
      if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
    }
  };

  return {
    loading,
    error,
    ControlDocVehiculosDB,
    ControlDocVehiculos,
    getControlDocVehiculosDB,
    getControlDocVehiculos,
    addControlDocVehiculos,
    updateControlDocVehiculos,
    deleteControlDocVehiculos,
  };
}
