import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from '@mui/material/styles';
import React, { useState } from "react";

import {
  ControlMedicion,
  ModuloControl,
  ModuloControlConductor,
  ModuloControlOperadores,
  ModuloControlTransportadora,
  ModuloControlVehiculos,
} from "../../../Components/Client";

export function ControlDocumentalAdmin() {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    overflow: 'auto',

  }));

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Item><ModuloControl /></Item>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Paper style={{ height: '600px' }}>
            <Tabs value={selectedTab} onChange={handleTabChange} centered>
              <Tab label="Vehículos" />
              <Tab label="Transportadora" />
              <Tab label="Operadores" />
              <Tab label="Conductores" />
            </Tabs>

            {selectedTab === 0 && <ModuloControlVehiculos />}
            {selectedTab === 1 && <ModuloControlTransportadora />}
            {selectedTab === 2 && <ModuloControlOperadores />}
            {selectedTab === 3 && <ModuloControlConductor />}
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <Item><ControlMedicion /></Item>
        </Grid> */}

        {/* <Grid item xs={8}>
          <Item xs={8}></Item>
        </Grid> */}
      </Grid>
    </>
  );
}
