import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import {
  AddEditValidacion,
  HeaderPage,
} from "../../../Components/Client";
import { DataGridBasic, ModalBasic } from "../../../Components/Comons";
import { useValidaciones } from "../../../hooks";
import { columns, filterValue } from "./ValidacionesFormat";

export function Validaciones() {
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const { loading, Validaciones, getValidaciones } = useValidaciones();
  useEffect(() => {
    getValidaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);

  const updateDatos = (data) => {
    setTitleModal("Actualizar parametrización");
    setContentModal(
      <AddEditValidacion
        onClose={openCloseModal}
        onRefetch={onRefetch}
        Datos={data}
      />
    );
    openCloseModal();
  };

  return (
    <>
      <HeaderPage title="Configuración Validaciones" icono="plus square icon" />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGridBasic
          descarga={1}
          data={Validaciones}
          columns={columns}
          id="id"
          filterValue={filterValue}
          title="Parametrización de Validaciones"
          updateData={updateDatos}
        />
      )}

      <ModalBasic
        show={showModal}
        onClose={openCloseModal}
        title={titleModal}
        children={contentModal}

      />
    </>
  );
}
