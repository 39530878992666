import React, { useCallback } from "react";
import { Input, Modal, Form } from "antd";
import { ContainerInput } from "./styles";
import { useFormik } from "formik";
import * as yup from "yup";

// Define validation schema
const validationSchema = yup.object().shape({
  veryHigh: yup
    .number()
    .when("high", (high, schema) => (high ? schema.min(high, "Muy Alto debe ser mayor que Alto") : schema)),
  high: yup
    .number()
    .when("low", (low, schema) => (low ? schema.min(low, "Alto debe ser mayor que Bajo") : schema)),
  low: yup.number().required("Bajo es requerido"),
  veryLow: yup
    .number()
    .when("low", (low, schema) => (low ? schema.max(low, "Muy Bajo debe ser menor que Bajo") : schema)),
});

export const ModalsAlerts = ({
  modalLevel,
  modalTemp,
  modalFlow,
  open,
  setOpen,
  alarm,
  setAlarm,
  formCompleted,
}) => {
  const modalAlamrsTitle = useCallback(() => {
    if (modalLevel) {
      return "ALARMAS NIVEL DE PRODUCTO";
    } else if (modalTemp) {
      return "ALARMAS TEMPERATURA DE PRODUCTO";
    } else if (modalFlow) {
      return "ALARMAS FLUJO DE PRODUCTO";
    }
  }, [modalFlow, modalLevel, modalTemp]);

  const formik = useFormik({
    initialValues: alarm,
    validationSchema,
    onSubmit: (values) => {
      setOpen(false);
      setAlarm(values);
    },
  });

  return (
    <Modal
      title={modalAlamrsTitle()}
      open={open}
      onOk={formik.handleSubmit}
      onCancel={() => {
        setOpen(false);
        setAlarm({
          veryHigh: "",
          high: "",
          low: "",
          veryLow: "",
        });
      }}
      centered
    >
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        {formCompleted && (
          <ContainerInput>
            <Form.Item
              label="Muy Alto"
              validateStatus={formik.errors.veryHigh && formik.touched.veryHigh ? "error" : ""}
              help={formik.errors.veryHigh && formik.touched.veryHigh ? formik.errors.veryHigh : null}
            >
              <Input
                id="veryHigh"
                name="veryHigh"
                type="number"
                min="0"
                value={formik.errors.veryHigh && formik.touched.veryHigh ? (formik.values.high + 2):formik.values.veryHigh}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </ContainerInput>
        )}
        <ContainerInput>
          <Form.Item
            label="Alto"
            validateStatus={formik.errors.high && formik.touched.high ? "error" : ""}
            help={formik.errors.high && formik.touched.high ? formik.errors.high : null}
          >
            <Input
              id="high"
              name="high"
              type="number"
              min="0"
              value={formik.errors.high && formik.touched.high ? (formik.values.low + 2):formik.values.high}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </ContainerInput>
        <ContainerInput>
          <Form.Item
            label="Bajo"
            validateStatus={formik.errors.low && formik.touched.low ? "error" : ""}
            help={formik.errors.low && formik.touched.low ? formik.errors.low : null}
          >
            <Input
              id="low"
              name="low"
              type="number"
              min="0"
              value={formik.values.low}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </ContainerInput>
        {formCompleted && (
          <ContainerInput>
            <Form.Item
              label="Muy Bajo"
              validateStatus={formik.errors.veryLow && formik.touched.veryLow ? "error" : ""}
              help={formik.errors.veryLow && formik.touched.veryLow ? formik.errors.veryLow : null}
            >
              <Input
                id="veryLow"
                name="veryLow"
                type="number"
                min="0"
                value={formik.errors.veryLow && formik.touched.veryLow ? (formik.values.low - 1): formik.values.veryLow}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </ContainerInput>
        )}
      </Form>
    </Modal>
  );
};
